import { FrontpageModule } from './../frontpage/frontpage.module';
import { RibbonModule } from './../shared/ribbon/ribbon.module';
import { TranslateModule } from '@ngx-translate/core';
import { PageLoaderModule } from 'app/shared/pageloader/pageloader.module';
import { MailticketService } from './contact/mailticket.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { MenuModule } from 'app/shared/menu/menu.module';
import { TrustpilotModule } from 'app/shared/trustpilot/trustpilot.module';
import { TopbarModule } from 'app/shared/topbar/topbar.module';
import { FooterModule } from 'app/shared/footer/footer.module';
import { NavigationService } from 'app/utils/navigation/navigation.service';
import { ContexService } from 'app/utils/contex/contex.service';
import { CustomModalModule } from 'app/shared/custommodal/custommodal.module';
import { ReCaptchaModule } from 'angular2-recaptcha';
import { AboutNextComponent } from './aboutnext/components/aboutnext/aboutnext.component';
import { OnlineInsuranceComponent } from './onlineinsurance/components/onlineinsurance/onlineinsurance.component';
import { PressComponent } from './press/components/press/press.component';
import { NewCustomerComponent } from './newcustomer/components/newcustomer/newcustomer.component';
import { WhyPeopleChangeComponent } from './whypeoplechange/components/whypeoplechange/whypeoplechange.component';
import { TermsComponent } from './terms/components/terms/terms.component';
import { JobInNextComponent } from './jobinnext/components/jobinnext/jobinnext.component';
import { AgencyComponent } from './agency/components/agency/agency.component';
import { ReportDamageComponent } from './reportdamage/components/reportdamage/reportdamage.component';
import { PrivacyCookieComponent } from './privacy/components/privacycookie/privacycookie.component';
import { VerifyemailComponent } from './verifyemail/components/verifyemail/verifyemail.component';
import { ContactComponent } from './contact/components/contact/contact.component';
import { ComplaintsManagerComponent } from './complaintsmanager/components/complaintsmanager/complaintsmanager.component';
import { DisclaimerComponent } from './disclaimer/components/disclaimer/disclaimer.component';
import { SelfcareLoginComponent } from './selfcarelogin/components/selfcare-login.component';
import { TrustpilotTermsComponent } from './trustpilotterms/components/trustpilot-terms/trustpilot-terms.component';
import { FaqModule } from '../shared/faq/faq.module';
import { AnswerQuestionComponent } from './answerquestion/components/answerquestion/answer-question.component';
import { ManualpaymentComponent } from './manualpayment/components/manualpayment/manualpayment.component';
import { LandingComponent } from './landing/landing/landing.component';
import { EmaillandingComponent } from './emaillanding/emaillanding/emaillanding.component';
import { DocumentService } from 'app/selfcare/document.service';
import { DownloaddocumentComponent } from './terms/components/downloaddocument/downloaddocument.component';
import { LoginModule } from 'app/shared/login/login.module';
import { ProductInformationComponent } from './productinformation/components/productinformation/productinformation.component';
import { ExpiredBasketModule } from 'app/shared/expired-basket/expired-basket.module';
import { PagenotfoundComponent } from './pagenotfound/components/pagenotfound/pagenotfound.component';
import { PayWithMobilePayComponent } from './paywithmobilepay/pay-with-mobilepay.component';
import { EmployeesComponent } from './employees/components/employees/employees.component';
import { EmployeeboxComponent } from './employees/components/employeebox/employeebox.component';
import { PaymentCollectorService } from 'app/selfcare/payments/paymentcollector.service';
import { BlogPreviewsPageComponent } from './blog/components/blogPreviewsPage/blog-previews-page.component';
import { BlogContentComponent } from './blog/components/blogContent/blog-content.component';
import { BlogPostPageComponent } from './blog/components/blogPostPage/blog-post-page.component';
import { BlogsOverviewComponent } from './blog/components/blogsOverview/blogs-overview.component';
import { KoerselvferieComponent } from './koerselvferie/koerselvferie/koerselvferie.component';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { CustomPipeModule } from 'app/class/pipe/custompipe.module';
import { VacancyComponent } from './jobinnext/components/vacancy/vacancy.component';
import { SqueezepageComponent } from './landing/squeezepage/squeezepage.component';
import { InputModule } from 'app/shared/input/input.module';
import { ConsentComponent } from './consent/components/consent/consent.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        MenuModule,
        TrustpilotModule,
        TopbarModule,
        FooterModule,
        CustomModalModule,
        ReCaptchaModule,
        AccordionModule.forRoot(),
        FaqModule,
        LoginModule,
        ExpiredBasketModule,
        PageLoaderModule,
        PaginationModule.forRoot(),
        TranslateModule,
        RibbonModule,
        CustomPipeModule,
        InputModule,
        FrontpageModule,
    ],
    declarations: [
        AboutNextComponent,
        OnlineInsuranceComponent,
        PressComponent,
        NewCustomerComponent,
        WhyPeopleChangeComponent,
        TermsComponent,
        ProductInformationComponent,
        JobInNextComponent,
        AgencyComponent,
        EmployeesComponent,
        ReportDamageComponent,
        ContactComponent,
        ComplaintsManagerComponent,
        DisclaimerComponent,
        PrivacyCookieComponent,
        VerifyemailComponent,
        TrustpilotTermsComponent,
        SelfcareLoginComponent,
        AnswerQuestionComponent,
        ManualpaymentComponent,
        LandingComponent,
        EmaillandingComponent,
        DownloaddocumentComponent,
        PagenotfoundComponent,
        PayWithMobilePayComponent,
        EmployeeboxComponent,
        BlogPreviewsPageComponent,
        BlogContentComponent,
        BlogPostPageComponent,
        BlogsOverviewComponent,
        KoerselvferieComponent,
        VacancyComponent,
        SqueezepageComponent,
        ConsentComponent,
    ],
    providers: [NavigationService, ContexService, MailticketService, DocumentService, PaymentCollectorService],
})
export class PageModule {}
