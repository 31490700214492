import { TelemetryService } from '../telemetry/telemetry.service';
import { Observable } from 'rxjs';
import * as sourceStackTrace from 'sourcemapped-stacktrace';
import { Injectable } from '@angular/core';

@Injectable()
export class ObservableHelper {
    constructor(private telemetryService: TelemetryService) {}

    public CreateFailedObservableAndLogStackTrace(message: string): Observable<any> {
        const minifiedStack = new Error().stack;
        const observable = new Observable((observer) => {
            sourceStackTrace.mapStackTrace(minifiedStack, (stackTrace: string[]) => {
                this.telemetryService.logTrace(
                    message,
                    {
                        stackTrace,
                    },
                    3
                );
                observer.error();
                observer.complete();
            });
        });

        return observable;
    }
}
