import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, TemplateRef, OnDestroy } from '@angular/core';
import { NavigationService } from 'app/utils/navigation/navigation.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { MetaTagsHelper } from 'app/utils/metaTags/metaTagsHelper';
import { BaxService } from 'app/utils/bax/bax.service';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    moduleId: module.id,
    selector: 'next-frontpage',
    templateUrl: './frontpage.component.html',
    styleUrls: ['./frontpage.component.less'],
})
export class FrontpageComponent implements OnInit, OnDestroy {
    bsModalRef: BsModalRef;

    loadingProduct = '';

    subscriptions: Subscription[] = [];
    vacantPositionsText: string;
    calculateCarUrl: string;
    constructor(
        public navigationService: NavigationService,
        private modalService: BsModalService,
        private metaTagsHelper: MetaTagsHelper,
        private baxService: BaxService,
        private translateService: TranslateService
    ) {
        this.metaTagsHelper.setTitleAndMetaTags('META.FRONTPAGE');
    }

    ngOnInit(): void {
        const basketId = Cookie.get('basketid');

        this.calculateCarUrl = this.navigationService.getCarCalculateUrl();

        this.vacantPositionsText = this.translateService.instant('FRONTPAGE.VACANT_POSITIONS');
        if (basketId) {
            // Check and handle if the basket has expired
            const subscription = this.baxService.basketGet(basketId).subscribe({
                error: (error) => {
                    if (error instanceof HttpErrorResponse && error.status === 404) {
                        Cookie.delete('basketid');
                    }
                },
            });

            this.subscriptions.push(subscription);
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    openModal(template: TemplateRef<any>) {
        this.bsModalRef = this.modalService.show(template);
    }

    setLoadingProduct(product: string) {
        this.loadingProduct = product;
    }
}
