<div>
    <div class="full-width-image-section jobinnext-top">
        <div>
            <next-topbar></next-topbar>
            <next-menu></next-menu>
            <div class="container white">
                <div class="row">
                    <div class="col-sm-12 text-center">
                        <h1>{{ 'PAGE.BLOG.TITLE' | translate }}</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container container-padding-40-40">
        <div class="column-left col-xs-12 col-md-9">
            <div *ngFor="let preview of blogPostPreviews">
                <div class="row">
                    <blog-content [html]="preview.content" [created]="preview.created" [online]="preview.online" [url]="preview.url"></blog-content>
                    <a class="read-article-button btn btn-success" [routerLink]="preview.url">{{ 'PAGE.BLOG.READ_ARTICLE' | translate }}</a>
                </div>
                <div class="row">
                    <br />
                    <br />
                    <hr />
                    <br />
                    <br />
                </div>
            </div>
            <div class="pagination-container">
                <pagination
                    *ngIf="postCount > 5"
                    previousText="<"
                    nextText=">"
                    itemsPerPage="5"
                    [totalItems]="postCount"
                    [(ngModel)]="page"
                    (pageChanged)="onPageChanged($event)"
                ></pagination>
            </div>
        </div>
        <div class="column-right hidden-xs col-xs-12 col-md-3 pull-right padding-right-0 padding-left-0">
            <blogs-overview></blogs-overview>
        </div>
    </div>
    <next-footer></next-footer>
</div>
