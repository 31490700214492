import { Injectable, OnDestroy } from '@angular/core';
import { Subject, Observable, Subscription } from 'rxjs';
import { Message } from './message.model';
import { AlertType } from './alert-type.model';
import { Router, NavigationStart } from '@angular/router';

@Injectable()
export class MessageService implements OnDestroy {
    subscriptions: Subscription[] = [];

    private subject = new Subject<Message>();

    constructor(private router: Router) {
        this.subscriptions.push(
            this.router.events.subscribe((event) => {
                if (event instanceof NavigationStart) {
                    // clear alert messages
                    this.clear();
                }
            })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    getMessage(): Observable<Message> {
        return this.subject.asObservable();
    }

    error(text: string) {
        this.subject.next({ text, alertType: AlertType.Error } as Message);
    }

    alert(text: string, alertType: AlertType) {
        this.subject.next({ text, alertType } as Message);
    }

    clear() {
        this.subject.next();
    }
}
