<div class="container">
    <div class="row">
        <div class="col-sm-12 text-center cover-header">
            <h2>{{ 'INSURANCE.DOG.COMPONENTS.COVER.TITLE' | translate }}</h2>
        </div>
    </div>

    <div class="row">
        <div class="col-sm-4 text-center">
            <div class="insurance-container">
                <a [routerLink]="navigationService.getUrl('cover-dog-liability')">
                    <div class="ribbon-parent insurance-box insurance-img img-dog-liability">
                        <ribbon [type]="'required'"></ribbon>
                        <div class="icon-circle center">
                            <div class="icon-wrap">
                                <i class="nf nf-dog-ansvar"></i>
                            </div>
                        </div>
                    </div>
                    <div class="insurance-bottom-box">
                        <h2>{{ 'INSURANCE.DOG.COMPONENTS.COVER.LIABILITY' | translate }}</h2>
                        <p class="readmore">{{ 'SHARED.READ_MORE' | translate }}</p>
                    </div>
                </a>
            </div>
        </div>

        <div class="col-sm-4 text-center">
            <div class="insurance-container">
                <a [routerLink]="navigationService.getUrl('cover-dog-extended')">
                    <div class="ribbon-parent insurance-box insurance-img img-dog-extended">
                        <ribbon [type]="'optional'"></ribbon>
                        <div class="icon-circle center">
                            <div class="icon-wrap">
                                <i class="nf nf-dog-udvidet-ansvar"></i>
                            </div>
                        </div>
                    </div>
                    <div class="insurance-bottom-box">
                        <h2>{{ 'INSURANCE.DOG.COMPONENTS.COVER.EXTENDED' | translate }}</h2>
                        <p class="readmore">{{ 'SHARED.READ_MORE' | translate }}</p>
                    </div>
                </a>
            </div>
        </div>

        <div class="col-sm-4 text-center">
            <div class="insurance-container">
                <a [routerLink]="navigationService.getUrl('cover-dog-sickness')">
                    <div class="ribbon-parent insurance-box insurance-img img-dog-sickness">
                        <ribbon [type]="'optional'"></ribbon>
                        <div class="icon-circle center">
                            <div class="icon-wrap">
                                <i class="nf nf-dog-hundesyge"></i>
                            </div>
                        </div>
                    </div>
                    <div class="insurance-bottom-box">
                        <h2>{{ 'INSURANCE.DOG.COMPONENTS.COVER.SICKNESS' | translate }}</h2>
                        <p class="readmore">{{ 'SHARED.READ_MORE' | translate }}</p>
                    </div>
                </a>
            </div>
        </div>
    </div>

    <h2 class="text-center">{{ 'INSURANCE.SHARED.CHOOSE_COVERS' | translate }}</h2>

    <cover-table productCode="dog"></cover-table>
    <br />
    <br />

    <div class="row">
        <div class="col-sm-12 text-center">
            <a class="btn btn-success bottom-spacer-big" [routerLink]="navigationService.getUrl('calculate-dog')" rel="nofollow">
                {{ 'INSURANCE.DOG.COMPONENTS.COVER.CALCULATE' | translate | uppercase }}
            </a>
        </div>
    </div>
</div>
