import { Address } from 'app/class/address';
import { Cover } from 'app/class/cover';
import { CoverSelect } from 'app/class/cover-select';

export class Home {
    usageCode: string;
    roofTypeCode: string;
    livingArea?: number;
    ownerAge?: number;
    numberOfResidents?: number;
    deductibleId: string;
    address: Address;
    covers: CoverSelect[];

    isBBRChecked: boolean;
    adoptAddressAndAge = true;
    eventDate?: Date;

    constructor() {
        this.usageCode = '';
        this.roofTypeCode = '';
        this.deductibleId = '';
        this.address = {
            addressText: '',
            addressId: '',
        };
        this.isBBRChecked = false;
    }
}
