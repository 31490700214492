export class Cover {
    name: string;
    code: string;
    additionalCodes?: string[];
    showDescription?: boolean;
    description?: string;
    conditions?: CoverCondition[];
    requiresCoverCodes?: string[];
    pricePeriod?: number;
    pricePeriodDisplay?: string;
    priceAnnual?: number;
    priceAnnualDisplay?: string;
    sum?: number;
    specialText?: string;
    chosen: boolean;
    required: boolean;
    hidePrice?: boolean;
    disabled?: boolean;
    icon: string;
    state?: string;
    item?: string;
}

export class CoverCondition {
    code: CoverConditionCode;
    type: CoverConditionType;
    show: boolean;
    text: string;
    acceptButtonText: string;
    denyButtonText: string;
}

export enum CoverConditionType {
    add = 1,
    remove = 2,
}

export enum RibbonType {
    required = 'required',
    optional = 'optional',
    travel = 'travel',
}

export enum CoverConditionCode {
    accidentRiskSportHighSum = 1,
    carRemoveHull = 2,
    carAddGlassNoHull = 3,
    carRemoveHullNew = 4,
    houseAddExpandedCover = 5,
    houseAddRot = 6,
    houseRemoveRot = 7,
    houseRemoveOutdoorPipes = 8,
    houseRemoveFungusInsect = 9,
    houseRemoveFungusInsectOnlyRot = 10,
    houseRemovePipesCables = 11,
    homeAddGlassSanitation = 12,
    homeRemoveTravelInsurance = 13,
    homeAddCancellation = 14,
    carAddNoDeductibleNoHull = 16,
    carAddParkingDamageNoHull = 17,
    carAddPlusCoverNoHull = 18,
}

export class CoverConditionAnswer {
    answer: boolean;
    code: CoverConditionCode;
}
