import { TopbarModule } from './../../shared/topbar/topbar.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContexpageComponent } from './components/contexpage/contexpage.component';
import { FooterModule } from 'app/shared/footer/footer.module';
import { PageLoaderModule } from 'app/shared/pageloader/pageloader.module';
import { MenuModule } from 'app/shared/menu/menu.module';
import { TranslateModule } from '@ngx-translate/core';
import { CustomPipeModule } from 'app/class/pipe/custompipe.module';

@NgModule({
    declarations: [ContexpageComponent],
    imports: [CommonModule, MenuModule, TopbarModule, FooterModule, PageLoaderModule, TranslateModule, CustomPipeModule],
})
export class ContexpageModule {}
