import { Person } from 'app/class/bax/person';
import { Address } from 'app/class/bax/address';
import { RkiRegistered } from 'next-typescript-api';

export class CustomerAdditionalInfo {
    acceptNewsletter: boolean;
    terminatedOrRestrictedConditionsInfo: TerminatedOrRestrictedConditionsInfoItem;
    rki: RkiRegistered;
}

export class TerminatedOrRestrictedConditionsInfoItem {
    terminatedOrRestrictedConditions: boolean;
    terminatedOrRestrictedConditionsText: string;
    formerInsuranceCompanyName: string;
}

export class UpdateBasketCustomer {
    customer: Person;
    address: Address;
    email: string;
    phoneNumber: string;
    existingCustomerNumber: string;
    data = new CustomerAdditionalInfo();
    relation: Person;
}
