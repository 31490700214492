<div *ngIf="isCoveragePage && pageScrolledDown" id="side-container">
    <div class="basket-icon-container">
        <i class="nf nf-basket-icon"></i>
    </div>
    <div class="price-container">
        <div *ngIf="!calculating">
            <span class="product-name">{{ productName }} {{ 'SHARED.COMMON.FOR' | translate }}</span>
            <br /><span class="price">{{ priceResult?.pricePeriod?.display }} {{ 'SHARED.PRICE_PR_MONTH' | translate }}</span>
        </div>
        <div *ngIf="calculating">
            <div class="circle-loader center green thin"></div>
        </div>
    </div>
</div>
