import { TranslationService } from './../../../../utils/translation/translation.service';
import { Component, OnInit, Input } from '@angular/core';
import { AppConfigService } from 'app/utils/appconfig/app-config.service';
import { TelemetryService } from 'app/utils/telemetry/telemetry.service';

@Component({
    selector: 'language-selector',
    templateUrl: './language-selector.component.html',
    styleUrls: ['./language-selector.component.less'],
})
export class LanguageSelectorComponent implements OnInit {
    @Input() mini = false;
    showOptions = false;
    languages: string[] = [];
    currentLanguage: string;
    enabled = false;

    constructor(private translationService: TranslationService, private config: AppConfigService, private telemetryService: TelemetryService) {}

    ngOnInit() {
        this.enabled = this.config.appConfig.multiLanguageSite;

        this.updateCurrentLanguage();
        this.languages = this.translationService.getLanguages();

        this.translationService.getLanguageChangeEmitter().subscribe(x => {
            this.currentLanguage = x.lang;
        });
    }

    updateCurrentLanguage() {
        this.currentLanguage = this.translationService.getSelectedLanguage();
    }

    toggleShowOptions() {
        this.showOptions = !this.showOptions;
    }

    setLanguage(languageCode: string) {
        this.translationService.setLanguage(languageCode);
        this.showOptions = false;
        this.telemetryService.logEvent('Language set to ' + languageCode);
        this.updateCurrentLanguage();
    }
}
