import { Injectable, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { environment } from 'environments/environment';

declare let dataLayer: any[];
// eslint-disable-next-line @typescript-eslint/naming-convention
declare let Cookiebot: any;

@Injectable()
export class PageChangeTracker implements OnDestroy {
    subscriptions: Subscription[] = [];
    numPageEvents = 0;

    constructor(private router: Router) {
        if (environment.production === 'false') {
            return;
        }

        const routeChangeSubscription = router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
            this.numPageEvents += 1;

            // strip url parameters
            const page = event.url.split('?')[0];

            // Track with Google Tag manager
            dataLayer = dataLayer || [];
            // Only trigger page events after first page view, because GTM recieves a page event from CookieBot, when cookies are accepted
            if (this.numPageEvents !== 1) {
                dataLayer.push({ event: 'page', pageName: page });
            }

            if (!Cookiebot.consent.marketing || !Cookiebot.consent.statistics) {
                return;
            }
        });

        this.subscriptions.push(routeChangeSubscription);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }
}
