<div id="aboutnext">
    <div class="full-width-image-section about-top">
        <div class="arrow-up">
            <next-topbar></next-topbar>
            <next-menu></next-menu>

            <div class="container white">
                <div class="row">
                    <div class="col-sm-12 text-center">
                        <h1>{{ 'PAGE.ABOUT_NEXT.TITLE' | translate }}</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container container-padding-20-40">
        <div class="row">
            <div class="col-sm-12" [innerHTML]="contex?.content | safehtml"></div>
        </div>
    </div>

    <div class="full-width-image-section ehandelbox">
        <div class="col-lg-9">
            <div class="header">{{ 'PAGE.ABOUT_NEXT.AWARD_TITLE' | translate | uppercase }}</div>
            <div class="text" [innerHTML]="'PAGE.ABOUT_NEXT.AWARD_TEXT' | translate"></div>
            <br />
            <div>
                <a class="btn btn-success linkNextBlog" [routerLink]="navigationService.getUrl('blog')">{{ 'SHARED.READ_MORE' | translate }}</a>
            </div>
        </div>
    </div>

    <div class="gray-bg text-center">
        <div class="container container-padding-20-40">
            <h2>{{ 'PAGE.ABOUT_NEXT.MENTIONED_BY' | translate }}</h2>
            <img src="https://static.next.dk/website/images/pages/about/media-coverage.png" class="img-responsive center" />
            <br />
            <br />
            <a [routerLink]="navigationService.getUrl('press')" class="btn btn-success btn-small">{{ 'PAGE.ABOUT_NEXT.MEDIA_SAYS' | translate }}</a>
        </div>
    </div>

    <trustpilot-carousel></trustpilot-carousel>

    <next-footer></next-footer>
</div>
