export class BasketDetails {
    commitErrorCode: number;
    commitErrorDescription: string;
    commitRequested: string;
    created: string;
    expires: string;
    orderId: number;
    publicId: string;
    data: any;
    externalReference: string;
}
