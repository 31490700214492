import { Routes } from '@angular/router';
import { IndexComponent } from './components/index/index.component';
import { CoverInfoComponent } from '../components/cover-info/cover-info.component';
import { CoverInfoSettings } from '../components/cover-info/CoverInfoSettings';

export const caravanRoutes: Routes = [
    { path: '', component: IndexComponent },
    { path: 'kasko', component: CoverInfoComponent, data: new CoverInfoSettings('caravan', 'hull', 'nf nf-caravan-kasko') },
    { path: 'indbo', component: CoverInfoComponent, data: new CoverInfoSettings('caravan', 'home', 'nf nf-caravan-indbo') },
    { path: 'vejhjaelp-campingvogn', component: CoverInfoComponent, data: new CoverInfoSettings('caravan', 'road', 'nf nf-caravan-vejhjlp') },
];
