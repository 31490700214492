import { Routes } from '@angular/router';
import { IndexComponent } from './components/index/index.component';
import { CoverInfoComponent } from '../components/cover-info/cover-info.component';
import { CoverInfoSettings } from '../components/cover-info/CoverInfoSettings';
import { ContexpageComponent } from '../contexpage/components/contexpage/contexpage.component';

export const accidentRoutes: Routes = [
    { path: '', component: IndexComponent },
    { path: 'heltidsulykkesforsikring', component: CoverInfoComponent, data: new CoverInfoSettings('accident', 'full', 'nf nf-accident-fuldtidsdkning') },
    { path: 'fritidsdsulykkesforsikring', component: CoverInfoComponent, data: new CoverInfoSettings('accident', 'leisure', 'nf nf-accident-fritidsdkning') },
    { path: 'boerneforsikring', component: CoverInfoComponent, data: new CoverInfoSettings('accident', 'child', 'nf nf-accident-brnedkning') },
    { path: 'behandlingsforsikring', component: CoverInfoComponent, data: new CoverInfoSettings('accident', 'treatment', 'nf nf-accident-behandlingsudgifter') },
    { path: 'invaliditetsforsikring', component: CoverInfoComponent, data: new CoverInfoSettings('accident', 'disability', 'nf nf-accident-invaliditetsdkning') },
    { path: 'tandforsikring', component: CoverInfoComponent, data: new CoverInfoSettings('accident', 'dental', 'nf nf-accident-tandskade') },
    { path: 'doedsfaldsdaekning', component: CoverInfoComponent, data: new CoverInfoSettings('accident', 'death', 'nf nf-accident-ddsfaldsdkning') },
    { path: 'dobbelt-kompensation', component: CoverInfoComponent, data: new CoverInfoSettings('accident', 'double', 'nf nf-accident-dobbelt-kompensation') },
    { path: 'foerer-af-mc-eu-knallert', component: CoverInfoComponent, data: new CoverInfoSettings('accident', 'bike', 'nf nf-accident-motorcykel') },
    { path: 'farlig-sport-forsikring', component: CoverInfoComponent, data: new CoverInfoSettings('accident', 'sport', 'nf nf-accident-farlig-sport') },
    // Contex Pages
    { path: 'mengrad', component: ContexpageComponent },
];
