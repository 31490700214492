<div *ngIf="enabled">
    <div (click)="toggleShowOptions()" id="language-dropdown" [class.mini]="mini">
        <img
            [src]="'https://static.next.dk/website/images/pages/languages/' + currentLanguage + '.png'"
            [srcset]="'https://static.next.dk/website/images/pages/languages/' + currentLanguage + '@2x.png 2x'"
        />
        <span *ngIf="!mini" class="language-name">{{ 'SHARED.LANGUAGES.' + currentLanguage.replace('-', '_').toUpperCase() | translate }}</span>
        <img *ngIf="mini" class="down-arrow" src="https://static.next.dk/website/images/pages/languages/down-arrow-white.svg" />
        <img *ngIf="!mini" class="down-arrow" src="https://static.next.dk/website/images/pages/languages/down-arrow-black.svg" />
    </div>

    <div *ngIf="showOptions" id="language-options" [class.mini]="mini">
        <div *ngFor="let language of languages">
            <div *ngIf="language !== currentLanguage" class="language-option" (click)="setLanguage(language)">
                <img
                    [src]="'https://static.next.dk/website/images/pages/languages/' + language + '.png'"
                    [srcset]="'https://static.next.dk/website/images/pages/languages/' + language + '@2x.png 2x'"
                />
                <span class="language-name">{{ 'SHARED.LANGUAGES.' + language.replace('-', '_').toUpperCase() | translate }}</span>
            </div>
        </div>
    </div>
</div>
