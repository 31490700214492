import { Component, TemplateRef } from '@angular/core';
import { NavigationService } from 'app/utils/navigation/navigation.service';
import { MetaTagsHelper } from 'app/utils/metaTags/metaTagsHelper';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

@Component({
    moduleId: module.id,
    selector: 'whypeoplechange',
    templateUrl: './whypeoplechange.component.html',
    styleUrls: ['./whypeoplechange.component.less'],
})
export class WhyPeopleChangeComponent {
    bsModalRef: BsModalRef;
    constructor(public navigationService: NavigationService, private metaTagsHelper: MetaTagsHelper, private modalService: BsModalService) {
        this.metaTagsHelper.setTitleAndMetaTags('META.WHY_PEOPLE_CHANGE');
    }

    openModal(template: TemplateRef<any>) {
        this.bsModalRef = this.modalService.show(template);
    }
}
