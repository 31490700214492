<div class="full-width-image-section iphone-next-top">
    <next-topbar></next-topbar>
    <next-menu></next-menu>

    <div class="container white">
        <h1>
            {{ 'PAGE.PAY_WITH_MOBILEPAY.TITLE' | translate }}
        </h1>

        <div class="row">
            <div class="link-box">
                <h3>{{ 'PAGE.PAY_WITH_MOBILEPAY.ALREADY_CUSTOMER_TITLE' | translate }}</h3>
                <p>
                    {{ 'PAGE.PAY_WITH_MOBILEPAY.ALREADY_CUSTOMER_TEXT_1' | translate }}
                </p>
                <p>
                    {{ 'PAGE.PAY_WITH_MOBILEPAY.ALREADY_CUSTOMER_TEXT_2' | translate }}
                </p>

                <button type="button" class="btn btn-success" (click)="goToSelfservice()">
                    {{ 'PAGE.PAY_WITH_MOBILEPAY.GO_TO_SELFCARE' | translate }}
                </button>
            </div>

            <div class="link-box">
                <h3>{{ 'PAGE.PAY_WITH_MOBILEPAY.NOT_CUSTOMER_TITLE' | translate }}</h3>
                <p>
                    {{ 'PAGE.PAY_WITH_MOBILEPAY.NOT_CUSTOMER_TEXT_1' | translate }}
                </p>
                <br />
                <p>
                    {{ 'PAGE.PAY_WITH_MOBILEPAY.NOT_CUSTOMER_TEXT_2' | translate }}
                </p>

                <button type="button" class="btn btn-success" (click)="goToCalculateInsurances()">
                    {{ 'PAGE.PAY_WITH_MOBILEPAY.CALCULATE_INSURANCES' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>

<br />
<br />
<div class="container container-padding-40-40">
    <h1>
        {{ 'PAGE.PAY_WITH_MOBILEPAY.WHY_CHOOSE_MOBILEPAY' | translate }}
    </h1>

    <div class="row">
        <img class="another-iphone" alt="Phone image" />

        <ul class="bullets">
            <li>
                {{ 'PAGE.PAY_WITH_MOBILEPAY.BULLET_1' | translate }}
            </li>
            <li>
                {{ 'PAGE.PAY_WITH_MOBILEPAY.BULLET_2' | translate }}
            </li>
            <li>
                {{ 'PAGE.PAY_WITH_MOBILEPAY.BULLET_3' | translate }}
            </li>
            <li>
                {{ 'PAGE.PAY_WITH_MOBILEPAY.BULLET_4' | translate }}
            </li>
            <li>
                {{ 'PAGE.PAY_WITH_MOBILEPAY.BULLET_5' | translate }}
            </li>
        </ul>
    </div>

    <h1>
        {{ 'PAGE.PAY_WITH_MOBILEPAY.FAQ' | translate }}
    </h1>

    <div class="bg-gray padding-30-0-50">
        <next-faq category="FAQ_MOBILEPAY"></next-faq>
    </div>
</div>
<next-footer></next-footer>
