import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { MenuComponent } from './components/menu/menu.component';
import { SelfcareMenuComponent } from './components/selfcaremenu/selfcaremenu.component';
import { NavigationService } from 'app/utils/navigation/navigation.service';
import { LeftmenuComponent } from './components/leftmenu/leftmenu.component';
import { CustomerService } from 'app/selfcare/personal/customer.service';
import { LanguageSelectorModule } from '../language-selector/language-selector.module';

@NgModule({
    imports: [CommonModule, RouterModule, FormsModule, TranslateModule, LanguageSelectorModule],
    declarations: [MenuComponent, SelfcareMenuComponent, LeftmenuComponent],
    exports: [MenuComponent, SelfcareMenuComponent, LeftmenuComponent],
    providers: [NavigationService, CustomerService],
})
export class MenuModule {}
