import { RegisterRequestInput } from 'next-typescript-api';
import { PersonNameInput } from './../../../calculate/summary/models/personNameInput';
import { Component, OnInit } from '@angular/core';
import { Calltime } from 'app/calculate/summary/models/calltime';
import { TranslateService } from '@ngx-translate/core';
import { ContactRequestService } from 'app/utils/contactrequest/contactrequest.service';
import { TokenStorage } from 'app/auth/token-storage.service';
import { NavigationService } from 'app/utils/navigation/navigation.service';

declare let dataLayer: any[];
@Component({
    selector: 'squeezepage',
    templateUrl: './squeezepage.component.html',
    styleUrls: ['./squeezepage.component.less', '../../../../styles/app/calculate.less'],
})
export class SqueezepageComponent implements OnInit {
    name: string;
    phone: string;
    calltime: Calltime = new Calltime();
    calltimes: Calltime[] = [];
    okToSend = false;
    sending = false;
    createRequestSuccess = false;
    errorMessage: string;
    selecttime = 'select';

    constructor(
        public navigationService: NavigationService,
        private tokenStorage: TokenStorage,
        private contactRequestService: ContactRequestService,
        private translateService: TranslateService
    ) {}

    ngOnInit(): void {
        this.calltimes = this.contactRequestService.getCallTimeOptions();
        this.calltime = this.calltimes[0]; // Add default non selectable value to support mobile dropdown

        this.validate();
    }

    sendContactRequest() {
        this.validate();
        if (!this.okToSend) {
            return;
        }

        const nameInput: PersonNameInput = new PersonNameInput();
        const tokens = this.name.split(' ');
        nameInput.firstName = tokens[0];
        if (tokens.length > 1) {
            nameInput.surName = '';
            for (let index = 1; index < tokens.length; index++) {
                nameInput.surName += tokens[index] + ' ';
            }
            nameInput.surName = nameInput.surName.trim();
        }
        const leadCampaignId = this.tokenStorage.getLeadCampaignId();
        const data: RegisterRequestInput = {
            phoneNo: this.phone,
            callTimeText: this.calltime.text,
            name: nameInput,
            campaignId: leadCampaignId,
            reasonId: '1', // Sales
            sourceId: 'squeezepage',
        };

        console.log(data);

        this.sending = true;
        this.contactRequestService.registerContactRequest(data).subscribe(
            (resp) => {
                if (resp) {
                    this.createRequestSuccess = true;

                    // Track contact request with Google Tag Manager
                    dataLayer = dataLayer || [];
                    dataLayer.push({ event: 'formSubmission', pageName: 'squeezePageForm' });
                } else {
                    this.sending = false;
                    this.errorMessage = this.translateService.instant('SHARED.TOPBAR.COMPONENTS.CALLME_MODAL.ERROR');
                }
            },
            (err) => {
                console.log(err);
                this.sending = false;
                this.errorMessage = this.translateService.instant('SHARED.TOPBAR.COMPONENTS.CALLME_MODAL.ERROR');
            }
        );
    }

    validate() {
        if (!this.name || this.name.length === 0) {
            this.okToSend = false;
            return;
        }

        if (!this.phone || this.phone.length !== 8) {
            this.okToSend = false;
            return;
        }

        if (!this.calltime) {
            this.okToSend = false;
            return;
        }

        if (this.calltime.value === this.selecttime) {
            this.okToSend = false;
            return;
        }

        this.okToSend = true;
    }
}
