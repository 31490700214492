<div class="box" *ngIf="!loading">
    <div class="quotationmarks">“</div>
    <a target="_blank" [href]="'https://dk.trustpilot.com/reviews/' + review.id" class="review-text">“{{ review.text }}“</a>

    <div class="logo-and-stars">
        <img class="tp-logo" src="https://static.next.dk/website/images/logos/trustpilot-logo-white.svg" />
        <img class="stars" [src]="review.starsImageUrl" />
        <!-- No translation below to look like other Trustpilot Widgets -->
        <div class="review-type-disclaimer">Viser vores favoritanmeldelser</div>
    </div>
    <div class="customer-name">{{ review.customerName }}</div>
</div>
