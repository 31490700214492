import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'thousandSeperatorFloatDisplay',
})
export class ThousandSeperatorFloatDisplayPipe implements PipeTransform {
    transform(val: number): string {
        if (!val && val !== 0) {
            return '';
        }

        // val.toLocaleString is not supported by safari
        return val
            .toFixed(2)
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1T')
            .replace('.', ',')
            .replace(/T/g, '.');
    }
}
