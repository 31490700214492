import { Observable, of, Subscription, BehaviorSubject } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from 'app/utils/appconfig/app-config.service';
import { AuthService } from 'app/auth/auth.service';
import { CustomerTaskDetailsExtend } from 'app/class/nextapiextend/CustomerTaskDetailsExtend';
import { ObservableHelper } from 'app/utils/observableHelper/observableHelper';
import { SelfcareService } from '../selfcare.service';

@Injectable({
    providedIn: 'root',
})
export class CustomerTaskService implements OnDestroy {
    public tasksChanged = new BehaviorSubject<CustomerTaskDetailsExtend[]>(null);

    private apiUrl: string;
    private tasksData: CustomerTaskDetailsExtend[];
    private tasksObservable: Observable<CustomerTaskDetailsExtend[]>;
    private subscriptions: Subscription[] = [];

    constructor(private http: HttpClient, private config: AppConfigService, private auth: AuthService, private observableHelper: ObservableHelper, private selfcareService: SelfcareService) {
        this.apiUrl = this.config.appConfig.apiHost;
        const subscription = this.auth.clearTokenObserveable.subscribe(this.invalidateCache.bind(this));
        this.subscriptions.push(subscription);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    invalidateCache() {
        this.tasksData = null;
    }

    refreshTasks(): Observable<CustomerTaskDetailsExtend[]> {
        this.tasksData = null;
        const obs = this.getTasks();
        obs.subscribe(); // cache tasks
        return obs;
    }

    getTasks(): Observable<CustomerTaskDetailsExtend[]> {
        const customerId = this.auth.getCustomerId();

        if (!customerId) {
            return this.observableHelper.CreateFailedObservableAndLogStackTrace('Missing customer number');
        }

        if (this.tasksData) {
            return of(this.tasksData);
        } else if (this.tasksObservable) {
            return this.tasksObservable;
        } else {
            const url = `${this.apiUrl}/customers/${customerId}/tasks?api-version=2.0`;

            this.tasksObservable = this.http
                .get<CustomerTaskDetailsExtend[]>(url, {
                    observe: 'response',
                })
                .pipe(
                    map((response) => {
                        this.tasksObservable = null;
                        if (response.status === 200) {
                            this.tasksData = response.body;
                            this.tasksChanged.next(this.tasksData);
                            return this.tasksData;
                        }

                        throw new Error('Failed to get tasks');
                    }),
                    share()
                );
            return this.tasksObservable;
        }
    }
}
