import { Component, Input, OnInit, AfterViewInit, ViewEncapsulation, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    moduleId: module.id,
    selector: 'next-videomodal',
    templateUrl: './videomodal.component.html',
    styleUrls: ['./videomodal.component.less'],
    encapsulation: ViewEncapsulation.None, // allows us to style modal
})
export class VideoModalComponent implements OnInit, AfterViewInit {
    title: string;
    closeBtnName: string;

    @Input() videoid: string;
    @Input() bsModalRef: BsModalRef;

    constructor(@Inject(PLATFORM_ID) private platformId: any) {}

    ngOnInit() {}

    ngAfterViewInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            const url = 'https://www.youtube.com/embed/' + this.videoid + '?autoplay=1&showinfo=0&rel=0&loop=1';

            const player = <HTMLIFrameElement>document.getElementById('player-iframe');
            if (player != null) {
                player.src = url;
            }
        }
    }
}
