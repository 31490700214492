import { Address } from 'app/class/address';
import { Cover } from 'app/class/cover';
import { CoverSelect } from 'app/class/cover-select';

export class Moped {
    usageCode?: number;
    brand: string;
    address: Address;
    covers: CoverSelect[];
    adoptAddress = true;
    eventDate?: Date;

    constructor() {
        this.usageCode = null;
        this.brand = null;
        this.address = new Address();
    }
}
