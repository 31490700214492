import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { AuthService } from 'app/auth/auth.service';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { Injector, Injectable } from '@angular/core';
import { TokenStorage } from './token-storage.service';
import { tap } from 'rxjs/operators';

@Injectable()
export class AuthRequestInterceptor implements HttpInterceptor {
    router: Router;
    refreshTokenInProgress = false;
    refreshTokenMinutesLeft = 5;

    constructor(private inj: Injector, private tokenStorage: TokenStorage) {}

    addHeader(request: HttpRequest<any>): HttpRequest<any> {
        // "Request header field Authorization is not allowed by Access-Control-Allow-Headers in preflight response"
        // is thrown if we call an endpoint with our Authorization - we should only send it when neeeded!
        if (request.url.indexOf('_apis') > 0) {
            // only add Authorization to header when we call our own api
            const token = this.tokenStorage.getAccessToken();
            if (token) {
                return request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${token}`,
                    },
                });
            }
        }

        return request;
    }

    refreshToken() {
        if (!this.refreshTokenInProgress) {
            this.refreshTokenInProgress = true;
            const auth = this.inj.get(AuthService);
            return auth
                .refreshToken()
                .then(res => {
                    this.refreshTokenInProgress = false;
                })
                .catch(res => {
                    this.refreshTokenInProgress = false;
                    return throwError(res);
                });
        }
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        request = this.addHeader(request);

        return next.handle(request).pipe(
            tap(response => {
                // We need to skip intersecting the request for config.json, because auth.service isn't ready. Also disregard third party requests.
                if (request.url.indexOf('config.json') !== -1 || request.url.indexOf('_apis') === -1) {
                    return;
                }

                const auth = this.inj.get(AuthService);

                if (auth.isAuthenticated() && auth.getExpirationDate().getTime() - new Date().getTime() < this.refreshTokenMinutesLeft * 60 * 1000) {
                    this.refreshToken();
                }
            })
        );
    }
}
