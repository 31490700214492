import { ReviewService } from './../../utils/review/review.service';
import { TrustpilotMiniComponent } from './components/trustPilotMini/trustpilot-mini.component';
import { TrustpilotCarouselLightComponent } from './components/trustpilotCarouselLight/trustpilot-carousel-light.component';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TrustpilotCarouselComponent } from './components/trustpilotCarousel/trustpilot-carousel.component';
import { TrustpilotquotesComponent } from './components/trustpilotquotes/trustpilotquotes.component';
import { PageLoaderModule } from '../pageloader/pageloader.module';
import { TrustpilotQuotesboxComponent } from './components/trustpilot-quotesbox/trustpilot-quotesbox.component';

@NgModule({
    imports: [RouterModule, CommonModule, FormsModule, PageLoaderModule],
    declarations: [TrustpilotCarouselComponent, TrustpilotCarouselLightComponent, TrustpilotquotesComponent, TrustpilotMiniComponent, TrustpilotQuotesboxComponent],
    exports: [TrustpilotCarouselComponent, TrustpilotCarouselLightComponent, TrustpilotquotesComponent, TrustpilotMiniComponent, TrustpilotQuotesboxComponent],
    providers: [ReviewService],
})
export class TrustpilotModule {}
