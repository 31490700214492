import { Routes } from '@angular/router';
import { IndexComponent } from './components/index/index.component';
import { CoverInfoComponent } from '../components/cover-info/cover-info.component';
import { CoverInfoSettings } from '../components/cover-info/CoverInfoSettings';

export const dogRoutes: Routes = [
    { path: '', component: IndexComponent },
    { path: 'hundeansvarsforsikring', component: CoverInfoComponent, data: new CoverInfoSettings('dog', 'liability', 'nf nf-dog-ansvar') },
    { path: 'udvidet-hundeforsikring', component: CoverInfoComponent, data: new CoverInfoSettings('dog', 'extended', 'nf nf-dog-udvidet-ansvar') },
    { path: 'hundesyge', component: CoverInfoComponent, data: new CoverInfoSettings('dog', 'sickness', 'nf nf-dog-hundesyge') },
];
