<div class="container">
    <div class="row">
        <div class="col-sm-12 text-center cover-header">
            <h2>{{ 'INSURANCE.CAR.COMPONENTS.COVER.TITLE' | translate }}</h2>
        </div>
    </div>

    <div class="row">
        <div *ngFor="let c of covers" class="col-sm-4 text-center">
            <cover-thumbnail [cover]="c"></cover-thumbnail>
        </div>
    </div>

    <h2 class="text-center">{{ 'INSURANCE.SHARED.CHOOSE_COVERS' | translate }}</h2>

    <cover-table [productCode]="productCode"></cover-table>
    <br />
    <br />

    <div class="row">
        <div class="col-sm-12 text-center">
            <a class="btn btn-success bottom-spacer-big calculate-insurance-btn" [routerLink]="calculateCarUrl" rel="nofollow">
                {{ 'INSURANCE.CAR.COMPONENTS.COVER.CALCULATE' | translate | uppercase }}
            </a>
        </div>
    </div>
</div>
