import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { Injectable, EventEmitter, PLATFORM_ID, Inject, OnDestroy } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { CsRep } from 'app/class/csrep';
import { PriceResult } from 'app/class/priceresult';
import { CalculateNavigation } from './calculate-navigation';
import { BaxService } from 'app/utils/bax/bax.service';
import { Params, Router, UrlSegment } from '@angular/router';
import { BasketInsuranceDetails, DataCalculation } from 'app/class/bax/basketInsuranceDetails';
import { BasketInsuranceCalculationDetails } from 'app/class/bax/basketInsuranceCalculationDetails';
import { Premium } from 'app/class/bax/premium';
import { Cover } from 'app/class/cover';
import { CoverSelect } from 'app/class/cover-select';
import { Cookie } from 'ng2-cookies';
import { ReplaySubject, Subject } from 'rxjs';
import { Car } from './car/models/car';
import { House } from './house/models/house';
import { Home } from './home/models/home';
import { Accident } from './accident/models/accident';
import { Dog } from './dog/models/dog';
import { Caravan } from './caravan/models/caravan';
import { SecondHouse } from './secondhouse/models/secondhouse';
import { Moped } from './moped/models/moped';
import { DataCalculationWrapper } from './datacalculationwrapper';
import { each } from 'lodash-es';
import { UsageTypeItem2, ResidentialTypeItem, RoofTypeItem, HeatingTypeItem, WallConstructionTypeItem, SupplementaryHeatingTypeItem } from 'next-typescript-api';
import { Person } from './accident/models/person';
import { AppConfigService } from 'app/utils/appconfig/app-config.service';
import { NavigationService } from 'app/utils/navigation/navigation.service';
import { TrackingService } from 'app/utils/tracking/tracking.service';
import { CarNew } from './carNew/models/carNew';
import { cloneDeep } from 'lodash-es';
import { ThousandSeperatorFloatDisplayPipe } from 'app/class/pipe/thousandSeperatorFloatDisplayPipe';

@Injectable()
export class CalculateService implements OnDestroy {
    public product: string;
    public step: number;
    public totalSteps: number;
    public csrep: CsRep;
    public nav: CalculateNavigation;

    public showLeftMenu = false;
    public showSendBasket: boolean;
    public showSummaryBasket: boolean;
    public showAddProducts: boolean;

    public basketId: string;
    public insuranceId: string;
    public calculationId: string;

    basketProductEmitter: EventEmitter<any> = new EventEmitter();
    basketMultiProductEmitter: EventEmitter<any> = new EventEmitter();
    basketMultiProductEmitterStarted: EventEmitter<any> = new EventEmitter();

    calculationIds = new BehaviorSubject<string>(null);

    public hasHomeLoading: boolean;
    public hasHome: boolean;

    subscriptions: Subscription[] = [];
    progress$: Observable<number>;
    basketCount$: Observable<number>;
    showSendBasket$: Observable<boolean>;
    addProducts$: Observable<boolean>;
    productRemoved$: Observable<string>;
    showSummaryBasket$: Observable<boolean>;
    showLeftMenu$: Observable<boolean>;
    showAlreadyCustomer$: Observable<boolean>;
    showSelectProducts$: Observable<string>;
    disableSelectProducts = false;
    showCalculateButton$: Observable<boolean>;
    resetOffer$: Observable<boolean>;
    calculating$: Observable<boolean>;
    calculationFailed$: Observable<boolean>;
    btnBlinkSource$: Observable<boolean>;
    priceResult$: Observable<PriceResult>;
    loadedProduct: DataCalculation;

    pathToProductMap = {
        bil: 'car',
        nybil: 'car_simple',
        indbo: 'home',
        hus: 'house',
        campingvogn: 'caravan',
        knallert: 'moped',
        fritidshus: 'secondhouse',
        hund: 'dog',
        ulykke: 'accident',
    };

    private progressSource = new BehaviorSubject<number>(0);

    private basketCountSource = new BehaviorSubject<number>(0);

    private sendBasketSource = new BehaviorSubject<boolean>(false);

    private addProductsSource = new BehaviorSubject<boolean>(false);

    private productRemovedSource = new BehaviorSubject<string>(null);

    private summaryBasketSource = new BehaviorSubject<boolean>(false);

    private leftMenuSource = new BehaviorSubject<boolean>(false);

    private showAlreadyCustomerSource = new BehaviorSubject<boolean>(false);

    private showSelectProductsSource = new BehaviorSubject<string>(null);

    private showCalculateButtonSource = new BehaviorSubject<boolean>(false);

    private resetOfferSource = new ReplaySubject<boolean>();

    private calculatingSource = new BehaviorSubject<boolean>(false);

    private calculationFailedSource = new BehaviorSubject<boolean>(false);

    private btnBlinkSource = new BehaviorSubject<boolean>(false);

    private priceResultSource = new BehaviorSubject<PriceResult>(new PriceResult());

    private productLoadedSources: Map<
        string,
        {
            behaviourSubject: BehaviorSubject<boolean>;
            observeable: Observable<boolean>;
        }
    > = new Map();

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private baxService: BaxService,
        private config: AppConfigService,
        private router: Router,
        private navService: NavigationService,
        private trackingService: TrackingService
    ) {
        this.progress$ = this.progressSource.asObservable();
        this.basketCount$ = this.basketCountSource.asObservable();
        this.showSendBasket$ = this.sendBasketSource.asObservable();
        this.addProducts$ = this.addProductsSource.asObservable();
        this.productRemoved$ = this.productRemovedSource.asObservable();
        this.showSummaryBasket$ = this.summaryBasketSource.asObservable();
        this.showLeftMenu$ = this.leftMenuSource.asObservable();
        this.showAlreadyCustomer$ = this.showAlreadyCustomerSource.asObservable();
        this.showSelectProducts$ = this.showSelectProductsSource.asObservable();
        this.showCalculateButton$ = this.showCalculateButtonSource.asObservable();
        this.resetOffer$ = this.resetOfferSource.asObservable();
        this.calculating$ = this.calculatingSource.asObservable();
        this.calculationFailed$ = this.calculationFailedSource.asObservable();
        this.btnBlinkSource$ = this.btnBlinkSource.asObservable();
        this.priceResult$ = this.priceResultSource.asObservable();

        each(this.pathToProductMap, (productName) => {
            const behaviourSubject = new BehaviorSubject<boolean>(false);
            const observeable = behaviourSubject.asObservable();

            this.productLoadedSources.set(productName, {
                behaviourSubject,
                observeable,
            });
        });
    }

    getBasketTrackingCompleted(): Observable<boolean> {
        return this.trackingService.getBasketCompleted(this.basketId);
    }

    validateNewCarAllowed() {
        if (!this.config.appConfig.useNewCarProduct) {
            console.log('useNewCarProduct is false - redirect to frontpage');
            this.router.navigateByUrl(this.navService.getUrl('frontpage'));
        }
    }

    getBasketProductEmitter() {
        return this.basketProductEmitter;
    }

    getBasketMultiProductEmitter() {
        return this.basketMultiProductEmitter;
    }

    getBasketMultiProductEmitterStarted() {
        return this.basketMultiProductEmitterStarted;
    }

    getCalculationIds() {
        return this.calculationIds;
    }

    async getDataCalculationByProduct(basketId: string, productName: string, basketInsuranceDetails: BasketInsuranceDetails[] = null): Promise<DataCalculationWrapper> {
        const insuranceList = basketInsuranceDetails ? basketInsuranceDetails : await this.baxService.insurancesGet(basketId, 'getDataCalculationByProduct').toPromise();

        let addAddress = true;
        let addAge = false;
        let returnProduct = null;

        switch (productName.toLowerCase()) {
            case 'car_simple':
                returnProduct = new CarNew();
                addAge = true;
                break;
            case 'car':
                returnProduct = new Car();
                addAge = true;
                break;
            case 'house':
                returnProduct = new House();
                break;
            case 'home':
                returnProduct = new Home();
                addAge = true;
                break;
            case 'accident':
                returnProduct = new Accident();
                break;
            case 'dog':
                returnProduct = new Dog();
                break;
            case 'caravan':
                returnProduct = new Caravan();
                break;
            case 'secondhouse':
                returnProduct = new SecondHouse();
                addAddress = false;
                break;
            case 'moped':
                returnProduct = new Moped();
                break;
        }

        if (addAddress) {
            const insuranceWithAddress = insuranceList.find(
                (i) => i.product.toLowerCase() !== 'secondhouse' && i.dataCalculation && Boolean(i.dataCalculation.address) && Boolean(i.dataCalculation.address.addressText)
            );

            if (insuranceWithAddress) {
                returnProduct.address = insuranceWithAddress.dataCalculation.address;
            }
        }

        if (addAge) {
            const ageProductCodes = ['home', 'car', 'car_simple'];

            const insuranceWithAge = insuranceList.find((i) => {
                const productNameLower = i.product.toLowerCase();

                return ageProductCodes.includes(productNameLower) && i.dataCalculation && (i.dataCalculation.ownerAge || i.dataCalculation.userAge);
            });

            if (insuranceWithAge) {
                const age = insuranceWithAge.dataCalculation.ownerAge ? insuranceWithAge.dataCalculation.ownerAge : insuranceWithAge.dataCalculation.userAge;

                if (returnProduct instanceof Car) {
                    returnProduct.userAge = age;
                } else if (returnProduct instanceof Home) {
                    returnProduct.ownerAge = age;
                }
            }
        }

        const wrapper: DataCalculationWrapper = {
            productName: productName.toUpperCase(),
            product: returnProduct,
        };

        return wrapper;
    }

    async addBasketProducts(productNames: string[], carRegNo: string = null): Promise<BasketInsuranceDetails[]> {
        // TODO: Create an endpoint on the API, which does all this in an optimized way and use it here. Issue number: 8812
        this.basketMultiProductEmitterStarted.emit();

        const cookie = Cookie.get('basketid');
        if (!this.basketId && cookie) {
            this.basketId = cookie;
        }

        // No basket - create one
        if (!this.basketId) {
            const newBasket = await this.baxService.basketCreate();
            this.basketId = newBasket.publicId;
            Cookie.set('basketid', this.basketId, null, '/');
        }

        const promises: Promise<DataCalculationWrapper>[] = [];

        const basketInsuranceDetails = await this.baxService.insurancesGet(this.basketId, 'getDataCalculationByProduct').toPromise();

        productNames.forEach((productName) => {
            promises.push(this.getDataCalculationByProduct(this.basketId, productName.toUpperCase(), basketInsuranceDetails));
        });

        const dataCalculationWrappers: DataCalculationWrapper[] = await Promise.all(promises);

        const basketInsuranceInputs = dataCalculationWrappers.map((dc) => {
            if (dc.productName === 'CAR' || dc.productName === 'CAR_SIMPLE') {
                dc.product.regNo = carRegNo;
            }

            return {
                dataCalculation: dc.product,
                dataCommit: null,
                product: dc.productName.toUpperCase(),
            };
        });

        const basketInsurances = await this.baxService.insurancesAddBulk(this.basketId, basketInsuranceInputs).toPromise();

        this.basketMultiProductEmitter.emit(basketInsurances);

        return basketInsurances;
    }

    async addBasketProduct(productName: string): Promise<string> {
        const cookie = Cookie.get('basketid');
        if (!this.basketId && cookie) {
            this.basketId = cookie;
        }

        const dataCalculation = await this.getDataCalculationByProduct(this.basketId, productName);

        const basketInsurance = await this.baxService
            .insuranceAdd(this.basketId, {
                dataCalculation: dataCalculation.product,
                dataCommit: null,
                product: productName.toUpperCase(),
            })
            .toPromise();

        this.basketProductEmitter.emit(basketInsurance);

        return basketInsurance.publicId;
    }

    async addAccidentChildProduct(numberOfChilds: number): Promise<string> {
        const productName = 'accident';
        const cookie = Cookie.get('basketid');
        if (!this.basketId && cookie) {
            this.basketId = cookie;
        }

        const dataCalculation = await this.getDataCalculationByProduct(this.basketId, productName);

        const accident = dataCalculation.product as Accident;
        accident.isAdult = false;
        accident.numberOfChilds = numberOfChilds;
        for (let _i = 0; _i < accident.numberOfChilds; _i++) {
            const child: Person = { personNo: _i + 1 };
            accident.persons.push(child);
        }

        dataCalculation.product = accident;

        const basketInsurance = await this.baxService
            .insuranceAdd(this.basketId, {
                dataCalculation: dataCalculation.product,
                dataCommit: null,
                product: productName.toUpperCase(),
            })
            .toPromise();

        this.basketProductEmitter.emit(basketInsurance);

        return basketInsurance.publicId;
    }

    async addAccidentAdultProduct(numberOfAdults: number): Promise<string[]> {
        const productName = 'accident';
        const cookie = Cookie.get('basketid');
        if (!this.basketId && cookie) {
            this.basketId = cookie;
        }

        const result: string[] = [];
        for (let _i = 0; _i < numberOfAdults; _i++) {
            const dataCalculation = await this.getDataCalculationByProduct(this.basketId, productName);

            const accident = dataCalculation.product as Accident;
            accident.isAdult = true;
            const adult: Person = { personNo: 1 };
            accident.persons.push(adult);

            dataCalculation.product = accident;

            const basketInsurance = await this.baxService
                .insuranceAdd(this.basketId, {
                    dataCalculation: dataCalculation.product,
                    dataCommit: null,
                    product: productName.toUpperCase(),
                })
                .toPromise();
            result.push(basketInsurance.publicId);
            this.basketProductEmitter.emit(basketInsurance);
        }

        return result;
    }

    productRemoved(productId: string) {
        this.productRemovedSource.next(productId);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    getProductLoadedObserveable(productName: string) {
        const loadedSource = this.productLoadedSources.get(productName);

        if (!loadedSource) {
            return null;
        }

        return loadedSource.observeable;
    }

    setBasketCount(count: number) {
        this.basketCountSource.next(count);
    }

    async resolvePage(params: Params, type: UrlSegment) {
        this.product = this.pathToProductMap[type.path];

        this.basketId = params['basketid'];
        this.insuranceId = params['insuranceid'];
        if (isPlatformServer(this.platformId)) {
            return;
        }

        if (!this.basketId) {
            this.basketId = Cookie.get('basketid');
        }

        if (!this.basketId) {
            // NO BASKET - CREATE ONE AND ADD PRODUCT
            const newBasket = await this.baxService.basketCreate();
            this.setShowAlreadyCustomer(true);
            this.basketId = newBasket.publicId;
            Cookie.set('basketid', this.basketId, null, '/');
            if (this.product) {
                const insuranceAdd = await this.baxService
                    .insuranceAdd(this.basketId, {
                        dataCalculation: null,
                        dataCommit: null,
                        product: this.product.toUpperCase(),
                    })
                    .toPromise();
                this.insuranceId = insuranceAdd.publicId;
                this.loadedProduct = insuranceAdd.dataCalculation;
                this.getProductLoadedSubject(this.product).next(true);
            }
        } else {
            const basket = await this.baxService.basketGet(this.basketId).toPromise();
            const commitRequested = basket.commitRequested != null;
            if (commitRequested) {
                // BASKET ALREADY COMMITED. A NEW BASKET WILL BE CREATED
                const newBasket = await this.baxService.basketCreate();
                this.setShowAlreadyCustomer(true);
                this.basketId = newBasket.publicId;
                Cookie.set('basketid', this.basketId, null, '/');
            }

            if (this.product && (!this.insuranceId || this.insuranceId.length === 0)) {
                // A BASKET IS EXISTING AND NO INSURANCEID IS SET
                Cookie.set('basketid', this.basketId, null, '/');
                const insurancesGet = await this.baxService.insurancesGet(this.basketId, 'resolvePage').toPromise();
                // FIND A UNCALCULATED PRODUCT OF CORRECT PRODUCT TYPE - OR CREATE A NEW PRODUCT
                const find = insurancesGet.find((ins) => ins.dataCalculation == null && ins.product?.toUpperCase() === this.product.toUpperCase());

                if (find) {
                    this.insuranceId = find.publicId;
                    this.loadedProduct = find.dataCalculation;
                } else {
                    const dataCalculation = await this.getDataCalculationByProduct(this.basketId, this.product);
                    const insuranceAdd = await this.baxService
                        .insuranceAdd(this.basketId, {
                            dataCalculation: dataCalculation.product,
                            dataCommit: null,
                            product: this.product.toUpperCase(),
                        })
                        .toPromise();
                    this.insuranceId = insuranceAdd.publicId;
                    this.loadedProduct = insuranceAdd.dataCalculation;
                }
                this.getProductLoadedSubject(this.product).next(true);
            } else {
                // BASKET ID AND INSURANCE ID IS SET
                Cookie.set('basketid', this.basketId, null, '/');

                if (this.product) {
                    const insuranceGet = await this.baxService.insuranceGet(this.basketId, this.insuranceId).toPromise();

                    if (insuranceGet != null) {
                        this.loadedProduct = insuranceGet.dataCalculation;
                        this.getProductLoadedSubject(this.product).next(true);
                    }
                }
            }
        }
    }

    updateStep(step: number): void {
        this.step = step;
        this.progressSource.next((this.step / this.totalSteps) * 100);
    }

    toggleLeftMenu(show?: boolean): void {
        if (show === undefined) {
            this.showLeftMenu = !this.showLeftMenu;
        } else {
            this.showLeftMenu = show;
        }
        this.leftMenuSource.next(this.showLeftMenu);
    }

    toggleSendBasket(): void {
        this.showSendBasket = !this.showSendBasket;
        if (this.showSendBasket && this.showSummaryBasket) {
            this.toggleSummaryBasket();
        }
        this.sendBasketSource.next(this.showSendBasket);
    }

    setShowAlreadyCustomer(show: boolean): void {
        this.showAlreadyCustomerSource.next(show);
    }

    setShowSelectProducts(entryProduct: string): void {
        if (!this.disableSelectProducts) {
            this.showSelectProductsSource.next(entryProduct);
        }
    }

    setDisableSelectProducts(disable: boolean) {
        if (disable) {
            this.showSelectProductsSource.next(null);
        }

        this.disableSelectProducts = disable;
    }

    toggleAddProducts(): void {
        this.showAddProducts = !this.showAddProducts;
        if (this.showAddProducts) {
            this.hasHomeLoading = true;
            this.subscriptions.push(
                this.baxService.insurancesGet(this.basketId, 'toggleAddProducts').subscribe((resp) => {
                    this.hasHome = resp.filter((ins) => ins.product.toLowerCase() === 'home').length > 0;
                    this.hasHomeLoading = false;
                })
            );
        }

        this.addProductsSource.next(this.showAddProducts);
    }

    toggleSummaryBasket(show?: boolean): void {
        if (show === undefined) {
            this.showSummaryBasket = !this.showSummaryBasket;
        } else {
            this.showSummaryBasket = show;
        }
        this.summaryBasketSource.next(this.showSummaryBasket);
    }

    calculating(calc: boolean): void {
        this.calculatingSource.next(calc);
    }

    calculationFailed(calcFailed: boolean): void {
        this.calculationFailedSource.next(calcFailed);
    }

    startBlink(): void {
        this.btnBlinkSource.next(true);
    }

    toggleCalculateButton(show: boolean): void {
        this.showCalculateButtonSource.next(show);
    }

    updatePrice(result: PriceResult): void {
        this.priceResultSource.next(result);
    }

    resetOffer(): void {
        this.resetOfferSource.next(true);
    }

    calculate(productObject: any): Observable<PriceResult> {
        const calculateResult = new Subject<PriceResult>();

        productObject.covers = productObject.covers.filter(function (c) {
            return !c.required && !c.disabled;
        });

        this.subscriptions.push(
            this.baxService
                .insuranceUpdateAndCalculate(this.basketId, this.insuranceId, {
                    dataCalculation: productObject,
                    dataCommit: null,
                })
                .subscribe(
                    (response) => {
                        // clone to prevent mutating the object for other subscribers
                        const noRefResponse = cloneDeep(response);

                        const selectedCalculation = noRefResponse.find(function (c) {
                            return c.name === 'CALCULATION_REQUESTED';
                        });
                        const allCovers = noRefResponse.find(function (c) {
                            return c.name === 'CALCULATION_ALL_COVERS';
                        });

                        this.calculationId = selectedCalculation.publicId;

                        this.calculationIds.next(this.calculationId);

                        const priceResult = this.convertToPriceResult(selectedCalculation);
                        if (!allCovers) {
                            priceResult.premiumForAllCovers = priceResult.premiumForCovers;
                        } else {
                            priceResult.premiumForAllCovers = this.getPremiumsForCovers(allCovers);
                        }

                        // All covers must not include thos covers in selected covers. Here we excludes these
                        priceResult.premiumForAllCovers = priceResult.premiumForAllCovers.filter((c) => !selectedCalculation.premiums.some((sc) => sc.coverName === c.coverName));

                        // Gather the two lists
                        priceResult.premiumForAllCovers = priceResult.premiumForCovers.concat(priceResult.premiumForAllCovers);

                        calculateResult.next(priceResult);
                    },
                    (err) => {
                        console.log(err);
                        this.calculationFailed(true);
                    }
                )
        );

        return calculateResult.asObservable();
    }

    selectCalculation(): Observable<void> {
        return this.baxService.insuranceCalculationSelect(this.basketId, this.insuranceId, this.calculationId);
    }

    getCoverPrices(premiums: Premium[], covers: Cover[]): Cover[] {
        const pipe = new ThousandSeperatorFloatDisplayPipe();

        for (const key in premiums) {
            const prem = premiums[key];
            let price = prem.amountPeriod.orgValue;

            const cover = covers.find(function (entry) {
                return prem.coverName && entry.code.toLowerCase() === prem.coverName.toLowerCase();
            });

            if (!cover) {
                continue;
            }

            if (cover.additionalCodes) {
                const specPrems = premiums.filter(function (pp) {
                    return cover.additionalCodes.indexOf(pp.coverName) >= 0;
                });
                for (const pk in specPrems) {
                    price += specPrems[pk].amountPeriod.orgValue;
                }
            }

            cover.pricePeriodDisplay = pipe.transform(price);
        }
        return covers;
    }

    getPremiumsForCovers(input: BasketInsuranceCalculationDetails): Premium[] {
        const premiumForCovers = [];
        if (!input) {
            return premiumForCovers;
        }
        const premiums = input.premiums;
        const allCoverNames = input.premiums.filter((p) => p.coverName).map((p) => p.coverName);
        const coverNames = [...new Set(allCoverNames)]; // remove duplicates

        const pipe = new ThousandSeperatorFloatDisplayPipe();

        coverNames.forEach((coverName) => {
            const premiumsForCoverName = premiums.filter(function (p) {
                return p.coverName === coverName;
            });

            if (premiumsForCoverName.length === 0) {
                return;
            }

            const premium = premiumsForCoverName[0];

            const sumAnnual = premiumsForCoverName.map((p) => p.amountAnnual.orgValue).reduce((a, b) => a + b, 0);
            const sumPeriod = premiumsForCoverName.map((p) => p.amountPeriod.orgValue).reduce((a, b) => a + b, 0);

            premium.amountAnnual = {
                orgValue: sumAnnual,
                display: pipe.transform(sumAnnual),
            };
            premium.amountPeriod = {
                orgValue: sumPeriod,
                display: pipe.transform(sumPeriod),
            };
            premiumForCovers.push(premium);
        });

        return premiumForCovers;
    }

    saveBasket(obj: any): Observable<BasketInsuranceDetails> {
        return this.baxService.insuranceUpdate(this.basketId, this.insuranceId, {
            dataCalculation: obj,
            dataCommit: null,
        });
    }

    getCoverSelection(covers: Cover[]): CoverSelect[] {
        const selected = [];
        for (const key in covers) {
            const cover = covers[key];
            if (cover.chosen && !cover.required) {
                selected.push({ code: cover.code });
            }
        }

        return selected;
    }

    updateCovers(covers: Cover[], productCovers: CoverSelect[]): Cover[] {
        if (!covers) {
            return covers;
        }

        for (const cover of covers) {
            cover.chosen = cover.required || productCovers.some((pc) => pc.code === cover.code);
        }

        return covers;
    }

    enumAllowedForSignup(
        items: UsageTypeItem2[] & ResidentialTypeItem[] & RoofTypeItem[] & HeatingTypeItem[] & WallConstructionTypeItem[] & SupplementaryHeatingTypeItem[],
        chosenCode: string
    ): boolean {
        if (!items) {
            return false;
        }

        if (!chosenCode || chosenCode.length === 0) {
            return false;
        }

        const find = items.find(function (t) {
            return t.code === chosenCode;
        });
        if (!find) {
            return false;
        }

        if (find.allowedForSignup != null && !find.allowedForSignup) {
            return false;
        }

        return true;
    }

    enumDisallowedCovers(
        items: WallConstructionTypeItem[] & UsageTypeItem2[] & ResidentialTypeItem[] & RoofTypeItem[] & HeatingTypeItem[] & SupplementaryHeatingTypeItem[],
        chosenCode: string
    ): string[] {
        if (!items) {
            return null;
        }

        if (!chosenCode || chosenCode.length === 0) {
            return null;
        }

        const find = items.find(function (t) {
            return t.code === chosenCode;
        });
        if (!find) {
            return null;
        }

        if (!find.disallowedCovers || find.disallowedCovers.length === 0) {
            return null;
        }

        return find.disallowedCovers;
    }

    async getCalculateUrl(productcode: string, basketPublicId: string, insurancePublicId: string) {
        let url = '/beregn/';
        switch (productcode.toLowerCase()) {
            case 'car_simple':
            case 'car':
                if (productcode.toLowerCase() === 'car_simple') {
                    url += 'nybil';
                } else {
                    url += 'bil';
                }
                const insuranceGet = await this.baxService.insuranceGet(basketPublicId, insurancePublicId).toPromise();
                if (insuranceGet.dataCalculation && insuranceGet.dataCalculation.brandId) {
                    url += '/manual';
                } // send the customer to page two if he already filled out some details.
                break;

            case 'home':
                url += 'indbo';
                break;

            case 'house':
                url += 'hus';
                break;

            case 'caravan':
                url += 'campingvogn';
                break;

            case 'moped':
                url += 'knallert';
                break;

            case 'secondhouse':
                url += 'fritidshus';
                break;

            case 'dog':
                url += 'hund';
                break;

            case 'accident':
                url += 'ulykke';
                break;
        }

        return url + '?basketid=' + basketPublicId + '&insuranceid=' + insurancePublicId;
    }

    private getProductLoadedSubject(productName: string): BehaviorSubject<boolean> {
        const loadedSource = this.productLoadedSources.get(productName);

        if (!loadedSource) {
            return null;
        }

        return loadedSource.behaviourSubject;
    }

    private convertToPriceResult(input: BasketInsuranceCalculationDetails): PriceResult {
        const pipe = new ThousandSeperatorFloatDisplayPipe();

        return {
            success: true,
            premiumForCovers: this.getPremiumsForCovers(input),
            faultMessage: null,
            pricePeriod: {
                orgValue: input.pricePeriod.orgValue,
                display: pipe.transform(input.pricePeriod.orgValue),
            },
            priceAnnual: {
                orgValue: input.priceAnnual.orgValue,
                display: pipe.transform(input.priceAnnual.orgValue),
            },
            premiumForAllCovers: null,
        };
    }
}
