<div class="container">
    <div class="row">
        <div class="col-sm-12 text-center cover-header">
            <h2>{{ 'INSURANCE.ACCIDENT.COMPONENTS.COVER.TITLE' | translate }}</h2>
        </div>
    </div>

    <div class="row">
        <div class="col-sm-4 text-center">
            <div class="insurance-container">
                <a [routerLink]="navigationService.getUrl('cover-accident-full')">
                    <div class="insurance-box insurance-img img-accident-full">
                        <div class="icon-circle center">
                            <div class="icon-wrap">
                                <i class="nf nf-accident-fuldtidsdkning"></i>
                            </div>
                        </div>
                    </div>
                    <div class="insurance-bottom-box">
                        <h2>{{ 'INSURANCE.ACCIDENT.COMPONENTS.COVER.FULL' | translate }}</h2>
                        <p class="readmore">{{ 'SHARED.READ_MORE' | translate }}</p>
                    </div>
                </a>
            </div>
        </div>

        <div class="col-sm-4 text-center">
            <div class="insurance-container">
                <a [routerLink]="navigationService.getUrl('cover-accident-leisure')">
                    <div class="insurance-box insurance-img img-accident-leisure">
                        <div class="icon-circle center">
                            <div class="icon-wrap">
                                <i class="nf nf-accident-fritidsdkning"></i>
                            </div>
                        </div>
                    </div>
                    <div class="insurance-bottom-box">
                        <h2>{{ 'INSURANCE.ACCIDENT.COMPONENTS.COVER.LEISURE' | translate }}</h2>
                        <p class="readmore">{{ 'SHARED.READ_MORE' | translate }}</p>
                    </div>
                </a>
            </div>
        </div>

        <div class="col-sm-4 text-center">
            <div class="insurance-container">
                <a [routerLink]="navigationService.getUrl('cover-accident-child')">
                    <div class="insurance-box insurance-img img-accident-child">
                        <div class="icon-circle center">
                            <div class="icon-wrap">
                                <i class="nf nf-accident-brnedkning"></i>
                            </div>
                        </div>
                    </div>
                    <div class="insurance-bottom-box">
                        <h2>{{ 'INSURANCE.ACCIDENT.COMPONENTS.COVER.CHILD' | translate }}</h2>
                        <p class="readmore">{{ 'SHARED.READ_MORE' | translate }}</p>
                    </div>
                </a>
            </div>
        </div>

        <div class="col-sm-4 text-center">
            <div class="insurance-container">
                <a [routerLink]="navigationService.getUrl('cover-accident-treatment')">
                    <div class="ribbon-parent insurance-box insurance-img img-accident-treatment">
                        <ribbon [type]="'required'"></ribbon>
                        <div class="icon-circle center">
                            <div class="icon-wrap">
                                <i class="nf nf-accident-behandlingsudgifter"></i>
                            </div>
                        </div>
                    </div>
                    <div class="insurance-bottom-box">
                        <h2>{{ 'INSURANCE.ACCIDENT.COMPONENTS.COVER.TREATMENT' | translate }}</h2>
                        <p class="readmore">{{ 'SHARED.READ_MORE' | translate }}</p>
                    </div>
                </a>
            </div>
        </div>

        <div class="col-sm-4 text-center">
            <div class="insurance-container">
                <a [routerLink]="navigationService.getUrl('cover-accident-disability')">
                    <div class="ribbon-parent insurance-box insurance-img img-accident-disability">
                        <ribbon [type]="'required'"></ribbon>
                        <div class="icon-circle center">
                            <div class="icon-wrap">
                                <i class="nf nf-accident-invaliditetsdkning"></i>
                            </div>
                        </div>
                    </div>
                    <div class="insurance-bottom-box">
                        <h2>{{ 'INSURANCE.ACCIDENT.COMPONENTS.COVER.DISABILITY' | translate }}</h2>
                        <p class="readmore">{{ 'SHARED.READ_MORE' | translate }}</p>
                    </div>
                </a>
            </div>
        </div>
        <div class="col-sm-4 text-center">
            <div class="insurance-container">
                <a [routerLink]="navigationService.getUrl('cover-accident-dental')">
                    <div class="ribbon-parent insurance-box insurance-img img-accident-dental">
                        <ribbon [type]="'required'"></ribbon>
                        <div class="icon-circle center">
                            <div class="icon-wrap">
                                <i class="nf nf-accident-tandskade"></i>
                            </div>
                        </div>
                    </div>
                    <div class="insurance-bottom-box">
                        <h2>{{ 'INSURANCE.ACCIDENT.COMPONENTS.COVER.DENTAL' | translate }}</h2>
                        <p class="readmore">{{ 'SHARED.READ_MORE' | translate }}</p>
                    </div>
                </a>
            </div>
        </div>
        <div class="col-sm-4 text-center">
            <div class="insurance-container">
                <a [routerLink]="navigationService.getUrl('cover-accident-death')">
                    <div class="ribbon-parent insurance-box insurance-img img-accident-death">
                        <ribbon [type]="'optional'"></ribbon>
                        <div class="icon-circle center">
                            <div class="icon-wrap">
                                <i class="nf nf-accident-ddsfaldsdkning"></i>
                            </div>
                        </div>
                    </div>
                    <div class="insurance-bottom-box">
                        <h2>{{ 'INSURANCE.ACCIDENT.COMPONENTS.COVER.DEATH' | translate }}</h2>
                        <p class="readmore">{{ 'SHARED.READ_MORE' | translate }}</p>
                    </div>
                </a>
            </div>
        </div>

        <div class="col-sm-4 text-center">
            <div class="insurance-container">
                <a [routerLink]="navigationService.getUrl('cover-accident-double')">
                    <div class="ribbon-parent insurance-box insurance-img img-accident-double">
                        <ribbon [type]="'optional'"></ribbon>
                        <div class="icon-circle center">
                            <div class="icon-wrap">
                                <i class="nf nf-accident-dobbelt-kompensation"></i>
                            </div>
                        </div>
                    </div>
                    <div class="insurance-bottom-box">
                        <h2>{{ 'INSURANCE.ACCIDENT.COMPONENTS.COVER.DOUBLE' | translate }}</h2>
                        <p class="readmore">{{ 'SHARED.READ_MORE' | translate }}</p>
                    </div>
                </a>
            </div>
        </div>
        <div class="col-sm-4 text-center">
            <div class="insurance-container">
                <a [routerLink]="navigationService.getUrl('cover-accident-bike')">
                    <div class="ribbon-parent insurance-box insurance-img img-accident-bike">
                        <ribbon [type]="'optional'"></ribbon>
                        <div class="icon-circle center">
                            <div class="icon-wrap">
                                <i class="nf nf-accident-motorcykel"></i>
                            </div>
                        </div>
                    </div>
                    <div class="insurance-bottom-box">
                        <h2>{{ 'INSURANCE.ACCIDENT.COMPONENTS.COVER.BIKE' | translate }}</h2>
                        <p class="readmore">{{ 'SHARED.READ_MORE' | translate }}</p>
                    </div>
                </a>
            </div>
        </div>

        <div class="col-sm-4 text-center">
            <div class="insurance-container">
                <a [routerLink]="navigationService.getUrl('cover-accident-sport')">
                    <div class="ribbon-parent insurance-box insurance-img img-accident-sport">
                        <ribbon [type]="'optional'"></ribbon>
                        <div class="icon-circle center">
                            <div class="icon-wrap">
                                <i class="nf nf-accident-farlig-sport"></i>
                            </div>
                        </div>
                    </div>
                    <div class="insurance-bottom-box">
                        <h2>{{ 'INSURANCE.ACCIDENT.COMPONENTS.COVER.SPORT' | translate }}</h2>
                        <p class="readmore">{{ 'SHARED.READ_MORE' | translate }}</p>
                    </div>
                </a>
            </div>
        </div>
    </div>

    <h2 class="text-center">{{ 'INSURANCE.SHARED.CHOOSE_COVERS' | translate }}</h2>
    <p class="cover-table-text" [innerHTML]="'INSURANCE.ACCIDENT.COMPONENTS.COVER.COVER_TABLE_TEXT' | translate | safehtml"></p>

    <cover-table productCode="accident"></cover-table>
    <br />
    <br />

    <div class="row">
        <div class="col-sm-12 text-center">
            <a class="btn btn-success bottom-spacer-big calculate-insurance-btn" [routerLink]="navigationService.getUrl('calculate-accident')" rel="nofollow">
                {{ 'INSURANCE.ACCIDENT.COMPONENTS.COVER.CALCULATE' | translate | uppercase }}</a
            >
        </div>
    </div>
</div>
