import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoverThumbnailComponent } from './components/cover-thumbnail/cover-thumbnail.component';
import { RibbonModule } from 'app/shared/ribbon/ribbon.module';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';

@NgModule({
    declarations: [CoverThumbnailComponent],
    exports: [CoverThumbnailComponent],
    imports: [CommonModule, RouterModule, RibbonModule, TranslateModule],
})
export class CoverThumbnailModule {}
