import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BaxService } from 'app/utils/bax/bax.service';
import { NavigationService } from 'app/utils/navigation/navigation.service';
import { Cookie } from 'ng2-cookies';
import { Subscription } from 'rxjs';

@Component({
    selector: 'next-emaillanding',
    template: '',
})
export class EmaillandingComponent implements OnInit {
    subscriptions: Subscription[] = [];

    constructor(private router: Router, private route: ActivatedRoute, private navigationService: NavigationService, private baxService: BaxService) {}

    ngOnInit() {
        this.subscriptions.push(
            this.route.params.subscribe((params) => {
                const basketId = params['basketid'];
                Cookie.set('basketid', basketId, null, '/');

                const goto = this.navigationService.getUrl('summary');
                this.router.navigate([goto]);
            })
        );
    }
}
