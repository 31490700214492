<div class="full-width-image-section press-top">
    <div class="arrow-up">
        <next-topbar></next-topbar>
        <next-menu></next-menu>

        <div class="container white">
            <div class="row">
                <div class="col-sm-12 text-center">
                    <h1>{{ 'PAGE.ABOUT_NEXT.MENTIONED_BY' | translate }}</h1>
                </div>
                <div class="col-sm-12">
                    <img
                        class="img-responsive"
                        src="https://static.next.dk/website/images/pages/press/media-top.png"
                        srcset="https://static.next.dk/website/images/pages/press/media-top@2x.png 2x"
                    />
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container">
    <div class="articles-container">
        <h2 class="text-center">{{ 'PAGE.PRESS.ARTICLES' | translate }}</h2>
        <div class="row article-space">
            <div class="col-xs-12 col-sm-4 col-sm-3">
                <img class="img-responsive" src="https://static.next.dk/website/images/pages/press/logo-press.png" />
                <span>
                    next.dk
                    <br />
                    12. november 2020
                </span>
            </div>
            <div class="col-xs-12 col-sm-8 col-md-9 seperator">
                <h4>NEXT Forsikring får nye ejere</h4>
                <p>
                    <i> Ejerne af den 100 procent digitale forsikringsformidler NEXT Forsikring sælger virksomheden til Basileia Holdings. </i>
                </p>
                <p>
                    {{ 'PAGE.PRESS.READ_ARTICLE' | translate }}
                    <a target="_blank" rel="nofollow" href="https://static.next.dk/website/press/NEXT-Forsikring-faar-nye-ejere.pdf">{{ 'PAGE.PRESS.HERE' | translate }}</a>
                </p>
            </div>
        </div>

        <div class="row article-space">
            <div class="col-xs-12 col-sm-4 col-sm-3">
                <img class="img-responsive" src="https://static.next.dk/website/images/pages/press/logo-samlino.png" />
                <span>
                    Samlino.dk
                    <br />
                    19. december 2017
                </span>
            </div>
            <div class="col-xs-12 col-sm-8 col-md-9 seperator">
                <h4>Husforsikring: Så stor er prisforskellen!</h4>
                <p>
                    <i> Undersøgelsens billigste husforsikring kan du få hos Next Forsikring. </i>
                </p>
                <p>
                    {{ 'PAGE.PRESS.READ_ARTICLE' | translate }}
                    <a target="_blank" rel="nofollow" href="https://www.samlino.dk/blog/husforsikring">{{ 'PAGE.PRESS.HERE' | translate }}</a>
                </p>
            </div>
        </div>
        <div class="row article-space">
            <div class="col-xs-12 col-sm-4 col-sm-3">
                <img class="img-responsive" src="https://static.next.dk/website/images/pages/press/logo-aarhusstift.png" />
                <span>
                    Århus Stiftstidende
                    <br />
                    29. november 2016
                </span>
            </div>
            <div class="col-xs-12 col-sm-8 col-md-9 seperator">
                <h4>NEXT Forsikring vil være branchens Netflix</h4>
                <p>
                    <i> Det gav genlyd i det meste af Danmark, da en 30-årig Skanderborg-iværksætter for tre år siden lancerede en helt ny type forsikringsselskab. </i>
                </p>
                <p>
                    {{ 'PAGE.PRESS.READ_ARTICLE' | translate }}
                    <a target="_blank" rel="nofollow" href="https://stiften.dk/skanderborg/Next-Forsikring-vil-vaere-branchens-Netflix/artikel/425116">{{ 'PAGE.PRESS.HERE' | translate }}</a>
                </p>
            </div>
        </div>
        <div class="row article-space">
            <div class="col-xs-12 col-sm-4 col-sm-3">
                <img class="img-responsive" src="https://static.next.dk/website/images/pages/press/logo-finanswatch.png" />
                <span>
                    Finanswatch
                    <br />
                    20. maj 2016
                </span>
            </div>
            <div class="col-xs-12 col-sm-8 col-md-9 seperator">
                <h4>Next Forsikring vinder e-handelspris</h4>
                <p>
                    <i> Forsikringsselskabet, der kun eksisterer online, har fået en pris for sin evne til at nytænke e-handel. </i>
                </p>
                <p>
                    {{ 'PAGE.PRESS.READ_ARTICLE' | translate }}
                    <a target="_blank" rel="nofollow" href="https://finanswatch.dk/Finansnyt/article8676460.ece">{{ 'PAGE.PRESS.HERE' | translate }}</a>
                </p>
            </div>
        </div>
        <div class="row article-space">
            <div class="col-xs-12 col-sm-4 col-sm-3">
                <img class="img-responsive" src="https://static.next.dk/website/images/pages/press/logo-aarhusstift.png" />
                <span>
                    Århus Stiftstidende
                    <br />
                    17. marts 2016
                </span>
            </div>
            <div class="col-xs-12 col-sm-8 col-md-9 seperator">
                <h4>Lokalt firma i fornemt selskab</h4>
                <p>
                    <i> Det unge firma Next Forsikring fra Skanderborg var inviteret til at tale på Børsens nyligt afholdte konference om forsikring og pension. </i>
                </p>
                <p>
                    {{ 'PAGE.PRESS.READ_ARTICLE' | translate }}
                    <a target="_blank" rel="nofollow" href="https://stiften.dk/skanderborg/Lokalt-firma-i-fornemt-selskab/artikel/308754">{{ 'PAGE.PRESS.HERE' | translate }}</a>
                </p>
            </div>
        </div>
        <div class="row article-space">
            <div class="col-xs-12 col-sm-4 col-sm-3">
                <img class="img-responsive" src="https://static.next.dk/website/images/pages/press/logo-aarhusstift.png" />
                <span>
                    Århus Stiftstidende
                    <br />
                    8. november 2015
                </span>
            </div>
            <div class="col-xs-12 col-sm-8 col-md-9 seperator">
                <h4>IT-nørdernes paradis ligger i Skanderborg</h4>
                <p>
                    <i>
                        Det startede foran computerne i en privat kælder for 16 år siden. I dag er 32-årige Pelle Martin Smidt direktør i en koncern med otte forskellige virksomheder og i
                        alt 100 medarbejdere samlet under ét tag.
                    </i>
                </p>
                <p>
                    {{ 'PAGE.PRESS.READ_ARTICLE' | translate }}
                    <a target="_blank" rel="nofollow" href="https://stiften.dk/skanderborg/IT-noerdernes-paradis-ligger-i-Skanderborg/artikel/277254">{{ 'PAGE.PRESS.HERE' | translate }}</a>
                </p>
            </div>
        </div>
        <div class="row article-space">
            <div class="col-xs-12 col-sm-4 col-sm-3 col-sm-3">
                <img class="img-responsive" src="https://static.next.dk/website/images/pages/press/logo-borsen.png" />
                <span>
                    Børsen
                    <br />
                    8. oktober 2015
                </span>
            </div>
            <div class="col-xs-12 col-sm-8 col-md-9 seperator">
                <h4>Codan får bundplacering i prisdyst på samlerabatter</h4>
                <p>
                    <i> "Vi er super glade for at være blandt de billigste," siger Pelle Martin Smidt, direktør og medstifter af Next Forsikring. </i>
                </p>
                <p>
                    {{ 'PAGE.PRESS.READ_ARTICLE' | translate }}
                    <a target="_blank" rel="nofollow" href="https://borsen.dk/nyheder/finans/codan-faar-bundplacering-i-prisdyst-paa-samlerabatter-8zcfu">{{
                        'PAGE.PRESS.HERE' | translate
                    }}</a>
                </p>
            </div>
        </div>
        <div class="row article-space">
            <div class="col-xs-12 col-sm-4 col-sm-3">
                <img class="img-responsive" src="https://static.next.dk/website/images/pages/press/logo-borsen.png" />
                <span>
                    Børsen
                    <br />
                    29. maj 2015
                </span>
            </div>
            <div class="col-xs-12 col-sm-8 col-md-9 seperator">
                <h4>Forsikringsudfordrer: De store bruger ufine metoder</h4>
                <p>
                    <i>
                        Omkring hver fjerde nye kunde i internetbaserede Next Forsikring bliver aldrig kunde, fordi deres tidligere forsikringsselskab i sidste øjeblik overtaler dem til at
                        blive.
                    </i>
                </p>
                <p>
                    {{ 'PAGE.PRESS.READ_ARTICLE' | translate }}
                    <a target="_blank" rel="nofollow" href="https://borsen.dk/nyheder/finans/forsikringsudfordrer-de-store-bruger-ufine-metoder-2alvu">{{ 'PAGE.PRESS.HERE' | translate }}</a>
                </p>
            </div>
        </div>
        <div class="row article-space">
            <div class="col-xs-12 col-sm-4 col-sm-3">
                <img class="img-responsive" src="https://static.next.dk/website/images/pages/press/logo-politiken.png" />
                <span>
                    Politiken
                    <br />
                    16. maj 2015
                </span>
            </div>
            <div class="col-xs-12 col-sm-8 col-md-9 seperator">
                <h4>Du kan spare lette penge på dyre forsikringer</h4>
                <p>
                    <i> Lad andre gøre arbejdet og spar op mod 30 procent. Men hveranden gør det ikke. </i>
                </p>
                <p>
                    {{ 'PAGE.PRESS.READ_ARTICLE' | translate }}
                    <a target="_blank" rel="nofollow" href="https://politiken.dk/forbrugogliv/boligogdesign/boligforsikring/art5576584/Du-kan-spare-lette-penge-p%C3%A5-dyre-forsikringer">{{
                        'PAGE.PRESS.HERE' | translate
                    }}</a>
                </p>
            </div>
        </div>
        <div class="row article-space">
            <div class="col-xs-12 col-sm-4 col-sm-3">
                <img class="img-responsive" src="https://static.next.dk/website/images/pages/press/logo-politiken.png" />
                <span>
                    Politiken
                    <br />
                    16. maj 2015
                </span>
            </div>
            <div class="col-xs-12 col-sm-8 col-md-9 seperator">
                <h4>Prut eller smæk med døren og spar et par tusinde på forsikringen</h4>
                <p>
                    <i>
                        Sebrina Thagaard udfordrede sit forsikringsselskab Tryg ved at søge tilbud fra andre selskaber. Det endte med at prisen på hendes bilforsikring blev halveret sammen
                        med selvrisikoen.
                    </i>
                </p>
                <p>
                    {{ 'PAGE.PRESS.READ_ARTICLE' | translate }}
                    <a
                        target="_blank"
                        rel="nofollow"
                        href="https://politiken.dk/oekonomi/privatoekonomi/art5576557/Prut-eller-sm%C3%A6k-med-d%C3%B8ren-og-spar-et-par-tusinde-p%C3%A5-forsikringen"
                        >{{ 'PAGE.PRESS.HERE' | translate }}</a
                    >
                </p>
            </div>
        </div>
        <div class="row article-space">
            <div class="col-xs-12 col-sm-4 col-sm-3">
                <img class="img-responsive" src="https://static.next.dk/website/images/pages/press/logo-jyllandsposten.png" />
                <span>
                    Jyllands-Posten
                    <br />
                    18. april 2015
                </span>
            </div>
            <div class="col-xs-12 col-sm-8 col-md-9 seperator">
                <h4>De gode forsikringskunder får ekstratilbud, hvis de er på vej ud ad døren</h4>
                <p>
                    <i> Er du en af de populære kunder, bliver du måske ringet op med et godt tilbud, hvis du vil skifte selskab. </i>
                </p>
                <p>
                    {{ 'PAGE.PRESS.READ_ARTICLE' | translate }}
                    <a
                        target="_blank"
                        rel="nofollow"
                        href="https://finans.dk/protected/finans/penge/ECE7633942/de-gode-forsikringskunder-faar-ekstratilbud-hvis-de-er-paa-vej-ud-ad-doeren/"
                        >{{ 'PAGE.PRESS.HERE' | translate }}</a
                    >
                </p>
            </div>
        </div>
        <div class="row article-space">
            <div class="col-xs-12 col-sm-4 col-sm-3">
                <img class="img-responsive" src="https://static.next.dk/website/images/pages/press/logo-finanswatch.png" />
                <span>
                    Finanswatch
                    <br />
                    22. februar 2015
                </span>
            </div>
            <div class="col-xs-12 col-sm-8 col-md-9 seperator">
                <h4>Forsiksingstjek kan spare dig for tusindvis af kroner</h4>
                <p>
                    <i> Prisstigninger og prisforskelle sammen med nye og billigere online-forsikringsselskaber gør det til en god ide at holde styr på forsikringspolicerne </i>
                </p>
                <p>
                    {{ 'PAGE.PRESS.READ_ARTICLE' | translate }}
                    <a
                        target="_blank"
                        rel="nofollow"
                        href="https://finanswatch.dk/Finansnyt/Forsikring___Pension/article7473003.ece?utm_source=Feed&utm_medium=top&utm_campaign=Morgen&utm_content=2015-02-23+08%3A10%3A00"
                        >her</a
                    >
                </p>
            </div>
        </div>
        <div class="row article-space">
            <div class="col-xs-12 col-sm-4 col-sm-3">
                <img class="img-responsive" src="https://static.next.dk/website/images/pages/press/logo-berlingske.png" />
                <span>
                    Berlingske Business
                    <br />
                    21. september 2014
                </span>
            </div>
            <div class="col-xs-12 col-sm-8 col-md-9 seperator">
                <h4>Forsikringsselskaber i jagt på kundeadfærd</h4>
                <p>
                    <i>
                        Forsikringsselskaberne beder om flere og flere oplysninger fra kunderne for at kunne inddele dem i forskellige risiko- og prisgrupper. Forbrugerrådet mener, at det
                        strider imod hele forsikringstanken. Senest ønsker Next Forsikring at følge kunderne via app og sociale medier.
                    </i>
                </p>
                <p>
                    {{ 'PAGE.PRESS.READ_ARTICLE' | translate }}
                    <a target="_blank" rel="nofollow" href="https://www.business.dk/finans/forsikringsselskaber-i-jagt-paa-kundeadfaerd">{{ 'PAGE.PRESS.HERE' | translate }}</a>
                </p>
            </div>
        </div>
        <div class="row article-space">
            <div class="col-xs-12 col-sm-4 col-sm-3">
                <img class="img-responsive" src="https://static.next.dk/website/images/pages/press/logo-dagens.png" />
                <span>
                    Dagens.dk
                    <br />
                    14. februar 2014
                </span>
            </div>
            <div class="col-xs-12 col-sm-8 col-md-9 seperator">
                <h4>Ny generation af online kunder - er de gamle</h4>
                <p>
                    <i>
                        Glem alt om digitalt hjælpeløse ældre. Stik imod fordommene er ældre forbrugere hurtige til at hente besparelser på onlinemuligheder på alt fra mobiltelefoni,
                        forsikringer og shopping.
                    </i>
                </p>
                <p>
                    {{ 'PAGE.PRESS.READ_ARTICLE' | translate }}
                    <a target="_blank" rel="nofollow" href="http://www.dagens.dk/teknologi/web-tv-ny-generation-af-online-kunder-er-de-gamle">{{ 'PAGE.PRESS.HERE' | translate }}</a>
                </p>
            </div>
        </div>
        <div class="row article-space">
            <div class="col-xs-12 col-sm-4 col-sm-3">
                <img class="img-responsive" src="https://static.next.dk/website/images/pages/press/logo-eb.png" />
                <span>
                    Ekstra Bladet
                    <br />
                    23. december 2013
                </span>
            </div>
            <div class="col-xs-12 col-sm-8 col-md-9 seperator">
                <h4>Virksomheders julegave til dig: Større regninger over natten</h4>
                <p>
                    <i> Flere danske virksomheder hæver priserne på deres varer og ydelser, når kalenderen skifter til 2014. </i>
                </p>
                <p>
                    {{ 'PAGE.PRESS.READ_ARTICLE' | translate }}
                    <a target="_blank" rel="nofollow" href="https://ekstrabladet.dk/kup/dinepenge/article4826662.ece">{{ 'PAGE.PRESS.HERE' | translate }}</a>
                </p>
            </div>
        </div>
        <div class="row article-space">
            <div class="col-xs-12 col-sm-4 col-sm-3">
                <img class="img-responsive" src="https://static.next.dk/website/images/pages/press/logo-politiken.png" />
                <span>
                    Politiken
                    <br />
                    23. december 2013
                </span>
            </div>
            <div class="col-xs-12 col-sm-8 col-md-9 seperator">
                <h4>Nyd julen nu: Alting stiger til januar</h4>
                <p>
                    <i> Godt juleråd: Hold dig fra e-Boks og netbank i julen. Der er dårlige nyheder. </i>
                </p>
                <p>
                    {{ 'PAGE.PRESS.READ_ARTICLE' | translate }}
                    <a target="_blank" rel="nofollow" href="https://politiken.dk/forbrugogliv/forbrug/indkoeb/art5491339/Nyd-julen-nu-Alting-stiger-til-januar">{{
                        'PAGE.PRESS.HERE' | translate
                    }}</a>
                </p>
            </div>
        </div>
        <div class="row article-space">
            <div class="col-xs-12 col-sm-4 col-sm-3">
                <img class="img-responsive" src="https://static.next.dk/website/images/pages/press/logo-borsen.png" />
                <span>
                    Børsen
                    <br />
                    13. december 2013
                </span>
            </div>
            <div class="col-xs-12 col-sm-8 col-md-9 seperator">
                <h4>Forsikringsopkomling tager nyt middel i brug</h4>
                <p>
                    <i>
                        For knap tre uger siden gik et nyt forsikringsselskab ind på det danske marked. Det internetbaserede Next Forsikring vil tage kampen op mod de store etablerede
                        spillere som Tryg og Topdanmark, og selskabet lover lave priser og fjerner nu som det første forsikringsselskab flyttegebyret.
                    </i>
                </p>
                <p>
                    {{ 'PAGE.PRESS.READ_ARTICLE' | translate }}
                    <a target="_blank" rel="nofollow" href="https://borsen.dk/nyheder/finans/forsikringsopkomling-tager-nyt-middel-i-brug">{{ 'PAGE.PRESS.HERE' | translate }}</a>
                </p>
            </div>
        </div>
        <div class="row article-space">
            <div class="col-xs-12 col-sm-4 col-sm-3">
                <img class="img-responsive" src="https://static.next.dk/website/images/pages/press/logo-berlingske.png" />
                <span>
                    Berlingske Business
                    <br />
                    13. december 2013
                </span>
            </div>
            <div class="col-xs-12 col-sm-8 col-md-9 seperator">
                <h4>Ny forsikringsspiller dropper omstridt flyttegebyr</h4>
                <p>
                    <i>
                        Pelle Martin Smidt, direktør i Next Forsikring, tror på, at fri konkurrence og loyalitet skal skabes gennem tilfredse kunder. Men i Topdanmark er man ikke imponeret
                        og har ikke tænkt sig at sløjfe gebyrerne og betragter det som en gratis markedsføringsgimmick fra Next Forsikring.
                    </i>
                </p>
                <p>
                    {{ 'PAGE.PRESS.READ_ARTICLE' | translate }}
                    <a target="_blank" rel="nofollow" href="https://www.business.dk/finans/ny-forsikringsspiller-dropper-omstridt-flyttegebyr">{{ 'PAGE.PRESS.HERE' | translate }}</a>
                </p>
            </div>
        </div>
        <div class="row article-space">
            <div class="col-xs-12 col-sm-4 col-sm-3">
                <img class="img-responsive" src="https://static.next.dk/website/images/pages/press/logo-borsen.png" />
                <span>
                    Børsen
                    <br />
                    26. november 2013
                </span>
            </div>
            <div class="col-xs-12 col-sm-8 col-md-9 seperator">
                <h4>Niels Lunde: Hovsa, har forsikringsbranchen fået en Netflix?</h4>
                <p>
                    <i>
                        For knap tre uger siden gik et nyt forsikringsselskab ind på det danske marked. Det internetbaserede Next Forsikring vil tage kampen op mod de store etablerede
                        spillere som Tryg og Topdanmark, og selskabet lover lave priser og fjerner nu som det første forsikringsselskab flyttegebyret.
                    </i>
                </p>
                <p>
                    {{ 'PAGE.PRESS.READ_ARTICLE' | translate }}
                    <a target="_blank" rel="nofollow" href="https://borsen.dk/nyheder/opinion/niels-lunde-hovsa-har-forsikringsbranchen-faaet-en-netflix-6034x">{{
                        'PAGE.PRESS.HERE' | translate
                    }}</a>
                </p>
            </div>
        </div>
        <div class="row article-space">
            <div class="col-xs-12 col-sm-4 col-sm-3">
                <img class="img-responsive" src="https://static.next.dk/website/images/pages/press/logo-borsen.png" />
                <span>
                    Børsen
                    <br />
                    26. november 2013
                </span>
            </div>
            <div class="col-xs-12 col-sm-8 col-md-9 seperator">
                <h4>Lavprisselskab angriber forsikringsgiganter</h4>
                <p>
                    <i>
                        "Vi står midt i en digital guldalder," siger adm. direktør i Next Pelle Martin Smidt, der i dag går i luften med et forsikringsselskab på internettet. Foto: Anita
                        Graversen
                    </i>
                </p>
                <p>
                    {{ 'PAGE.PRESS.READ_ARTICLE' | translate }}
                    <a target="_blank" rel="nofollow" href="https://borsen.dk/nyheder/finans/lavprisselskab-angriber-forsikringsgiganter-7bxus">{{ 'PAGE.PRESS.HERE' | translate }}</a>
                </p>
            </div>
        </div>
        <div class="row article-space">
            <div class="col-xs-12 col-sm-4 col-sm-3">
                <img class="img-responsive" src="https://static.next.dk/website/images/pages/press/logo-politiken.png" />
                <span>
                    Politiken
                    <br />
                    25. november 2013
                </span>
            </div>
            <div class="col-xs-12 col-sm-8 col-md-9 seperator">
                <h4>Nyt lavprisselskab vil lave ravage i forsikringsbranchen</h4>
                <p>
                    <i> Der er alt for lidt konkurrence, siger chefen for nyt selskab, der vil ruske op i forsikringsmarkedet. </i>
                </p>
                <p>
                    {{ 'PAGE.PRESS.READ_ARTICLE' | translate }}
                    <a target="_blank" rel="nofollow" href="https://politiken.dk/oekonomi/virksomheder/art5485076/Nyt-lavprisselskab-vil-lave-ravage-i-forsikringsbranchen">{{
                        'PAGE.PRESS.HERE' | translate
                    }}</a>
                </p>
            </div>
        </div>
        <div class="row article-space">
            <div class="col-xs-12 col-sm-4 col-sm-3">
                <img class="img-responsive" src="https://static.next.dk/website/images/pages/press/logo-berlingske.png" />
                <span>
                    Berlingske Business
                    <br />
                    25. november 2013
                </span>
            </div>
            <div class="col-xs-12 col-sm-8 col-md-9 seperator">
                <h4>IT-gazeller vil udfordre forsikringsgiganter</h4>
                <p>
                    <i>
                        Et nyt forsikringsselskab ser dagens lys: Det vil tage kampen op mod de store spillere på det danske forsikringsmarked, der er præget af alt for lidt konkurrence,
                        ifølge bagmændene, som kommer fra IT-selskabet Zitcom.
                    </i>
                </p>
                <p>
                    {{ 'PAGE.PRESS.READ_ARTICLE' | translate }}
                    <a target="_blank" rel="nofollow" href="https://www.business.dk/finans/it-gazeller-vil-udfordre-forsikringsgiganter">{{ 'PAGE.PRESS.HERE' | translate }}</a>
                </p>
            </div>
        </div>
        <div class="row article-space">
            <div class="col-xs-12 col-sm-4 col-sm-3">
                <img class="img-responsive" src="https://static.next.dk/website/images/pages/press/logo-tv2.png" />
                <span>
                    TV2
                    <br />
                    25. november 2013
                </span>
            </div>
            <div class="col-xs-12 col-sm-8 col-md-9 seperator">
                <h4>Nyt lavprisselskab vil udfordre forsikringsgiganter</h4>
                <p>
                    <i> Der er alt for lidt konkurrence, siger chefen for nyt selskab, der vil ruske op i forsikringsmarkedet. </i>
                </p>
                <p>
                    {{ 'PAGE.PRESS.READ_ARTICLE' | translate }}
                    <a target="_blank" rel="nofollow" href="https://nyheder.tv2.dk/samfund/2013-11-25-nyt-lavprisselskab-vil-udfordre-forsikringsgiganter">{{
                        'PAGE.PRESS.HERE' | translate
                    }}</a>
                </p>
            </div>
        </div>
        <div class="row article-space">
            <div class="col-xs-12 col-sm-4 col-sm-3">
                <img class="img-responsive" src="https://static.next.dk/website/images/pages/press/logo-dr.png" />
                <span>
                    DR1
                    <br />
                    25. november 2013
                </span>
            </div>
            <div class="col-xs-12 col-sm-8 col-md-9 seperator">
                <h4>Nyt lavprisselskab vil udfordre forsikringsgiganter</h4>
                <p>
                    <i> Der er alt for lidt konkurrence, siger chefen for nyt selskab, der vil ruske op i forsikringsmarkedet. </i>
                </p>
                <p>
                    {{ 'PAGE.PRESS.READ_ARTICLE' | translate }}
                    <a target="_blank" rel="nofollow" href="https://www.dr.dk/nyheder/penge/nyt-lavprisselskab-vil-udfordre-forsikringsgiganter">{{ 'PAGE.PRESS.HERE' | translate }}</a>
                </p>
            </div>
        </div>
    </div>
    <div class="logo-container">
        <div class="row">
            <h2 class="text-center">{{ 'PAGE.PRESS.LOGO' | translate }}</h2>
            <div class="col-xs-12 logo-center">
                <img id="nextlogo" src="https://static.next.dk/website/images/pages/press/logo-press.png" />
            </div>
            <div class="col-xs-12 text-center">
                <span>
                    <a href="https://static.next.dk/website/images/pages/press/presse_logo_rgb.png" target="_blank" rel="nofollow">PNG</a> |
                    <a href="https://static.next.dk/website/images/pages/press/presse_logo_rgb.ai">AI RGB</a> |
                    <a href="https://static.next.dk/website/images/pages/press/presse_logo_cmyk.ai">AI CMYK</a>
                </span>
            </div>
        </div>
    </div>
    <div class="press-contact-container">
        <div class="row">
            <h2 class="text-center">{{ 'PAGE.PRESS.PRESS_CONTACT' | translate }}</h2>
            <div class="col-xs-12 text-center">
                <span>Tine B. Pedersen</span>
                <p>
                    {{ 'PAGE.PRESS.RESPONSIBLE_TITLE' | translate }}
                    <br />
                    {{ 'PAGE.PRESS.EMAIL' | translate }} <a href="mailto:tbp@next.dk">tbp@next.dk</a> <br />
                    {{ 'PAGE.PRESS.PHONE' | translate }} 92 15 70 79
                </p>
            </div>
        </div>
    </div>
</div>
<next-footer></next-footer>
