import { Address } from 'app/class/address';
import { CoverSelect } from 'app/class/cover-select';
import { Person } from './person';
export class Accident {
    isAdult?: boolean;
    address: Address;
    covers: CoverSelect[];
    occupationCode: string;
    under25Hours?: boolean;
    leisureTime?: boolean;
    forceFull: boolean;
    numberOfChilds?: number;
    persons: Person[];

    sumDisabilityValue: number;
    sumDeathValue?: number;
    adoptAddress = true;
    eventDate?: Date;

    constructor() {
        this.isAdult = null;
        this.address = new Address();
        this.under25Hours = null;
        this.sumDisabilityValue = 0;
        this.sumDeathValue = null;
        this.leisureTime = false;
        this.forceFull = false;
        this.persons = [];
    }
}
