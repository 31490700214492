import { Component, OnInit } from '@angular/core';
import { MetaTagsHelper } from 'app/utils/metaTags/metaTagsHelper';

@Component({
    selector: 'koerselvferie',
    templateUrl: './koerselvferie.component.html',
    styleUrls: ['./koerselvferie.component.less'],
})
export class KoerselvferieComponent implements OnInit {
    constructor(private metaTagsHelper: MetaTagsHelper) {
        this.metaTagsHelper.setTitleAndMetaTags('META.KOERSELVFERIE');
    }

    ngOnInit() {}
}
