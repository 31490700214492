<div class="full-width-image-section terms-top">
    <div class="arrow-up">
        <next-topbar></next-topbar>
        <next-menu></next-menu>

        <div class="container white">
            <div class="row">
                <div class="col-sm-12 text-center">
                    <h1>{{ 'PAGE.TERMS.TITLE' | translate }}</h1>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-4 col-sm-offset-4">
                    <div class="icon-bg center">
                        <div class="icon-wrap">
                            <i class="nf nf-terms-icon-v3"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="subpage">
    <div class="container">
        <div class="row">
            <div class="col-sm-4 text-center">
                <div class="insurance-container">
                    <div class="insurance-box insurance-img img-terms-car">
                        <div class="icon-circle center">
                            <div class="icon-wrap">
                                <i class="nf nf-car-icon-v3"></i>
                            </div>
                        </div>
                    </div>
                    <div class="insurance-bottom-box">
                        <h2>{{ 'INSURANCE.CAR.PRODUCT' | translate }} (01-08-2024)</h2>
                        <next-downloaddocument [product]="8" termsName="01-08-2024"></next-downloaddocument>
                    </div>
                </div>
            </div>

            <div class="col-sm-4 text-center">
                <div class="insurance-container">
                    <div class="insurance-box insurance-img img-terms-car">
                        <div class="icon-circle center">
                            <div class="icon-wrap">
                                <i class="nf nf-car-icon-v3"></i>
                            </div>
                        </div>
                    </div>
                    <div class="insurance-bottom-box">
                        <h2>{{ 'INSURANCE.CAR.PRODUCT' | translate }} (01-10-2021)</h2>
                        <next-downloaddocument [product]="8" termsName="01-10-2021"></next-downloaddocument>
                    </div>
                </div>
            </div>

            <div class="col-sm-4 text-center">
                <div class="insurance-container">
                    <div class="insurance-box insurance-img img-terms-car">
                        <div class="icon-circle center">
                            <div class="icon-wrap">
                                <i class="nf nf-car-icon-v3"></i>
                            </div>
                        </div>
                    </div>
                    <div class="insurance-bottom-box">
                        <h2>{{ 'INSURANCE.CAR.PRODUCT' | translate }} (01-07-2013)</h2>
                        <next-downloaddocument [product]="0"></next-downloaddocument>
                    </div>
                </div>
            </div>

            <div class="col-sm-4 text-center">
                <div class="insurance-container">
                    <div class="insurance-box insurance-img img-terms-house">
                        <div class="icon-circle center">
                            <div class="icon-wrap">
                                <i class="nf nf-house-icon-v3"></i>
                            </div>
                        </div>
                    </div>
                    <div class="insurance-bottom-box">
                        <h2>{{ 'INSURANCE.HOUSE.PRODUCT' | translate }}</h2>
                        <next-downloaddocument [product]="1"></next-downloaddocument>
                    </div>
                </div>
            </div>
            <div class="col-sm-4 text-center">
                <div class="ribbon-parent insurance-container">
                    <div class="insurance-box insurance-img img-terms-home">
                        <div class="icon-circle center">
                            <div class="icon-wrap">
                                <i class="nf nf-home-icon-v3"></i>
                            </div>
                        </div>
                        <ribbon [type]="'travel'"></ribbon>
                    </div>
                    <div class="insurance-bottom-box">
                        <h2>{{ 'INSURANCE.HOME.PRODUCT' | translate }}</h2>
                        <next-downloaddocument [product]="2"></next-downloaddocument>
                    </div>
                </div>
            </div>

            <div class="col-sm-4 text-center">
                <div class="insurance-container">
                    <div class="insurance-box insurance-img img-terms-accident">
                        <div class="icon-circle center">
                            <div class="icon-wrap">
                                <i class="nf nf-accident-icon-v3"></i>
                            </div>
                        </div>
                    </div>
                    <div class="insurance-bottom-box">
                        <h2>{{ 'INSURANCE.ACCIDENT.PRODUCT' | translate }} (01-07-2013)</h2>
                        <next-downloaddocument [product]="3" termsName="01-07-2013"></next-downloaddocument>
                    </div>
                </div>
            </div>

            <div class="col-sm-4 text-center">
                <div class="insurance-container">
                    <div class="insurance-box insurance-img img-terms-accident">
                        <div class="icon-circle center">
                            <div class="icon-wrap">
                                <i class="nf nf-accident-icon-v3"></i>
                            </div>
                        </div>
                    </div>
                    <div class="insurance-bottom-box">
                        <h2>{{ 'INSURANCE.ACCIDENT.PRODUCT' | translate }} (01-01-2024)</h2>
                        <next-downloaddocument [product]="3" termsName="01-01-24"></next-downloaddocument>
                    </div>
                </div>
            </div>

            <div class="col-sm-4 text-center">
                <div class="insurance-container">
                    <div class="insurance-box insurance-img img-terms-dog">
                        <div class="icon-circle center">
                            <div class="icon-wrap">
                                <i class="nf nf-dog-icon-v3"></i>
                            </div>
                        </div>
                    </div>
                    <div class="insurance-bottom-box">
                        <h2>{{ 'INSURANCE.DOG.PRODUCT' | translate }}</h2>
                        <next-downloaddocument [product]="4"></next-downloaddocument>
                    </div>
                </div>
            </div>
            <div class="col-sm-4 text-center">
                <div class="insurance-container">
                    <div class="insurance-box insurance-img img-terms-caravan">
                        <div class="icon-circle center">
                            <div class="icon-wrap">
                                <i class="nf nf-caravan-icon-v3"></i>
                            </div>
                        </div>
                    </div>
                    <div class="insurance-bottom-box">
                        <h2>{{ 'INSURANCE.CARAVAN.PRODUCT' | translate }}</h2>
                        <next-downloaddocument [product]="6"></next-downloaddocument>
                    </div>
                </div>
            </div>

            <div class="col-sm-4 text-center">
                <div class="insurance-container">
                    <div class="insurance-box insurance-img img-terms-secondhouse">
                        <div class="icon-circle center">
                            <div class="icon-wrap">
                                <i class="nf nf-secondhouse-icon-v3"></i>
                            </div>
                        </div>
                    </div>
                    <div class="insurance-bottom-box">
                        <h2>{{ 'INSURANCE.SECONDHOUSE.PRODUCT' | translate }}</h2>
                        <next-downloaddocument [product]="7"></next-downloaddocument>
                    </div>
                </div>
            </div>
            <div class="col-sm-4 text-center">
                <div class="insurance-container">
                    <div class="insurance-box insurance-img img-terms-moped">
                        <div class="icon-circle center">
                            <div class="icon-wrap">
                                <i class="nf nf-moped-icon-v3"></i>
                            </div>
                        </div>
                    </div>
                    <div class="insurance-bottom-box">
                        <h2>{{ 'INSURANCE.MOPED.PRODUCT' | translate }}</h2>
                        <next-downloaddocument [product]="5"></next-downloaddocument>
                    </div>
                </div>
            </div>

            <div class="col-sm-4 text-center">
                <div class="insurance-container">
                    <div class="insurance-box insurance-img img-terms-common">
                        <div class="icon-circle center">
                            <div class="icon-wrap">
                                <i class="nf nf-terms-icon-v3"></i>
                            </div>
                        </div>
                    </div>
                    <div class="insurance-bottom-box">
                        <h2>{{ 'PAGE.TERMS.COMMON_TERMS' | translate }}</h2>
                        <next-downloaddocument [common]="true"></next-downloaddocument>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<trustpilot-carousel></trustpilot-carousel>

<next-footer></next-footer>
