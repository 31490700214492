import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'trustpilot-line',
    templateUrl: './trustpilot-line.component.html',
})
export class TrustpilotLineComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {
        setTimeout(function () {
            // Hack - but without this the noopener button in shown instead of the widget content on page change
            const trustpilotRef = document.getElementById('trustpilot-line');
            window.Trustpilot.loadFromElement(trustpilotRef);
        }, 200);
    }
}
