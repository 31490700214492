<div class="full-width-image-section koer-selv-ferie-top">
    <div class="arrow-up">
        <next-topbar></next-topbar>
        <next-menu></next-menu>

        <div class="container white">
            <div class="row">
                <div class="col-sm-12 text-center header">
                    <h1>Det skal du huske<br />på kør-selv-ferien</h1>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container section1">
    <div class="row">
        <div class="col-sm-10">
            <div class="pull-right">
                <img
                    class="img-responsive"
                    src="https://static.next.dk/website/koerselvferie/sektion-1-grafik.png"
                    srcset="https://static.next.dk/website/koerselvferie/sektion-1-grafik@2x.png 2x"
                />
            </div>
            <h2>Det skal du huske<br />på kør-selv-ferien</h2>

            <p>
                Når du og din familie skal på kør-selv-ferie, er der en række ting i forbindelse med din bilforsikring, du skal have styr på, inden I tager afsted. Derfor har vi lavet denne
                guide, som skal hjælpe dig med at få svar på de forsikringsmæssige spørgsmål, der ofte opstår, når man skal på kør-selv-ferie i udlandet.
            </p>

            <a class="btn btn-success" href="https://static.next.dk/website/Infografik-det-skal-du-huske-paa-koer-selv-ferien.pdf" target="_blank">Hent infografik som PDF</a>
        </div>
    </div>
</div>

<div class="section2">
    <img
        class="absoluteimage visible-lg img-responsive"
        src="https://static.next.dk/website/koerselvferie/sektion-2-grafik.png"
        srcset="https://static.next.dk/website/koerselvferie/sektion-2-grafik@2x.png 2x"
    />
    <div class="container">
        <div class="row">
            <div class="col-lg-4">
                <div class="hidden-lg">
                    <img
                        class="img-responsive"
                        src="https://static.next.dk/website/koerselvferie/sektion-2-grafik.png"
                        srcset="https://static.next.dk/website/koerselvferie/sektion-2-grafik@2x.png 2x"
                    />
                </div>
            </div>
            <div class="col-lg-8">
                <h2>Det røde kort</h2>

                <p>
                    Hvis du har kasko som dækning til din bilforsikring, vil du være dækket af det røde kort. Det gælder bl.a. i forhold til vejhjælp, når du skal på bilferie og køre igennem
                    lande i EU. OBS: Hvis du ikke har tegnet kasko til din bilforsikring, er du ikke dækket af det røde kort. Hvis du og din familie skulle være så uheldige at få problemer
                    med bilen på vej til, under eller på vej hjem fra jeres ferie i Europa, vil det røde kort bl.a. dække:
                </p>

                <div class="checkmarks">
                    <img src="https://static.next.dk/website/koerselvferie/checkmark.svg" />
                    <div class="checkmarktext">Vejhjælp det sted, hvor et uheld sker, eller der opstår problemer med bilen</div>
                    <img src="https://static.next.dk/website/koerselvferie/checkmark.svg" />
                    <div class="checkmarktext">Bugsering af din bil - Den nødvendige transport til dig og din familie</div>
                    <img src="https://static.next.dk/website/koerselvferie/checkmark.svg" />
                    <div class="checkmarktext">Hjemrejse for dig og familien</div>
                    <img src="https://static.next.dk/website/koerselvferie/checkmark.svg" />
                    <div class="checkmarktext">Hjemtransport af din bil</div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <hr />

                <p>
                    <span class="redcard">Det røde kort</span> dækker i Europa og i nogle få lande udenfor de europæiske grænser. Hvis du gerne vil se præcist, hvad og i hvilke lande det
                    røde kort dækker, kan du finde informationen på SOS Internationals hjemmeside.
                    <br />
                    <br />
                    Hvis du skal på bilferie i Europa med familien, er det vigtigt at huske det røde kort. SOS International, som varetager det røde kort, har lavet en app, så du ikke
                    længere behøver at have en fysisk version med af det røde kort. Du kan hente appen ’Rødt Kort’ i App Store eller Google Play. Appen er brugervenlig og gør det let for dig
                    hurtigt at tilkalde vejhjælp gennem appen, hvis der skulle opstå problemer med bilen på vej, under eller hjem fra din bilferie med familien.
                    <br />
                    <br />
                    Du skal dog være opmærksom på, at det røde kort ikke dækker i det land, hvor bilen er indregistreret. Det vil sige, at det røde kort ikke dækker i Danmark, hvis du er på
                    vej på ferie og får brug for vejhjælp inden for de danske grænser. Hvis din bilforsikring skal dække vejhjælp i Danmark, skal du sørge for at have tegnet dækningen til
                    din bilforsikring.
                </p>
            </div>
        </div>
    </div>
</div>

<div class="container section3">
    <div class="row">
        <div class="col-sm-10">
            <h2>Det grønne kort</h2>

            <p>
                Det grønne kort er et bevis på, at man har den lovpligtige ansvarsdækning på sin bil. Den lovpligtige ansvarsforsikring gælder i alle EU-lande. Du kan altid se den komplette
                liste over, hvilke lande din bils lovpligtige ansvarsforsikring gælder på bagsiden af det grønne kort. Det grønne kort er som udgangspunkt ikke et must at have med i bilen,
                når man skal køre til eller igennem et EU-land. Der er dog nogle lande, hvor det er vigtigt, at man har det grønne kort med sig, så man kan bevise, at man har tegnet den
                lovpligtige ansvarsforsikring til sin bil. Vi anbefaler derfor, at du husker at tage det grønne kort med på ferie, hvis dig og din familie skal på bilferie til sommer – og I
                enten skal køre igennem eller til et af de nedenstående lande:
            </p>

            <div class="pull-right">
                <img
                    class="carmap img-responsive"
                    src="https://static.next.dk/website/koerselvferie/sektion-3-grafik.png"
                    srcset="https://static.next.dk/website/koerselvferie/sektion-3-grafik@2x.png 2x"
                />
            </div>

            <div class="checkmarks">
                <img src="https://static.next.dk/website/koerselvferie/checkmark-green.svg" />
                <div class="checkmarktext">Albanien</div>
                <img src="https://static.next.dk/website/koerselvferie/checkmark-green.svg" />
                <div class="checkmarktext">Bosnien</div>
                <img src="https://static.next.dk/website/koerselvferie/checkmark-green.svg" />
                <div class="checkmarktext">Hercegovina</div>
                <img src="https://static.next.dk/website/koerselvferie/checkmark-green.svg" />
                <div class="checkmarktext">Hviderusland</div>
                <img src="https://static.next.dk/website/koerselvferie/checkmark-green.svg" />
                <div class="checkmarktext">Israel</div>
                <img src="https://static.next.dk/website/koerselvferie/checkmark-green.svg" />
                <div class="checkmarktext">Makedonien</div>
                <img src="https://static.next.dk/website/koerselvferie/checkmark-green.svg" />
                <div class="checkmarktext">Moldavien</div>
                <img src="https://static.next.dk/website/koerselvferie/checkmark-green.svg" />
                <div class="checkmarktext">Montenegro</div>
                <img src="https://static.next.dk/website/koerselvferie/checkmark-green.svg" />
                <div class="checkmarktext">Rusland</div>
                <img src="https://static.next.dk/website/koerselvferie/checkmark-green.svg" />
                <div class="checkmarktext">Tunesien</div>
                <img src="https://static.next.dk/website/koerselvferie/checkmark-green.svg" />
                <div class="checkmarktext">Tyrkiet</div>
                <img src="https://static.next.dk/website/koerselvferie/checkmark-green.svg" />
                <div class="checkmarktext">Ukraine</div>
                <img src="https://static.next.dk/website/koerselvferie/checkmark-green.svg" />
                <div class="checkmarktext">Iran</div>
                <img src="https://static.next.dk/website/koerselvferie/checkmark-green.svg" />
                <div class="checkmarktext">Kosovo*</div>
            </div>

            <p>
                <b>OBS:</b> Hvis du skal på bilferie i et af de overstående lande eller skal køre igennem, er det vigtigt, at du får en kopi af modpartens grønne kort, hvis du skulle være så
                uheldig at være involveret i et biluheld.
            </p>

            <p class="small">*Hvis du skal til Kosovo, skal du tegne en særskilt ansvarsforsikring, som kun gælder i det pågældende land, ved grænsen, når du kører ind i landet.</p>
        </div>
    </div>
</div>

<div class="section4">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <h2>Tegn den rigtige bilforsikring</h2>

                <p>
                    Når man tegner en bilforsikring, skal man vælge, hvor mange kilometer man regner med at køre årligt i sin bil. Det kan dog være svært at angive præcist, hvor mange
                    kilometer man har brug for, da behovet naturligvis kan ændre sig. En af de ting, der kan være med til at ændre behovet for det årlige antal kilometer, er hvis man
                    planlægger at tage sin familie med på bilferie igennem Europa og ender med at køre en del flere kilometer i sin bil end først antaget.<br /><br />

                    En bilforsikring bliver bl.a. beregnet efter, hvor mange kilometer man kører årligt i sin bil. Det skyldes, at risikoen for, at man får en skade, er højere, hvis man
                    kører mange kilometer årligt, end hvis man kører få kilometer. Hvis man derfor kører en del flere kilometer årligt end det, man har angivet til sit forsikringsselskab,
                    kan man risikere ikke at få udbetalt den fulde erstatning, hvis man skulle få en skade. Det er derfor vigtigt, at man oplyser det korrekte antal kilometer, man kører
                    årligt, til sit forsikringsselskab, så man er sikker på at være korrekt dækket ind. Hvis du skal på bilferie, anbefaler vi derfor, at du sørger for at tjekke, om dit
                    årlige kørselsbehov på din bilforsikring er dækkende.
                    <br /><br />
                    Hvis du er i tvivl om, hvor mange kilometer du har valgt til din bilforsikring, kan du kontakte og få svar fra dit forsikringsselskab, inden du og familien drager af sted
                    på ferie.
                </p>
                <img
                    class="img-responsive"
                    src="https://static.next.dk/website/koerselvferie/sektion-4-grafik.png"
                    srcset="https://static.next.dk/website/koerselvferie/sektion-4-grafik@2x.png 2x"
                />
            </div>
        </div>
    </div>
</div>

<div class="container section5">
    <div class="row">
        <div class="col-sm-10">
            <h2>
                Hvis du får brug for din<br />
                rejseforsikring på ferien
            </h2>
            <div class="pull-right">
                <img
                    class="img-responsive"
                    src="https://static.next.dk/website/koerselvferie/sektion-5-grafik.png"
                    srcset="https://static.next.dk/website/koerselvferie/sektion-5-grafik@2x.png 2x"
                />
            </div>

            <p>
                Hvis du selv eller et medlem af din familie, som er dækket af en rejseforsikring, skulle få brug for rejseforsikringen under jeres ferie, skal I kontakte SOS International.
                Hos SOS International sidder der læger, sygeplejersker og øvrige specialister klar til at give kompetent og hurtig rådgivning i forhold til jeres situation.
                <br />
                <br />

                <b>Eksempel:</b> Hvis du eller et medlem af familien bliver syg eller kommer til skade på ferien, skal I sørge for at kontakte SOS Internationals døgnbemandede alarmcentral
                på +45 7010 5050. SOS Internationals kompetente medarbejdere vil sørge for at rådgive dig og familien til, hvad I skal foretage jer.
                <br />
                <br />

                Det kan være, at en af jer pga. sygdom eller tilskadekomst skal indlægges og derfor have en ekstraordinær sygetransport hjem. Det kan også være, at jeres hjemrejse bliver
                forsinket grundet sygdommen eller tilskadekomsten. I disse tilfælde vil SOS Internationals læger sørge for at kontakte hospitalet eller den behandlende læge for at lave en
                aftale om den videre behandling, forløb og overførsel.
                <br />
                <br />
                Hvis du skulle være i tvivl om, hvad rejseforsikringen dækker over, hvis du eller familien får brug for jeres rejseforsikring under ferien, er det altid en god ide at
                kontakte sit forsikringsselskab og spørge dem til råds.
            </p>
        </div>
    </div>
</div>

<div class="section6">
    <img
        class="absoluteimage visible-lg img-responsive"
        src="https://static.next.dk/website/koerselvferie/sektion-6-grafik.png"
        srcset="https://static.next.dk/website/koerselvferie/sektion-6-grafik@2x.png 2x"
    />
    <div class="container">
        <div class="row">
            <div class="col-lg-4">
                <div class="hidden-lg">
                    <img
                        class="img-responsive"
                        src="https://static.next.dk/website/koerselvferie/sektion-6-grafik.png"
                        srcset="https://static.next.dk/website/koerselvferie/sektion-6-grafik@2x.png 2x"
                    />
                </div>
            </div>
            <div class="col-lg-8">
                <h2>Det blå sygesikringskort</h2>

                <p>
                    Hvis du skal på ferie til sommer i EU, er det en god ide at få bestilt det blå EU-sygesikringskort til dig selv og resten af familien – og det er udover rejsekortet. Det
                    blå sygesikringskort er et bevis på, at du har ret til at få behandling på samme vilkår, som de borgere, der bor i det land, du rejser igennem eller er på ferie i. Vær
                    opmærksom på, at det blå EU-sygesikringskort kun kan bruges, hvis du rejser igennem eller til et EU-land, til Island, Norge, Schweiz eller Liechtenstein. Det blå
                    EU-sygesikringskort er gratis og kan bestilles til dig selv og resten af familien på borger.dk.
                </p>
            </div>
        </div>
    </div>
</div>

<next-footer></next-footer>
