<div class="modal-mask" id="expired-basket-modal">
    <div class="modal-wrapper">
        <div class="modal-container">
            <div class="circle">
                <div class="circle-icon-wrapper"><i class="nf nf-basket-icon"></i></div>
            </div>

            <h5>{{ 'SHARED.EXPIRED_BASKET.EXPIRED_TITLE' | translate }}</h5>
            <p>{{ 'SHARED.EXPIRED_BASKET.EXPIRED_TEXT' | translate }}</p>

            <button class="btn btn-success close-button" type="button" (click)="onCloseDialogClick()">
                <span class="ng-tns-c11-1">{{ 'SHARED.CONTINUE' | translate }}</span>
            </button>
        </div>
    </div>
</div>
