import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NavigationService } from 'app/utils/navigation/navigation.service';
import { tap } from 'rxjs/operators';
import { DepartmentDetails } from './models/departmentdetails';
import { BlogPostPreview } from './models/blogPostPreview';
import { BlogPost } from './models/blogPost';
import { Vacancy } from './models/vacancy';
import { PagedList } from './models/pagedList';
import { PageContentDetails } from './panel/pageContentDetails';
import { TranslationService } from '../translation/translation.service';

@Injectable()
export class ContexService {
    contentObservablesDictionary: { [pageCode: string]: Observable<PageContentDetails> } = {};

    constructor(public http: HttpClient, private navService: NavigationService, private translationService: TranslationService) {}

    getContent(pagecode: string): Observable<PageContentDetails> {
        const obs = this.contentObservablesDictionary[pagecode];
        if (obs) {
            return obs;
        }

        this.contentObservablesDictionary[pagecode] = new Observable<PageContentDetails>((subscriber) => {
            this.getContentOnce(pagecode).subscribe((pageContentDetails) => {
                subscriber.next(pageContentDetails);
            });

            this.translationService.getLanguageChangeEmitter().subscribe(() => {
                this.getContentOnce(pagecode).subscribe((pageContentDetails) => {
                    subscriber.next(pageContentDetails);
                });
            });
        });

        return this.contentObservablesDictionary[pagecode];
    }

    getDepartments(): Observable<DepartmentDetails[]> {
        const url = `/content/departments?languageCode=${this.translationService.getSelectedLanguage()}`;
        return this.http.get<DepartmentDetails[]>(url);
    }

    getBlogPostPreviews(page: number, pageSize?: number): Observable<BlogPostPreview[]> {
        const url = `/content/blog/previews/${page}/${pageSize}?languageCode=${this.translationService.getSelectedLanguage()}`;
        return this.http.get<BlogPostPreview[]>(url);
    }

    getBlogPost(urlParameter: string): Observable<BlogPost> {
        const url = `/content/blog/post/${urlParameter}?languageCode=${this.translationService.getSelectedLanguage()}`;
        return this.http.get<BlogPost>(url);
    }

    getBlogPostPageCount(): Observable<number> {
        const url = `/content/blog/count`;
        return this.http.get<number>(url);
    }

    getVacancies(page: number, pageSize?: number): Observable<PagedList<Vacancy>> {
        const url = `/content/vacancy/${page}/${pageSize}?languageCode=${this.translationService.getSelectedLanguage()}`;
        return this.http.get<PagedList<Vacancy>>(url);
    }

    getVacancy(vacancyUrl: string): Observable<Vacancy> {
        const url = `/content/vacancy/url/${vacancyUrl}?languageCode=${this.translationService.getSelectedLanguage()}`;
        return this.http.get<Vacancy>(url);
    }

    replaceUrlActionCodes(content: string): string {
        let modifiedContent = content;

        const allObjects = this.navService.getAllObjects();
        for (const key in allObjects) {
            const item = allObjects[key];
            if (item.contexUrlActionCode) {
                modifiedContent = modifiedContent.replace(new RegExp(item.contexUrlActionCode, 'gi'), item.url);
            }
        }

        return modifiedContent;
    }

    private getContentOnce(pagecode: string): Observable<PageContentDetails> {
        const url = `/content/pages/${pagecode}?languageCode=${this.translationService.getSelectedLanguage()}`;
        return this.http.get<PageContentDetails>(url).pipe(
            tap((data) => {
                data.content = this.replaceUrlActionCodes(data.content);
            })
        );
    }
}
