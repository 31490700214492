import { Component, Input, ViewEncapsulation } from '@angular/core';
import { NavigationService } from 'app/utils/navigation/navigation.service';
import { SiteSettingsService } from 'app/utils/sitesettings/site-settings.service';
import { SiteSettingType } from 'next-typescript-api';

@Component({
    moduleId: module.id,
    selector: 'next-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.less'],
    encapsulation: ViewEncapsulation.None, // allows us to style accordion-group
})
export class FooterComponent {
    @Input() simple = false;
    public currentYear: string;
    displayPhoneNumber: boolean;

    constructor(public navigationService: NavigationService, private siteSettingsService: SiteSettingsService) {
        this.currentYear = new Date().getFullYear().toString();

        this.siteSettingsService.getBooleanSetting(SiteSettingType.DisplayNextPhoneNumber).subscribe((display) => (this.displayPhoneNumber = display));
    }
}
