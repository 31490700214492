<div class="container">
    <div class="row">
        <div class="col-sm-12 text-center cover-header">
            <h2>{{ 'INSURANCE.CARAVAN.COMPONENTS.COVER.TITLE' | translate }}</h2>
        </div>
    </div>

    <div class="row">
        <div class="col-sm-4 text-center">
            <div class="insurance-container">
                <a [routerLink]="navigationService.getUrl('cover-caravan-hull')">
                    <div class="ribbon-parent insurance-box insurance-img img-caravan-hull">
                        <ribbon [type]="'required'"></ribbon>
                        <div class="icon-circle center">
                            <div class="icon-wrap">
                                <i class="nf nf-caravan-kasko"></i>
                            </div>
                        </div>
                    </div>
                    <div class="insurance-bottom-box">
                        <h2>{{ 'INSURANCE.CARAVAN.COMPONENTS.COVER.HULL' | translate }}</h2>
                        <p class="readmore">{{ 'SHARED.READ_MORE' | translate }}</p>
                    </div>
                </a>
            </div>
        </div>
        <div class="col-sm-4 text-center">
            <div class="insurance-container">
                <a [routerLink]="navigationService.getUrl('cover-caravan-home')">
                    <div class="ribbon-parent insurance-box insurance-img img-caravan-home">
                        <ribbon [type]="'required'"></ribbon>
                        <div class="icon-circle center">
                            <div class="icon-wrap">
                                <i class="nf nf-caravan-indbo"></i>
                            </div>
                        </div>
                    </div>
                    <div class="insurance-bottom-box">
                        <h2>{{ 'INSURANCE.CARAVAN.COMPONENTS.COVER.HOME' | translate }}</h2>
                        <p class="readmore">{{ 'SHARED.READ_MORE' | translate }}</p>
                    </div>
                </a>
            </div>
        </div>
        <div class="col-sm-4 text-center">
            <div class="insurance-container">
                <a [routerLink]="navigationService.getUrl('cover-caravan-road')">
                    <div class="ribbon-parent insurance-box insurance-img img-caravan-road">
                        <ribbon [type]="'optional'"></ribbon>
                        <div class="icon-circle center">
                            <div class="icon-wrap">
                                <i class="nf nf-caravan-vejhjlp"></i>
                            </div>
                        </div>
                    </div>
                    <div class="insurance-bottom-box">
                        <h2>{{ 'INSURANCE.CARAVAN.COMPONENTS.COVER.ROAD' | translate }}</h2>
                        <p class="readmore">{{ 'SHARED.READ_MORE' | translate }}</p>
                    </div>
                </a>
            </div>
        </div>
    </div>

    <h2 class="text-center">{{ 'INSURANCE.SHARED.CHOOSE_COVERS' | translate }}</h2>

    <cover-table productCode="caravan"></cover-table>
    <br />
    <br />

    <div class="row">
        <div class="col-sm-12 text-center">
            <a class="btn btn-success bottom-spacer-big" [routerLink]="navigationService.getUrl('calculate-caravan')" rel="nofollow">
                {{ 'INSURANCE.CARAVAN.COMPONENTS.COVER.CALCULATE' | translate | uppercase }}
            </a>
        </div>
    </div>
</div>
