import { Component, OnInit, TemplateRef } from '@angular/core';
import { NavigationService } from 'app/utils/navigation/navigation.service';
import { Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { MetaTagsHelper } from 'app/utils/metaTags/metaTagsHelper';
import { AppConfigService } from 'app/utils/appconfig/app-config.service';

@Component({
    moduleId: module.id,
    selector: 'carindex',
    templateUrl: './index.component.html',
    styleUrls: ['./index.component.less'],
})
export class IndexComponent implements OnInit {
    regno: string;
    bsModalRef: BsModalRef;
    loadingProduct = '';
    calculateCarUrl: string;
    useNewCarProduct: boolean;

    constructor(
        public navigationService: NavigationService,
        private router: Router,
        private metaTagsHelper: MetaTagsHelper,
        private modalService: BsModalService,
        private config: AppConfigService
    ) {
        this.metaTagsHelper.setTitleAndMetaTags('META.COVER_CAR_INDEX');
    }

    ngOnInit() {
        this.useNewCarProduct = this.config.appConfig.useNewCarProduct;
        this.calculateCarUrl = this.navigationService.getCarCalculateUrl();
    }

    onLicenseplateChange() {
        this.regno = this.regno.replace(/\s+/g, ''); // remove whitespace
        if (this.regno.length === 7) {
            this.startCarCalculation('car-btn');
        }
    }

    startCarCalculation(product: string) {
        if (!this.regno) {
            this.regno = '';
        }
        this.loadingProduct = product;

        const goto = this.calculateCarUrl + '/1/indtast-nummerplade/' + this.regno;
        this.router.navigate([goto]);
    }

    startWithOutRegNo(product: string) {
        this.loadingProduct = product;
    }

    openModal(template: TemplateRef<any>) {
        this.bsModalRef = this.modalService.show(template);
    }

    closeModal() {
        this.bsModalRef.hide();
    }
}
