<div class="selfcare">
    <div class="selfcare-topmenu">
        <next-topbar></next-topbar>
    </div>
    <div class="selfcare-topmenu">
        <div class="container pad0-large">
            <div class="row">
                <div class="col-sm-3 hidden-xs">
                    <a [routerLink]="'/'">
                        <img src="https://static.next.dk/website/images/logos/next_logo_white.svg" class="navbar-brand" alt="NEXT logo" />
                    </a>
                </div>
                <div class="col-sm-9">
                    <next-selfcaremenu></next-selfcaremenu>
                </div>
            </div>
        </div>
    </div>

    <div class="text-center">
        <div *ngIf="loading">
            <h2>{{ 'PAGE.VERIFY_EMAIL.PLEASE_WAIT' | translate }}</h2>
            <pageloader [show]="true"></pageloader>
            <br />
            <br />
            <br />
        </div>

        <div *ngIf="!loading">
            <div *ngIf="success">
                <h2>{{ 'PAGE.VERIFY_EMAIL.VERIFIED_TITLE' | translate }}</h2>
                <p>
                    {{ 'PAGE.VERIFY_EMAIL.VERIFIED_TEXT' | translate }}
                </p>
            </div>

            <div *ngIf="alreadyVerified">
                <h2>{{ 'PAGE.VERIFY_EMAIL.ALREADY_VERIFIED_TITLE' | translate }}</h2>
                <p>
                    {{ 'PAGE.VERIFY_EMAIL.ALREADY_VERIFIED_TEXT' | translate }} <a [routerLink]="navigationService.getUrl('selfcare')">{{ 'PAGE.VERIFY_EMAIL.HERE' | translate }}</a>
                </p>
            </div>

            <div *ngIf="tokenExpiredOrNotFound">
                <h2>{{ 'PAGE.VERIFY_EMAIL.ERROR' | translate }}</h2>
                <p>
                    {{ 'PAGE.VERIFY_EMAIL.EXPIRED' | translate }}
                    <a [routerLink]="navigationService.getUrl('selfcare-yourinfo')">{{ 'PAGE.VERIFY_EMAIL.HERE' | translate }}</a>
                </p>
            </div>

            <div *ngIf="invalidVerifyData">
                <h2>{{ 'PAGE.VERIFY_EMAIL.ERROR' | translate }}</h2>
                <p>
                    {{ 'PAGE.VERIFY_EMAIL.INVALID' | translate }}
                    <a [routerLink]="navigationService.getUrl('selfcare-yourinfo')">{{ 'PAGE.VERIFY_EMAIL.HERE' | translate }}</a>
                </p>
            </div>

            <div *ngIf="generalError || conflicts">
                <h2>{{ 'PAGE.VERIFY_EMAIL.ERROR' | translate }}</h2>
                <p>
                    {{ 'PAGE.VERIFY_EMAIL.GENERAL_ERROR' | translate }}
                    <a [routerLink]="navigationService.getUrl('selfcare-yourinfo')">{{ 'PAGE.VERIFY_EMAIL.HERE' | translate }}</a>
                </p>
            </div>
        </div>
    </div>
</div>
<next-footer></next-footer>
