<div class="full-width-image-section secondhouse-top">
    <div class="arrow-up">
        <next-topbar></next-topbar>
        <next-menu></next-menu>

        <div class="container white">
            <div class="row">
                <div class="col-sm-12 text-center">
                    <h1>{{ 'INSURANCE.SECONDHOUSE.PRODUCT' | translate }}</h1>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-4 col-sm-offset-4">
                    <div class="icon-bg center">
                        <div class="icon-wrap">
                            <i class="nf nf-secondhouse-icon-v3"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container">
    <div class="row">
        <div class="col-sm-12 text-center">
            <br />
            <br />
            <calculate-btn [gotoLink]="navigationService.getUrl('calculate-secondhouse')" [productName]="'INSURANCE.SECONDHOUSE.PRODUCT' | translate | uppercase"></calculate-btn>
            <br />
            <br />
        </div>
    </div>
</div>

<secondhouse-cover></secondhouse-cover>

<trustpilot-carousel></trustpilot-carousel>

<div class="gray-bg">
    <div class="container">
        <next-faqarticle [category]="'FAQ_SECONDHOUSE'"></next-faqarticle>
    </div>
</div>

<nicetoknow [pagecode]="'INSURANCE_SECONDHOUSE_NICETOKNOW'"></nicetoknow>

<next-footer></next-footer>
