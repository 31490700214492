import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TimeoutComponent } from './timeout.component';
import { CustomPipeModule } from 'app/class/pipe/custompipe.module';
import { RouterModule } from '@angular/router';
import { AuthService } from 'app/auth/auth.service';
import { NavigationService } from 'app/utils/navigation/navigation.service';

@NgModule({
    imports: [CommonModule, CustomPipeModule, RouterModule],
    declarations: [TimeoutComponent],
    exports: [TimeoutComponent],
    providers: [AuthService, NavigationService],
})
export class TimeoutModule {}
