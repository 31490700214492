import { DecimalDisplay } from 'app/class/bax/decimalDisplay';
import { Premium } from 'app/class/bax/premium';

export class PriceResult {
    success: boolean;
    faultMessage: string;
    pricePeriod: DecimalDisplay;
    priceAnnual: DecimalDisplay;
    premiumForCovers: Premium[];
    premiumForAllCovers: Premium[];
}
