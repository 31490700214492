<div class="product-menu" [class]="iconTheme" [class.no-scroll]="!scroll">
    <div class="slidebox">
        <div class="product-item">
            <a (click)="setLoadingProduct('car')" [routerLink]="navigationService.getUrl('cover-car')">
                <i *ngIf="loadingProduct !== 'car'" class="nf nf-car-icon-v3"></i>
                <div *ngIf="loadingProduct === 'car'" class="circle-loader"></div>
                <div class="product-name" [innerHTML]="'FRONTPAGE.CAR' | translate | uppercase"></div>
            </a>
        </div>
        <div class="product-item">
            <a (click)="setLoadingProduct('house')" [routerLink]="navigationService.getUrl('cover-house')">
                <i *ngIf="loadingProduct !== 'house'" class="nf nf-house-icon-v3"></i>
                <div *ngIf="loadingProduct === 'house'" class="circle-loader"></div>
                <div class="product-name" [innerHTML]="'FRONTPAGE.HOUSE' | translate | uppercase"></div>
            </a>
        </div>
        <div class="product-item">
            <a (click)="setLoadingProduct('home')" [routerLink]="navigationService.getUrl('cover-home')">
                <i *ngIf="loadingProduct !== 'home'" class="nf nf-home-icon-v3"></i>
                <div *ngIf="loadingProduct === 'home'" class="circle-loader"></div>
                <div class="product-name" [innerHTML]="'FRONTPAGE.HOME' | translate | uppercase"></div>
            </a>
        </div>
        <div class="product-item">
            <a (click)="setLoadingProduct('accident')" [routerLink]="navigationService.getUrl('cover-accident')">
                <i *ngIf="loadingProduct !== 'accident'" class="nf nf-accident-icon-v3"></i>
                <div *ngIf="loadingProduct === 'accident'" class="circle-loader"></div>
                <div class="product-name" [innerHTML]="'FRONTPAGE.ACCIDENT' | translate | uppercase"></div>
            </a>
        </div>
        <div class="product-item">
            <a (click)="setLoadingProduct('dog')" [routerLink]="navigationService.getUrl('cover-dog')">
                <i *ngIf="loadingProduct !== 'dog'" class="nf nf-dog-icon-v3"></i>
                <div *ngIf="loadingProduct === 'dog'" class="circle-loader"></div>
                <div class="product-name" [innerHTML]="'FRONTPAGE.DOG' | translate | uppercase"></div>
            </a>
        </div>
        <div class="product-item">
            <a (click)="setLoadingProduct('secondhouse')" [routerLink]="navigationService.getUrl('cover-secondhouse')">
                <i *ngIf="loadingProduct !== 'secondhouse'" class="nf nf-secondhouse-icon-v3"></i>
                <div *ngIf="loadingProduct === 'secondhouse'" class="circle-loader"></div>
                <div class="product-name" [innerHTML]="'FRONTPAGE.SECONDHOUSE' | translate | uppercase"></div>
            </a>
        </div>
        <div class="product-item caravan">
            <a (click)="setLoadingProduct('caravan')" [routerLink]="navigationService.getUrl('cover-caravan')">
                <i *ngIf="loadingProduct !== 'caravan'" class="nf nf-caravan-icon-v3"></i>
                <div *ngIf="loadingProduct === 'caravan'" class="circle-loader"></div>
                <div class="product-name" [innerHTML]="'FRONTPAGE.CARAVAN' | translate | uppercase"></div>
            </a>
        </div>
        <div class="product-item">
            <a (click)="setLoadingProduct('moped')" [routerLink]="navigationService.getUrl('cover-moped')">
                <i *ngIf="loadingProduct !== 'moped'" class="nf nf-moped-icon-v3"></i>
                <div *ngIf="loadingProduct === 'moped'" class="circle-loader"></div>
                <div class="product-name" [innerHTML]="'FRONTPAGE.MOPED' | translate | uppercase"></div>
            </a>
        </div>
    </div>
</div>
