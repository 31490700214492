import { Component, OnInit } from '@angular/core';

@Component({
    moduleId: module.id,
    selector: 'next-insurance',
    templateUrl: './insurance.component.html',
})
export class InsuranceComponent implements OnInit {
    public currentPage = '';

    insuranceMenuVisible = false;

    constructor() {}

    ngOnInit(): void {}
}
