import { ContexService } from 'app/utils/contex/contex.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { PagedPanelSearchResult } from './pagedPanelSearchResult';
import { TelemetryService } from 'app/utils/telemetry/telemetry.service';
import { PanelGroupDetails } from './panelGroupDetails';
import { PanelTypeDetails } from './panelTypeDetails';
import { TranslationService } from 'app/utils/translation/translation.service';

@Injectable()
export class ContexPanelService {
    private contexService: ContexService;

    constructor(public http: HttpClient, private contex: ContexService, private telemetryService: TelemetryService, private translationService: TranslationService) {
        this.contexService = this.contex;
    }

    getPanelTypeGroups(paneltypeCode: string): Observable<PanelGroupDetails[]> {
        const url = `/content/panel/${paneltypeCode}/panel-groups?api-version=2.0&languageCode=${this.translationService.getSelectedLanguage()}`;

        return this.http.get<PanelGroupDetails[]>(url).pipe(
            tap((data) => {
                data.forEach((panel) => {
                    panel.panels.forEach((element) => {
                        element.content = this.contexService.replaceUrlActionCodes(element.content);
                        element.subject = this.contexService.replaceUrlActionCodes(element.subject);
                    });
                });
            })
        );
    }

    getPanelType(paneltypeCode: string): Observable<PanelTypeDetails> {
        const url = `/content/panel/${paneltypeCode}?api-version=2.0&languageCode=${this.translationService.getSelectedLanguage()}`;

        return this.http.get<PanelTypeDetails>(url);
    }

    getSearchResult(panelTypeCode: string, panelGroupCode: string, searchValue: string, pageNumber: number, pageSize: number): Observable<PagedPanelSearchResult> {
        let url = `/content/panel/search?paneltypecode=${panelTypeCode}&panelgroupcode=${panelGroupCode}&searchvalue=${searchValue}`;
        url += `&pagenumber=${pageNumber}&pagesize=${pageSize}&api-version=2.0&languageCode=${this.translationService.getSelectedLanguage()}`;

        return this.http.get<PagedPanelSearchResult>(url).pipe(
            tap((data) => {
                this.telemetryService.logEvent('ContexPanelSearchResult', { searchValue });

                data.panels.forEach((element) => {
                    element.content = this.contexService.replaceUrlActionCodes(element.content);
                    element.subject = this.contexService.replaceUrlActionCodes(element.subject);
                });
            })
        );
    }
}
