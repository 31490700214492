import { Component, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';
import { NavigationService } from 'app/utils/navigation/navigation.service';
import { ContexService } from 'app/utils/contex/contex.service';
import { MetaTagsHelper } from 'app/utils/metaTags/metaTagsHelper';
import { Subscription } from 'rxjs';

@Component({
    moduleId: module.id,
    selector: 'agency',
    templateUrl: './agency.component.html',
    styleUrls: ['./agency.component.less'],
    encapsulation: ViewEncapsulation.None, // allows us to style contex content
})
export class AgencyComponent implements OnInit, OnDestroy {
    contex: any;
    subscriptions: Subscription[] = [];

    constructor(public navigationService: NavigationService, public contexService: ContexService, private metaTagsHelper: MetaTagsHelper) {
        this.metaTagsHelper.setTitleAndMetaTags('META.AGENCY');
    }

    ngOnInit(): void {
        const pagecode = this.navigationService.getPathObject('agency').contexPageCode;
        this.subscriptions.push(
            this.contexService.getContent(pagecode).subscribe((x) => {
                this.contex = x;
            })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }
}
