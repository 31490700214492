import { Component, Input } from '@angular/core';

@Component({
    moduleId: module.id,
    selector: 'calculate-btn',
    templateUrl: './calculate-button.component.html',
    styleUrls: ['./calculate-button.component.less'],
})
export class CalculateButtonComponent {
    loadingProduct = false;

    @Input() productName: string;
    @Input() gotoLink: string;

    constructor() {}
}
