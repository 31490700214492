import { Component, OnInit, Input, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ContexService } from 'app/utils/contex/contex.service';
import { PageContentDetails } from 'app/utils/contex/panel/pageContentDetails';

@Component({
    selector: 'nicetoknow',
    templateUrl: './nicetoknow.component.html',
    styleUrls: ['./nicetoknow.component.less'],
    encapsulation: ViewEncapsulation.None, // allows us to style contex content
})
export class NicetoknowComponent implements OnInit, OnDestroy {
    @Input() pagecode: string;
    contex: PageContentDetails;
    subscriptions: Subscription[] = [];

    constructor(private contexService: ContexService) {}

    ngOnInit(): void {
        this.subscriptions.push(
            this.contexService.getContent(this.pagecode).subscribe(x => {
                this.contex = x;
            })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}
