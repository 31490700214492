import { TranslateService } from '@ngx-translate/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { Injectable, OnDestroy } from '@angular/core';

@Injectable()
export class MetaTagsHelper implements OnDestroy {
    private oldTags: { name: string; content: string }[] = [];
    private newTags: HTMLMetaElement[] = [];
    private changedTags: HTMLMetaElement[] = [];

    private author: string;
    constructor(private meta: Meta, private title: Title, private translateService: TranslateService) {
        // only work on tags with names
        this.oldTags = this.meta.getTags('name').map((tag) => ({ name: tag.name, content: tag.content }));

        this.author = this.translateService.instant('META.AUTHOR');
    }

    ngOnDestroy(): void {
        this.restoreOldTags();
    }

    public addTags(tags: MetaDefinition[]) {
        tags.push({ name: 'author', content: this.author });

        tags.forEach((tag) => {
            const existingTag = this.meta.getTag('name = "' + tag.name + '"');

            if (existingTag) {
                this.changedTags.push(existingTag);
                existingTag.content = tag.content;
            } else {
                const newTag = this.meta.addTag(tag);
                this.newTags.push(newTag);
            }
        });
    }

    public restoreOldTags() {
        this.newTags.forEach((tag) => {
            this.meta.removeTagElement(tag);
        });

        this.changedTags.forEach((tag) => {
            const oldTag = this.oldTags.find((ot) => ot.name === tag.name);
            tag.content = oldTag ? oldTag.content : tag.content;
        });
    }

    public setTitle(title: string) {
        this.title.setTitle(title);
    }

    public setDescription(description: string) {
        this.addTags([
            {
                name: 'description',
                content: description,
            },
        ]);
    }

    public setTitleAndMetaTags(page: string) {
        const titleKey = page + '.TITLE';
        const title = this.translateService.instant(titleKey);
        if (title !== titleKey) {
            this.title.setTitle(title);
        }

        const tags: MetaDefinition[] = [];
        const keywordsKey = page + '.KEYWORDS';
        const keywords = this.translateService.instant(keywordsKey);
        if (keywords !== keywordsKey) {
            tags.push({
                name: 'keywords',
                content: keywords,
            });
        }

        const descriptionKey = page + '.DESCRIPTION';
        const description = this.translateService.instant(descriptionKey);
        if (description !== descriptionKey) {
            tags.push({
                name: 'description',
                content: description,
            });
        }
        this.addTags(tags);
    }
}
