import { TranslationService } from 'app/utils/translation/translation.service';
import { CalculateService } from './calculate/calculate.service';
import { BaxService } from './utils/bax/bax.service';

import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, PLATFORM_ID, Inject, Injector } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
export { AppComponent };
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FrontpageModule } from './frontpage/frontpage.module';
import { InsuranceModule } from './insurance/insurance.module';
import { PageModule } from './page/page.module';
import { FunctionsService } from 'app/utils/functions/functions.service';
import { ChatService } from 'app/utils/chat/chat.service';
import { BasketHeardAboutService } from 'app/utils/basketheardabout/basketheardabout.service';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { CustomPipeModule } from 'app/class/pipe/custompipe.module';
import { AuthService } from 'app/auth/auth.service';
import { TokenStorage } from 'app/auth/token-storage.service';
import { AuthRequestInterceptor } from 'app/auth/authrequest.interceptor';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { TelemetryService } from './utils/telemetry/telemetry.service';
import { TelemetryInterceptor } from './utils/telemetry/telemetry.interceptor';
import { GoogleMapsService } from './utils/googlemaps/googlemaps.service';
import { AppConfigService } from './utils/appconfig/app-config.service';
import { ContactRequestService } from './utils/contactrequest/contactrequest.service';
import { MailListSubscriberService } from './utils/maillistsubscriber/maillistsubscriber.service';
import { MessageModule } from './utils/message/message.module';
import { TimeoutModule } from './selfcare/timeout/timeout.module';
import { RetryInterceptor } from './utils/retry/retry.interceptor';
import { MetaTagsHelper } from './utils/metaTags/metaTagsHelper';
import { InsuranceDataService } from './utils/insurancedata/insurancedata.service';
import { ProductResolver } from './calculate/productresolver';
import { CampaignService } from './utils/campaign/campaign.service';
import { ConsoleloggerModule } from './shared/consolelogger/consolelogger.module';
import { CustomerOfferService } from './selfcare/tasks/customeroffer.service';
import { ObservableHelper } from './utils/observableHelper/observableHelper';
import { PageChangeTracker } from './utils/tracking/pageChangeTracker';
import { ChatConfigurator } from './utils/chat/chatConfigurator';
import { TranslateModule } from '@ngx-translate/core';
import { LeadCampaignLandingService } from './utils/leadCampaignLanding/leadCampaignLanding.service';
import { CalculateHelperModule } from './calculate/helper/calculate-helper.module';
import { MaintenanceInfoModule } from './shared/maintenance-info/maintenance-info.module';
import { TrackingService } from './utils/tracking/tracking.service';

// Runs before anything else
const appInitializerFn = (appConfig: AppConfigService, translationService: TranslationService, ts: TelemetryService, gms: GoogleMapsService) => () =>
    appConfig.loadAppConfig().then(() =>
        translationService.loadLanguages().then(() => {
            ts.init();
            gms.init();
        })
    );

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule.withServerTransition({ appId: 'next-v3' }),
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        FrontpageModule,
        CustomPipeModule,
        InsuranceModule,
        PageModule,
        TooltipModule.forRoot(),
        MessageModule,
        TimeoutModule,
        ModalModule.forRoot(),
        ConsoleloggerModule,
        TranslateModule.forRoot(),
        CalculateHelperModule,
        MaintenanceInfoModule,
    ],
    exports: [AppComponent],
    providers: [
        AppConfigService,
        TranslationService,
        TelemetryService,
        GoogleMapsService,
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFn,
            multi: true,
            deps: [AppConfigService, TranslationService, TelemetryService, GoogleMapsService],
        },
        FunctionsService,
        AuthService,
        BaxService,
        BsModalService,
        ChatService,
        BasketHeardAboutService,
        TokenStorage,
        BsModalService,
        MetaTagsHelper,
        InsuranceDataService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthRequestInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TelemetryInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RetryInterceptor,
            multi: true,
        },
        ContactRequestService,
        ChatConfigurator,
        MailListSubscriberService,
        ProductResolver,
        TrackingService,
        CalculateService,
        CampaignService,
        CustomerOfferService,
        ObservableHelper,
        PageChangeTracker,
        LeadCampaignLandingService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    static injector: Injector;
    constructor(injector: Injector, @Inject(PLATFORM_ID) private readonly platformId: any) {
        AppModule.injector = injector;
    }
}
