import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RibbonComponent } from './components/ribbon/ribbon.component';

@NgModule({
    declarations: [RibbonComponent],
    imports: [CommonModule],
    exports: [RibbonComponent],
})
export class RibbonModule {}
