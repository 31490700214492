import { AppConfigService } from 'app/utils/appconfig/app-config.service';
import { Injectable, EventEmitter } from '@angular/core';
import { PriceResult } from 'app/class/priceresult';
import { AuthService } from 'app/auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { InsuranceProvider, AddClaimHistoryInput } from 'next-typescript-api';
import { ObservableHelper } from 'app/utils/observableHelper/observableHelper';

@Injectable({
    providedIn: 'root',
})
export class SelfcareService {
    public step: number;
    public totalSteps: number;

    public showSendBasket: boolean;
    public showSummaryBasket: boolean;
    progress$: Observable<number>;
    showSendBasket$: Observable<boolean>;
    showSummaryBasket$: Observable<boolean>;
    showVideo$: Observable<boolean>;
    showCalculateButton$: Observable<boolean>;
    calculating$: Observable<boolean>;
    priceResult$: Observable<PriceResult>;

    claimTaskEmitter: EventEmitter<any> = new EventEmitter();

    videoEmitter: EventEmitter<any> = new EventEmitter();
    private apiUrl: string;

    private progressSource = new BehaviorSubject<number>(0);

    private sendBasketSource = new BehaviorSubject<boolean>(false);

    private summaryBasketSource = new BehaviorSubject<boolean>(false);

    private showVideoSource = new BehaviorSubject<boolean>(false);

    private showCalculateButtonSource = new BehaviorSubject<boolean>(false);

    private calculatingSource = new BehaviorSubject<boolean>(false);

    private priceResultSource = new BehaviorSubject<PriceResult>(new PriceResult());
    // CustomerClaimHistoryController end

    private header = new Subject<string>();

    constructor(private http: HttpClient, private config: AppConfigService, private auth: AuthService, private observableHelper: ObservableHelper) {
        this.apiUrl = this.config.appConfig.apiHost;
        this.progress$ = this.progressSource.asObservable();
        this.showSendBasket$ = this.sendBasketSource.asObservable();
        this.showSummaryBasket$ = this.summaryBasketSource.asObservable();
        this.showVideo$ = this.showVideoSource.asObservable();
        this.showCalculateButton$ = this.showCalculateButtonSource.asObservable();
        this.calculating$ = this.calculatingSource.asObservable();
        this.priceResult$ = this.priceResultSource.asObservable();
    }

    getClaimTaskEmitter() {
        return this.claimTaskEmitter;
    }

    removeClaim(index: number) {
        this.claimTaskEmitter.emit(index);
    }

    getVideoEmitter() {
        return this.videoEmitter;
    }

    hideVideo() {
        this.videoEmitter.emit();
    }

    updateStep(step: number): void {
        this.step = step;
        this.progressSource.next((this.step / this.totalSteps) * 100);
    }

    toggleSendBasket(): void {
        this.showSendBasket = !this.showSendBasket;
        if (this.showSendBasket && this.showSummaryBasket) {
            this.toggleSummaryBasket();
        }
        this.sendBasketSource.next(this.showSendBasket);
    }

    toggleSummaryBasket(): void {
        this.showSummaryBasket = !this.showSummaryBasket;
        this.summaryBasketSource.next(this.showSummaryBasket);
    }

    calculating(calc: boolean): void {
        this.calculatingSource.next(calc);
    }

    toggleCalculateButton(show: boolean): void {
        this.showCalculateButtonSource.next(show);
    }

    updatePrice(result: PriceResult): void {
        this.priceResultSource.next(result);
    }

    // InsuranceDataController start
    getInsuranceProviders(): Observable<InsuranceProvider[]> {
        const url = `${this.apiUrl}/insurancedata/general/insuranceproviders?api-version=2.0`;
        return this.http.get<InsuranceProvider[]>(url);
    }
    // InsuranceDataController end

    // CustomerClaimHistoryController start
    addClaimHistoryAnswer(addclaimhistoryinput: AddClaimHistoryInput): Observable<boolean> {
        const customerId = this.auth.getCustomerId();

        if (!customerId) {
            return this.observableHelper.CreateFailedObservableAndLogStackTrace('Missing customer number');
        }

        const url = `${this.apiUrl}/customers/${customerId}/claimhistories?api-version=2.0`;
        return this.http.post(url, addclaimhistoryinput, { observe: 'response' }).pipe(
            map((resp) => {
                const statusCode = resp.status;

                if (statusCode === 200 || statusCode === 204) {
                    return true;
                }

                return false;
            })
        );
    }
    setHeader(header: string) {
        this.header.next(header);
    }

    getHeader(): Observable<string> {
        return this.header.asObservable();
    }
}
