<div id="consent">
    <div class="full-width-image-section disclaimer-top">
        <div class="arrow-up">
            <next-topbar></next-topbar>
            <next-menu></next-menu>

            <div class="container white">
                <div class="row">
                    <div class="col-sm-12 text-center">
                        <h1>{{ 'PAGE.CONSENT.TITLE' | translate }}</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container container-padding-20-40" *ngIf="consentGiven === false">
        <div class="row" *ngIf="pageContent">
            <div [innerHTML]="pageContent.content | safehtml"></div>
        </div>
        <br />
        <div class="row">
            <button class="btn btn-success pull-right" [class.loading]="isLoading" [disabled]="isLoading" (click)="sign()">{{ 'PAGE.CONSENT.GO_TO_SIGNING' | translate }}</button>
        </div>
    </div>

    <div class="container container-padding-20-40" *ngIf="consentGiven === true">
        <div class="row">
            <p>{{ 'PAGE.CONSENT.THANKS_FOR_CONSENT' | translate }}</p>
        </div>
        <br />
        <div class="row">
            <button class="btn btn-success pull-right" [class.loading]="isLoading" [disabled]="isLoading" [routerLink]="navigationService.getUrl('frontpage')" target="_blank">
                {{ 'PAGE.CONSENT.GO_TO_FRONTPAGE' | translate }}
            </button>
        </div>
    </div>

    <div class="container container-padding-20-40" *ngIf="error">
        <div class="row">
            <h3 class="error-message">{{ error }}</h3>
        </div>
        <br />
        <div class="row" *ngIf="pageContent">
            <div [innerHTML]="pageContent.content | safehtml"></div>
        </div>
        <br />
        <div class="row">
            <button class="btn btn-success pull-right" [class.loading]="isLoading" [disabled]="isLoading" (click)="sign()">{{ 'PAGE.CONSENT.GO_TO_SIGNING' | translate }}</button>

            <button class="btn btn-success pull-right" [class.loading]="isLoading" [disabled]="isLoading" [routerLink]="navigationService.getUrl('frontpage')" target="_blank">
                {{ 'PAGE.CONSENT.GO_TO_FRONTPAGE' | translate }}
            </button>
        </div>
    </div>

    <next-footer></next-footer>
</div>
