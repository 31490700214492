import { Observable, of, Subscription } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from 'app/utils/appconfig/app-config.service';
import { AuthService } from 'app/auth/auth.service';
import { Language, OfferInsurance, OfferSetEdi, UnApprovedOffersSignRequest } from 'next-typescript-api';
import { ObservableHelper } from 'app/utils/observableHelper/observableHelper';
import { SigningDataExtended } from 'app/shared/nem-id-signing/NemIdSigningDataExtended';

// CustomerOfferController
@Injectable()
export class CustomerOfferService implements OnDestroy {
    private apiUrl: string;
    private offersData: OfferInsurance[];
    private offersObservable: Observable<OfferInsurance[]>;
    private subscriptions: Subscription[] = [];
    private missingCustomerMessage = 'Missing customer number';

    constructor(private http: HttpClient, private config: AppConfigService, private auth: AuthService, private observableHelper: ObservableHelper) {
        this.apiUrl = this.config.appConfig.apiHost;
        const subscription = this.auth.clearTokenObserveable.subscribe(this.invalidateCache.bind(this));
        this.subscriptions.push(subscription);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    invalidateCache() {
        this.offersData = null;
    }

    getOffers(signed: boolean): Observable<OfferInsurance[]> {
        const customerId = this.auth.getCustomerId();

        if (!customerId) {
            return this.observableHelper.CreateFailedObservableAndLogStackTrace(this.missingCustomerMessage);
        }

        if (this.offersData) {
            return of(this.offersData);
        } else if (this.offersObservable) {
            return this.offersObservable;
        } else {
            const url = `${this.apiUrl}/customers/${customerId}/offers?signed=${signed}&api-version=2.0`;
            this.offersObservable = this.http
                .get<OfferInsurance[]>(url, {
                    observe: 'response',
                })
                .pipe(
                    map((response) => {
                        this.offersObservable = null;
                        if (response.status === 200) {
                            this.offersData = response.body;
                            return this.offersData;
                        }

                        throw new Error('Failed to get offers');
                    }),
                    share()
                );
            return this.offersObservable;
        }
    }

    getOffer(offerId: string): Observable<OfferInsurance> {
        if (this.offersData) {
            const offer = this.offersData.find((o) => o.offerId === offerId);

            if (offer) {
                return of(offer);
            }
        }

        const customerId = this.auth.getCustomerId();

        if (!customerId) {
            return this.observableHelper.CreateFailedObservableAndLogStackTrace(this.missingCustomerMessage);
        }

        const url = `${this.apiUrl}/customers/${customerId}/offers/` + offerId + '?api-version=2.0';
        return this.http.get<OfferInsurance>(url);
    }

    setEdi(offerId: string, edi: boolean) {
        const customerId = this.auth.getCustomerId();

        if (!customerId) {
            return this.observableHelper.CreateFailedObservableAndLogStackTrace(this.missingCustomerMessage);
        }

        const body: OfferSetEdi = {
            isEdi: edi,
        };

        const url = `${this.apiUrl}/customers/${customerId}/offers/` + offerId + '/set-edi?api-version=2.0';
        return this.http.post(url, body);
    }

    getUnapprovedOfferSigning(customerId: string, offerId: string, redirectUri: string = null, language: Language = null): Observable<SigningDataExtended> {
        let url = `${this.apiUrl}/customers/${customerId}/unapprovedoffers/${offerId}/signing-data?api-version=2.0`;

        if (redirectUri) {
            url += `&redirectUri=${redirectUri}`;
        }

        if (language) {
            url += `&language=${language}`;
        }

        return this.http.post<SigningDataExtended>(url, null);
    }

    signUnapprovedOffers(customerId: string, offerId: string, signRequest: UnApprovedOffersSignRequest) {
        const url = `${this.apiUrl}/customers/${customerId}/unapprovedoffers/${offerId}/sign?api-version=2.0`;
        return this.http.post(url, signRequest);
    }

    signUnapprovedOffersForSasExternalLogin(customerId: string, offerId: string, signRequest: UnApprovedOffersSignRequest) {
        const url = `${this.apiUrl}/customers/${customerId}/unapprovedoffers/${offerId}/approve?api-version=2.0`;
        return this.http.post(url, signRequest);
    }
}
