<div class="full-width-image-section reportdamage-top">
    <div class="arrow-up">
        <next-topbar [selfcaremodeoverride]="false"></next-topbar>
        <next-menu [selfcaremodeoverride]="false"></next-menu>

        <div class="video-container">
            <ng-template #reportdamageModal>
                <next-videomodal [videoid]="'S-xz51d8qGg'" [bsModalRef]="bsModalRef"></next-videomodal>
            </ng-template>
        </div>

        <div class="container white" (click)="openModal(reportdamageModal)">
            <div class="row">
                <div class="col-sm-12 text-center">
                    <h1>{{ 'PAGE.REPORT_DAMAGE.TITLE' | translate }}</h1>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12 text-center play-video-icon">
                    <i class="nf-play-video-icon-v3"></i>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container login-container">
    <div class="row">
        <div class="col-xs-12 col-sm-6">
            <h3>{{ 'PAGE.REPORT_DAMAGE.REPORT_GLASS_DAMAGE_TITLE' | translate }}</h3>
            <p class="reportdamage-text">
                {{ 'PAGE.REPORT_DAMAGE.REPORT_GLASS_DAMAGE_START' | translate }}
                <a class="linkBilglas text-green" href="https://bilglas.dk/forsikring/41/next" target="_blank">{{ 'PAGE.REPORT_DAMAGE.REPORT_GLASS_DAMAGE_CLICK' | translate }}</a
                >{{ 'PAGE.REPORT_DAMAGE.REPORT_GLASS_DAMAGE_END' | translate }}
            </p>
            <div class="border-bottom"></div>
            <h3>{{ 'PAGE.REPORT_DAMAGE.URGENT_TITLE' | translate }}</h3>
            <p class="reportdamage-text">
                {{ 'PAGE.REPORT_DAMAGE.URGENT_TEXT' | translate }}
            </p>
            <div class="text-bold reportdamage-contact">
                <p>{{ 'PAGE.REPORT_DAMAGE.URGENT_NAME' | translate }}</p>
                <p>{{ 'PAGE.REPORT_DAMAGE.URGENT_PHONE' | translate }}</p>
                <p>
                    {{ 'PAGE.REPORT_DAMAGE.URGENT_EMAIL' | translate }}
                    <a class="text-green" href="mailto:info@polygondb.dk">info@polygondb.dk</a>
                </p>
            </div>
        </div>

        <div class="col-xs-12 col-sm-6 border-left">
            <div class="border-bottom visible-xs"></div>
            <h3>{{ 'PAGE.REPORT_DAMAGE.LOG_ON_TITLE' | translate }}</h3>
            <p class="reportdamage-text">{{ 'PAGE.REPORT_DAMAGE.LOG_ON_TEXT' | translate }}</p>
            <nemid-login *ngIf="useMitId === false" (loginComplete)="nemIdLoginComplete()"></nemid-login>
            <mit-id-login *ngIf="useMitId === true" [redirectSuccessUrl]="mitIdRedirectSuccessUrl" [redirectFailUrl]="mitIdRedirectFailUrl"></mit-id-login>
        </div>
    </div>
</div>

<next-footer></next-footer>
