import { Address } from 'app/class/address';
import { CoverSelect } from 'app/class/cover-select';
import { CoverSelectDisallowedReason } from 'app/class/cover-select-disallowed-reason';

export class Car {
    dmr: boolean;
    foundDmr: boolean;
    regNo: string;
    variantId: string;
    coversDisallowed: CoverSelectDisallowedReason[] = [];
    newValueAction: number;
    brandName: string;
    brandId: string;
    modelId: string;
    modelName: string;
    year?: number;
    engine: string;
    userAge?: number;
    yearsOwningCar?: number;
    maxKmPrYearId: string;
    hasHome?: boolean;
    deductibleId: string;
    address: Address;
    covers: CoverSelect[];
    goodRating: boolean;
    licenceplateWhite?: boolean;
    variantIds?: string[];
    eventDate?: Date;

    constructor() {
        this.dmr = false;
        this.foundDmr = false;
        this.regNo = '';
        this.coversDisallowed = [];
        this.newValueAction = 0;
        this.brandName = '';
        this.modelName = '';
        this.year = null;
        this.deductibleId = '';
        this.maxKmPrYearId = '';
        this.address = {
            addressText: '',
            addressId: '',
        };

        this.goodRating = false;
        this.licenceplateWhite = null;
        this.variantIds = [];
    }
}
