<div class="padding-0-0-50">
    <h2 class="text-center">{{ 'SHARED.FAQ.COMPONENTS.FAQ_ARTICLE.FAQ' | translate }}</h2>
    <div class="faq-subpage">
        <div *ngFor="let category of contex">
            <div *ngFor="let panel of category.panels">
                <ul class="faq-question">
                    <li>
                        <accordion [closeOthers]="'true'">
                            <accordion-group #faq1 is-open="status.open" [panelClass]="'faq-accordion-group'">
                                <div accordion-heading class="faq-header row">
                                    <div class="question-text col-xs-10 col-sm-11">
                                        <span [innerHTML]="panel.subject"></span>
                                    </div>
                                    <div class="col-xs-2 col-sm-1">
                                        <i class="pull-right" [ngClass]="{ 'nf-faq-down': faq1?.isOpen, 'nf-faq-right': !faq1?.isOpen }"></i>
                                    </div>
                                </div>
                                <div class="answer-text">
                                    <div [innerHTML]="panel.content | safehtml"></div>
                                </div>
                            </accordion-group>
                        </accordion>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
