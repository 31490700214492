import { CoverInfoComponent } from './../components/cover-info/cover-info.component';
import { Routes } from '@angular/router';
import { IndexComponent } from './components/index/index.component';
import { CoverInfoSettings } from '../components/cover-info/CoverInfoSettings';
import { ContexpageComponent } from '../contexpage/components/contexpage/contexpage.component';
import { RibbonType } from 'app/class/cover';

export const carRoutes: Routes = [
    { path: '', component: IndexComponent },
    // { path: 'ansvarsforsikring', component: CoverInfoComponent, data: new CoverInfoSettings('car', 'liability', 'nf nf-car-ansvar') },
    // { path: 'retshjaelpforsikring-bil', component: CoverInfoComponent, data: new CoverInfoSettings('car', 'court', 'nf nf-car-retshjlp') },
    // { path: 'kaskoforsikring', component: CoverInfoComponent, data: new CoverInfoSettings('car', 'comprehensive', 'nf nf-car-kasko', RibbonType.optional) },
    // { path: 'foererulykkeforsikring', component: CoverInfoComponent, data: new CoverInfoSettings('car', 'driveraccident', 'nf nf-car-frerulykke', RibbonType.optional) },
    // { path: 'vejhjaelp', component: CoverInfoComponent, data: new CoverInfoSettings('car', 'road', 'nf nf-car-vejhjlp', RibbonType.optional) },
    // { path: 'udvidet-glasforsikring', component: CoverInfoComponent, data: new CoverInfoSettings('car', 'glass', 'nf nf-car-udvidetglas', RibbonType.optional) },

    { path: 'ansvarsforsikring', component: CoverInfoComponent, data: new CoverInfoSettings('car_simple', 'liability', 'nf nf-car-ansvar') },
    { path: 'retshjaelpforsikring-bil', component: CoverInfoComponent, data: new CoverInfoSettings('car_simple', 'court', 'nf nf-car-retshjlp') },
    { path: 'kaskoforsikring-ny', component: CoverInfoComponent, data: new CoverInfoSettings('car_simple', 'comprehensive', 'nf nf-car-kasko', RibbonType.optional) },
    { path: 'foererpladsdaekning', component: CoverInfoComponent, data: new CoverInfoSettings('car_simple', 'driveraccident', 'nf nf-car-frerulykke', RibbonType.optional) },
    { path: 'vejhjaelp', component: CoverInfoComponent, data: new CoverInfoSettings('car_simple', 'road', 'nf nf-car-vejhjlp', RibbonType.optional) },
    { path: 'udvidet-glasforsikring-ny', component: CoverInfoComponent, data: new CoverInfoSettings('car_simple', 'glass', 'nf nf-car-udvidetglas', RibbonType.optional) },
    { path: 'friskade', component: CoverInfoComponent, data: new CoverInfoSettings('car_simple', 'no-deductible', 'nf nf-car-friskade', RibbonType.optional) },
    { path: 'parkeringsskade', component: CoverInfoComponent, data: new CoverInfoSettings('car_simple', 'parking', 'nf nf-car-parking', RibbonType.optional) },
    { path: 'plusdaekning', component: CoverInfoComponent, data: new CoverInfoSettings('car_simple', 'plus', 'nf nf-car-plus', RibbonType.optional) },
    // { path: 'eftermonteret-ekstraudstyr', component: CoverInfoComponent, data: new CoverInfoSettings('car_simple', 'extra', 'nf nf-car-extra', RibbonType.optional) },
    // { path: 'trailer', component: CoverInfoComponent, data: new CoverInfoSettings('car_simple', 'trailer', 'nf nf-car-trailer', RibbonType.optional) },
    // { path: 'leasing', component: CoverInfoComponent, data: new CoverInfoSettings('car_simple', 'leasing', 'nf nf-car-leasing', RibbonType.optional) },
    // { path: 'afsavn', component: CoverInfoComponent, data: new CoverInfoSettings('car_simple', 'courtesycar', 'nf nf-car-afsavn', RibbonType.optional) },

    { path: 'bonus-anciennitet', redirectTo: '', pathMatch: 'full' },
    { path: 'roedt-og-groent-kort', redirectTo: '', pathMatch: 'full' },
    { path: 'ind-og-omregistrering', redirectTo: '', pathMatch: 'full' },
    { path: 'roede-og-groen-kort', component: ContexpageComponent },
];
