import { DawaService } from 'app/utils/dawa/dawa.service';
import { Observable, forkJoin, Subscription } from 'rxjs';
import { AppConfigService } from 'app/utils/appconfig/app-config.service';
import { Injectable, OnDestroy } from '@angular/core';
import { Product } from 'app/class/product';
import { Cover } from 'app/class/cover';
import { BaxService } from 'app/utils/bax/bax.service';
import { Policy } from 'app/selfcare/models/policy';
import { BasketInsuranceDetails, Address, DataCalculation } from 'app/class/bax/basketInsuranceDetails';
import { TotalPriceResult } from './models/totalPriceResult';
import { HttpClient } from '@angular/common/http';
import { BasketInsuranceCalculationDetails } from 'app/class/bax/basketInsuranceCalculationDetails';
import { Subject } from 'rxjs';
import { CarService } from 'app/calculate/car/car.service';
import { ProductCovers } from './models/productCovers';
import { PolicyEdit } from 'app/selfcare/models/policyedit';
import { DeductibleItem, PolicyInsurance, CoverPackageInfo, ProductType } from 'next-typescript-api';
import { TranslateService } from '@ngx-translate/core';
import { ProductApiService } from './product-api.service';
import { ThousandSeperatorFloatDisplayPipe } from 'app/class/pipe/thousandSeperatorFloatDisplayPipe';
import { MopedService } from 'app/calculate/moped/moped.service';

@Injectable()
export class ProductService implements OnDestroy {
    subscriptions: Subscription[] = [];
    baskettotal$: Observable<TotalPriceResult>;
    private apiUrl: string;

    private baskettotalSource = new Subject<TotalPriceResult>();

    constructor(
        private http: HttpClient,
        private config: AppConfigService,
        private baxService: BaxService,
        private dawaService: DawaService,
        private carService: CarService,
        private translateService: TranslateService,
        private productApiService: ProductApiService,
        private mopedService: MopedService
    ) {
        this.apiUrl = this.config.appConfig.apiHost;
        this.baskettotal$ = this.baskettotalSource.asObservable();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    getCoverPackages(productCode: string): Observable<CoverPackageInfo[]> {
        return this.productApiService.getCoverPackages(productCode);
    }

    getDeductibles(product: string): Observable<DeductibleItem[]> {
        if (product === 'accident' || product === 'dog' || product === 'moped') {
            console.log('No deductibles available for ' + product);
            return;
        }

        const productType = this.getProductTypeByProductCode(product);

        const url = `${this.apiUrl}/insurancedata/general/deductible/${productType}?api-version=2.0`;
        return this.http.get<DeductibleItem[]>(url);
    }

    getProductByCode(code: string): Product {
        if (code == null || code.length < 1) {
            return null;
        }

        switch (code.toLowerCase()) {
            case 'car_simple':
                return {
                    code: 'car_simple',
                    insuranceName: this.translateService.instant('INSURANCE.CAR.PRODUCT'),
                    icon: 'nf nf-car-icon-v3',
                    insuranceNameShort: 'Bil', // Never used - delete?
                    id: ProductType.CarSimple,
                };
            case 'car':
                return {
                    code: 'car',
                    insuranceName: this.translateService.instant('INSURANCE.CAR.PRODUCT'),
                    icon: 'nf nf-car-icon-v3',
                    insuranceNameShort: 'Bil',
                    id: ProductType.Car,
                };

            case 'house':
                return {
                    code: 'house',
                    insuranceName: this.translateService.instant('INSURANCE.HOUSE.PRODUCT'),
                    icon: 'nf nf-house-icon-v3',
                    insuranceNameShort: 'Hus',
                    id: ProductType.House,
                };

            case 'home':
                return {
                    code: 'home',
                    insuranceName: this.translateService.instant('INSURANCE.HOME.PRODUCT'),
                    icon: 'nf nf-home-icon-v3',
                    insuranceNameShort: 'Indbo',
                    id: ProductType.Home,
                };

            case 'accident':
                return {
                    code: 'accident',
                    insuranceName: this.translateService.instant('INSURANCE.ACCIDENT.PRODUCT'),
                    icon: 'nf nf-accident-icon-v3',
                    insuranceNameShort: 'Ulykke',
                    id: ProductType.Accident,
                };

            case 'dog':
                return {
                    code: 'dog',
                    insuranceName: this.translateService.instant('INSURANCE.DOG.PRODUCT'),
                    icon: 'nf nf-dog-icon-v3',
                    insuranceNameShort: 'Hund',
                    id: ProductType.Dog,
                };

            case 'moped':
                return {
                    code: 'moped',
                    insuranceName: this.translateService.instant('INSURANCE.MOPED.PRODUCT'),
                    icon: 'nf nf-moped-icon-v3',
                    insuranceNameShort: 'Knallert',
                    id: ProductType.Moped,
                };

            case 'caravan':
                return {
                    code: 'caravan',
                    insuranceName: this.translateService.instant('INSURANCE.CARAVAN.PRODUCT'),
                    icon: 'nf nf-caravan-icon-v3',
                    insuranceNameShort: 'Campingvogn',
                    id: ProductType.Caravan,
                };

            case 'secondhouse':
                return {
                    code: 'secondhouse',
                    insuranceName: this.translateService.instant('INSURANCE.SECONDHOUSE.PRODUCT'),
                    icon: 'nf nf-secondhouse-icon-v3',
                    insuranceNameShort: 'Fritidshus',
                    id: ProductType.SecondHouse,
                };

            default:
                console.error('Product Service: No product match for ' + code);
        }
    }

    async getBasketInsuranceDetails(details: BasketInsuranceDetails) {
        const calc = details.dataCalculation;
        switch (details.product.toLowerCase()) {
            case 'car_simple':
            case 'car':
                return await this.getCarText(calc);

            case 'house':
            case 'secondhouse':
            case 'home':
                return await this.getAddressText(calc.address);

            case 'accident':
                return calc.persons.map((p) => p.name).join(', ');

            case 'dog':
                return calc.name;

            case 'moped':
                return this.mopedService.getBrandText();

            case 'caravan':
                return calc.brandId + ' ' + calc.modelText + ', ' + calc.modelYear;

            default:
                console.error('Product Service: No product match for ' + details.product);
        }
    }

    async getBasketInsuranceProductName(details: BasketInsuranceDetails) {
        const productInsuranceName = this.getProductByCode(details.product).insuranceName;

        switch (details.product.toLowerCase()) {
            case 'accident':
                let suffix = '';

                if (details.dataCalculation.isAdult != null) {
                    if (!details.dataCalculation.isAdult) {
                        suffix = ' - ' + this.translateService.instant('CALCULATE.ACCIDENT.COMPONENTS.ACCIDENT_STEP_3.CHILD');
                    } else if (!details.dataCalculation.leisureTime) {
                        suffix = ' - ' + this.translateService.instant('CALCULATE.ACCIDENT.COMPONENTS.ACCIDENT_STEP_COVER.FULL');
                    } else {
                        suffix = ' - ' + this.translateService.instant('CALCULATE.ACCIDENT.COMPONENTS.ACCIDENT_STEP_COVER.LEISURE');
                    }
                }

                return productInsuranceName + suffix;

            default:
                return productInsuranceName;
        }
    }

    async getCarText(calc: DataCalculation) {
        let result = '';
        if (calc.brandName != null && calc.modelName != null && calc.year != null) {
            result = calc.brandName + ' ' + calc.modelName + ', ' + calc.year;
        }

        if (calc.variantId != null && calc.variantId.length > 0) {
            const variant = await this.carService.getCarVariantByVariantId(calc.brandId, calc.variantId).toPromise();
            if (variant == null) {
                return '';
            }
            result = variant.brand + ' ' + variant.model + ', ' + variant.year;
        }

        if (calc.regNo != null && calc.regNo.length > 0) {
            result += ', ' + calc.regNo;
        }

        return result;
    }

    async getAddressText(address: Address) {
        if (address == null) {
            return '';
        }

        if (address.addressText != null && address.addressText.length > 0) {
            return address.addressText;
        }

        if (address.addressId == null || address.addressId.length === 0) {
            return '';
        }

        const a = await this.dawaService.getDawaAddressById(address.addressId).toPromise();
        if (a == null) {
            return '';
        }
        return a.adressebetegnelse;
    }

    getProductNameSingular(productTypeCode: string): string {
        switch (productTypeCode.toLowerCase()) {
            case 'car_simple':
            case 'car':
                return this.translateService.instant('INSURANCE.CAR.NAME_SINGULAR'); // bilen

            case 'house':
                return this.translateService.instant('INSURANCE.HOUSE.NAME_SINGULAR');

            case 'secondhouse':
                return this.translateService.instant('INSURANCE.SECONDHOUSE.NAME_SINGULAR');

            case 'moped':
                return this.translateService.instant('INSURANCE.MOPED.NAME_SINGULAR');

            case 'caravan':
                return this.translateService.instant('INSURANCE.CARAVAN.NAME_SINGULAR');

            case 'home':
                return '';

            case 'accident':
                return '';

            case 'dog':
                return '';

            default:
                console.error('Product Service: No product match for ' + productTypeCode + ' in getProductNameSingular');
        }
    }

    getProductCoversByCode(code: string): Cover[] {
        if (code) {
            // create every time - we do not want to remember state changes
            const productCovers = new ProductCovers(this.translateService);
            const covers = productCovers.getCovers(code.toLowerCase());
            if (!covers) {
                console.error('Product Service: No product match for ' + code);
            }

            return covers;
        }
    }

    getBasketTotal(basketId: string): void {
        let totalPeriod = 0;
        let totalAnnual = 0;
        const calculated = [];

        this.subscriptions.push(
            this.baxService.insurancesGet(basketId, 'productservice').subscribe(
                (insurances) => {
                    const calcDetailObservables: Observable<BasketInsuranceCalculationDetails>[] = [];
                    for (const key in insurances) {
                        const ins = insurances[key];
                        calcDetailObservables.push(this.baxService.insuranceCalculationGetSelected(basketId, ins.publicId));
                    }

                    this.subscriptions.push(
                        forkJoin(calcDetailObservables).subscribe(
                            (resp) => {
                                for (const k in resp) {
                                    const selectedCalculation = resp[k];
                                    if (selectedCalculation != null && selectedCalculation.pricePeriod != null && selectedCalculation.priceAnnual != null) {
                                        totalPeriod += selectedCalculation.pricePeriod.orgValue;
                                        totalAnnual += selectedCalculation.priceAnnual.orgValue;
                                        calculated.push(selectedCalculation.basketInsurancePublicId);
                                    }
                                }
                            },
                            (err) => {
                                console.log(err);
                            },
                            () => {
                                const missing = insurances.filter((i) => calculated.indexOf(i.publicId) < 0);
                                const missingIds = [];
                                for (const k in missing) {
                                    missingIds.push(missing[k].publicId);
                                }

                                const pipe = new ThousandSeperatorFloatDisplayPipe();
                                const totalPrice: TotalPriceResult = new TotalPriceResult();
                                totalPrice.missingCalculations = missingIds;
                                totalPrice.priceAnnual = totalAnnual;
                                totalPrice.pricePeriod = totalPeriod;
                                totalPrice.priceAnnualDisplay = pipe.transform(totalAnnual);
                                totalPrice.pricePeriodDisplay = pipe.transform(totalPeriod);

                                this.baskettotalSource.next(totalPrice);
                            }
                        )
                    );
                },
                (err) => console.log(err)
            )
        );
    }

    // POLICY
    getProductDescription(policy: PolicyInsurance | Policy): string {
        switch (policy.productTypeCode.toUpperCase()) {
            case 'ACCIDENT':
            case 'DOG':
                return this.getMultiDescription(policy);

            default:
                return policy.policyDetails[0].itemDescription;
        }
    }

    getMultiDescription(policy: PolicyInsurance | Policy): string {
        let result = '';

        policy.policyDetails.forEach(function (value, index, array) {
            result += value.itemDescription + ', ';
        });

        return result.slice(0, -2); // remove last comma and space
    }

    getProductName(policy: Policy): string {
        switch (policy.productTypeCode.toUpperCase()) {
            case 'ACCIDENT':
                return this.getAccidentProductName(policy);

            default:
                return policy.policyDetails[0].productName;
        }
    }

    getInsuranceName(policy: PolicyInsurance | Policy): string {
        return policy.productInsuranceName;
    }

    getAccidentProductName(policy: Policy): string {
        if (policy.policyDetails.length > 1) {
            return policy.productInsuranceName;
        } else {
            return policy.policyDetails[0].productName;
        } // Ulykke - Heltid
    }

    // POLICY EDIT
    getEditProductDescription(policyedit: PolicyEdit, objectid: string): string {
        switch (policyedit.productTypeCode.toUpperCase()) {
            case 'ACCIDENT':
            case 'DOG':
                return this.getSpecialDescription(policyedit, objectid);

            default:
                return policyedit.policyEditDetails[0].itemDescription;
        }
    }

    getSpecialDescription(policyedit: PolicyEdit, objectid: string): string {
        if (objectid != null && objectid.length > 0 && objectid !== 'none') {
            // return "specific name for object id: " + objectid; //single name
            return policyedit.policyEditDetails.filter((x) => x.objectId === objectid)[0].itemDescription;
        } else {
            return this.getEditMultiDescription(policyedit);
        } // multiple names
    }

    getEditMultiDescription(policyedit: PolicyEdit): string {
        let result = '';

        policyedit.policyEditDetails.forEach(function (value, index, array) {
            result += value.itemDescription + ', ';
        });

        return result.slice(0, -2); // remove last comma and space
    }

    getEditProductName(policyedit: PolicyEdit, objectid: string): string {
        switch (policyedit.productTypeCode.toUpperCase()) {
            case 'ACCIDENT':
                return this.getEditAccidentProductName(policyedit, objectid);

            default:
                return policyedit.policyEditDetails[0].productName;
        }
    }

    getEditAccidentProductName(policyedit: PolicyEdit, objectid: string): string {
        if (objectid != null && objectid.length > 0 && objectid !== 'none') {
            return policyedit.policyEditDetails.filter((x) => x.objectId === objectid)[0].productName; // single fx "Ulykkesforsikring - Heltid"
        } else {
            return policyedit.productInsuranceName; // Multiple => "Ulykkesforsikring"
        }
    }

    getAccidentScopeDescription(scope: number): string {
        switch (scope) {
            case 1:
                return this.translateService.instant('CALCULATE.ACCIDENT.COMPONENTS.ACCIDENT_STEP_COVER.FULL');
            case 2:
                return this.translateService.instant('CALCULATE.ACCIDENT.COMPONENTS.ACCIDENT_STEP_COVER.LEISURE');
            case 3:
                return this.translateService.instant('CALCULATE.ACCIDENT.COMPONENTS.ACCIDENT_STEP_3.CHILD');

            default:
                console.error('Product Service: No scope match for ' + scope);
        }
    }

    getCoversRequiredText(coversRequired: any): string {
        if (coversRequired.length === 0) {
            return '';
        } else {
            let result = ' - ' + this.translateService.instant('SELFCARE.EDIT.COMMONADDCOVER.PRESUPPOSES_PURCHASE_OF') + ' ';
            for (const key in coversRequired) {
                const cover = coversRequired[key];
                result += '"' + cover.coverName + '", ';
            }
            return result.slice(0, -2); // remove last comma and space
        }
    }

    getSecondhouseCoverItem(coverCode: string): string {
        const covers = this.getProductCoversByCode('secondhouse');
        const c = covers.find((cover) => cover.code === coverCode);
        if (!c) {
            switch (coverCode) {
                case 'FireBuilding':
                case 'Hull':
                case 'GlassSanitation':
                    // "LegalAid" is valid for both home and house - handled in html
                    return 'house';
            }
            return '';
        }

        if (coverCode === 'Liability') {
            return 'home';
        }

        return c.item;
    }

    getCoverTextByCoverCode(productCode: string, coverCode: string): string {
        const covers = this.getProductCoversByCode(productCode);
        const c = covers.find((cover) => cover.code === coverCode);
        if (!c) {
            return '';
        }
        return c.name;
    }

    getDanishProductNameByProductTypeId(productTypeId: number): string {
        switch (productTypeId) {
            case 0: // car
            case 8: // car_simple
                return this.translateService.instant('INSURANCE.CAR.PRODUCT')?.toLowerCase(); // bilforsikring
            case 1:
                return this.translateService.instant('INSURANCE.HOUSE.PRODUCT')?.toLowerCase();
            case 2:
                return this.translateService.instant('INSURANCE.HOME.PRODUCT')?.toLowerCase();
            case 3:
                return this.translateService.instant('INSURANCE.ACCIDENT.PRODUCT')?.toLowerCase();
            case 4:
                return this.translateService.instant('INSURANCE.DOG.PRODUCT')?.toLowerCase();
            case 5:
                return this.translateService.instant('INSURANCE.MOPED.PRODUCT')?.toLowerCase();
            case 6:
                return this.translateService.instant('INSURANCE.CARAVAN.PRODUCT')?.toLowerCase();
            case 7:
                return this.translateService.instant('INSURANCE.SECONDHOUSE.PRODUCT')?.toLowerCase();
        }
        return '';
    }

    getProductIconByProductTypeId(productTypeId: number): string {
        switch (productTypeId) {
            case 0:
                return this.getProductByCode('car').icon;
            case 1:
                return this.getProductByCode('house').icon;
            case 2:
                return this.getProductByCode('home').icon;
            case 3:
                return this.getProductByCode('accident').icon;
            case 4:
                return this.getProductByCode('dog').icon;
            case 5:
                return this.getProductByCode('moped').icon;
            case 6:
                return this.getProductByCode('caravan').icon;
            case 7:
                return this.getProductByCode('secondhouse').icon;
            case 8:
                return this.getProductByCode('car_simple').icon;
        }
        return '';
    }

    getProductNameByProductTypeId(productType: ProductType): string {
        switch (productType) {
            case 0:
                return 'car';
            case 1:
                return 'house';
            case 2:
                return 'home';
            case 3:
                return 'accident';
            case 4:
                return 'dog';
            case 5:
                return 'moped';
            case 6:
                return 'caravan';
            case 7:
                return 'secondhouse';
            case 8:
                return 'car_simple';
        }
        return '';
    }

    getProductTypeByProductCode(productCode: string): ProductType {
        switch (productCode.toLowerCase()) {
            case 'car':
                return ProductType.Car;
            case 'house':
                return ProductType.House;
            case 'home':
                return ProductType.Home;
            case 'accident':
                return ProductType.Accident;
            case 'dog':
                return ProductType.Dog;
            case 'moped':
                return ProductType.Moped;
            case 'caravan':
                return ProductType.Caravan;
            case 'secondhouse':
                return ProductType.SecondHouse;
            case 'car_simple':
                return ProductType.CarSimple;
        }
    }
}
