import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ContexPanelService } from 'app/utils/contex/panel/contex.panel.service';
import { ActivatedRoute } from '@angular/router';
import { NavigationService } from 'app/utils/navigation/navigation.service';
import { Subscription } from 'rxjs';
import { PanelGroupDetails } from 'app/utils/contex/panel/panelGroupDetails';
import { TranslationService } from 'app/utils/translation/translation.service';

@Component({
    moduleId: module.id,
    selector: 'next-faq',
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.less'],
})
export class FaqComponent implements OnInit, OnDestroy {
    subscriptions: Subscription[] = [];

    @Input() category: string;
    @Input() allowSetUrl: boolean;

    contex: PanelGroupDetails[];
    opencategory: string;
    openquestion: string;

    constructor(private contexPanelService: ContexPanelService, private route: ActivatedRoute, private navigationService: NavigationService, private translationService: TranslationService) {
        this.subscriptions.push(
            this.route.queryParams.subscribe(params => {
                this.setOpenFaq(); // when the parameter changes
            })
        );
    }

    ngOnInit() {
        this.updatePanelGroupDetails();

        this.translationService.getLanguageChangeEmitter().subscribe(() => {
            this.updatePanelGroupDetails();
        });

        this.setOpenFaq();
    }

    updatePanelGroupDetails() {
        this.contexPanelService.getPanelTypeGroups(this.category).subscribe(x => {
            this.contex = x;
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    setOpenFaq() {
        this.opencategory = this.route.snapshot.queryParams['kategori'];
        this.openquestion = this.route.snapshot.queryParams['spørgsmål'];
    }

    onHeaderClick(category: string) {
        this.openItem(category, null);
        this.setUrl(category, null);
    }

    onQuestionClick(category: string, question: string) {
        this.openItem(category, question);
        this.setUrl(category, question);
    }

    openItem(category: string, question: string) {
        if (this.opencategory === category && question == null) {
            category = null;
        }

        if (this.openquestion === question) {
            question = null;
        }

        this.opencategory = category;
        this.openquestion = question;
    }

    setUrl(category: string, question: string) {
        if (!this.allowSetUrl) {
            return;
        }

        let url = this.navigationService.getUrl('answer-question');
        if (category != null) {
            url += '?kategori=' + category;
        }
        if (category != null && question != null) {
            url += '&spørgsmål=' + question;
        }

        window.history.replaceState({}, '', url); // update url without relaoding page
    }
}
