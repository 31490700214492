import { Address } from 'app/class/address';
import { Cover } from 'app/class/cover';
import { CoverSelect } from 'app/class/cover-select';

export class SecondHouse {
    supplementaryEnergyCode: string;
    wallConstructionCode: string;
    constructionYear?: number;
    livingArea?: number;
    garageArea?: number;
    usageCode: string;
    roofTypeCode: string;
    heatingCode: string;

    deductibleId: string;
    address: Address;
    covers: CoverSelect[];

    airRecycling: boolean;
    geothermal: boolean;
    heatPump: boolean;
    pelletStokers: boolean;
    solarCellsGround: boolean;
    solarCellsRoof: boolean;
    solarPanels: boolean;

    basementArea?: number;

    isBbrChecked: boolean;
    eventDate?: Date;

    constructor() {
        this.supplementaryEnergyCode = null;
        this.wallConstructionCode = null;
        this.constructionYear = null;
        this.livingArea = null;
        this.garageArea = null;
        this.usageCode = null;
        this.roofTypeCode = null;
        this.heatingCode = null;
        this.deductibleId = '';
        this.address = {
            addressId: '',
            addressText: '',
        };
        this.basementArea = null;
        this.geothermal = false;
        this.heatPump = false;
        this.pelletStokers = false;
        this.solarCellsGround = false;
        this.solarCellsRoof = false;
        this.solarPanels = false;

        this.isBbrChecked = false;
    }
}
