<!-- TrustBox widget - Mini -->
<div
    id="trustpilot-mini"
    class="trustpilot-widget"
    data-locale="da-DK"
    data-template-id="53aa8807dec7e10d38f59f32"
    data-businessunit-id="4c90a80400006400050ddc71"
    data-style-height="140px"
    data-style-width="100%"
    data-theme="dark"
>
    <a href="https://dk.trustpilot.com/review/next.dk" target="_blank" rel="noopener">Trustpilot</a>
</div>
<!-- End TrustBox widget -->
