import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { NavigationService } from 'app/utils/navigation/navigation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'app/auth/auth.service';
import { forkJoin, Subscription } from 'rxjs';
import { DeviceQueriesHelper } from 'app/utils/deviceQueries/deviceQueriesHelper';
import { TopbarService } from '../../topbar.service';
import { ChatService } from 'app/utils/chat/chat.service';
import { SiteSettingsService } from 'app/utils/sitesettings/site-settings.service';
import { SiteSettingType } from 'next-typescript-api';
import { ContactReason, ContactRequestService } from 'app/utils/contactrequest/contactrequest.service';
import { map } from 'rxjs/operators';

declare let $zopim: any;

@Component({
    moduleId: module.id,
    selector: 'next-topbar',
    templateUrl: './topbar.component.html',
    styleUrls: ['./topbar.component.less'],
})
export class TopbarComponent implements OnInit, OnDestroy {
    public ischatopen: boolean;
    public chatopenhours: string;

    userDisplayName: string;
    isAuthenticated: boolean;
    numInBasket?: number;
    goToSummaryLoading: boolean;

    @Input() selfcaremodeoverride: boolean;
    public selfcaremode: boolean;
    subscriptions: Subscription[] = [];
    isMobile = true; // set to true to avoid injecting unnecessary components (see html)
    componentInitialized = false;
    displayPhoneNumber: boolean;
    showContactRequestModal: boolean;
    callbackAllowed: boolean;

    constructor(
        public auth: AuthService,
        public chatService: ChatService,
        private route: ActivatedRoute,
        public navigationService: NavigationService,
        private deviceQueriesHelper: DeviceQueriesHelper,
        private router: Router,
        private topbarService: TopbarService,
        private siteSettingsService: SiteSettingsService,
        private contactRequestService: ContactRequestService
    ) {}

    ngOnInit(): void {
        this.isMobile = this.deviceQueriesHelper.isMobileDevice();
        this.subscriptions.push(
            this.deviceQueriesHelper.isMobileChanged.subscribe((isMobile: boolean) => {
                this.isMobile = isMobile;

                this.doInitComponent();
            })
        );

        this.doInitComponent();
    }

    doInitComponent(): void {
        this.topbarService.currentShowCallmeModal.subscribe(
            (show) => {
                this.showContactRequestModal = show;
            },
            () => {
                this.showContactRequestModal = false;
            }
        );
        this.siteSettingsService.getBooleanSetting(SiteSettingType.DisplayNextPhoneNumber).subscribe(
            (display) => {
                this.displayPhoneNumber = display;
            },
            () => {
                this.displayPhoneNumber = false;
            }
        );

        const salesObservable = this.contactRequestService.isContactRequestAllowed(null, ContactReason.Sales);
        const customerServiceObservable = this.contactRequestService.isContactRequestAllowed(null, ContactReason.CustomerService);

        forkJoin([salesObservable, customerServiceObservable])
            .pipe(map((result) => result.every((r) => r)))
            .subscribe(
                (resp) => {
                    this.callbackAllowed = resp;
                },
                () => {
                    this.callbackAllowed = false;
                }
            );

        if (this.componentInitialized || this.isMobile) {
            return;
        }

        this.componentInitialized = true;

        // Ask if chat is open everytime topbar is loaded
        this.subscriptions.push(
            this.chatService.getIsChatOpen().subscribe(
                (data) => (this.ischatopen = data),
                (err) => {
                    console.log('getIsChatOpen failed!');
                }
            )
        );

        this.subscriptions.push(this.topbarService.currentChatOpenHours.subscribe((x) => (this.chatopenhours = x)));

        this.subscriptions.push(this.topbarService.currentUserDisplayName.subscribe((x) => (this.userDisplayName = x)));

        this.subscriptions.push(this.topbarService.currentIsLoggedIn.subscribe((x) => (this.isAuthenticated = x)));

        this.subscriptions.push(this.topbarService.currentNumInBasket.subscribe((x) => (this.numInBasket = x)));

        this.selfcaremode = this.route.snapshot['_routerState'].url.indexOf('selvbetjening') >= 0;

        if (this.selfcaremodeoverride !== undefined) {
            this.selfcaremode = this.selfcaremodeoverride;
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    goToSelfcare() {
        if (this.isAuthenticated) {
            this.router.navigate([this.navigationService.getUrl('selfcare-overview')]);
        } else {
            this.router.navigate([this.navigationService.getUrl('selfcare-login')]);
        }
    }

    goToSummary() {
        this.goToSummaryLoading = true;
        if (this.numInBasket != null && this.numInBasket > 0) {
            this.router.navigate([this.navigationService.getUrl('summary')]);
        } else {
            this.router.navigate([this.navigationService.getUrl('newcustomer')]);
            this.goToSummaryLoading = false;
        }
    }

    openChat() {
        $zopim.livechat.window.show();
    }

    openContactRequestModal() {
        this.topbarService.setCallmeModal(true);
    }

    closeContactRequestModal() {
        this.topbarService.setCallmeModal(false);
    }
}
