import { RibbonType } from 'app/class/cover';

export class CoverInfoSettings {
    product: string;
    cover: string;
    coverIcon: string;
    ribbonType: RibbonType;

    constructor(product: string, cover: string, coverIcon: string, ribbonType: RibbonType = RibbonType.required) {
        this.product = product;
        this.cover = cover;
        this.coverIcon = coverIcon;
        this.ribbonType = ribbonType;
    }
}
