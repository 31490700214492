import { Observable, BehaviorSubject, of, Subscription } from 'rxjs';
import { AppConfigService } from 'app/utils/appconfig/app-config.service';
import { Injectable, OnDestroy } from '@angular/core';
import { CalculateService } from 'app/calculate/calculate.service';
import { HttpClient } from '@angular/common/http';
import { Car } from './models/car';
import { tap, map, mergeAll, filter, toArray } from 'rxjs/operators';
import { FunctionsService } from 'app/utils/functions/functions.service';
import { BrandItem3, ModelItem, VariantItem, DeductibleItem, MaxKmPrYearItem } from 'next-typescript-api';

@Injectable()
export class CarService implements OnDestroy {
    public totalSteps = 7;
    public product: Car;
    product$: Observable<Car>;

    subscriptions: Subscription[] = [];

    private apiUrl: string;

    private productSource = new BehaviorSubject<Car>(null);

    private extraHomeInsuranceSelected: Map<string, boolean> = new Map<string, boolean>();
    private extraHouseInsuranceSelected: Map<string, boolean> = new Map<string, boolean>();
    private extraAccidentInsuranceSelected: Map<string, boolean> = new Map<string, boolean>();

    private extraHomeInsurancePublicId: Map<string, string> = new Map<string, string>();
    private extraHouseInsurancePublicId: Map<string, string> = new Map<string, string>();
    private extraAccidentInsurancePublicId: Map<string, string> = new Map<string, string>();

    constructor(private http: HttpClient, private config: AppConfigService, private functionsService: FunctionsService, private calculateService: CalculateService) {
        this.apiUrl = this.config.appConfig.apiHost;
        this.product$ = this.productSource.asObservable();

        this.subscriptions.push(
            this.calculateService.getProductLoadedObserveable('car').subscribe((loaded) => {
                if (loaded) {
                    if (this.calculateService.loadedProduct != null) {
                        this.product = this.calculateService.loadedProduct;
                        this.productSource.next(this.product);
                    } else {
                        this.product = new Car();
                        this.productSource.next(this.product);
                    }
                }
            })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    setExtraHomeInsuranceSelected(insuranceId: string, selected: boolean): void {
        this.extraHomeInsuranceSelected.set(insuranceId, selected);
    }

    getExtraHomeInsuranceSelected(insuranceId: string): boolean {
        return this.extraHomeInsuranceSelected.get(insuranceId);
    }

    setExtraHouseInsuranceSelected(insuranceId: string, selected: boolean): void {
        this.extraHouseInsuranceSelected.set(insuranceId, selected);
    }

    getExtraHouseInsuranceSelected(insuranceId: string): boolean {
        return this.extraHouseInsuranceSelected.get(insuranceId);
    }

    setExtraAccidentInsuranceSelected(insuranceId: string, selected: boolean): void {
        this.extraAccidentInsuranceSelected.set(insuranceId, selected);
    }

    getExtraAccidentInsuranceSelected(insuranceId: string): boolean {
        return this.extraAccidentInsuranceSelected.get(insuranceId);
    }

    setExtraHomeInsurancePublicId(insuranceId: string, publicId: string): void {
        this.extraHomeInsurancePublicId.set(insuranceId, publicId);
    }

    getExtraHomeInsurancePublicId(insuranceId: string): string {
        return this.extraHomeInsurancePublicId.get(insuranceId);
    }

    setExtraHouseInsurancePublicId(insuranceId: string, publicId: string): void {
        this.extraHouseInsurancePublicId.set(insuranceId, publicId);
    }

    getExtraHouseInsurancePublicId(insuranceId: string): string {
        return this.extraHouseInsurancePublicId.get(insuranceId);
    }

    setExtraAccidentInsurancePublicId(insuranceId: string, publicId: string): void {
        this.extraAccidentInsurancePublicId.set(insuranceId, publicId);
    }

    getExtraAccidentInsurancePublicId(insuranceId: string): string {
        return this.extraAccidentInsurancePublicId.get(insuranceId);
    }

    updateProduct(car: Car): Observable<any> {
        this.product = car;
        return this.calculateService.saveBasket(this.product).pipe(
            tap(() => {
                this.productSource.next(car);
            })
        );
    }

    getProduct(): Car {
        return this.product;
    }

    getBrands(): Observable<BrandItem3[]> {
        const url = `${this.apiUrl}/insurancedata/car/brands?api-version=2.0`;
        return this.http.get<BrandItem3[]>(url);
    }

    getModels(brand: string): Observable<ModelItem[]> {
        if (brand == null || brand.length === 0) {
            return of<ModelItem[]>();
        }
        const url = `${this.apiUrl}/insurancedata/car/brands/${encodeURI(brand)}/models?api-version=2.0`;
        return this.http.get<ModelItem[]>(url);
    }

    getYears(brand: string, model: string): Observable<number[]> {
        const url = `${this.apiUrl}/insurancedata/car/brands/${encodeURI(brand)}/models/${encodeURI(model)}/years?api-version=2.0`;
        return this.http.get<number[]>(url);
    }

    getEngines(brand: string, model: string, year: number): Observable<string[]> {
        const url = `${this.apiUrl}/insurancedata/car/brands/${encodeURI(brand)}/models/${encodeURI(model)}/years/${year}/engines?api-version=2.0`;
        return this.http.get<string[]>(url);
    }

    getVariants(brand: string, model: string, year: number, engine: string): Observable<VariantItem[]> {
        const url = `${this.apiUrl}/car-products/CAR/brands/${encodeURI(brand)}/models/${encodeURI(model)}/years/${year}/engines/${encodeURI(engine)}/variants?api-version=2.0`;
        return this.http.get<VariantItem[]>(url);
    }

    getMaxKm(): Observable<MaxKmPrYearItem[]> {
        const url = `${this.apiUrl}/car-products/CAR/maxkms?api-version=2.0`;
        return this.http.get<MaxKmPrYearItem[]>(url);
    }

    getPossibleDeductibles(): Observable<DeductibleItem[]> {
        const url = `${this.apiUrl}/car-products/CAR/deductibles?driverAge=${this.product.userAge}&api-version=2.0`;
        if (this.product == null || this.product.userAge == null) {
            return of<DeductibleItem[]>();
        }

        const result = this.http.get<DeductibleItem[]>(url).pipe(
            mergeAll(),
            filter((d) => d.value <= 15000),
            toArray()
        );

        return result;
    }

    getCarVariantByVariantId(brandId: string, variantId: string): Observable<VariantItem> {
        const url = `${this.apiUrl}/car-products/CAR/brands/${brandId}/variants/${variantId}?api-version=2.0`;
        return this.http.get<VariantItem>(url);
    }

    checkStep(step: number): boolean {
        if (this.product == null) {
            return false;
        }

        switch (step) {
            case 1:
                return this.product.regNo != null && this.product.regNo.trim().length > 0 && this.functionsService.validateRegNo(this.product.regNo);
            case 2:
                if (this.product.licenceplateWhite == null) {
                    return false;
                }

                if (this.product.variantId == null || this.product.variantId.length === 0) {
                    return false;
                }

                if (this.product.newValueAction === 99) {
                    return false;
                }

                return true;
            case 3:
                return this.checkAge(this.product.userAge);
            case 4:
                if (!this.checkAddress(this.product.address.addressId)) {
                    return false;
                }

                if (!this.checkAge(this.product.userAge)) {
                    return false;
                }

                return true;
            case 5:
                if (this.product.yearsOwningCar == null || this.product.yearsOwningCar < 0) {
                    return false;
                }

                if (!this.checkAddress(this.product.address.addressId)) {
                    return false;
                }

                if (!this.checkAge(this.product.userAge)) {
                    return false;
                }

                if (this.product.userAge - this.product.yearsOwningCar < 18) {
                    return false;
                }

                return true;
            case 6:
                if (this.product.maxKmPrYearId == null || this.product.maxKmPrYearId.length === 0) {
                    return false;
                }

                if (!this.checkAddress(this.product.address.addressId)) {
                    return false;
                }

                if (!this.checkAge(this.product.userAge)) {
                    return false;
                }

                return true;
        }

        return false;
    }

    checkAge(userAge?: number): boolean {
        return userAge != null && userAge >= 18;
    }

    checkAddress(addressId: string): boolean {
        return addressId != null && addressId.length > 0;
    }

    getHighestStep(): number {
        if (this.checkStep(6)) {
            return 7;
        } else if (this.checkStep(5)) {
            return 6;
        } else if (this.checkStep(4)) {
            return 5;
        } else if (this.checkStep(3)) {
            return 4;
        } else if (this.checkStep(2)) {
            return 3;
        } else if (this.checkStep(1)) {
            return 2;
        } else {
            return 1;
        }
    }

    resetCovers() {
        this.product.covers = null;
    }
}
