<div class="full-width-image-section coverinfo {{ product }}-{{ cover }}-top">
    <div class="arrow-up">
        <next-topbar></next-topbar>
        <next-menu></next-menu>

        <div class="container white">
            <div class="row">
                <div class="col-sm-12 text-center">
                    <h1>{{ 'INSURANCE.' + product.toUpperCase() + '.COMPONENTS.' + cover.toUpperCase() + '.COVER' | translate | uppercase }}</h1>
                </div>
            </div>

            <div *ngIf="coverIcon" class="row">
                <div class="col-sm-12 text-center">
                    <div class="icon-circle center">
                        <div class="icon-wrap">
                            <i [class]="coverIcon"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container">
    <div class="row">
        <div class="col-sm-12 text-center">
            <br />
            <br />
            <calculate-btn
                [gotoLink]="navigationService.getUrl('calculate-' + product.toLowerCase())"
                [productName]="'INSURANCE.' + product.toUpperCase() + '.PRODUCT' | translate | uppercase"
            ></calculate-btn>
            <br />
            <br />
            <a class="back-button" [routerLink]="navigationService.getUrl('cover-' + product.toLowerCase())"
                ><i class="nf nf-chevron-left-icon-v3"></i> {{ 'INSURANCE.SHARED.BACK_TO' | translate }} {{ 'INSURANCE.' + product.toUpperCase() + '.PRODUCT' | translate }}</a
            >
        </div>
    </div>
    <hr class="subpage-seperator" />
</div>

<div class="container">
    <div class="row">
        <pageloader [show]="!contex?.content"></pageloader>
        <div *ngIf="contex?.content" class="col-sm-12 insurance-subpage" [innerHTML]="contex?.content | safehtml"></div>
    </div>
</div>

<trustpilot-carousel></trustpilot-carousel>

<ng-container [ngSwitch]="product.toUpperCase()">
    <accident-cover *ngSwitchCase="'ACCIDENT'"></accident-cover>
    <car-cover *ngSwitchCase="'CAR'"></car-cover>
    <caravan-cover *ngSwitchCase="'CARAVAN'"></caravan-cover>
    <dog-cover *ngSwitchCase="'DOG'"></dog-cover>
    <home-cover *ngSwitchCase="'HOME'"></home-cover>
    <house-cover *ngSwitchCase="'HOUSE'"></house-cover>
    <moped-cover *ngSwitchCase="'MOPED'"></moped-cover>
    <secondhouse-cover *ngSwitchCase="'SECONDHOUSE'"></secondhouse-cover>
</ng-container>

<next-footer></next-footer>
