import { Component } from '@angular/core';
import { NavigationService } from 'app/utils/navigation/navigation.service';

@Component({
    moduleId: module.id,
    selector: 'home-cover',
    templateUrl: './_cover.component.html',
    styleUrls: ['../../../../../styles/app/covers.less'],
})
export class CoverComponent {
    constructor(public navigationService: NavigationService) {}
}
