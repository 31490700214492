<div id="complaintsmanager">
    <div class="full-width-image-section complaintsmanager-top">
        <div class="arrow-up">
            <next-topbar></next-topbar>
            <next-menu></next-menu>

            <div class="container white">
                <div class="row">
                    <div class="col-sm-12 text-center">
                        <h1>{{ 'PAGE.COMPLAINTS_MANAGER.TITLE' | translate }}</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container container-padding-20-40">
        <div class="row">
            <div class="col-sm-12" [innerHTML]="contex?.content | safehtml"></div>
        </div>
    </div>

    <next-footer></next-footer>
</div>
