<div *ngIf="isVisible" class="container">
    <div class="timeout-overlay fullscreen" (click)="extendTimeout()"></div>
    <div class="timeout-container" #ref>
        <ng-content> </ng-content>
    </div>
    <div *ngIf="ref.children.length === 0" class="timeout-default-box timeout-container">
        <i class="nf nf-warning-icon-v3"></i>
        <button type="button" class="close" (click)="isVisible = false" aria-label="Close">
            <span aria-hidden="true"><i class="nf nf-close-icon-outlined"></i></span>
        </button>
        <h4 class="modal-title" id="myModalLabel">Du har været inaktiv i længere tid.</h4>
        <p>Af sikkerhedsmæssige årsager vil du blive logget ud, hvis du ikke foretager dig noget.</p>
        <p id="countdown-text">Du vil automatisk blive logget ud om</p>
        <span id="logout-countdown" *ngIf="warningCountdownSeconds > 0">{{ warningCountdownSeconds | timedisplay }}</span>
        <br />
        <div class="btn-actions">
            <a (click)="logout()" id="logoff-link" class="btn-log-off">Log af</a>
            <a (click)="extendTimeout()" class="btn btn-success">Bliv på siden</a>
        </div>
    </div>
</div>
