<div id="footer">
    <footer *ngIf="!simple" class="footer">
        <div class="container">
            <div class="row">
                <div class="col-sm-3">
                    <trustpilot-mini></trustpilot-mini>
                    <language-selector></language-selector>
                </div>
                <div class="col-sm-3 hidden-xs">
                    <span class="header">{{ 'SHARED.FOOTER.PRODUCTS' | translate }}</span>
                    <ul>
                        <li>
                            <a [routerLink]="navigationService.getUrl('product-car')">{{ 'INSURANCE.CAR.PRODUCT' | translate }}</a>
                        </li>
                        <li>
                            <a [routerLink]="navigationService.getUrl('product-house')">{{ 'INSURANCE.HOUSE.PRODUCT' | translate }}</a>
                        </li>
                        <li>
                            <a [routerLink]="navigationService.getUrl('product-home')">{{ 'INSURANCE.HOME.PRODUCT' | translate }}</a>
                        </li>
                        <li>
                            <a [routerLink]="navigationService.getUrl('product-accident')">{{ 'INSURANCE.ACCIDENT.PRODUCT' | translate }}</a>
                        </li>
                        <li>
                            <a [routerLink]="navigationService.getUrl('product-dog')">{{ 'INSURANCE.DOG.PRODUCT' | translate }}</a>
                        </li>
                        <li>
                            <a [routerLink]="navigationService.getUrl('product-caravan')">{{ 'INSURANCE.CARAVAN.PRODUCT' | translate }}</a>
                        </li>
                        <li>
                            <a [routerLink]="navigationService.getUrl('product-secondhouse')">{{ 'INSURANCE.SECONDHOUSE.PRODUCT' | translate }}</a>
                        </li>
                        <li>
                            <a [routerLink]="navigationService.getUrl('product-moped')">{{ 'INSURANCE.MOPED.PRODUCT' | translate }}</a>
                        </li>
                    </ul>
                </div>
                <div class="panel-bg">
                    <hr class="visible-xs footer-break-line" />
                    <accordion class="visible-xs" [closeOthers]="'true'">
                        <accordion-group #produkter is-open="status.open" [panelClass]="'footer-accordion-group'">
                            <div accordion-heading class="footerLinks">
                                {{ 'SHARED.FOOTER.PRODUCTS' | translate }}
                                <i class="pull-right" [ngClass]="{ 'nf-chevron-down-icon-v3': produkter?.isOpen, 'nf-chevron-right-icon-v3': !produkter?.isOpen }"></i>
                            </div>
                            <ul>
                                <li>
                                    <a [routerLink]="navigationService.getUrl('product-car')">{{ 'INSURANCE.CAR.PRODUCT' | translate }}</a>
                                </li>
                                <li>
                                    <a [routerLink]="navigationService.getUrl('product-house')">{{ 'INSURANCE.HOUSE.PRODUCT' | translate }}</a>
                                </li>
                                <li>
                                    <a [routerLink]="navigationService.getUrl('product-home')">{{ 'INSURANCE.HOME.PRODUCT' | translate }}</a>
                                </li>
                                <li>
                                    <a [routerLink]="navigationService.getUrl('product-accident')">{{ 'INSURANCE.ACCIDENT.PRODUCT' | translate }}</a>
                                </li>
                                <li>
                                    <a [routerLink]="navigationService.getUrl('product-dog')">{{ 'INSURANCE.DOG.PRODUCT' | translate }}</a>
                                </li>
                                <li>
                                    <a [routerLink]="navigationService.getUrl('product-caravan')">{{ 'INSURANCE.CARAVAN.PRODUCT' | translate }}</a>
                                </li>
                                <li>
                                    <a [routerLink]="navigationService.getUrl('product-secondhouse')">{{ 'INSURANCE.SECONDHOUSE.PRODUCT' | translate }}</a>
                                </li>
                                <li>
                                    <a [routerLink]="navigationService.getUrl('product-moped')">{{ 'INSURANCE.MOPED.PRODUCT' | translate }}</a>
                                </li>
                            </ul>
                        </accordion-group>
                    </accordion>
                </div>
                <div class="col-sm-3 hidden-xs">
                    <span class="header">{{ 'SHARED.FOOTER.INFORMATION' | translate }}</span>
                    <ul>
                        <li>
                            <a [routerLink]="navigationService.getUrl('contact')">{{ 'SHARED.FOOTER.CONTACT' | translate }}</a>
                        </li>
                        <li>
                            <a class="linkNextBlog" [routerLink]="navigationService.getUrl('blog')">{{ 'SHARED.FOOTER.BLOG' | translate }}</a>
                        </li>
                        <li>
                            <a [routerLink]="navigationService.getUrl('privacycookie')">{{ 'SHARED.FOOTER.PRIVACY' | translate }}</a>
                        </li>
                        <li>
                            <a [routerLink]="navigationService.getUrl('disclaimer')">{{ 'SHARED.FOOTER.DISCLAIMER' | translate }}</a>
                        </li>
                        <li>
                            <a [routerLink]="navigationService.getUrl('terms')">{{ 'SHARED.FOOTER.TERMS' | translate }}</a>
                        </li>
                        <li>
                            <a [routerLink]="navigationService.getUrl('productinformation')">{{ 'SHARED.FOOTER.PRODUCT_INFORMATION' | translate }}</a>
                        </li>
                        <li>
                            <a [routerLink]="navigationService.getUrl('complaintsmanager')">{{ 'SHARED.FOOTER.COMPLAINTS_MANAGER' | translate }}</a>
                        </li>
                        <li>
                            <a [routerLink]="navigationService.getUrl('answer-question')">{{ 'SHARED.FOOTER.FAQ' | translate }}</a>
                        </li>
                        <li>
                            <a [routerLink]="navigationService.getUrl('answer-question')" [queryParams]="{ kategori: 'afgifter og gebyrer' }">{{ 'SHARED.FOOTER.FEES' | translate }}</a>
                        </li>
                        <li>
                            <a href="/spoergsmaal-og-svar?kategori=Vi%20skifter%20risikobærer">{{ 'SHARED.FOOTER.INFO_RISK_CARRIER_CHANGE' | translate }}</a>
                        </li>
                        <!--
                        <li><a [routerLink]="navigationService.getUrl('jobinnext')">{{'SHARED.FOOTER.JOB' | translate}}</a></li>
                        -->
                    </ul>
                </div>
                <hr class="visible-xs footer-break-line" />
                <accordion class="visible-xs" [closeOthers]="'true'">
                    <accordion-group #information is-open="status.open" [panelClass]="'footer-accordion-group'">
                        <div accordion-heading class="footerLinks">
                            {{ 'SHARED.FOOTER.INFORMATION' | translate }}
                            <i class="pull-right" [ngClass]="{ 'nf-chevron-down-icon-v3': information?.isOpen, 'nf-chevron-right-icon-v3': !information?.isOpen }"></i>
                        </div>
                        <ul>
                            <li>
                                <a [routerLink]="navigationService.getUrl('contact')">{{ 'SHARED.FOOTER.CONTACT' | translate }}</a>
                            </li>
                            <li>
                                <a [routerLink]="navigationService.getUrl('blog')">{{ 'SHARED.FOOTER.BLOG' | translate }}</a>
                            </li>
                            <li>
                                <a [routerLink]="navigationService.getUrl('privacycookie')">{{ 'SHARED.FOOTER.PRIVACY' | translate }}</a>
                            </li>
                            <li>
                                <a [routerLink]="navigationService.getUrl('disclaimer')">{{ 'SHARED.FOOTER.DISCLAIMER' | translate }}</a>
                            </li>
                            <li>
                                <a [routerLink]="navigationService.getUrl('terms')">{{ 'SHARED.FOOTER.TERMS' | translate }}</a>
                            </li>
                            <li>
                                <a [routerLink]="navigationService.getUrl('productinformation')">{{ 'SHARED.FOOTER.PRODUCT_INFORMATION' | translate }}</a>
                            </li>
                            <li>
                                <a [routerLink]="navigationService.getUrl('complaintsmanager')">{{ 'SHARED.FOOTER.COMPLAINTS_MANAGER' | translate }}</a>
                            </li>
                            <li>
                                <a [routerLink]="navigationService.getUrl('answer-question')">{{ 'SHARED.FOOTER.FAQ' | translate }}</a>
                            </li>
                            <li>
                                <a [routerLink]="navigationService.getUrl('answer-question')" [queryParams]="{ kategori: 'afgifter og gebyrer' }">{{ 'SHARED.FOOTER.FEES' | translate }}</a>
                            </li>
                            <li>
                                <a href="/spoergsmaal-og-svar?kategori=Vi%20skifter%20risikobærer">{{ 'SHARED.FOOTER.INFO_RISK_CARRIER_CHANGE' | translate }}</a>
                            </li>
                        </ul>
                    </accordion-group>
                </accordion>
                <hr class="visible-xs footer-break-line" />
                <div class="col-sm-3 hidden-xs">
                    <span class="header">{{ 'SHARED.FOOTER.WORKING_WITH' | translate }}</span>
                    <a class="linkSos" href="https://www.sos.dk/da/privat/" target="_blank">
                        <img src="https://static.next.dk/website/images/logos/sos.svg" />
                    </a>
                    <a class="linkEir" href="https://www.eirforsakring.com/" target="_blank">
                        <img src="https://static.next.dk/website/images/logos/eir-white.png" />
                    </a>
                    <span class="header">{{ 'SHARED.FOOTER.FIND_US' | translate }}</span>
                    <a class="linkForsikringsguiden" href="http://forsikringsguiden.dk" target="_blank">
                        <img src="https://static.next.dk/website/images/logos/forsikringsguiden.svg" />
                    </a>
                </div>
            </div>
        </div>
        <hr class="hidden-xs" />
    </footer>
    <div class="bottomfooter">
        <a class="nextlogo" [routerLink]="'/'">
            <img width="164" height="64" [class.topspacer]="simple" src="https://static.next.dk/website/images/logos/next_logo_white.svg" alt="NEXT logo" />
        </a>
        <p>
            NEXT er forsikringsformidler og risikomæssigt afdækket hos Eir Försäkring AB (Org.nr. 559166-0617), Stockholm, Sverige. Eir er naturligvis medlem af Garantifonden i Danmark.<br />
            © 2013 - {{ currentYear }} NEXT Forsikring A/S - Forsikringsformidling<br />
            Danmarksvej 26, 8660 Skanderborg<br />
            CVR: 34706905<br />
            <b>{{ 'SHARED.FOOTER.MAIL' | translate }} </b> <a href="mailto:mail@next.dk">mail@next.dk</a> - <b>{{ 'SHARED.FOOTER.PHONE' | translate }} </b>

            <a *ngIf="!displayPhoneNumber" [routerLink]="navigationService.getUrl('onlineinsurance')">{{ 'SHARED.FOOTER.CLICK_HERE' | translate }}</a>
            <a *ngIf="displayPhoneNumber" href="tel:+4570407040">70 40 70 40</a>
        </p>
    </div>
</div>
