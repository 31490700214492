<div class="container">
    <div class="row">
        <div class="col-sm-12 text-center cover-header">
            <h2>{{ 'INSURANCE.SECONDHOUSE.COMPONENTS.COVER.TITLE' | translate }}</h2>
        </div>
    </div>

    <div class="row">
        <div class="col-sm-4 text-center">
            <div class="insurance-container">
                <a [routerLink]="navigationService.getUrl('cover-secondhouse-houseLiability')">
                    <div class="ribbon-parent insurance-box insurance-img img-house-liability">
                        <ribbon [type]="'required'"></ribbon>
                        <div class="icon-circle center">
                            <div class="icon-wrap">
                                <i class="nf nf-house-husejeransvar"></i>
                            </div>
                        </div>
                    </div>
                    <div class="insurance-bottom-box">
                        <h2>{{ 'INSURANCE.HOUSE.COMPONENTS.COVER.LIABILITY' | translate }}</h2>
                        <p class="readmore">{{ 'SHARED.READ_MORE' | translate }}</p>
                    </div>
                </a>
            </div>
        </div>

        <div class="col-sm-4 text-center">
            <div class="insurance-container">
                <a [routerLink]="navigationService.getUrl('cover-secondhouse-fire')">
                    <div class="ribbon-parent insurance-box insurance-img img-secondhouse-fire">
                        <ribbon [type]="'required'"></ribbon>
                        <div class="icon-circle center">
                            <div class="icon-wrap">
                                <i class="nf nf-house-husansvar"></i>
                            </div>
                        </div>
                    </div>
                    <div class="insurance-bottom-box">
                        <h2>{{ 'INSURANCE.HOUSE.COMPONENTS.COVER.FIRE' | translate }}</h2>
                        <p class="readmore">{{ 'SHARED.READ_MORE' | translate }}</p>
                    </div>
                </a>
            </div>
        </div>

        <div class="col-sm-4 text-center">
            <div class="insurance-container">
                <a [routerLink]="navigationService.getUrl('cover-secondhouse-comprehensive')">
                    <div class="ribbon-parent insurance-box insurance-img img-secondhouse-compehensive">
                        <ribbon [type]="'required'"></ribbon>
                        <div class="icon-circle center">
                            <div class="icon-wrap">
                                <i class="nf nf-house-kasko"></i>
                            </div>
                        </div>
                    </div>
                    <div class="insurance-bottom-box">
                        <h2>{{ 'INSURANCE.HOUSE.COMPONENTS.COVER.COMPREHENSIVE' | translate }}</h2>
                        <p class="readmore">{{ 'SHARED.READ_MORE' | translate }}</p>
                    </div>
                </a>
            </div>
        </div>

        <div class="col-sm-4 text-center">
            <div class="insurance-container">
                <a [routerLink]="navigationService.getUrl('cover-secondhouse-glass')">
                    <div class="ribbon-parent insurance-box insurance-img img-secondhouse-glass">
                        <ribbon [type]="'required'"></ribbon>
                        <div class="icon-circle center">
                            <div class="icon-wrap">
                                <i class="nf nf-house-glas-og-sanitet"></i>
                            </div>
                        </div>
                    </div>
                    <div class="insurance-bottom-box">
                        <h2>{{ 'INSURANCE.HOUSE.COMPONENTS.COVER.GLASS' | translate }}</h2>
                        <p class="readmore">{{ 'SHARED.READ_MORE' | translate }}</p>
                    </div>
                </a>
            </div>
        </div>

        <div class="col-sm-4 text-center">
            <div class="insurance-container">
                <a [routerLink]="navigationService.getUrl('cover-secondhouse-court')">
                    <div class="ribbon-parent insurance-box img-house-court">
                        <ribbon [type]="'required'"></ribbon>
                        <div class="icon-circle center">
                            <div class="icon-wrap">
                                <i class="nf nf-car-retshjlp"></i>
                            </div>
                        </div>
                    </div>
                    <div class="insurance-bottom-box">
                        <h2>{{ 'INSURANCE.HOUSE.COMPONENTS.COVER.COURT' | translate }}</h2>
                        <p class="readmore">{{ 'SHARED.READ_MORE' | translate }}</p>
                    </div>
                </a>
            </div>
        </div>

        <div class="col-sm-4 text-center">
            <div class="insurance-container">
                <a [routerLink]="navigationService.getUrl('cover-secondhouse-pipecable')">
                    <div class="ribbon-parent insurance-box insurance-img img-secondhouse-pipecable">
                        <ribbon [type]="'optional'"></ribbon>
                        <div class="icon-circle center">
                            <div class="icon-wrap">
                                <i class="nf nf-house-roer-og-kabel"></i>
                            </div>
                        </div>
                    </div>
                    <div class="insurance-bottom-box">
                        <h2>{{ 'INSURANCE.HOUSE.COMPONENTS.COVER.PIPE' | translate }}</h2>
                        <p class="readmore">{{ 'SHARED.READ_MORE' | translate }}</p>
                    </div>
                </a>
            </div>
        </div>

        <div class="col-sm-4 text-center">
            <div class="insurance-container">
                <a [routerLink]="navigationService.getUrl('cover-secondhouse-insectfungi')">
                    <div class="ribbon-parent insurance-box insurance-img img-secondhouse-insectfungi">
                        <ribbon [type]="'optional'"></ribbon>
                        <div class="icon-circle center">
                            <div class="icon-wrap">
                                <i class="nf nf-house-insekt-og-svamp"></i>
                            </div>
                        </div>
                    </div>
                    <div class="insurance-bottom-box">
                        <h2>{{ 'INSURANCE.HOUSE.COMPONENTS.COVER.INSECT' | translate }}</h2>
                        <p class="readmore">{{ 'SHARED.READ_MORE' | translate }}</p>
                    </div>
                </a>
            </div>
        </div>
        <div class="col-sm-4 text-center">
            <div class="insurance-container">
                <a [routerLink]="navigationService.getUrl('cover-secondhouse-serviceline')">
                    <div class="ribbon-parent insurance-box insurance-img img-secondhouse-serviceline">
                        <ribbon [type]="'optional'"></ribbon>
                        <div class="icon-circle center">
                            <div class="icon-wrap">
                                <i class="nf nf-broken-pipe"></i>
                            </div>
                        </div>
                    </div>
                    <div class="insurance-bottom-box">
                        <h2>{{ 'INSURANCE.HOUSE.COMPONENTS.COVER.SERVICE_LINE' | translate }}</h2>
                        <p class="readmore">{{ 'SHARED.READ_MORE' | translate }}</p>
                    </div>
                </a>
            </div>
        </div>

        <div class="col-sm-4 text-center">
            <div class="insurance-container">
                <a [routerLink]="navigationService.getUrl('cover-secondhouse-extended')">
                    <div class="ribbon-parent insurance-box img-secondhouse-extended">
                        <ribbon [type]="'optional'"></ribbon>
                        <div class="icon-circle center">
                            <div class="icon-wrap">
                                <i class="nf nf-house-udvidet-deakning"></i>
                            </div>
                        </div>
                    </div>
                    <div class="insurance-bottom-box">
                        <h2>{{ 'INSURANCE.HOUSE.COMPONENTS.COVER.EXTENDED' | translate }}</h2>
                        <p class="readmore">{{ 'SHARED.READ_MORE' | translate }}</p>
                    </div>
                </a>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-sm-12 text-center cover-header">
            <h2>{{ 'INSURANCE.SECONDHOUSE.COMPONENTS.COVER.SECONDHOUSE_CONTAINS_HOME' | translate }}</h2>
        </div>
    </div>

    <div class="row">
        <div class="col-sm-4 text-center">
            <div class="insurance-container">
                <a [routerLink]="navigationService.getUrl('cover-secondhouse-private')">
                    <div class="ribbon-parent insurance-box insurance-img img-secondhouse-private">
                        <ribbon [type]="'required'"></ribbon>
                        <div class="icon-circle center">
                            <div class="icon-wrap">
                                <i class="nf nf-home-ansvar"></i>
                            </div>
                        </div>
                    </div>
                    <div class="insurance-bottom-box">
                        <h2>{{ 'INSURANCE.HOME.COMPONENTS.COVER.PRIVATE' | translate }}</h2>
                        <p class="readmore">{{ 'SHARED.READ_MORE' | translate }}</p>
                    </div>
                </a>
            </div>
        </div>

        <div class="col-sm-4 text-center">
            <div class="insurance-container">
                <a [routerLink]="navigationService.getUrl('cover-secondhouse-electronic')">
                    <div class="ribbon-parent insurance-box insurance-img img-secondhouse-electronic">
                        <ribbon [type]="'optional'"></ribbon>
                        <div class="icon-circle center">
                            <div class="icon-wrap">
                                <i class="nf nf-home-elektronik-dkning"></i>
                            </div>
                        </div>
                    </div>
                    <div class="insurance-bottom-box">
                        <h2>{{ 'INSURANCE.HOME.COMPONENTS.COVER.ELECTRONIC' | translate }}</h2>
                        <p class="readmore">{{ 'SHARED.READ_MORE' | translate }}</p>
                    </div>
                </a>
            </div>
        </div>

        <div class="col-sm-4 text-center">
            <div class="insurance-container">
                <a [routerLink]="navigationService.getUrl('cover-secondhouse-sudden')">
                    <div class="ribbon-parent insurance-box insurance-img img-secondhouse-sudden">
                        <ribbon [type]="'optional'"></ribbon>
                        <div class="icon-circle center">
                            <div class="icon-wrap">
                                <i class="nf nf-home-pludselig-skade"></i>
                            </div>
                        </div>
                    </div>
                    <div class="insurance-bottom-box">
                        <h2>{{ 'INSURANCE.HOME.COMPONENTS.COVER.SUDDEN' | translate }}</h2>
                        <p class="readmore">{{ 'SHARED.READ_MORE' | translate }}</p>
                    </div>
                </a>
            </div>
        </div>
    </div>

    <h2 class="text-center">{{ 'INSURANCE.SHARED.CHOOSE_COVERS' | translate }}</h2>

    <cover-table productCode="secondhouse"></cover-table>
    <br />
    <br />

    <div class="row">
        <div class="col-sm-12 text-center">
            <a class="btn btn-success bottom-spacer-big" [routerLink]="navigationService.getUrl('calculate-secondhouse')" rel="nofollow">
                {{ 'INSURANCE.SECONDHOUSE.COMPONENTS.COVER.CALCULATE' | translate | uppercase }}
            </a>
        </div>
    </div>
</div>
