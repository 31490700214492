import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';
import { AppConfig } from './app-config';

declare let process: any;

@Injectable()
export class AppConfigService {
    appConfig: AppConfig;

    constructor(private http: HttpClient, @Inject(PLATFORM_ID) private platformId: any) {}

    async loadAppConfig(): Promise<void> {
        let configUrl = '/config';

        if (!isPlatformBrowser(this.platformId)) {
            if (process.env != null && process.env.FrontendHostname != null) {
                configUrl = 'https://' + process.env.FrontendHostname + configUrl;
            } else {
                configUrl = 'http://localhost:4000' + configUrl;
            }
        }

        return this.http
            .get<AppConfig>(configUrl)
            .toPromise()
            .then((c) => {
                this.appConfig = c;
            });
    }
}
