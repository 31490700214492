import { Component, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
    moduleId: module.id,
    selector: 'expired-basket',
    templateUrl: './expired-basket.component.html',
    styleUrls: ['./expired-basket.component.less'],
})
export class ExpiredBasketComponent implements OnInit {
    @Output() closeDialog: EventEmitter<boolean> = new EventEmitter();

    constructor() {}

    ngOnInit() {}

    onCloseDialogClick(): void {
        this.closeDialog.emit();
    }
}
