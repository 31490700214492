import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { AppConfigService } from '../appconfig/app-config.service';
import { SigningInfoModel } from 'next-typescript-api';

@Injectable({
    providedIn: 'root',
})
export class ConsentService {
    private apiUrl: string;

    constructor(private httpClient: HttpClient, private config: AppConfigService) {
        this.apiUrl = this.config.appConfig.apiHost;
    }

    getSigningInfo(customerNo: string, consentId: string, redirectUri: string): Observable<HttpResponse<SigningInfoModel>> {
        const url = `${this.apiUrl}/customers/${customerNo}/consent/${consentId}/sign-info?redirectUri=${redirectUri}&api-version=2.0`;

        return this.httpClient.get(url, { observe: 'response' });
    }

    executeSigning(customerNo: string, consentId: string, state: string, code: string): Observable<HttpResponse<any>> {
        const url = `${this.apiUrl}/customers/${customerNo}/consent/${consentId}/sign-execute?state=${state}&code=${code}&api-version=2.0`;

        return this.httpClient.post(url, null, { observe: 'response' });
    }
}
