import { Routes } from '@angular/router';

import { IndexComponent } from './components/index/index.component';
import { CoverInfoComponent } from '../components/cover-info/cover-info.component';
import { CoverInfoSettings } from '../components/cover-info/CoverInfoSettings';
import { ContexpageComponent } from '../contexpage/components/contexpage/contexpage.component';

export const houseRoutes: Routes = [
    { path: '', component: IndexComponent },
    { path: 'husejeransvar', component: CoverInfoComponent, data: new CoverInfoSettings('house', 'liability', 'nf nf-house-husejeransvar') },
    { path: 'bygningsbrand', component: CoverInfoComponent, data: new CoverInfoSettings('house', 'fire', 'nf nf-house-husansvar') },
    { path: 'bygningskasko', component: CoverInfoComponent, data: new CoverInfoSettings('house', 'comprehensive', 'nf nf-house-kasko') },
    { path: 'glas-og-sanitet', component: CoverInfoComponent, data: new CoverInfoSettings('house', 'glass', 'nf nf-house-glas-og-sanitet') },
    { path: 'retshjaelp', component: CoverInfoComponent, data: new CoverInfoSettings('house', 'court', 'nf nf-car-retshjlp') },
    { path: 'roer-og-kabel-forsikring', component: CoverInfoComponent, data: new CoverInfoSettings('house', 'pipecable', 'nf nf-house-roer-og-kabel') },
    { path: 'insekt-og-svamp', component: CoverInfoComponent, data: new CoverInfoSettings('house', 'insectfungi', 'nf nf-house-insekt-og-svamp') },
    { path: 'stikledningsforsikring', component: CoverInfoComponent, data: new CoverInfoSettings('house', 'serviceline', 'nf nf-broken-pipe') },
    { path: 'raadforsikring', component: CoverInfoComponent, data: new CoverInfoSettings('house', 'rot', 'nf nf-house-raad') },
    { path: 'udvidet-daekning', component: CoverInfoComponent, data: new CoverInfoSettings('house', 'extended', 'nf nf-house-udvidet-deakning') },
    // Non cover pages
    { path: 'alternativ-energi', component: CoverInfoComponent, data: new CoverInfoSettings('house', 'alternativeenergy', '') },
    { path: 'tilbygning-ombygning', component: CoverInfoComponent, data: new CoverInfoSettings('house', 'extension', '') },
    // Contex Pages
    { path: 'genhusning', component: ContexpageComponent },
    { path: 'stormskade', component: ContexpageComponent },
];
