import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AppConfigService } from 'app/utils/appconfig/app-config.service';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'app/auth/auth.service';
import { GetDocumentResponse } from 'next-typescript-api';
import { ObservableHelper } from 'app/utils/observableHelper/observableHelper';

@Injectable()
export class DocumentService {
    private apiUrl: string;
    private missingCustomerIdMessage = 'Missing customer ID';

    constructor(private config: AppConfigService, private http: HttpClient, private authService: AuthService, private observableHelper: ObservableHelper) {
        this.apiUrl = this.config.appConfig.apiHost;
    }

    getDocumentUrl(policyId: string, documenttypeCode: number): Observable<GetDocumentResponse> {
        const customerId = this.authService.getCustomerId();

        if (!customerId) {
            return this.observableHelper.CreateFailedObservableAndLogStackTrace(this.missingCustomerIdMessage);
        }

        const url = `${this.apiUrl}/customers/${customerId}/policies/` + policyId + '/documents/' + documenttypeCode + '?api-version=2.1';
        return this.http.get<GetDocumentResponse>(url);
    }

    getConsentDocumentUrl(): Observable<GetDocumentResponse> {
        const customerId = this.authService.getCustomerId();

        if (!customerId) {
            return this.observableHelper.CreateFailedObservableAndLogStackTrace(this.missingCustomerIdMessage);
        }

        const url = `${this.apiUrl}/customers/${customerId}/documents/consent?api-version=2.0`;
        return this.http.get<GetDocumentResponse>(url);
    }

    getTermsDocumentUrl(product: number): Observable<GetDocumentResponse> {
        const url = `${this.apiUrl}/insurancedata/general/terms/${product}?api-version=2.0`;
        return this.http.get<GetDocumentResponse>(url);
    }

    getTermsDocumentUrlByTermsName(termsName: string, product: number): Observable<GetDocumentResponse> {
        const url = `${this.apiUrl}/insurancedata/general/terms/${termsName}/product/${product}?api-version=2.0`;
        return this.http.get<GetDocumentResponse>(url);
    }
}
