import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CalculateService } from 'app/calculate/calculate.service';
import { ContexService } from 'app/utils/contex/contex.service';
import { MetaTagsHelper } from 'app/utils/metaTags/metaTagsHelper';
import { NavigationService } from 'app/utils/navigation/navigation.service';

@Component({
    selector: 'app-cover-info',
    templateUrl: './cover-info.component.html',
    styleUrls: ['./cover-info.component.less'],
})
export class CoverInfoComponent implements OnInit {
    contex: any;
    product: string;
    cover: string;
    coverIcon: string;
    newCarProduct: boolean;

    constructor(
        public navigationService: NavigationService,
        public contexService: ContexService,
        private metaTagsHelper: MetaTagsHelper,
        private route: ActivatedRoute,
        private calculateService: CalculateService
    ) {}

    ngOnInit(): void {
        this.route.data.subscribe((x) => {
            console.log(x);
            this.product = x.product;
            this.cover = x.cover;
            this.coverIcon = x.coverIcon;
            this.newCarProduct = x.newCarProduct;
            console.log(this.product, this.cover);

            if (this.newCarProduct) {
                this.calculateService.validateNewCarAllowed();
            }

            // TODO We should use title and description from the contexService instead of the translation service
            this.metaTagsHelper.setTitleAndMetaTags('META.COVER_' + this.product.toUpperCase() + '_' + this.cover.toUpperCase());

            const pagecode = this.navigationService.getPathObject('cover-' + this.product.toLowerCase() + '-' + this.cover.toLowerCase()).contexPageCode;

            this.contexService.getContent(pagecode).subscribe((content) => {
                this.contex = content;
            });
        });
    }
}
