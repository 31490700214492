import { FunctionsService } from './../utils/functions/functions.service';
import { Injectable, EventEmitter } from '@angular/core';
import { TokenStorage } from 'app/auth/token-storage.service';
import { Observable, throwError, Subject } from 'rxjs';
import { AppConfigService } from 'app/utils/appconfig/app-config.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AuthData } from 'app/class/authdata';

@Injectable()
export class AuthService {
    public clearTokenObserveable: Observable<void>;

    loginStatusChanged: EventEmitter<boolean> = new EventEmitter();
    tokenExpirationChanged: EventEmitter<Date> = new EventEmitter();
    private authUrl: string;
    private apiUrl: string;
    private clearTokenSource = new Subject<void>();

    constructor(private tokenStorage: TokenStorage, private config: AppConfigService, private http: HttpClient, private functionService: FunctionsService) {
        this.clearTokenObserveable = this.clearTokenSource.asObservable();

        this.apiUrl = this.config.appConfig.apiHost;
        this.authUrl = this.apiUrl.replace('/_apis', '/auth/token');
    }

    public getToken(): string {
        return this.tokenStorage.getAccessToken();
    }

    public getCustomerId(): string {
        return this.tokenStorage.getCustomerId();
    }

    public getAuthenticationType(): string {
        return this.tokenStorage.getAuthenticationType();
    }

    public token(grantType: string, parameters: string): Promise<any> {
        const body = 'grant_type=' + grantType + '&' + parameters;

        const headers = new HttpHeaders().append('Content-Type', 'application/x-www-form-urlencoded');
        headers.append('Access-Control-Allow-Origin', '*');

        const saveDataPromise = new Promise((resolve, reject) => {
            this.http
                .post<any>(this.authUrl, body, { headers })
                .toPromise()
                .then((data) => {
                    const authData = data as AuthData;
                    authData.expires_at = new Date(authData.expires_at);
                    this.saveData(authData);
                    resolve(null);
                }, reject);
        });

        return saveDataPromise;
    }

    public login(challenge: string, signature: string, ssn?: string): Promise<any> {
        let tokenParameters = 'result=ok&signature=' + encodeURIComponent(signature) + '&challenge=' + encodeURIComponent(challenge);

        if (ssn != null) {
            tokenParameters += '&ssn=' + ssn;
        }

        return this.token('nemid', tokenParameters);
    }

    public sas(sasToken: string): Promise<any> {
        const tokenParameters = 'token=' + encodeURIComponent(sasToken);

        return this.token('sas', tokenParameters);
    }

    public getSASToken(): Observable<string> {
        const customerId = this.getCustomerId();
        const url = `${this.apiUrl}/auth/sas/customer/${customerId}?api-version=2.0`;

        return this.http.get<string>(url);
    }

    public clearToken(): void {
        this.tokenStorage.clear();
        this.clearTokenSource.next();
    }

    public isAuthenticated(): boolean {
        return !this.tokenExpired();
    }

    public refreshToken(): Promise<any> {
        const body = 'grant_type=refresh_token&refresh_token=' + encodeURIComponent(this.tokenStorage.getRefreshToken());

        const headers = new HttpHeaders().append('Content-Type', 'application/x-www-form-urlencoded');
        headers.append('Access-Control-Allow-Origin', '*');

        const saveDataPromise = new Promise<any>((resolve, reject) => {
            this.http
                .post(this.authUrl, body, { headers })
                .toPromise()
                .then(
                    (response) => {
                        const authData = response as AuthData;
                        authData.expires_at = new Date(authData.expires_at);
                        this.saveData(authData);

                        resolve(response);
                    },
                    (error) => {
                        this.clearToken();
                        throwError(error);
                        reject(error);
                    }
                );
        });

        return saveDataPromise;
    }

    public getExpirationDate(): Date {
        return this.tokenStorage.getExpirationDate();
    }

    public saveData(data: AuthData): TokenStorage {
        const nowDate = this.functionService.convertToUtcDate(new Date());

        // Do not use data.expires_at since users time settings might be messed up
        const expireDate = new Date(nowDate.getTime() + data.expires_in * 1000);

        const ts = this.tokenStorage
            .setAccessToken(data.access_token)
            .setRefreshToken(data.refresh_token)
            .setCustomerId(data.customer_no)
            .setAuthenticationType(data.authentication_type)
            .setExpDate(expireDate);

        this.tokenExpirationChanged.next(this.tokenStorage.getExpirationDate());
        this.loginStatusChanged.next(true);

        return ts;
    }

    public isSasAuthExternal(): boolean {
        return this.getAuthenticationType() === 'SAS_External';
    }

    private tokenExpired(): boolean {
        const nowDate = this.functionService.convertToUtcDate(new Date());
        const expDate = this.tokenStorage.getExpirationDate();

        if (expDate != null && expDate.getTime() > nowDate.getTime()) {
            return false;
        }
        this.clearToken();
        return true;
    }
}
