import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { DawaAddress } from './dawaaddress';
import { DawaAddressFull } from './dawaaddressfull';
import { AddressInsurance } from 'next-typescript-api';

@Injectable()
export class DawaService {
    constructor(private http: HttpClient) {}

    getDawaAddressById(addressId: string): Observable<DawaAddressFull> {
        const url = `https://dawa.aws.dk/adresser/${addressId}`;
        return this.http.get<DawaAddressFull>(url);
    }

    getDawaAddressByCustomerAddress(address: AddressInsurance): Observable<DawaAddress[]> {
        if (address == null) {
            return of([]);
        }

        let addressString = '';
        if (address.streetName) {
            addressString += address.streetName + ' ';
        }
        if (address.streetNo) {
            addressString += address.streetNo + ' ';
        }
        if (address.floor) {
            addressString += address.floor + ' ';
        }
        if (address.doorSide) {
            addressString += address.doorSide + ' ';
        }
        if (address.zipcode) {
            addressString += address.zipcode + ' ';
        }
        if (address.city) {
            addressString += address.city + ' ';
        }

        return new Observable<DawaAddress[]>((subscriber) => {
            this.getDawaAddressByText(addressString).subscribe((addressResponses) => {
                if (addressResponses?.length > 0) {
                    subscriber.next(addressResponses);
                    subscriber.complete();
                    return;
                }

                this.getDawaFullAddress(address).subscribe((fullAddressResponses) => {
                    const dawaAddresses = fullAddressResponses.map((a) => this.mapDawaAddressFull(a));
                    subscriber.next(dawaAddresses);
                    subscriber.complete();
                });
            });
        });
    }

    getDawaAddressByText(addressText: string): Observable<DawaAddress[]> {
        if (addressText == null || addressText.length < 1) {
            return of([]);
        }

        const url = 'https://dawa.aws.dk/adresser/autocomplete?q=' + encodeURIComponent(addressText) + '&format=json';

        return this.http.get<DawaAddress[]>(url);
    }

    getDawaFullAddress(address: AddressInsurance): Observable<DawaAddressFull[]> {
        const url = `https://dawa.aws.dk/adresser?vejnavn=${address.streetName}&postnr=${address.zipcode}&husnr=${address.streetNo}&format=json`;

        return this.http.get<DawaAddressFull[]>(url);
    }

    mapDawaAddressFull(fullAddress: DawaAddressFull): DawaAddress {
        const dawaAddress: DawaAddress = {
            adresse: {
                id: fullAddress.adgangsadresse.id,
                href: fullAddress.href,
                vejnavn: fullAddress.adgangsadresse.vejstykke.navn,
                husnr: fullAddress.adgangsadresse.husnr,
                etage: fullAddress.etage,
                dør: fullAddress.dør,
                supplerendebynavn: fullAddress.adgangsadresse.supplerendebynavn,
                postnr: fullAddress.adgangsadresse.postnummer.nr,
                postnrnavn: fullAddress.adgangsadresse.postnummer.navn,
                stormodtagerpostnr: fullAddress.adgangsadresse.stormodtagerpostnummer,
            },
            tekst: fullAddress.adressebetegnelse,
        };

        return dawaAddress;
    }
}
