import { Component, Input } from '@angular/core';

@Component({
    moduleId: module.id,
    selector: 'pageloader',
    templateUrl: './pageloader.component.html',
    styleUrls: ['./pageloader.component.less'],
})
export class PageLoaderComponent {
    @Input() stepState: string;
    @Input() show: boolean;

    constructor() {}
}
