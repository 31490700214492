import { MetaTagsHelper } from 'app/utils/metaTags/metaTagsHelper';
import { Component, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';
import { ContexService } from 'app/utils/contex/contex.service';
import { NavigationService } from 'app/utils/navigation/navigation.service';
import { Subscription } from 'rxjs';

@Component({
    moduleId: module.id,
    selector: 'privacycookie',
    templateUrl: './privacycookie.component.html',
    styleUrls: ['./privacycookie.component.less'],
    encapsulation: ViewEncapsulation.None, // allows us to style contex content
})
export class PrivacyCookieComponent implements OnInit, OnDestroy {
    contex: any;
    activeTab = 'privacy';

    subscriptions: Subscription[] = [];

    constructor(public navigationService: NavigationService, private contexService: ContexService, private metaTagsHelper: MetaTagsHelper) {
        this.metaTagsHelper.setTitleAndMetaTags('META.PRIVACY_COOKIE');
    }

    ngOnInit(): void {
        const pagecode = this.navigationService.getPathObject('privacycookie').contexPageCode;
        this.subscriptions.push(
            this.contexService.getContent(pagecode).subscribe((x) => {
                this.contex = x;
            })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    setTab(tabName: string) {
        this.activeTab = tabName;
        if (this.activeTab === 'cookie') {
            setTimeout(function () {
                const scriptNode = document.createElement('script');
                scriptNode.type = 'text/javascript';
                scriptNode.src = 'https://consent.cookiebot.com/7bac119e-fd12-4b3d-b0fe-3f10b595e064/cd.js';
                scriptNode.async = true;
                scriptNode.id = 'CookieDeclaration';

                document.getElementById('CookieDeclarationWrapper').appendChild(scriptNode);
            }, 0);
        }
    }
}
