import { NavigationService } from './../../../../utils/navigation/navigation.service';
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ViewEncapsulation } from '@angular/core';
import { MaintenanceService } from 'app/utils/maintenance/maintenance.service';
import { Subscription } from 'rxjs';
import { MaintenanceItem } from 'next-typescript-api';
import { TranslateService } from '@ngx-translate/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
    selector: 'maintenance-info',
    templateUrl: './maintenance-info.component.html',
    styleUrls: ['./maintenance-info.component.less'],
    encapsulation: ViewEncapsulation.None, // allows us to style contex content
})
export class MaintenanceInfoComponent implements OnInit, OnDestroy {
    @Input() maintenanceType: number;
    @Input() dmrproduct: string;
    @Input() thedmrproduct: string;
    @Input() dmriconname: string;
    @Output() btnClicked: EventEmitter<boolean> = new EventEmitter();
    dmrBtnText: string;
    maintenanceExpanded: boolean;
    hideMaintenance: boolean; // is set to true on OK-click
    hasMaintenance: boolean;
    currentMaintenance: MaintenanceItem;
    defaultValues: MaintenanceItem[] = [];
    defaultMaintenanceTypes = [2, 3]; // 2: DMR, 3: CPR

    subscriptions: Subscription[] = [];

    constructor(private maintenanceService: MaintenanceService, private translateService: TranslateService, private router: Router, private navigationService: NavigationService) {}

    ngOnInit() {
        if ((this.maintenanceType === 1 || this.maintenanceType === 4) && this.maintenanceService.closedByUser) {
            // 1: General, 4: Selfcare
            return;
        }

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                const squeezeUrl = this.navigationService.getPathObject('squeeze')?.url;
                if (squeezeUrl && event.url.indexOf(squeezeUrl) >= 0) {
                    this.hideMaintenance = true;
                }
            }
        });

        this.dmrBtnText = this.translateService.instant('SHARED.MAINTENANCE_INFO.CLICK_HERE') + ' ' + this.thedmrproduct;

        // Order in defaultValues must match API MaintenanceType number values
        this.defaultValues = [
            { title: '', description: '' }, // No enum for value 0
            { title: '', description: '' }, // General - values from MaintenanceItem are used
            {
                title: this.translateService.instant('SHARED.MAINTENANCE_INFO.DMR_TITLE'),
                description:
                    this.translateService.instant('SHARED.MAINTENANCE_INFO.DMR_TEXT_START') +
                    ' ' +
                    this.dmrproduct +
                    ' ' +
                    this.translateService.instant('SHARED.MAINTENANCE_INFO.DMR_TEXT_END'),
            },
            {
                title: this.translateService.instant('SHARED.MAINTENANCE_INFO.CPR_TITLE'),
                description: this.translateService.instant('SHARED.MAINTENANCE_INFO.CPR_TEXT'),
            },
        ];

        this.subscriptions.push(
            this.maintenanceService.getMaintenanceItems(this.maintenanceType, 1, 1).subscribe(
                (resp) => {
                    this.hasMaintenance = resp != null && resp.length > 0;
                    if (this.hasMaintenance) {
                        this.currentMaintenance = resp[0];

                        if (this.defaultMaintenanceTypes.some((t) => t === this.maintenanceType)) {
                            this.currentMaintenance = this.defaultValues[this.maintenanceType];
                        }
                    }
                },
                (err) => {
                    console.log('getMaintenanceItems failed!');
                }
            )
        );
    }

    btnClick() {
        this.btnClicked.next(true);
    }

    toogleMaintenanceExpanded(): void {
        this.maintenanceExpanded = !this.maintenanceExpanded;
    }

    setHideMaintenance(): void {
        if (this.maintenanceType === 1 || this.maintenanceType === 4) {
            this.maintenanceService.closedByUser = true;
        }
        this.hideMaintenance = true;
    }

    ngOnDestroy() {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }
}
