<!-- TrustBox widget - Carousel -->
<div
    id="trustpilot-review-carousel-light"
    class="trustpilot-widget"
    data-locale="da-DK"
    data-template-id="53aa8912dec7e10d38f59f36"
    data-businessunit-id="4c90a80400006400050ddc71"
    data-style-height="140px"
    data-style-width="100%"
    data-theme="light"
    data-stars="5"
>
    <a href="https://dk.trustpilot.com/review/next.dk" target="_blank" rel="noopener">Trustpilot</a>
</div>
<!-- End TrustBox widget -->
