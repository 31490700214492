<div class="container">
    <div class="row">
        <div class="col-sm-12 text-center cover-header">
            <h2>{{ 'INSURANCE.MOPED.COMPONENTS.COVER.TITLE' | translate }}</h2>
        </div>
    </div>

    <div class="row">
        <div class="col-sm-4 text-center">
            <div class="insurance-container">
                <a [routerLink]="navigationService.getUrl('cover-moped-liability')">
                    <div class="ribbon-parent insurance-box insurance-img img-moped-liability">
                        <ribbon [type]="'required'"></ribbon>
                        <div class="icon-circle center">
                            <div class="icon-wrap">
                                <i class="nf nf-product-moped"></i>
                            </div>
                        </div>
                    </div>
                    <div class="insurance-bottom-box">
                        <h2>{{ 'INSURANCE.MOPED.COMPONENTS.COVER.LIABILITY' | translate }}</h2>
                        <p class="readmore">{{ 'SHARED.READ_MORE' | translate }}</p>
                    </div>
                </a>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-sm-12 text-center">
            <a class="btn btn-success bottom-spacer-big" [routerLink]="navigationService.getUrl('calculate-moped')" rel="nofollow">
                {{ 'INSURANCE.MOPED.COMPONENTS.COVER.CALCULATE' | translate | uppercase }}
            </a>
        </div>
    </div>
</div>
