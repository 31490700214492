import { Component, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';
import { ContexService } from 'app/utils/contex/contex.service';
import { NavigationService } from 'app/utils/navigation/navigation.service';
import { MetaTagsHelper } from 'app/utils/metaTags/metaTagsHelper';
import { Subscription } from 'rxjs';

@Component({
    moduleId: module.id,
    selector: 'complaintsmanager',
    templateUrl: './complaintsmanager.component.html',
    styleUrls: ['./complaintsmanager.component.less'],
    encapsulation: ViewEncapsulation.None, // allows us to style contex content
    providers: [ContexService],
})
export class ComplaintsManagerComponent implements OnInit, OnDestroy {
    contex: any;
    subscriptions: Subscription[] = [];

    constructor(public navigationService: NavigationService, private contexService: ContexService, private metaTagsHelper: MetaTagsHelper) {
        this.metaTagsHelper.setTitleAndMetaTags('META.COMPLAINTSMANAGER');
    }

    ngOnInit(): void {
        const pagecode = this.navigationService.getPathObject('complaintsmanager').contexPageCode;
        this.subscriptions.push(
            this.contexService.getContent(pagecode).subscribe((x) => {
                this.contex = x;
            })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }
}
