import { Injectable } from '@angular/core';
import { AppConfigService } from '../appconfig/app-config.service';

@Injectable()
export class GoogleMapsService {
    constructor(private appConfigService: AppConfigService) {}

    init() {
        // Load Google Maps API JS
        const node = document.createElement('script');
        node.src = 'https://maps.googleapis.com/maps/api/js?key=' + this.appConfigService.appConfig.googleApiKey;
        node.type = 'text/javascript';
        document.getElementsByTagName('head')[0].appendChild(node);
    }
}
