import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfigService } from 'app/utils/appconfig/app-config.service';
import { Injectable } from '@angular/core';
import { ResidentialTypeItem } from 'next-typescript-api';

@Injectable()
export class InsuranceDataService {
    private apiUrl: string;

    constructor(private http: HttpClient, private config: AppConfigService) {
        this.apiUrl = this.config.appConfig.apiHost;
    }

    getHomeHousingTypes(): Observable<ResidentialTypeItem[]> {
        const url = `${this.apiUrl}/insurancedata/home/housing-types?api-version=2.0`;
        return this.http.get<ResidentialTypeItem[]>(url);
    }
}
