<a *ngIf="!loading && !documentUrl" (click)="getDownloadUrl()">
    <span class="text-green">{{ 'PAGE.DOWNLOAD_DOCUMENT.DOWNLOAD_TERMS' | translate }}</span>
    <i class="nf-selvbetjening-pdf"></i>
</a>
<div *ngIf="loading" class="circle-loader gray thin inline small"></div>
<!-- File download - do not replace [href] with [routerLink] -->
<a [class.hidden]="!documentUrl" #showbtn [href]="documentUrl" target="_blank">
    <span class="text-green">{{ 'PAGE.DOWNLOAD_DOCUMENT.SHOW' | translate }}</span>
    <i class="nf-selvbetjening-pdf"></i>
</a>
