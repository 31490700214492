import { NavigationService } from 'app/utils/navigation/navigation.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PaymentCollectorService } from 'app/selfcare/payments/paymentcollector.service';
import { AppConfigService } from 'app/utils/appconfig/app-config.service';
import { OrderPaymentCollectorDetails } from 'next-typescript-api';

@Component({
    selector: 'app-manualpayment',
    templateUrl: './manualpayment.component.html',
    styleUrls: ['./manualpayment.component.less'],
})
export class ManualpaymentComponent implements OnInit {
    collectionid: string;
    secure: string;
    loading: boolean;
    manuelPaymentMethods: OrderPaymentCollectorDetails[];
    errorGettingPaymentMethods: boolean;
    paymentresult: boolean;
    private paymentchangedparam = 'betaling-accepteret';

    constructor(private route: ActivatedRoute, private paymentcollectorService: PaymentCollectorService, private config: AppConfigService, private navigationService: NavigationService) {}

    async ngOnInit() {
        this.loading = true;

        if (this.route.snapshot.queryParams[this.paymentchangedparam] != null) {
            this.paymentresult = this.route.snapshot.queryParams[this.paymentchangedparam] === '1';
        } else {
            this.collectionid = this.route.snapshot.queryParams['collectionId'];
            this.secure = this.route.snapshot.queryParams['secure'];

            this.paymentresult = null;

            const frontendHost = this.config.appConfig.frontendHost;
            const acceptUrl = frontendHost + this.navigationService.getUrl('manuelpayment') + '?' + this.paymentchangedparam + '=1';
            const declineUrl = frontendHost + this.navigationService.getUrl('manuelpayment') + '?' + this.paymentchangedparam + '=0';

            try {
                this.manuelPaymentMethods = await this.paymentcollectorService.getManuelPaymentCollectors(this.collectionid, this.secure, acceptUrl, declineUrl).toPromise();
            } catch (e) {
                this.errorGettingPaymentMethods = true;
            }
        }

        this.loading = false;
    }
}
