import { Injectable } from '@angular/core';
import { AppConfigService } from '../appconfig/app-config.service';
import { AppConfigPage } from '../appconfig/app-config-page';

@Injectable()
export class NavigationService {
    pages: AppConfigPage[];

    constructor(private configService: AppConfigService) {
        this.pages = this.configService.appConfig.pages;
    }

    getUrl(name: string): string {
        const obj = this.getPathObject(name);
        if (obj == null) {
            return '';
        }

        return obj.url;
    }

    getPathObject(name: string): AppConfigPage {
        if (name == null || name.length < 1) {
            return null;
        }

        const page = this.pages.find((po) => po.code.toLowerCase() === name.toLowerCase() || (po.codeSecondary != null && po.codeSecondary.toLowerCase() === name.toLowerCase()));
        if (!page) {
            return null;
        }

        return page;
    }

    getAllObjects(): AppConfigPage[] {
        return this.pages;
    }

    getCarCalculateUrl() {
        let urlStart = this.getUrl('calculate-car');
        if (this.configService.appConfig.useNewCarProduct) {
            urlStart = this.getUrl('calculate-carnew');
        }
        return urlStart;
    }
}
