<div *ngIf="timedOut" class="nem-id-session-expired">
    <div class="msg msg-error">
        {{ 'SHARED.LOGIN.SESSION_EXPIRED' | translate }}
    </div>

    <button class="btn btn-success" (click)="reloadNemId()" [class.loading]="loading" type="button">
        {{ 'SHARED.LOGIN.RELOAD' | translate }}
    </button>
</div>
<div *ngIf="!showSSNInput && !timedOut">
    <div class="msg msg-error" *ngIf="showNotFoundError">{{ 'SHARED.LOGIN.NOT_FOUND_ERROR' | translate }}</div>
    <div *ngIf="loadingIframe">
        {{ 'SHARED.LOGIN.LOADING' | translate }}
        <div class="text-center uploadspinner">
            <div class="circle-loader gray thin inline"></div>
        </div>
    </div>
    <iframe
        id="nemid_iframe"
        name="nemid_iframe"
        ontouchstart=""
        scrolling="no"
        frameborder="0"
        style="width: 300px; height: 460px; border: 0px; margin: 0px; padding: 0px; overflow: hidden"
    ></iframe>
</div>
<div *ngIf="showSSNInput && !timedOut">
    <div class="col-xs-12">
        <h4>{{ 'SHARED.LOGIN.SSN_INPUT' | translate }}</h4>
        <div class="field-input">
            <input
                class="cpr-input"
                type="text"
                name="cpr"
                autocomplete="off"
                [(ngModel)]="ssnInput"
                (keyup.enter)="loginWithSsn()"
                [class.missing-input]="!ssnInput || ssnInput.length === 0"
                mask="999999-9999"
            />
            <span class="floating-label">{{ 'SHARED.LOGIN.SSN_LABEL' | translate }}</span>
        </div>
        <div class="field-input">
            <button class="btn btn-success btn-login" (click)="loginWithSsn()" [disabled]="!ssnInput || ssnInput.length !== 10 || loading" [class.loading]="loading" type="button">
                <span>{{ 'SHARED.LOGIN.LOGIN' | translate }}</span>
            </button>
        </div>
        <div class="msg msg-error" *ngIf="showSSNError">{{ 'SHARED.LOGIN.SSN_ERROR' | translate }}</div>
        <div class="msg msg-error" *ngIf="showNotFoundError">{{ 'SHARED.LOGIN.NOT_FOUND_ERROR' | translate }}</div>
    </div>
</div>
<div class="msg msg-error" *ngIf="showError">
    <b>{{ 'SHARED.LOGIN.ERROR_TITLE' | translate }} {{ errorCode }}</b>
    {{ 'SHARED.LOGIN.ERROR_TEXT' | translate }}
</div>
