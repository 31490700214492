<div class="container">
    <div class="row">
        <div class="col-sm-12 text-center cover-header">
            <h2>{{ 'INSURANCE.HOME.COMPONENTS.COVER.TITLE' | translate }}</h2>
        </div>
    </div>

    <div class="row">
        <div class="col-sm-4 text-center">
            <div class="insurance-container">
                <a [routerLink]="navigationService.getUrl('cover-home-liability')">
                    <div class="ribbon-parent insurance-box insurance-img img-home-liability">
                        <ribbon [type]="'required'"></ribbon>
                        <div class="icon-circle center">
                            <div class="icon-wrap">
                                <i class="nf nf-home-privat-ansvar"></i>
                            </div>
                        </div>
                    </div>
                    <div class="insurance-bottom-box">
                        <h2>{{ 'INSURANCE.HOME.COMPONENTS.COVER.LIABILITY' | translate }}</h2>
                        <p class="readmore">{{ 'SHARED.READ_MORE' | translate }}</p>
                    </div>
                </a>
            </div>
        </div>

        <div class="col-sm-4 text-center">
            <div class="insurance-container">
                <a [routerLink]="navigationService.getUrl('cover-home-private')">
                    <div class="ribbon-parent insurance-box insurance-img img-home-private">
                        <ribbon [type]="'required'"></ribbon>
                        <div class="icon-circle center">
                            <div class="icon-wrap">
                                <i class="nf nf-home-ansvar"></i>
                            </div>
                        </div>
                    </div>
                    <div class="insurance-bottom-box">
                        <h2>{{ 'INSURANCE.HOME.COMPONENTS.COVER.PRIVATE' | translate }}</h2>
                        <p class="readmore">{{ 'SHARED.READ_MORE' | translate }}</p>
                    </div>
                </a>
            </div>
        </div>
        <div class="col-sm-4 text-center">
            <div class="insurance-container">
                <a [routerLink]="navigationService.getUrl('cover-home-court')">
                    <div class="ribbon-parent insurance-box insurance-img img-home-court">
                        <ribbon [type]="'required'"></ribbon>
                        <div class="icon-circle center">
                            <div class="icon-wrap">
                                <i class="nf nf-car-retshjlp"></i>
                            </div>
                        </div>
                    </div>
                    <div class="insurance-bottom-box">
                        <h2>{{ 'INSURANCE.HOME.COMPONENTS.COVER.COURT' | translate }}</h2>
                        <p class="readmore">{{ 'SHARED.READ_MORE' | translate }}</p>
                    </div>
                </a>
            </div>
        </div>
        <div class="col-sm-4 text-center">
            <div class="insurance-container">
                <a [routerLink]="navigationService.getUrl('cover-home-travel')">
                    <div class="ribbon-parent insurance-box insurance-img img-home-travel">
                        <ribbon [type]="'optional'"></ribbon>
                        <div class="icon-circle center">
                            <div class="icon-wrap">
                                <i class="nf nf-home-rejseforsikring"></i>
                            </div>
                        </div>
                    </div>
                    <div class="insurance-bottom-box">
                        <h2>{{ 'INSURANCE.HOME.COMPONENTS.COVER.TRAVEL' | translate }}</h2>
                        <p class="readmore">{{ 'SHARED.READ_MORE' | translate }}</p>
                    </div>
                </a>
            </div>
        </div>

        <div class="col-sm-4 text-center">
            <div class="insurance-container">
                <a [routerLink]="navigationService.getUrl('cover-home-cancellation')">
                    <div class="ribbon-parent insurance-box insurance-img img-home-cancellation">
                        <ribbon [type]="'optional'"></ribbon>
                        <div class="icon-circle center">
                            <div class="icon-wrap">
                                <i class="nf nf-home-afbestillingsforsikring"></i>
                            </div>
                        </div>
                    </div>
                    <div class="insurance-bottom-box">
                        <h2>{{ 'INSURANCE.HOME.COMPONENTS.COVER.CANCELLATION' | translate }}</h2>
                        <p class="readmore">{{ 'SHARED.READ_MORE' | translate }}</p>
                    </div>
                </a>
            </div>
        </div>

        <div class="col-sm-4 text-center">
            <div class="insurance-container">
                <a [routerLink]="navigationService.getUrl('cover-home-identity')">
                    <div class="ribbon-parent insurance-box insurance-img img-home-identity">
                        <ribbon [type]="'optional'"></ribbon>
                        <div class="icon-circle center">
                            <div class="icon-wrap">
                                <i class="nf nf-home-idsikring"></i>
                            </div>
                        </div>
                    </div>
                    <div class="insurance-bottom-box">
                        <h2>{{ 'INSURANCE.HOME.COMPONENTS.COVER.IDENTITY' | translate }}</h2>
                        <p class="readmore">{{ 'SHARED.READ_MORE' | translate }}</p>
                    </div>
                </a>
            </div>
        </div>

        <div class="col-sm-4 text-center">
            <div class="insurance-container">
                <a [routerLink]="navigationService.getUrl('cover-home-counseling')">
                    <div class="ribbon-parent insurance-box insurance-img img-home-counseling">
                        <ribbon [type]="'optional'"></ribbon>
                        <div class="icon-circle center">
                            <div class="icon-wrap">
                                <i class="nf nf-home-krisehjaelp"></i>
                            </div>
                        </div>
                    </div>
                    <div class="insurance-bottom-box">
                        <h2>{{ 'INSURANCE.HOME.COMPONENTS.COVER.COUNSELING' | translate }}</h2>
                        <p class="readmore">{{ 'SHARED.READ_MORE' | translate }}</p>
                    </div>
                </a>
            </div>
        </div>

        <div class="col-sm-4 text-center">
            <div class="insurance-container">
                <a [routerLink]="navigationService.getUrl('cover-home-glass')">
                    <div class="ribbon-parent insurance-box insurance-img img-home-glass">
                        <ribbon [type]="'optional'"></ribbon>
                        <div class="icon-circle center">
                            <div class="icon-wrap">
                                <i class="nf nf-house-glas-og-sanitet"></i>
                            </div>
                        </div>
                    </div>
                    <div class="insurance-bottom-box">
                        <h2>{{ 'INSURANCE.HOME.COMPONENTS.COVER.GLASS' | translate }}</h2>
                        <p class="readmore">{{ 'SHARED.READ_MORE' | translate }}</p>
                    </div>
                </a>
            </div>
        </div>

        <div class="col-sm-4 text-center">
            <div class="insurance-container">
                <a [routerLink]="navigationService.getUrl('cover-home-electronic')">
                    <div class="ribbon-parent insurance-box insurance-img img-home-electronic">
                        <ribbon [type]="'optional'"></ribbon>
                        <div class="icon-circle center">
                            <div class="icon-wrap">
                                <i class="nf nf-home-elektronik-dkning"></i>
                            </div>
                        </div>
                    </div>
                    <div class="insurance-bottom-box">
                        <h2>{{ 'INSURANCE.HOME.COMPONENTS.COVER.ELECTRONIC' | translate }}</h2>
                        <p class="readmore">{{ 'SHARED.READ_MORE' | translate }}</p>
                    </div>
                </a>
            </div>
        </div>

        <div class="col-sm-4 text-center">
            <div class="insurance-container">
                <a [routerLink]="navigationService.getUrl('cover-home-sudden')">
                    <div class="ribbon-parent insurance-box insurance-img img-home-sudden">
                        <ribbon [type]="'optional'"></ribbon>
                        <div class="icon-circle center">
                            <div class="icon-wrap">
                                <i class="nf nf-home-pludselig-skade"></i>
                            </div>
                        </div>
                    </div>
                    <div class="insurance-bottom-box">
                        <h2>{{ 'INSURANCE.HOME.COMPONENTS.COVER.SUDDEN' | translate }}</h2>
                        <p class="readmore">{{ 'SHARED.READ_MORE' | translate }}</p>
                    </div>
                </a>
            </div>
        </div>
        <div class="col-sm-4 text-center">
            <div class="insurance-container">
                <a [routerLink]="navigationService.getUrl('cover-home-biketheft')">
                    <div class="ribbon-parent insurance-box insurance-img img-home-biketheft">
                        <ribbon [type]="'optional'"></ribbon>
                        <div class="icon-circle center">
                            <div class="icon-wrap">
                                <i class="nf nf-plus-icon-v3"></i>
                            </div>
                        </div>
                    </div>
                    <div class="insurance-bottom-box">
                        <h2>{{ 'INSURANCE.HOME.COMPONENTS.COVER.BIKETHEFT' | translate }}</h2>
                        <p class="readmore">{{ 'SHARED.READ_MORE' | translate }}</p>
                    </div>
                </a>
            </div>
        </div>
    </div>

    <h2 class="text-center">{{ 'INSURANCE.SHARED.CHOOSE_COVERS' | translate }}</h2>

    <cover-table productCode="home"></cover-table>
    <br />
    <br />

    <div class="row">
        <div class="col-sm-12 text-center">
            <a class="btn btn-success bottom-spacer-big calculate-insurance-btn" [routerLink]="navigationService.getUrl('calculate-home')" rel="nofollow">
                {{ 'INSURANCE.HOME.COMPONENTS.COVER.CALCULATE' | translate | uppercase }}
            </a>
        </div>
    </div>
</div>
