import { TokenStorage } from 'app/auth/token-storage.service';
import { RequestReason } from './../../../../utils/contactrequestreason/requestreason';
import { ContactRequestReasonService } from './../../../../utils/contactrequestreason/contactrequestreason.service';
import { NavigationService } from 'app/utils/navigation/navigation.service';
import { Calltime } from './../../../../calculate/summary/models/calltime';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RegisterRequestInput } from 'next-typescript-api';
import { ContactReason, ContactRequestService } from 'app/utils/contactrequest/contactrequest.service';
import { PersonNameInput } from 'app/calculate/summary/models/personNameInput';
import { Experience } from './Experience';
import { CustomerService } from 'app/selfcare/personal/customer.service';

declare let dataLayer: any[];
@Component({
    selector: 'callme-modal',
    templateUrl: './callme-modal.component.html',
    styleUrls: ['./callme-modal.component.less'],
})
export class CallmeModalComponent implements OnInit {
    @Output() closeDialog: EventEmitter<boolean> = new EventEmitter();
    @Output() openChatDialog: EventEmitter<boolean> = new EventEmitter();
    @Input() isChatOpen: boolean;
    @Input() showPhoneNumberOnMobile: boolean;
    @Input() showCallMeBack: boolean;
    @Input() callmeExperience: Experience = Experience.default;
    @Input() policyNo: string;
    selectedReason: RequestReason = new RequestReason();
    name: string;
    phone: string;
    calltime: Calltime = new Calltime();
    calltimes: Calltime[] = [];
    reasons: RequestReason[] = [];
    okToSend = false;
    sending = false;
    createRequestSuccess = false;
    errorMessage: string;
    selecttime = 'select';
    customerName: PersonNameInput = new PersonNameInput();
    customerNo: string;

    constructor(
        public navigationService: NavigationService,
        private translateService: TranslateService,
        private contactRequestService: ContactRequestService,
        private contactRequestReasonService: ContactRequestReasonService,
        private tokenStorage: TokenStorage,
        private customerService: CustomerService
    ) {}

    ngOnInit(): void {
        this.calltimes = this.contactRequestService.getCallTimeOptions();

        this.calltime = this.calltimes[0]; // Add default non selectable value to support mobile dropdown

        this.contactRequestReasonService.getReasons().subscribe((reasons) => {
            this.reasons = reasons;

            switch (this.callmeExperience) {
                case Experience.showOnlySales:
                    this.selectedReason = this.reasons.find((t) => t.id === ContactReason.Sales);
                    break;
                case Experience.showOnlyCustomerService:
                    this.selectedReason = this.reasons.find((t) => t.id === ContactReason.CustomerService);
                    break;
                case Experience.callMe:
                case Experience.callMeTermination:
                    this.customerService.getCustomerDetailsSimple().subscribe((response) => {
                        this.customerName.firstName = response.nameFirst;
                        this.customerName.surName = response.nameLast;
                        this.phone = response.phoneNo;
                        this.customerNo = response.customerNo;
                        this.validate();
                    });
                    break;
                default:
                    break;
            }
        });
        this.validate();
    }

    sendContactRequest() {
        this.validate();
        if (!this.okToSend) {
            return;
        }

        switch (this.callmeExperience) {
            case Experience.callMe:
            case Experience.callMeTermination:
                this.callmeSendRequestHandling();
                break;
            default:
                this.defaultSendRequestHandling();
                break;
        }
    }

    callmeSendRequestHandling() {
        this.sending = true;

        const data: RegisterRequestInput = {
            phoneNo: this.phone,
            callTimeText: this.calltime.text,
            name: this.customerName,
            campaignId: this.tokenStorage.getLeadCampaignId(),
            reasonId: this.callmeExperience === Experience.callMe ? ContactReason.CallMe : ContactReason.CallMeTermination,
            sourceId: 'callme-modal (top menu)',
            customerId: this.customerNo,
            policyNo: this.policyNo,
        };

        this.contactRequestService.registerContactRequest(data).subscribe(
            (resp) => {
                if (resp) {
                    this.createRequestSuccess = true;

                    // Track contact request with Google Tag Manager
                    dataLayer = dataLayer || [];
                    dataLayer.push({ event: 'formSubmission', pageName: 'topContactForm' });
                } else {
                    this.sending = false;
                    this.errorMessage = this.translateService.instant('SHARED.TOPBAR.COMPONENTS.CALLME_MODAL.ERROR');
                }
            },
            (err) => {
                console.log(err);
                this.sending = false;
                this.errorMessage = this.translateService.instant('SHARED.TOPBAR.COMPONENTS.CALLME_MODAL.ERROR');
            }
        );
    }

    defaultSendRequestHandling() {
        if (this.selectedReason.id === ContactReason.CustomerService) {
            this.calltime = this.calltimes.find((ct) => ct.value === '3'); // 3: ASAP
        }

        const nameInput: PersonNameInput = new PersonNameInput();
        const tokens = this.name.split(' ');
        nameInput.firstName = tokens[0];
        if (tokens.length > 1) {
            nameInput.surName = '';
            for (let index = 1; index < tokens.length; index++) {
                nameInput.surName += tokens[index] + ' ';
            }
            nameInput.surName = nameInput.surName.trim();
        }
        const leadCampaignId = this.tokenStorage.getLeadCampaignId();
        const data: RegisterRequestInput = {
            phoneNo: this.phone,
            callTimeText: this.calltime.text,
            name: nameInput,
            campaignId: leadCampaignId,
            reasonId: this.selectedReason.id,
            sourceId: 'callme-modal (top menu)',
        };

        console.log(data);

        this.sending = true;
        this.contactRequestService.registerContactRequest(data).subscribe(
            (resp) => {
                if (resp) {
                    this.createRequestSuccess = true;

                    // Track contact request with Google Tag Manager
                    dataLayer = dataLayer || [];
                    dataLayer.push({ event: 'formSubmission', pageName: 'topContactForm' });
                } else {
                    this.sending = false;
                    this.errorMessage = this.translateService.instant('SHARED.TOPBAR.COMPONENTS.CALLME_MODAL.ERROR');
                }
            },
            (err) => {
                console.log(err);
                this.sending = false;
                this.errorMessage = this.translateService.instant('SHARED.TOPBAR.COMPONENTS.CALLME_MODAL.ERROR');
            }
        );
    }

    validate() {
        if (this.callmeExperience.includes('CALLME') && this.phone && this.phone.length === 8) {
            this.okToSend = true;
            return;
        }

        if (!this.selectedReason) {
            this.okToSend = false;
            return;
        }

        if (!this.name || this.name.length === 0) {
            this.okToSend = false;
            return;
        }

        if (!this.phone || this.phone.length !== 8) {
            this.okToSend = false;
            return;
        }

        if (!this.calltime && this.selectedReason?.id === ContactReason.Sales) {
            this.okToSend = false;
            return;
        }

        if (this.calltime.value === this.selecttime && this.selectedReason?.id === ContactReason.Sales) {
            this.okToSend = false;
            return;
        }

        this.okToSend = true;
    }

    closeModal() {
        this.closeDialog.emit();
    }

    openChat() {
        this.openChatDialog.emit();
    }

    translateReasonName(reason: RequestReason): string {
        const key = 'SHARED.TOPBAR.COMPONENTS.CALLME_MODAL.REASON_' + reason.id;
        const reasonName: string = this.translateService.instant(key);
        if (reasonName === key) {
            // No translation found - fall back to name from API
            return reason.name;
        }
        return reasonName;
    }
}
