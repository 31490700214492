import { TranslateModule } from '@ngx-translate/core';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { FooterModule } from 'app/shared/footer/footer.module';
import { MenuModule } from 'app/shared/menu/menu.module';
import { TopbarModule } from 'app/shared/topbar/topbar.module';
import { CustomModalModule } from 'app/shared/custommodal/custommodal.module';

import { NavigationService } from 'app/utils/navigation/navigation.service';
import { MaintenanceService } from 'app/utils/maintenance/maintenance.service';
import { FrontpageComponent } from './components/frontpage/frontpage.component';
import { MaintenanceInfoModule } from 'app/shared/maintenance-info/maintenance-info.module';
import { TrustpilotLineComponent } from 'app/shared/trustpilot/components/trustpilotLine/trustpilot-line.component';
import { ProductMenuComponent } from './components/product-menu/product-menu.component';

@NgModule({
    imports: [BrowserModule, FormsModule, FooterModule, TopbarModule, MenuModule, RouterModule, CustomModalModule, MaintenanceInfoModule, TranslateModule],
    declarations: [FrontpageComponent, TrustpilotLineComponent, ProductMenuComponent],
    exports: [ProductMenuComponent],
    bootstrap: [FrontpageComponent],
    providers: [NavigationService, MaintenanceService],
})
export class FrontpageModule {}
