<div class="full-width-image-section front-top">
    <next-topbar></next-topbar>
    <next-menu></next-menu>

    <div class="container">
        <div class="whitebox">
            <h2>{{ 'PAGE.PAGE_NOT_FOUND.TITLE' | translate }}</h2>
            <p>
                {{ 'PAGE.PAGE_NOT_FOUND.CANT_FIND' | translate }}
                <br /><br />
                {{ 'PAGE.PAGE_NOT_FOUND.MOVE_ON' | translate }}
                <br /><br />
                <a [routerLink]="navigationService.getUrl('frontpage')">{{ 'PAGE.PAGE_NOT_FOUND.TO_FRONTPAGE' | translate }}</a
                ><br />
                <a [routerLink]="navigationService.getUrl('newcustomer')">{{ 'PAGE.PAGE_NOT_FOUND.CALCULATE_PAGE' | translate }}</a>
            </p>
        </div>
    </div>
</div>
<next-footer></next-footer>
