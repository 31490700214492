import { TranslationService } from './../../../../utils/translation/translation.service';
import { Component, OnInit, Input, ChangeDetectorRef, ViewChild, TemplateRef, ElementRef, OnDestroy } from '@angular/core';
import { DocumentService } from 'app/selfcare/document.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'next-downloaddocument',
    templateUrl: './downloaddocument.component.html',
    styleUrls: ['./downloaddocument.component.less'],
})
export class DownloaddocumentComponent implements OnInit, OnDestroy {
    loading: boolean;
    documentUrl: string;
    @Input() product: number;
    @Input() common: boolean;
    @Input() termsName: string;

    @ViewChild('showbtn', { static: true }) showbtn: ElementRef;

    subscriptions: Subscription[] = [];
    currentLanguageCode: string;

    constructor(private documentService: DocumentService, private changeDetectorRef: ChangeDetectorRef, private translationService: TranslationService) {}

    ngOnInit() {
        this.currentLanguageCode = this.translationService.getSelectedLanguage();

        this.translationService.getLanguageChangeEmitter().subscribe((x) => {
            this.currentLanguageCode = x.lang;
            this.documentUrl = null;
        });
    }

    getDownloadUrl() {
        this.loading = true;
        if (this.common) {
            // Change 'v' when the file has been changed to break the caching
            this.documentUrl = 'https://static.next.dk/website/terms/AlmBetingelser_' + this.currentLanguageCode + '.pdf?v=261023';
            this.showDocument();
        } else {
            if (this.termsName) {
                this.documentService.getTermsDocumentUrlByTermsName(this.termsName, this.product).subscribe((resp) => {
                    this.documentUrl = resp.url;

                    this.showDocument();
                });
            } else {
                this.documentService.getTermsDocumentUrl(this.product).subscribe((resp) => {
                    this.documentUrl = resp.url;

                    this.showDocument();
                });
            }
        }
    }

    showDocument() {
        // window.open(resp.url, "_blank"); //Not working - pop up blocked
        this.changeDetectorRef.detectChanges();
        this.showbtn.nativeElement.click();

        this.loading = false;
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }
}
