import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { MitIdService } from '../../../../utils/mitId/mit-id.service';

@Component({
    selector: 'mit-id-login',
    templateUrl: './mit-id-login.component.html',
    styleUrls: ['./mit-id-login.component.less'],
})
export class MitIdLoginComponent implements OnInit {
    @Output() beforeRedirect: EventEmitter<void> = new EventEmitter();
    @Input() bigButton = false;
    @Input() redirectSuccessUrl: string;
    @Input() redirectFailUrl: string;
    @Input() requireSsn = false;

    unknownError: boolean;
    customerNotFoundError: boolean;
    showNormalLogin: boolean;
    error: string;
    isLoggingIn = false;

    constructor(private mitIdService: MitIdService, private route: ActivatedRoute) {}

    ngOnInit(): void {
        this.route.queryParams.subscribe((params: Params) => {
            this.onErrorChanged(params['mitIdError']);

            if (params['nu']) {
                this.login();
            }
        });
    }

    onErrorChanged(inputError: string = null) {
        this.requireSsn = false;
        this.customerNotFoundError = false;
        this.unknownError = false;
        this.showNormalLogin = false;

        const error = inputError ?? this.error;

        if (!error || error === 'mitid_user_aborted' || error === 'nemid_user_aborted') {
            this.showNormalLogin = true;
            return;
        }

        if (error === 'useridentity_not_found' || error === 'identity_not_found') {
            this.showNormalLogin = true;
            this.requireSsn = true;
            this.login();
        } else if (error === 'customer_not_found') {
            this.customerNotFoundError = true;
        } else {
            this.error = error;
            this.unknownError = true;
        }
    }

    login() {
        this.isLoggingIn = true;

        this.mitIdService.login(this.redirectSuccessUrl, this.redirectFailUrl, this.requireSsn).subscribe(
            (result) => {
                this.beforeRedirect.emit();
                window.open(result.uri, '_self');
            },
            (error) => {
                this.unknownError = true;
                this.isLoggingIn = false;
            }
        );
    }
}
