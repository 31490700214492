import { PriceSidebarComponent } from './components/price-sidebar/price-sidebar.component';
import { PhonecallboxComponent } from './../summary/components/phonecallbox/phonecallbox.component';
import { SummaryModule } from './../summary/summary.module';
import { InputModule } from './../../shared/input/input.module';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { CustomTooltipModule } from './../../shared/tooltip/customtooltip.module';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FilterModule } from 'app/utils/filter/filter.module';
import { CustomPipeModule } from 'app/class/pipe/custompipe.module';
import { CoversComponent } from './components/covers/covers.component';
import { DeductibleComponent } from './components/deductible/deductible.component';
import { EventDateComponent } from './components/event-date/event-date.component';
import { AddToCartComponent } from './components/addtocartbtn/addtocart-btn.component';
import { CampaignDiscountCircleComponent } from './components/campaign-discount-circle/campaign-discount-circle.component';
import { CampaignDiscountBannerComponent } from './components/campaign-discount-banner/campaign-discount-banner.component';
import { CampaignService } from 'app/utils/campaign/campaign.service';
import { PriceComponent } from './components/price/price.component';
import { PriceConditionsComponent } from './components/price-conditions/price-conditions.component';
import { TermsButtonsComponent } from './components/terms-buttons/terms-buttons.component';
import { ProductHeaderComponent } from './components/product-header/product-header.component';
import { CoverPackageButtonsComponent } from './components/cover-package-buttons/cover-package-buttons.component';
import { ProductService } from 'app/utils/product/product.service';
import { MatSelectModule } from '@angular/material/select';
import { YesNoSwitchComponent } from './components/yes-no-switch/yes-no-switch.component';
import { UpDownInputComponent } from './components/up-down-input/up-down-input.component';
import { MyDatePickerModule } from 'mydatepicker';

@NgModule({
    imports: [CommonModule, FormsModule, FilterModule, CustomPipeModule, CustomTooltipModule, RouterModule, TranslateModule, MatSelectModule, InputModule, MyDatePickerModule],
    declarations: [
        CoversComponent,
        DeductibleComponent,
        AddToCartComponent,
        CampaignDiscountCircleComponent,
        CampaignDiscountBannerComponent,
        PriceComponent,
        PriceSidebarComponent,
        PriceConditionsComponent,
        TermsButtonsComponent,
        ProductHeaderComponent,
        CoverPackageButtonsComponent,
        YesNoSwitchComponent,
        UpDownInputComponent,
        PhonecallboxComponent,
        EventDateComponent,
    ],
    exports: [
        CoversComponent,
        DeductibleComponent,
        AddToCartComponent,
        CampaignDiscountCircleComponent,
        CampaignDiscountBannerComponent,
        PriceComponent,
        PriceSidebarComponent,
        PriceConditionsComponent,
        TermsButtonsComponent,
        ProductHeaderComponent,
        YesNoSwitchComponent,
        UpDownInputComponent,
        PhonecallboxComponent,
        EventDateComponent,
    ],
    providers: [CampaignService, ProductService],
})
export class CalculateHelperModule {}
