<div class="full-width-image-section car-top">
    <div class="arrow-up">
        <next-topbar></next-topbar>
        <next-menu></next-menu>

        <div class="container white">
            <div class="row">
                <div class="col-sm-12 text-center">
                    <h1 id="header">{{ 'INSURANCE.CAR.COMPONENTS.INDEX.CALCULATE_AND_BUY' | translate }}</h1>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-5 col-sm-offset-2 col-md-4 col-md-offset-3">
                    <div class="input-group">
                        <span class="input-group-addon">DK</span>
                        <input
                            [(ngModel)]="regno"
                            type="text"
                            class="form-control search-input"
                            (keyup.enter)="startCarCalculation('car-btn')"
                            (ngModelChange)="onLicenseplateChange()"
                            [placeholder]="'CALCULATE.CAR.COMPONENTS.CAR_STEP_1.INPUT_LICENCE_PLATE' | translate | uppercase"
                            maxlength="7"
                            spellcheck="false"
                        />
                    </div>
                </div>
                <div class="col-sm-1">
                    <button type="button" id="btn-calculate" [class.loading]="loadingProduct === 'car-btn'" class="btn btn-success" (click)="startCarCalculation('car-btn')">
                        <span>{{ 'SHARED.CALCULATE' | translate | uppercase }}</span>
                    </button>
                </div>
            </div>

            <div class="row">
                <div id="calculate-manual" class="col-sm-12 text-center text-bold">
                    <a [routerLink]="calculateCarUrl + '/2/om-bilen'" (click)="startWithOutRegNo('car-link')">
                        <span *ngIf="loadingProduct !== 'car-link'">{{ 'CALCULATE.CAR.COMPONENTS.CAR_STEP_1.CALCULATE_WITHOUT_LICENCE_PLATE' | translate }}</span>
                        <div *ngIf="loadingProduct === 'car-link'" class="circle-loader thin inline"></div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
<br />
<br />

<car-cover></car-cover>

<div class="container" *ngIf="!useNewCarProduct">
    <div class="row">
        <div class="col-sm-12">
            <div class="points-text-container">
                <ng-template #popTemplate2>
                    <div [innerHtml]="'INSURANCE.CAR.COMPONENTS.INDEX.FREE_CLAIM_TEXT' | translate"></div>
                </ng-template>
                <div class="col-xs-12 col-md-3 col-md-offset-2 hidden-xs" [tooltip]="popTemplate2">
                    <i class="nf nf-checkmark-icon-v3"></i>
                    <h3>{{ 'INSURANCE.CAR.COMPONENTS.INDEX.FREE_CLAIM_TITLE' | translate | uppercase }}</h3>
                </div>
                <ng-template #pop2Modal>
                    <div class="tooltip-modal">
                        {{ 'INSURANCE.CAR.COMPONENTS.INDEX.FREE_CLAIM_TEXT' | translate }}
                        <button class="btn btn-success closeConfirmRemoveModal" (click)="closeModal()">{{ 'SHARED.OK' | translate }}</button>
                    </div>
                </ng-template>
                <div class="col-xs-12 col-md-3 col-md-offset-2 visible-xs" (click)="openModal(pop2Modal)">
                    <i class="nf nf-checkmark-icon-v3 hand"></i>
                    <h3>{{ 'INSURANCE.CAR.COMPONENTS.INDEX.FREE_CLAIM_TITLE' | translate | uppercase }}</h3>
                </div>

                <ng-template #popTemplate3>
                    <div [innerHtml]="'INSURANCE.CAR.COMPONENTS.INDEX.GLASS_TEXT' | translate"></div>
                </ng-template>
                <div class="col-xs-12 col-md-6 hidden-xs" [tooltip]="popTemplate3">
                    <i class="nf nf-checkmark-icon-v3"></i>
                    <h3>{{ 'INSURANCE.CAR.COMPONENTS.INDEX.GLASS_TITLE' | translate | uppercase }}</h3>
                </div>
                <ng-template #pop3Modal>
                    <div class="tooltip-modal">
                        {{ 'INSURANCE.CAR.COMPONENTS.INDEX.GLASS_TEXT' | translate }}
                        <button class="btn btn-success closeConfirmRemoveModal" (click)="closeModal()">{{ 'SHARED.OK' | translate }}</button>
                    </div>
                </ng-template>
                <div class="col-xs-12 col-md-3 col-md-offset-2 visible-xs" (click)="openModal(pop3Modal)">
                    <i class="nf nf-checkmark-icon-v3 hand"></i>
                    <h3>{{ 'INSURANCE.CAR.COMPONENTS.INDEX.GLASS_TITLE' | translate | uppercase }}</h3>
                </div>
            </div>
        </div>
    </div>
</div>
<trustpilot-carousel></trustpilot-carousel>
<div class="car-faq">
    <h2 class="text-center">{{ 'SHARED.FAQ.COMPONENTS.FAQ_ARTICLE.FAQ' | translate }}</h2>
    <next-faq [category]="'FAQ_CAR'"></next-faq>
</div>

<nicetoknow [pagecode]="'INSURANCE_CAR_NICETOKNOW'"></nicetoknow>

<next-footer></next-footer>
