import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService } from 'app/utils/navigation/navigation.service';

@Component({
    moduleId: module.id,
    selector: 'pay-with-mobilepay',
    templateUrl: './pay-with-mobilepay.component.html',
    styleUrls: ['./pay-with-mobilepay.component.less'],
})
export class PayWithMobilePayComponent implements OnInit {
    constructor(private router: Router, private navService: NavigationService) {}

    ngOnInit() {}

    goToSelfservice() {
        this.router.navigateByUrl(this.navService.getUrl('selfcare-login'));
    }

    goToCalculateInsurances() {
        this.router.navigateByUrl(this.navService.getUrl('newcustomer'));
    }
}
