import { Injectable, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';

// eslint-disable-next-line @typescript-eslint/naming-convention
declare let Cookiebot: any;

@Injectable({
    providedIn: 'root',
})
export class SeoService {
    constructor(@Inject(DOCUMENT) private doc: Document) {}

    setCanonicalLink() {
        let link: HTMLLinkElement = this.doc.querySelector('link[rel="canonical"]');

        if (!link) {
            link = this.doc.createElement('link');
            link.setAttribute('rel', 'canonical');
            this.doc.head.appendChild(link);
        }

        // strip url parameters
        const url = this.doc.URL.split('?')[0];
        link.setAttribute('href', url);
    }

    injectGoogleTagManager(gtmId: string) {
        const gtmScript = `
            (function (w, d, s, l, i) {
                w[l] = w[l] || [];
                w[l].push({
                    'gtm.start': new Date().getTime(),
                    event: 'gtm.js',
                });
                var f = d.getElementsByTagName(s)[0],
                    j = d.createElement(s),
                    dl = l != 'dataLayer' ? '&l=' + l : '';
                j.async = true;
                j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
                f.parentNode.insertBefore(j, f);
            })(window, document, 'script', 'dataLayer', '${gtmId}');
        `;

        this.injectScript(gtmScript);
    }

    private injectScript(script: string) {
        const scriptNode = document.createElement('script');
        scriptNode.type = 'text/javascript';
        scriptNode.innerHTML = script;
        scriptNode.async = true;

        document.getElementsByTagName('head')[0].appendChild(scriptNode);
    }
}
