<div class="menu-area hidden-xs">
    <nav id="primary_nav_wrap">
        <ul>
            <li>
                <a>{{ 'SHARED.MENU.INSURANCES' | translate }}</a>
                <ul>
                    <li>
                        <a [routerLink]="navigationService.getUrl('forsikringer')" routerLinkActive="active">{{ 'SHARED.MENU.INSURANCE_OVERVIEW' | translate }}</a>
                    </li>
                    <li>
                        <a [routerLink]="navigationService.getUrl('product-car')" routerLinkActive="active">{{ 'INSURANCE.CAR.PRODUCT' | translate }}</a>
                    </li>

                    <li>
                        <a [routerLink]="navigationService.getUrl('product-house')" routerLinkActive="active">{{ 'INSURANCE.HOUSE.PRODUCT' | translate }}</a>
                    </li>

                    <li>
                        <a [routerLink]="navigationService.getUrl('product-home')" routerLinkActive="active">{{ 'INSURANCE.HOME.PRODUCT' | translate }}</a>
                    </li>

                    <li>
                        <a [routerLink]="navigationService.getUrl('product-accident')" routerLinkActive="active">{{ 'INSURANCE.ACCIDENT.PRODUCT' | translate }}</a>
                    </li>

                    <li>
                        <a [routerLink]="navigationService.getUrl('product-dog')" routerLinkActive="active">{{ 'INSURANCE.DOG.PRODUCT' | translate }}</a>
                    </li>

                    <li>
                        <a [routerLink]="navigationService.getUrl('product-caravan')" routerLinkActive="active">{{ 'INSURANCE.CARAVAN.PRODUCT' | translate }}</a>
                    </li>

                    <li>
                        <a [routerLink]="navigationService.getUrl('product-secondhouse')" routerLinkActive="active">{{ 'INSURANCE.SECONDHOUSE.PRODUCT' | translate }}</a>
                    </li>

                    <li>
                        <a [routerLink]="navigationService.getUrl('product-moped')" routerLinkActive="active">{{ 'INSURANCE.MOPED.PRODUCT' | translate }}</a>
                    </li>

                    <li>
                        <a [routerLink]="navigationService.getUrl('terms')" routerLinkActive="active">{{ 'SHARED.MENU.TERMS' | translate }}</a>
                    </li>
                </ul>
            </li>
            <li>
                <a [routerLink]="navigationService.getUrl('answer-question')" routerLinkActive="active">{{ 'SHARED.MENU.FAQ' | translate }}</a>
            </li>
            <li>
                <a [routerLink]="navigationService.getUrl('contact')" routerLinkActive="active">{{ 'SHARED.MENU.CONTACT' | translate }}</a>
            </li>
            <li class="visible-xs break-line">
                <hr />
            </li>
        </ul>
    </nav>
</div>
<div class="menu-area visible-xs">
    <div class="left-menu-container" [class.show]="showLeftMenu">
        <div class="background" (click)="toggleLeftMenu()"></div>
        <div class="left-menu">
            <a [routerLink]="'/'" class="logo" style="left: 30px; top: 13px">
                <i class="nf nf-next-logo"></i>
            </a>
            <a class="back-btn" (click)="toggleLeftMenu()">
                <i class="nf nf-chevron-left-icon-v3"></i>
            </a>
            <nav id="primary_nav">
                <ul *ngIf="isAuthenticated">
                    <li>
                        <div class="header-item top-padding">
                            <a [routerLink]="navigationService.getUrl('selfcare-overview')">
                                <span>{{ userDisplayName }}</span>
                            </a>
                        </div>
                    </li>
                    <li>
                        <div class="header-item top-padding">
                            <a [routerLink]="navigationService.getUrl('selfcare-yourinfo')">
                                <span>{{ 'SHARED.MENU.YOUR_INFO' | translate }}</span>
                            </a>
                        </div>
                    </li>
                    <li class="break-line">
                        <hr />
                    </li>
                    <li class="basket-login-wrap">
                        <div *ngIf="selfcaremode === 'selfcare'">
                            <div class="header-item">
                                <div *ngIf="goToSummaryLoading" class="circle-loader small"></div>
                                <a *ngIf="!goToSummaryLoading" (click)="goToSummary()" id="cartlink" [title]="'SHARED.MENU.BASKET' | translate">
                                    <i class="nf nf-basket-icon"></i>
                                    <span class="badge" id="shoppingcart-status">{{ numInBasket }}</span>
                                </a>
                            </div>
                            <div class="break-line">
                                <hr />
                            </div>
                            <div *ngIf="!isAuthenticated" class="btn btn-success header-item login-button">
                                <a class="login" [routerLink]="navigationService.getUrl('selfcare-login')">
                                    <span
                                        >{{ 'SHARED.MENU.LOG_IN' | translate }}
                                        <i class="nf nf-lock-icon-v3"></i>
                                    </span>
                                </a>
                            </div>
                            <div *ngIf="isAuthenticated" class="btn btn-success header-item login-button">
                                <a class="login" [routerLink]="navigationService.getUrl('selfcare-signout')">
                                    <span
                                        >{{ 'SHARED.MENU.LOG_OUT' | translate }}
                                        <i class="nf nf-lock-icon-v3"></i>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </li>
                    <li>
                        <a [routerLink]="navigationService.getUrl('selfcare-tasks')" (click)="toggleLeftMenu()"
                            >{{ 'SHARED.MENU.TASKS' | translate }} <span *ngIf="tasksCount > 0">({{ tasksCount }})</span></a
                        >
                    </li>
                    <li>
                        <a [routerLink]="navigationService.getUrl('selfcare-offers')" (click)="toggleLeftMenu()">{{ 'SHARED.MENU.OFFERS' | translate }}</a>
                    </li>
                    <li>
                        <a [routerLink]="navigationService.getUrl('selfcare-overview')" (click)="toggleLeftMenu()">{{ 'SHARED.MENU.OVERVIEW' | translate }}</a>
                    </li>
                    <li>
                        <a [routerLink]="navigationService.getUrl('selfcare-newinsurance')" (click)="toggleLeftMenu()">{{ 'SHARED.MENU.NEW_INSURANCE' | translate }}</a>
                    </li>
                    <li>
                        <a [routerLink]="navigationService.getUrl('selfcare-claim')" (click)="toggleLeftMenu()">{{ 'SHARED.MENU.CLAIM' | translate }}</a>
                    </li>
                    <li>
                        <a [routerLink]="navigationService.getUrl('selfcare-payments')" (click)="toggleLeftMenu()">{{ 'SHARED.MENU.PAYMENTS' | translate }}</a>
                    </li>
                    <li class="break-line">
                        <hr />
                    </li>
                </ul>
                <ul>
                    <li>
                        <a (click)="toggleShowProducts()"
                            >{{ 'SHARED.MENU.INSURANCES' | translate }}
                            <i class="arrowicon" [ngClass]="{ 'nf-chevron-down-icon-v3': showProducts, 'nf-chevron-right-icon-v3': !showProducts }"></i
                        ></a>
                        <ul *ngIf="showProducts">
                            <li>
                                <a [routerLink]="navigationService.getUrl('forsikringer')" routerLinkActive="active">{{ 'SHARED.MENU.INSURANCE_OVERVIEW' | translate }}</a>
                            </li>
                            <li>
                                <a [routerLink]="navigationService.getUrl('product-car')" routerLinkActive="active">{{ 'INSURANCE.CAR.PRODUCT' | translate }}</a>
                            </li>
                            <li>
                                <a [routerLink]="navigationService.getUrl('product-house')" routerLinkActive="active">{{ 'INSURANCE.HOUSE.PRODUCT' | translate }}</a>
                            </li>
                            <li>
                                <a [routerLink]="navigationService.getUrl('product-home')" routerLinkActive="active">{{ 'INSURANCE.HOME.PRODUCT' | translate }}</a>
                            </li>
                            <li>
                                <a [routerLink]="navigationService.getUrl('product-accident')" routerLinkActive="active">{{ 'INSURANCE.ACCIDENT.PRODUCT' | translate }}</a>
                            </li>
                            <li>
                                <a [routerLink]="navigationService.getUrl('product-dog')" routerLinkActive="active">{{ 'INSURANCE.DOG.PRODUCT' | translate }}</a>
                            </li>
                            <li>
                                <a [routerLink]="navigationService.getUrl('product-caravan')" routerLinkActive="active">{{ 'INSURANCE.CARAVAN.PRODUCT' | translate }}</a>
                            </li>
                            <li>
                                <a [routerLink]="navigationService.getUrl('product-secondhouse')" routerLinkActive="active">{{ 'INSURANCE.SECONDHOUSE.PRODUCT' | translate }}</a>
                            </li>
                            <li>
                                <a [routerLink]="navigationService.getUrl('product-moped')" routerLinkActive="active">{{ 'INSURANCE.MOPED.PRODUCT' | translate }}</a>
                            </li>
                            <li>
                                <a [routerLink]="navigationService.getUrl('terms')" routerLinkActive="active">{{ 'SHARED.MENU.TERMS' | translate }}</a>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a [routerLink]="navigationService.getUrl('answer-question')" routerLinkActive="active">{{ 'SHARED.MENU.FAQ' | translate }}</a>
                    </li>
                    <li>
                        <a [routerLink]="navigationService.getUrl('contact')" routerLinkActive="active">{{ 'SHARED.MENU.CONTACT' | translate }}</a>
                    </li>
                    <li class="break-line">
                        <hr />
                    </li>
                </ul>
            </nav>
        </div>
    </div>

    <div class="container-fluid visible-xs" id="topbar">
        <div class="row">
            <div id="menu-topbar">
                <button type="button" class="burger-menu pull-left" (click)="toggleLeftMenu()">
                    <i class="nf nf-burger-icon-v3"></i>
                </button>
                <a [routerLink]="'/'" id="next-logo" *ngIf="!showLeftMenu">
                    <i class="nf nf-next-logo"></i>
                </a>
            </div>
        </div>
    </div>
</div>
