import { Component, OnInit } from '@angular/core';
import { ContexService } from 'app/utils/contex/contex.service';
import { Vacancy } from 'app/utils/contex/models/vacancy';
import { TranslationService } from 'app/utils/translation/translation.service';

@Component({
    selector: 'app-vacancy',
    templateUrl: './vacancy.component.html',
    styleUrls: ['./vacancy.component.less', '../../shared-styles.less'],
})
export class VacancyComponent implements OnInit {
    vacancy: Vacancy;

    constructor(private contexService: ContexService, private translationService: TranslationService) {}

    ngOnInit(): void {
        this.updateVacancy();

        this.translationService.getLanguageChangeEmitter().subscribe(() => {
            this.updateVacancy();
        });
    }

    updateVacancy() {
        const urlParam = location.href.substring(location.href.lastIndexOf('/') + 1);

        this.contexService.getVacancy(urlParam).subscribe((vacancy) => {
            this.vacancy = vacancy;
        });
    }
}
