import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ProductApiService } from 'app/utils/product/product-api.service';
import { CoverInfo, CoverPackageInfo } from 'next-typescript-api';

@Component({
    selector: 'cover-table',
    templateUrl: './cover-table.component.html',
    styleUrls: ['./cover-table.component.less'],
})
export class CoverTableComponent implements OnInit {
    @Input() productCode: string;
    coverPackageInfos: CoverPackageInfo[];
    covers: CoverInfo[];

    constructor(private productApiService: ProductApiService, private translateService: TranslateService) {}

    ngOnInit(): void {
        const coverPackagesPromise = this.productApiService.getCoverPackages(this.productCode).toPromise();
        const coversPromise = this.productApiService.getCovers(this.productCode).toPromise();

        Promise.all([coverPackagesPromise, coversPromise]).then((result) => {
            const nonApiCovers = this.getNonApiCovers(this.productCode);
            const covers = result[1].concat(nonApiCovers);

            if (result[0].length > 0) {
                const packages = result[0];
                // insert nonApiCovers in to packages if a matching required cover exists in the package.
                nonApiCovers
                    .filter((c) => c.requiredCoverCodes?.length > 0)
                    .forEach((c) => {
                        packages
                            .filter((p) => p.covers.some((pc) => c.requiredCoverCodes.some((rc) => rc === pc.coverCode)))
                            .forEach((p) => {
                                p.covers.push(c);
                            });
                    });

                this.initWithPackages(packages, covers);
            } else {
                this.initWithoutPackages(covers);
            }
        });
    }

    initWithoutPackages(covers: CoverInfo[]) {
        // we want to display the covers in mandatory and nonmandatory columns
        const packages: CoverPackageInfo[] = [];

        const mandatoryPackage: CoverPackageInfo = {
            covers: covers.filter((c) => c.mandatory),
            id: 'mandatory',
            name: this.translateService.instant('COMPONENTS.COVER_TABLE.MANDATORY'),
        };
        packages.push(mandatoryPackage);

        const extraCovers = covers.filter((c) => !c.mandatory);

        if (extraCovers.length > 0) {
            const extraPackage: CoverPackageInfo = {
                covers: extraCovers,
                id: 'extra',
                name: this.translateService.instant('COMPONENTS.COVER_TABLE.EXTRA_OPTIONS'),
            };

            packages.push(extraPackage);
        }

        this.coverPackageInfos = packages;
        this.covers = covers.sort((a, b) => (a.mandatory === b.mandatory ? 0 : b.mandatory > a.mandatory ? 1 : -1));
    }

    initWithPackages(packages: CoverPackageInfo[], covers: CoverInfo[]) {
        this.coverPackageInfos = packages;

        // mandatory covers are not in the packages - insert them
        const mandatoryCovers = covers.filter((c) => c.mandatory);
        this.coverPackageInfos.forEach((p) => p.covers.push(...mandatoryCovers));

        // sort covers by most occurances in packages
        this.covers = covers.sort((a, b) => {
            const aMatches = this.coverPackageInfos.filter((cp) => cp.covers.map((c) => c.coverCode).includes(a.coverCode)).length;
            const bMatches = this.coverPackageInfos.filter((cp) => cp.covers.map((c) => c.coverCode).includes(b.coverCode)).length;

            return bMatches > aMatches ? 1 : aMatches > bMatches ? -1 : 0;
        });

        const coversWithoutPackage = this.covers.filter((c) => this.coverPackageInfos.every((p) => !p.covers.map((pc) => pc.coverCode).includes(c.coverCode)));

        if (coversWithoutPackage.length > 0) {
            const extraPackage: CoverPackageInfo = {
                covers: coversWithoutPackage,
                id: 'extra',
                name: this.translateService.instant('COMPONENTS.COVER_TABLE.EXTRA_OPTIONS'),
            };
            this.coverPackageInfos.push(extraPackage);
        }
    }

    getNonApiCovers(product: string): CoverInfo[] {
        switch (product.toLowerCase()) {
            case 'car_simple':
            case 'car':
                const carlegal: CoverInfo = {
                    coverCode: 'Legal',
                    coverName: 'Retshjælp',
                    mandatory: false,
                    requiredCoverCodes: ['Hull'],
                };
                return [carlegal];

            case 'home':
                const homeLegal: CoverInfo = {
                    coverCode: 'Legal',
                    coverName: 'Retshjælp',
                    mandatory: true,
                };
                return [homeLegal];
            case 'accident':
                const disability: CoverInfo = {
                    coverCode: 'Disability',
                    coverName: 'Invaliditet',
                    mandatory: true,
                };
                const teeth: CoverInfo = {
                    coverCode: 'Teeth',
                    coverName: 'Tandskade',
                    mandatory: true,
                };
                const death: CoverInfo = {
                    coverCode: 'Death',
                    coverName: 'Dødsfald',
                    mandatory: false,
                };
                return [disability, teeth, death];
            case 'secondhouse':
                const secondHouseLegal: CoverInfo = {
                    coverCode: 'Legal',
                    coverName: 'Retshjælp',
                    mandatory: true,
                };

                return [secondHouseLegal];
            case 'moped':
            case 'caravan':
            case 'dog':
            case 'house':
            default:
                return [];
        }
    }
}
