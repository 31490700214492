import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CoverInfoSettings } from 'app/insurance/components/cover-info/CoverInfoSettings';
import { AppConfigService } from 'app/utils/appconfig/app-config.service';
import { NavigationService } from 'app/utils/navigation/navigation.service';

@Component({
    moduleId: module.id,
    selector: 'car-cover',
    templateUrl: './_cover.component.html',
    styleUrls: ['../../../../../styles/app/covers.less'],
})
export class CoverComponent implements OnInit {
    calculateCarUrl: string;
    covers: CoverInfoSettings[] = [];
    productCode: string;
    constructor(public navigationService: NavigationService, private router: Router, private config: AppConfigService) {}

    ngOnInit() {
        this.calculateCarUrl = this.navigationService.getCarCalculateUrl();
        const useNewCarProduct = this.config.appConfig.useNewCarProduct;
        this.productCode = useNewCarProduct ? 'car_simple' : 'car';
        this.covers = this.router.config.filter((x) => x.data?.product === this.productCode).map((x) => x.data as CoverInfoSettings);
    }
}
