import { Injectable, OnDestroy } from '@angular/core';
import { Subscription, BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from 'app/auth/auth.service';
import { CustomerService } from 'app/selfcare/personal/customer.service';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { BaxService } from 'app/utils/bax/bax.service';
import { TranslateService } from '@ngx-translate/core';
import { ChatService } from 'app/utils/chat/chat.service';

@Injectable({
    providedIn: 'root',
})
export class TopbarService implements OnDestroy {
    subscriptions: Subscription[] = [];
    public currentChatOpenHours: Observable<string>;
    public currentUserDisplayName: Observable<string>;
    public currentIsLoggedIn: Observable<boolean>;
    public currentNumInBasket: Observable<number>;
    public currentShowCallmeModal: Observable<boolean>;

    private chatOpenHoursSource = new BehaviorSubject(this.translateService.instant('SHARED.LOADING'));

    private userDisplayNameSource = new BehaviorSubject(this.translateService.instant('SHARED.LOADING'));

    private isLoggedInSource = new BehaviorSubject<boolean>(false);

    private numInBasketSource = new BehaviorSubject<number>(0);

    private showCallmeModal = new BehaviorSubject<boolean>(false);

    constructor(
        private chatService: ChatService,
        private auth: AuthService,
        private customerService: CustomerService,
        private baxService: BaxService,
        private translateService: TranslateService
    ) {
        this.currentChatOpenHours = this.chatOpenHoursSource.asObservable();
        this.currentUserDisplayName = this.userDisplayNameSource.asObservable();
        this.currentIsLoggedIn = this.isLoggedInSource.asObservable();
        this.currentNumInBasket = this.numInBasketSource.asObservable();
        this.currentShowCallmeModal = this.showCallmeModal.asObservable();

        this.changeIsLoggedIn(this.auth.isAuthenticated()); // set if page is reloaded while logged in

        this.subscriptions.push(
            this.auth.clearTokenObserveable.subscribe(() => {
                this.changeIsLoggedIn(false);
            })
        );

        this.subscriptions.push(
            this.chatService.getChatOpenHours().subscribe(
                (data) => {
                    this.changeChatOpenHours(data);
                },
                (err) => {
                    console.log('getChatOpenHours failed in topbar.service!');
                }
            )
        );

        this.subscriptions.push(this.auth.loginStatusChanged.subscribe((data) => this.changeIsLoggedIn(data)));

        this.subscriptions.push(this.auth.loginStatusChanged.subscribe((data) => this.changeIsLoggedIn(data)));

        this.subscriptions.push(
            this.baxService.basketInsuranceCountSubject.subscribe((basketInsuranceCount) => {
                this.numInBasketSource.next(basketInsuranceCount);
            })
        );

        // Only call BaxService if we do not already have a basket insurance count
        if (!this.numInBasketSource.value && this.numInBasketSource.value !== 0) {
            const basketid = Cookie.get('basketid');
            if (basketid) {
                this.baxService.insurancesGet(basketid, 'topbar').toPromise();
            } else {
                this.numInBasketSource.next(0);
            }
        }
    }

    setCallmeModal(show: boolean) {
        this.showCallmeModal.next(show);
    }

    changeChatOpenHours(text: string) {
        this.chatOpenHoursSource.next(text);
    }

    changeUserDisplayName(text: string) {
        this.userDisplayNameSource.next(text);
    }

    changeIsLoggedIn(loggedIn: boolean) {
        this.isLoggedInSource.next(loggedIn);
        if (loggedIn) {
            this.subscriptions.push(
                this.customerService.getCustomerDetailsSimple().subscribe(
                    (resp) => {
                        this.changeUserDisplayName(resp.nameFirst + ' ' + resp.nameLast);
                    },
                    (err) => {
                        console.log('getCustomerDetailsSimple failed in topbar.service!');
                    }
                )
            );
        } else {
            this.changeUserDisplayName(this.translateService.instant('SHARED.LOADING'));
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }
}
