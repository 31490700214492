<div id="jobinnext">
    <div class="full-width-image-section jobinnext-top" [ngStyle]="vacancy?.imageUrl && { 'background-image': 'url(' + vacancy.imageUrl + ')' }">
        <div class="arrow-up">
            <next-topbar></next-topbar>
            <next-menu></next-menu>

            <div class="container white">
                <div class="row">
                    <div class="col-sm-12 text-center">
                        <h1 class="jobinnext-h1">{{ vacancy?.headline }}</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="vacancy?.content" class="container padding-40-20 page-content" [innerHTML]="vacancy?.content | safehtml"></div>

    <next-footer></next-footer>
</div>
