<div *ngFor="let category of contex">
    <div class="container">
        <div class="row">
            <div class="col-sm-12 faq-subpage">
                <ul class="faq-category">
                    <li>
                        <div>
                            <div>
                                <div class="faq-header row" (click)="onHeaderClick(category.name)">
                                    <div class="col-xs-10 col-sm-11">
                                        <h3 [innerHTML]="category.name"></h3>
                                    </div>
                                    <div class="col-xs-2 col-sm-1">
                                        <i class="pull-right" [ngClass]="{ 'nf-faq-down': opencategory === category.name, 'nf-faq-right': opencategory !== category.name }"></i>
                                    </div>
                                </div>
                                <div class="question-container" [class.hidden]="opencategory?.toLowerCase() !== category.name?.toLowerCase()">
                                    <div *ngFor="let panel of category.panels">
                                        <ul class="faq-question">
                                            <li>
                                                <div>
                                                    <div>
                                                        <div class="faq-subject row" (click)="onQuestionClick(category.name, panel.subject)">
                                                            <div class="question-text col-xs-10 col-sm-11">
                                                                <span [innerHTML]="panel.subject"></span>
                                                            </div>
                                                            <div class="col-xs-2 col-sm-1">
                                                                <i
                                                                    class="pull-right"
                                                                    [ngClass]="{ 'nf-faq-down': openquestion === panel.subject, 'nf-faq-right': openquestion !== panel.subject }"
                                                                ></i>
                                                            </div>
                                                        </div>
                                                        <div class="answer-text" [class.hidden]="openquestion?.toLowerCase() !== panel.subject?.toLowerCase()">
                                                            <div [innerHTML]="panel.content | safehtml"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
