import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NicetoknowComponent } from './components/nicetoknow/nicetoknow.component';
import { CustomPipeModule } from 'app/class/pipe/custompipe.module';

@NgModule({
    imports: [CommonModule, CustomPipeModule],
    declarations: [NicetoknowComponent],
    exports: [NicetoknowComponent],
})
export class NicetoknowModule {}
