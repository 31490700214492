import { SqueezepageComponent } from './page/landing/squeezepage/squeezepage.component';
import { EmployeesComponent } from './page/employees/components/employees/employees.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutNextComponent } from './page/aboutnext/components/aboutnext/aboutnext.component';
import { OnlineInsuranceComponent } from './page/onlineinsurance/components/onlineinsurance/onlineinsurance.component';
import { PressComponent } from './page/press/components/press/press.component';
import { WhyPeopleChangeComponent } from './page/whypeoplechange/components/whypeoplechange/whypeoplechange.component';
import { TermsComponent } from './page/terms/components/terms/terms.component';
import { JobInNextComponent } from './page/jobinnext/components/jobinnext/jobinnext.component';
import { AgencyComponent } from './page/agency/components/agency/agency.component';
import { ContactComponent } from './page/contact/components/contact/contact.component';
import { ComplaintsManagerComponent } from './page/complaintsmanager/components/complaintsmanager/complaintsmanager.component';
import { DisclaimerComponent } from './page/disclaimer/components/disclaimer/disclaimer.component';
import { PrivacyCookieComponent } from './page/privacy/components/privacycookie/privacycookie.component';
import { ReportDamageComponent } from './page/reportdamage/components/reportdamage/reportdamage.component';
import { NewCustomerComponent } from './page/newcustomer/components/newcustomer/newcustomer.component';
import { VerifyemailComponent } from './page/verifyemail/components/verifyemail/verifyemail.component';
import { FrontpageComponent } from './frontpage/components/frontpage/frontpage.component';
import { InsuranceComponent } from './insurance/components/insurance/insurance.component';
import { SelfcareLoginComponent } from './page/selfcarelogin/components/selfcare-login.component';
import { TrustpilotTermsComponent } from './page/trustpilotterms/components/trustpilot-terms/trustpilot-terms.component';
import { AnswerQuestionComponent } from './page/answerquestion/components/answerquestion/answer-question.component';
import { ManualpaymentComponent } from './page/manualpayment/components/manualpayment/manualpayment.component';
import { LandingComponent } from 'app/page/landing/landing/landing.component';
import { EmaillandingComponent } from './page/emaillanding/emaillanding/emaillanding.component';
import { ProductInformationComponent } from './page/productinformation/components/productinformation/productinformation.component';
import { ProductResolver } from './calculate/productresolver';
import { PagenotfoundComponent } from './page/pagenotfound/components/pagenotfound/pagenotfound.component';
import { insuranceRoutes } from './insurance/insurance-routes';
import { PayWithMobilePayComponent } from './page/paywithmobilepay/pay-with-mobilepay.component';
import { BlogPreviewsPageComponent } from './page/blog/components/blogPreviewsPage/blog-previews-page.component';
import { BlogPostPageComponent } from './page/blog/components/blogPostPage/blog-post-page.component';
import { KoerselvferieComponent } from './page/koerselvferie/koerselvferie/koerselvferie.component';
import { ContexpageComponent } from './insurance/contexpage/components/contexpage/contexpage.component';
import { VacancyComponent } from './page/jobinnext/components/vacancy/vacancy.component';
import { MitIdLandingComponent } from './shared/login/components/mit-id-landing/mit-id-landing.component';
import { MitIdSigningLandingComponent } from './shared/signing/mit-id-signing-landing/mit-id-signing-landing.component';
import { MitIdPolicySigningLandingComponent } from './shared/signing/mit-id-policy-signing-landing/mit-id-policy-signing-landing.component';
import { ConsentComponent } from './page/consent/components/consent/consent.component';

const routes: Routes = [
    {
        path: '',
        component: FrontpageComponent,
    },
    {
        path: 'mitid-landing',
        component: MitIdLandingComponent,
    },
    {
        path: 'mitid-signering',
        component: MitIdSigningLandingComponent,
    },
    {
        path: 'mitid-aendring-signering',
        component: MitIdPolicySigningLandingComponent,
    },
    {
        path: 'betaling',
        component: ManualpaymentComponent,
    },
    {
        path: 'om-next',
        component: AboutNextComponent,
    },
    {
        path: 'online-forsikring',
        component: OnlineInsuranceComponent,
    },
    {
        path: 'presse-og-grafik',
        component: PressComponent,
    },
    {
        path: 'forsikringer/hvorfor-skifter-folk-til-os',
        component: WhyPeopleChangeComponent,
    },
    {
        path: 'betingelser',
        component: TermsComponent,
    },
    {
        path: 'produktinformation',
        component: ProductInformationComponent,
    },
    {
        path: 'job-i-next',
        component: JobInNextComponent,
    },
    {
        path: 'job-i-next/:url',
        component: VacancyComponent,
    },
    {
        path: 'forsikringsformidler',
        component: AgencyComponent,
    },
    {
        path: 'spoergsmaal-og-svar',
        component: AnswerQuestionComponent,
    },
    {
        path: 'kontakt',
        component: ContactComponent,
    },
    {
        path: 'klageansvarlig',
        component: ComplaintsManagerComponent,
    },
    {
        path: 'ansvarsfraskrivelse',
        component: DisclaimerComponent,
    },
    {
        path: 'persondata-og-cookiepolitik',
        component: PrivacyCookieComponent,
    },
    {
        path: 'anmeld-skade',
        component: ReportDamageComponent,
    },
    {
        path: 'bliv-kunde',
        component: NewCustomerComponent,
        resolve: { product: ProductResolver },
    },
    {
        path: 'v/:customerno/:token',
        component: VerifyemailComponent,
    },
    {
        path: 'b/:basketid',
        component: EmaillandingComponent,
    },
    {
        path: 'beregn/landing/:basketid',
        component: LandingComponent,
    },
    {
        path: 'velkommen',
        component: SqueezepageComponent,
    },
    {
        path: 'koer-selv-ferie',
        component: KoerselvferieComponent,
    },
    {
        path: 'trustpilot-konkurrence',
        component: TrustpilotTermsComponent,
    },
    {
        path: 'forsikringer',
        component: ContexpageComponent,
    },
    {
        path: 'oprettelse/:basketid',
        loadChildren: () => import('./signoff/signoff-complete.module').then((m) => m.SignoffCompleteModule),
    },
    {
        path: 'selvbetjening/log-paa',
        component: SelfcareLoginComponent,
    },
    {
        path: 'samtykke/:customerNo/:consentType/:consentId',
        component: ConsentComponent,
    },

    // Routes with children - "outer"-components html must have a <router-outlet></router-outlet>
    {
        path: 'forsikringer',
        component: InsuranceComponent,
        children: insuranceRoutes,
    },
    {
        path: 'beregn',
        loadChildren: () => import('./calculate/calculate.module').then((m) => m.CalculateModule),
    },
    {
        path: 'bekraeft',
        loadChildren: () => import('./signoff/signoff.module').then((m) => m.SignoffModule),
    },
    {
        path: 'selvbetjening',
        loadChildren: () => import('./selfcare/selfcare.module').then((m) => m.SelfcareModule),
    },

    {
        path: 'betal-forsikring-med-mobilepay',
        component: PayWithMobilePayComponent,
    },

    {
        path: 'blog/:url-parameter',
        component: BlogPostPageComponent,
    },
    {
        path: 'blog',
        component: BlogPreviewsPageComponent,
    },

    { path: '**', component: PagenotfoundComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
