import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { DogRoutingModule } from './dog-routing.module';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FooterModule } from 'app/shared/footer/footer.module';
import { MenuModule } from 'app/shared/menu/menu.module';
import { TrustpilotModule } from 'app/shared/trustpilot/trustpilot.module';
import { TopbarModule } from 'app/shared/topbar/topbar.module';

import { CoverComponent } from './components/_cover/_cover.component';
import { IndexComponent } from './components/index/index.component';
import { FaqModule } from 'app/shared/faq/faq.module';
import { CalculateButtonModule } from 'app/shared/calculate-button/calculate-button.module';
import { NicetoknowModule } from '../nicetoknow/nicetoknow.module';
import { PageLoaderModule } from 'app/shared/pageloader/pageloader.module';
import { RibbonModule } from 'app/shared/ribbon/ribbon.module';
import { CustomPipeModule } from 'app/class/pipe/custompipe.module';
import { CoverTableModule } from '../cover-table/cover-table.module';

@NgModule({
    imports: [
        DogRoutingModule,
        CommonModule,
        FormsModule,
        FooterModule,
        MenuModule,
        TrustpilotModule,
        TopbarModule,
        FaqModule,
        CalculateButtonModule,
        NicetoknowModule,
        PageLoaderModule,
        TranslateModule,
        RibbonModule,
        CustomPipeModule,
        CoverTableModule,
    ],
    declarations: [CoverComponent, IndexComponent],
    exports: [CoverComponent],
})
export class DogModule {}
