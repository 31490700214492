<div>
    <div class="full-width-image-section jobinnext-top">
        <div>
            <next-topbar></next-topbar>
            <next-menu></next-menu>
            <div class="container white">
                <div class="row">
                    <div class="col-sm-12 text-center">
                        <h1 class="headline" [routerLink]="navigationService.getUrl('blog')">{{ 'PAGE.BLOG.TITLE' | translate }}</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container container-padding-40-40">
        <div class="column-left col-xs-12 col-md-9">
            <div class="row">
                <blog-content *ngIf="blogPost" [html]="blogPost.content" [created]="blogPost.created" [online]="blogPost.online" [url]="blogPost.url"></blog-content>
            </div>
        </div>
        <div class="column-right hidden-xs col-xs-12 col-md-3 pull-right padding-right-0 padding-left-0">
            <blogs-overview></blogs-overview>
        </div>
    </div>

    <next-footer></next-footer>
</div>
