import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContexService } from 'app/utils/contex/contex.service';
import { MetaTagsHelper } from 'app/utils/metaTags/metaTagsHelper';
import { NavigationService } from 'app/utils/navigation/navigation.service';

@Component({
    selector: 'contexpage',
    templateUrl: './contexpage.component.html',
    styleUrls: ['./contexpage.component.less'],
    encapsulation: ViewEncapsulation.None, // allows us to style contex content
})
export class ContexpageComponent implements OnInit {
    content: string;
    header: string;
    loading = true;
    pageCode: string;

    constructor(public navigationService: NavigationService, private route: ActivatedRoute, private contexService: ContexService, private metaTagsHelper: MetaTagsHelper) {}

    ngOnInit(): void {
        this.route.url.subscribe((url) => {
            this.pageCode = url[0].path;

            const pathObject = this.navigationService.getPathObject(this.pageCode);
            if (!pathObject) {
                console.error('No pathObject found for pagecode ' + this.pageCode);
                return;
            }
            const contexPageCode = pathObject.contexPageCode;
            this.contexService.getContent(contexPageCode).subscribe(
                (x) => {
                    this.header = x.allPageElements.find((ape) => ape.key === 'HEADER')?.value;
                    this.content = x.content;
                    this.metaTagsHelper.setTitle(x.title);
                    this.metaTagsHelper.setDescription(x.metaDescription);
                    this.loading = false;
                },
                (err) => {
                    console.error('error in getContent for pagecode ' + contexPageCode, err);
                }
            );
        });
    }
}
