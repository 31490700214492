import { Observable } from 'rxjs';
import { AppConfigService } from 'app/utils/appconfig/app-config.service';
import { Injectable } from '@angular/core';
import { AuthService } from 'app/auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import {
    CustomerDetailsSimple,
    CustomerDetails,
    CustomerRelationDetails,
    AddCustomerRelationInput,
    UpdateCustomerInput,
    CustomerEmailVerificationItemDetails,
    VerifyEmailInput,
} from 'next-typescript-api';
import { ObservableHelper } from 'app/utils/observableHelper/observableHelper';

@Injectable()
export class CustomerService {
    private apiUrl: string;
    private missingCustomerIdMessage = 'Missing customer ID';

    constructor(private http: HttpClient, private config: AppConfigService, private auth: AuthService, private observableHelper: ObservableHelper) {
        this.apiUrl = this.config.appConfig.apiHost;
    }

    // Faster endpoint without info about newsletterSignup, consentDocumentAvailable paymentMethod and emailVerified
    getCustomerDetailsSimple(): Observable<CustomerDetailsSimple> {
        const customerId = this.auth.getCustomerId();

        if (!customerId) {
            return this.observableHelper.CreateFailedObservableAndLogStackTrace(this.missingCustomerIdMessage);
        }

        const url = `${this.apiUrl}/customers/${customerId}/simple?api-version=2.0`;
        return this.http.get<CustomerDetailsSimple>(url);
    }

    getCustomerDetailsFull(): Observable<CustomerDetails> {
        const customerId = this.auth.getCustomerId();

        if (!customerId) {
            return this.observableHelper.CreateFailedObservableAndLogStackTrace(this.missingCustomerIdMessage);
        }

        const url = `${this.apiUrl}/customers/${customerId}?api-version=2.0`;
        return this.http.get<CustomerDetails>(url);
    }

    getRelations(): Observable<CustomerRelationDetails> {
        const customerId = this.auth.getCustomerId();

        if (!customerId) {
            return this.observableHelper.CreateFailedObservableAndLogStackTrace(this.missingCustomerIdMessage);
        }

        const url = `${this.apiUrl}/customers/${customerId}/relations?api-version=2.0`;
        return this.http.get<CustomerRelationDetails>(url);
    }

    deleteRelation(): Observable<boolean> {
        const customerId = this.auth.getCustomerId();

        if (!customerId) {
            return this.observableHelper.CreateFailedObservableAndLogStackTrace(this.missingCustomerIdMessage);
        }

        const url = `${this.apiUrl}/customers/${customerId}/relations?api-version=2.0`;
        return this.http.delete(url, { observe: 'response' }).pipe(
            map((resp) => {
                const statusCode = resp.status;

                if (statusCode === 200 || statusCode === 204) {
                    return true;
                }

                return false;
            })
        );
    }

    addRelation(input: AddCustomerRelationInput): Observable<boolean> {
        const customerId = this.auth.getCustomerId();

        if (!customerId) {
            return this.observableHelper.CreateFailedObservableAndLogStackTrace(this.missingCustomerIdMessage);
        }

        const url = `${this.apiUrl}/customers/${customerId}/relations?api-version=2.0`;
        return this.http.post(url, input, { observe: 'response' }).pipe(
            map((resp) => {
                const statusCode = resp.status;

                if (statusCode === 200 || statusCode === 204) {
                    return true;
                }

                return false;
            })
        );
    }

    updateCustomer(input: UpdateCustomerInput): Observable<boolean> {
        const customerId = this.auth.getCustomerId();

        if (!customerId) {
            return this.observableHelper.CreateFailedObservableAndLogStackTrace(this.missingCustomerIdMessage);
        }

        const url = `${this.apiUrl}/customers/${customerId}/update?api-version=2.0`;
        return this.http.post(url, input, { observe: 'response' }).pipe(
            map((resp) => {
                const statusCode = resp.status;

                if (statusCode === 200 || statusCode === 204) {
                    return true;
                }

                return false;
            })
        );
    }

    sendEmailVerification(): Observable<boolean> {
        const customerId = this.auth.getCustomerId();

        if (!customerId) {
            return this.observableHelper.CreateFailedObservableAndLogStackTrace(this.missingCustomerIdMessage);
        }

        const url = `${this.apiUrl}/customers/${customerId}/email-verifications?api-version=2.0`;
        return this.http.post(url, null, { observe: 'response' }).pipe(
            map((resp) => {
                const statusCode = resp.status;

                if (statusCode === 200 || statusCode === 204) {
                    return true;
                }

                return false;
            })
        );
    }

    resendEmailVerification(verificationId: number): Observable<boolean> {
        const customerId = this.auth.getCustomerId();

        if (!customerId) {
            return this.observableHelper.CreateFailedObservableAndLogStackTrace(this.missingCustomerIdMessage);
        }

        const url = `${this.apiUrl}/customers/${customerId}/email-verifications/${verificationId}/resend?api-version=2.0`;
        return this.http.post(url, null, { observe: 'response' }).pipe(
            map((resp) => {
                const statusCode = resp.status;

                if (statusCode === 200 || statusCode === 204) {
                    return true;
                }

                return false;
            })
        );
    }

    getEmailChangeVerifications(): Observable<CustomerEmailVerificationItemDetails[]> {
        const customerId = this.auth.getCustomerId();

        if (!customerId) {
            return this.observableHelper.CreateFailedObservableAndLogStackTrace(this.missingCustomerIdMessage);
        }

        const url = `${this.apiUrl}/customers/${customerId}/email-verifications?api-version=2.0`;
        return this.http.get<CustomerEmailVerificationItemDetails[]>(url);
    }

    verifyEmail(customerId: string, input: VerifyEmailInput): Observable<boolean> {
        if (!customerId) {
            return this.observableHelper.CreateFailedObservableAndLogStackTrace(this.missingCustomerIdMessage);
        }

        const url = `${this.apiUrl}/customers/${customerId}/email-verify?api-version=2.0`;
        return this.http.post(url, input, { observe: 'response' }).pipe(
            map((resp) => {
                const statusCode = resp.status;

                if (statusCode === 200 || statusCode === 204) {
                    return true;
                }

                return false;
            })
        );
    }
}
