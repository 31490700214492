<div class="full-width-image-section whypeoplechange-top">
    <div class="arrow-up">
        <next-topbar></next-topbar>
        <next-menu></next-menu>

        <div class="video-container">
            <ng-template #whypeoplechangeModal>
                <next-videomodal [videoid]="'nnaR15pTzmI'" [bsModalRef]="bsModalRef"></next-videomodal>
            </ng-template>
        </div>

        <div class="container white" (click)="openModal(whypeoplechangeModal)">
            <div class="row">
                <div class="col-sm-12 text-center">
                    <h1>{{ 'PAGE.WHY_PEOPLE_CHANGE.TITLE' | translate }}</h1>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12 text-center play-video-icon">
                    <i class="nf nf-play-video-icon-v3"></i>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12 text-center play-btn-text">
                    {{ 'PAGE.WHY_PEOPLE_CHANGE.SEE_WHY' | translate }}
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container about-line">
    <div class="row">
        <div class="col-sm-12 text-center">
            <h2>{{ 'PAGE.WHY_PEOPLE_CHANGE.WHY_CHOOSE_NEXT' | translate }}</h2>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4 description smaller-top">
            <h3>{{ 'PAGE.WHY_PEOPLE_CHANGE.NO_FEES_TITLE' | translate }}</h3>
            <p>{{ 'PAGE.WHY_PEOPLE_CHANGE.NO_FEES_1' | translate }}</p>
            <p>
                {{ 'PAGE.WHY_PEOPLE_CHANGE.NO_FEES_2' | translate }}
            </p>
            <p>
                {{ 'PAGE.WHY_PEOPLE_CHANGE.NO_FEES_3' | translate }}
            </p>
            <p class="why-video">
                {{ 'PAGE.WHY_PEOPLE_CHANGE.NO_FEES_4' | translate }}
                <a class="text-green" (click)="openModal(whypeoplechangeModal)">{{ 'PAGE.WHY_PEOPLE_CHANGE.HERE' | translate }}</a>
            </p>
        </div>
        <div class="col-md-8 image">
            <img
                src="https://static.next.dk/website/images/pages/whypeoplechange/ingengebyrer.png"
                srcset="https://static.next.dk/website/images/pages/whypeoplechange/ingengebyrer@2x.png 2x"
                class="img-responsive"
            />
        </div>
    </div>
</div>

<div class="gray-bg">
    <div class="container about-line">
        <div class="row">
            <div class="col-md-4 description">
                <h3>{{ 'PAGE.WHY_PEOPLE_CHANGE.EASY_CLAIMS_TITLE' | translate }}</h3>
                <p>
                    {{ 'PAGE.WHY_PEOPLE_CHANGE.EASY_CLAIMS_1' | translate }}
                </p>
                <p>
                    {{ 'PAGE.WHY_PEOPLE_CHANGE.EASY_CLAIMS_2' | translate }}
                </p>
                <p class="why-video">
                    {{ 'PAGE.WHY_PEOPLE_CHANGE.EASY_CLAIMS_3' | translate }}
                    <a class="text-green" (click)="openModal(whypeoplechangeModal)">{{ 'PAGE.WHY_PEOPLE_CHANGE.HERE' | translate }}</a>
                </p>
            </div>
            <div class="col-md-8 image">
                <img
                    src="https://static.next.dk/website/images/pages/whypeoplechange/nemskadesanmeldelse.png"
                    srcset="https://static.next.dk/website/images/pages/whypeoplechange/nemskadesanmeldelse@2x.png 2x"
                    class="img-responsive"
                />
            </div>
        </div>
    </div>
</div>
<div class="container about-line">
    <div class="row">
        <div class="col-md-4 description">
            <h3>{{ 'PAGE.WHY_PEOPLE_CHANGE.EDIT_TITLE' | translate }}</h3>
            <p>
                {{ 'PAGE.WHY_PEOPLE_CHANGE.EDIT_1' | translate }}
            </p>
            <p>
                {{ 'PAGE.WHY_PEOPLE_CHANGE.EDIT_2' | translate }}
            </p>
            <p class="why-video">
                {{ 'PAGE.WHY_PEOPLE_CHANGE.EDIT_3' | translate }}
                <a class="text-green" (click)="openModal(whypeoplechangeModal)">{{ 'PAGE.WHY_PEOPLE_CHANGE.HERE' | translate }}</a>
            </p>
        </div>
        <div class="col-md-8 image">
            <img
                src="https://static.next.dk/website/images/pages/whypeoplechange/redigerforsikring.png"
                srcset="https://static.next.dk/website/images/pages/whypeoplechange/redigerforsikring@2x.png 2x"
                class="img-responsive"
            />
        </div>
    </div>
</div>

<trustpilot-carousel></trustpilot-carousel>

<next-footer></next-footer>
