import { Component, OnInit, OnDestroy } from '@angular/core';
import { PromiseHelper, CancelablePromise } from 'app/utils/promiseHelper/promiseHelper';
import { ContexService } from 'app/utils/contex/contex.service';
import { BlogPost } from 'app/utils/contex/models/blogPost';
import { MetaTagsHelper } from 'app/utils/metaTags/metaTagsHelper';
import { NavigationService } from 'app/utils/navigation/navigation.service';
import { Title } from '@angular/platform-browser';
import { TranslationService } from 'app/utils/translation/translation.service';

@Component({
    moduleId: module.id,
    selector: 'blog-post',
    templateUrl: './blog-post-page.component.html',
    styleUrls: ['./blog-post-page.component.less'],
})
export class BlogPostPageComponent implements OnInit, OnDestroy {
    getBlogPostPromise: CancelablePromise<BlogPost>;
    blogPost: BlogPost;

    constructor(
        private contexService: ContexService,
        private metaTagsHelper: MetaTagsHelper,
        private title: Title,
        public navigationService: NavigationService,
        private translationService: TranslationService
    ) {}

    ngOnInit(): void {
        this.updateBlogPost();

        this.translationService.getLanguageChangeEmitter().subscribe(() => {
            this.updateBlogPost();
        });
    }

    updateBlogPost() {
        const urlParam = location.href.substring(location.href.lastIndexOf('/') + 1);

        this.getBlogPostPromise = PromiseHelper.makeCancelable(this.contexService.getBlogPost(urlParam).toPromise());

        this.getBlogPostPromise.promise.then(blogPost => {
            this.blogPost = blogPost;

            const tags = [
                {
                    name: 'description',
                    content: blogPost.meta,
                },
            ];

            this.metaTagsHelper.addTags(tags);
            let title = blogPost.title;
            if (!title || title.length === 0) {
                title = blogPost.name;
            }
            this.title.setTitle(title);
        });
    }

    ngOnDestroy(): void {
        if (this.getBlogPostPromise) {
            this.getBlogPostPromise.cancel();
        }
    }
}
