import { Component, OnInit, OnDestroy } from '@angular/core';
import { ContexService } from 'app/utils/contex/contex.service';
import { Vacancy } from 'app/utils/contex/models/vacancy';
import { MetaTagsHelper } from 'app/utils/metaTags/metaTagsHelper';
import { TranslationService } from 'app/utils/translation/translation.service';
import { Subscription } from 'rxjs';

@Component({
    moduleId: module.id,
    selector: 'jobinnext',
    templateUrl: './jobinnext.component.html',
    styleUrls: ['./jobinnext.component.less', '../../shared-styles.less'],
})
export class JobInNextComponent implements OnInit, OnDestroy {
    vacancies: Vacancy[] = [];
    subscriptions: Subscription[] = [];

    constructor(private metaTagsHelper: MetaTagsHelper, private contexService: ContexService, private translationService: TranslationService) {
        this.metaTagsHelper.setTitleAndMetaTags('META.JOB_IN_NEXT');
        this.updateVacancies();

        this.translationService.getLanguageChangeEmitter().subscribe(() => {
            this.updateVacancies();
        });
    }

    updateVacancies() {
        this.contexService.getVacancies(0, 100).subscribe((vacancies) => {
            this.vacancies = vacancies.items;
        });
    }

    ngOnInit(): void {}

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }
}
