import { Component, Input, OnInit } from '@angular/core';
import { NavigationService } from 'app/utils/navigation/navigation.service';

@Component({
    selector: 'product-menu',
    templateUrl: './product-menu.component.html',
    styleUrls: ['./product-menu.component.less'],
})
export class ProductMenuComponent implements OnInit {
    loadingProduct = '';
    @Input() iconTheme: 'white' | 'blue' = 'white';
    @Input() scroll = true;
    constructor(public navigationService: NavigationService) {}

    ngOnInit(): void {}

    setLoadingProduct(product: string) {
        this.loadingProduct = product;
    }
}
