import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { AppConfigService } from 'app/utils/appconfig/app-config.service';
import { Injectable } from '@angular/core';
import { map, share, tap } from 'rxjs/operators';
import { SiteSettingType } from 'next-typescript-api';
import { ProductModalConsent } from './productModalConsent';
import { OfferContactInformationPromptType } from './offerContactInformationPromptType';
import { CheckCprBeforeCalculation } from './checkCprBeforeCalculation';
import { ShowCallMeModalOnTermination } from './showCallMeModalOnTermination';

@Injectable({
    providedIn: 'root',
})
export class SiteSettingsService {
    private apiUrl: string;

    private observableSettingDictionary: { [setting: number]: Observable<boolean> } = {};
    private cachedSettingDictionary: { [setting: number]: boolean } = {};

    private productModalConsentObservable: Observable<ProductModalConsent>;
    private productModalConsent: ProductModalConsent;
    private offerContactInformationPromptTypeObservable: Observable<OfferContactInformationPromptType>;
    private offerContactInformationPromptType: OfferContactInformationPromptType;
    private checkCprBeforeCalculation: CheckCprBeforeCalculation;
    private checkCprBeforeCalculationObservable: Observable<CheckCprBeforeCalculation>;
    private showCallMeModalOnTermination: ShowCallMeModalOnTermination;
    private showCallMeModalOnTerminationObservable: Observable<ShowCallMeModalOnTermination>;

    constructor(private http: HttpClient, private config: AppConfigService) {
        this.apiUrl = this.config.appConfig.apiHost;
    }

    getBooleanSetting(setting: SiteSettingType): Observable<boolean> {
        const cached = this.cachedSettingDictionary[setting];

        // Return cached result
        if (cached !== undefined) {
            return of(cached);
        }

        const observable = this.observableSettingDictionary[setting];

        // Return observable if a previous call has been made
        if (observable) {
            return observable;
        }

        const url = `${this.apiUrl}/sitesettings/${setting}?api-version=2.0`;
        this.observableSettingDictionary[setting] = this.http.get<string>(url).pipe(
            share(),
            map((result) => result?.toString().toLowerCase() === 'true'),
            tap((result) => (this.cachedSettingDictionary[setting] = result))
        );

        return this.observableSettingDictionary[setting];
    }

    getProductModalConsentSetting(): Observable<ProductModalConsent> {
        // Return cached result
        if (this.productModalConsent !== undefined) {
            return of(this.productModalConsent);
        }

        // Return observable if a previous call has been made
        if (this.productModalConsentObservable) {
            return this.productModalConsentObservable;
        }

        const url = `${this.apiUrl}/sitesettings/5?api-version=2.0`;
        this.productModalConsentObservable = this.http.get<string>(url).pipe(
            share(),
            map((result) => result as ProductModalConsent),
            tap((result) => (this.productModalConsent = result))
        );

        return this.productModalConsentObservable;
    }

    getOfferContactInformationPromptType(): Observable<OfferContactInformationPromptType> {
        // Return cached result
        if (this.offerContactInformationPromptType !== undefined) {
            return of(this.offerContactInformationPromptType);
        }

        // Return observable if a previous call has been made
        if (this.offerContactInformationPromptTypeObservable) {
            return this.offerContactInformationPromptTypeObservable;
        }

        const url = `${this.apiUrl}/sitesettings/6?api-version=2.0`;
        this.offerContactInformationPromptTypeObservable = this.http.get<string>(url).pipe(
            share(),
            map((result) => result as OfferContactInformationPromptType),
            tap((result) => (this.offerContactInformationPromptType = result))
        );

        return this.offerContactInformationPromptTypeObservable;
    }

    getCheckCprBeforeCalculation(): Observable<CheckCprBeforeCalculation> {
        // Return cached result
        if (this.checkCprBeforeCalculation !== undefined) {
            return of(this.checkCprBeforeCalculation);
        }

        // Return observable if a previous call has been made
        if (this.checkCprBeforeCalculationObservable) {
            return this.checkCprBeforeCalculationObservable;
        }

        const url = `${this.apiUrl}/sitesettings/7?api-version=2.0`;
        this.checkCprBeforeCalculationObservable = this.http.get<string>(url).pipe(
            share(),
            map((result) => result as CheckCprBeforeCalculation),
            tap((result) => (this.checkCprBeforeCalculation = result))
        );

        return this.checkCprBeforeCalculationObservable;
    }

    getShowCallMeModalOnTermination(): Observable<ShowCallMeModalOnTermination> {
        // Return cached result
        if (this.showCallMeModalOnTermination !== undefined) {
            return of(this.showCallMeModalOnTermination);
        }

        // Return observable if a previous call has been made
        if (this.showCallMeModalOnTerminationObservable) {
            return this.showCallMeModalOnTerminationObservable;
        }

        const url = `${this.apiUrl}/sitesettings/8?api-version=2.0`;

        this.showCallMeModalOnTerminationObservable = this.http.get<string>(url).pipe(
            share(),
            map((result) => result as ShowCallMeModalOnTermination),
            tap((result) => (this.showCallMeModalOnTermination = result))
        );

        return this.showCallMeModalOnTerminationObservable;
    }
}
