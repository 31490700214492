export class PromiseHelper {
    public static makeCancelable<T>(promise: Promise<T>): CancelablePromise<T> {
        let hasCanceled = false;

        const wrappedPromise = new Promise<T>((resolve, reject) => {
            promise.then((val) => (hasCanceled ? reject({ isCanceled: true }) : resolve(val)));
            promise.catch((error) => (hasCanceled ? reject({ isCanceled: true }) : reject(error)));
        });

        return {
            promise: wrappedPromise,
            cancel: () => (hasCanceled = true),
        };
    }
}

export class CancelablePromise<T> {
    promise: Promise<T>;
    cancel: () => void;
}
