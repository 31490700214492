import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { accidentRoutes } from './accident-routes';

@NgModule({
    imports: [RouterModule.forChild(accidentRoutes)],
    exports: [RouterModule],
})
export class AccidentRoutingModule {}
