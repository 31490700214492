import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class TrackingService {
    public openedTrackingId$;
    private openedTrackingId = new BehaviorSubject<any>('');

    constructor(public http: HttpClient) {
        this.openedTrackingId$ = this.openedTrackingId.asObservable();
    }

    updateOpenedTrackingId(openedTrackingId: string) {
        this.openedTrackingId.next(openedTrackingId);
    }

    selectProductsOpened(): Observable<string> {
        const url = `/tracking/select-products-opened`;
        const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
        return this.http.post(url, null, { headers, responseType: 'text' });
    }

    selectProductsCompleted(openedTrackingId: string, basketId: string): Observable<boolean> {
        const url = `/tracking/select-products-completed`;
        const input = { openedTrackingId, basketId };
        return this.http.post<boolean>(url, input);
    }

    getBasketCompleted(basketId: string): Observable<boolean> {
        const url = `/tracking/basket-completed/${basketId}`;
        return this.http.get<boolean>(url);
    }

    contactInfoPrompted(basketId: string, promptType: string): Observable<string> {
        const url = `/tracking/contact-info-prompted`;
        const input = { basketId, promptType };

        return this.http.post(url, input, { responseType: 'text' });
    }

    contactInfoCompleted(trackingId: string, allInfoGiven: boolean): Observable<boolean> {
        const url = `/tracking/contact-info-completed`;
        const input = { trackingId, allInfoGiven };
        return this.http.post<boolean>(url, input);
    }
}
