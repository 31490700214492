import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Input } from '@angular/core';
import { RibbonType } from 'app/class/cover';

@Component({
    selector: 'ribbon',
    templateUrl: './ribbon.component.html',
    styleUrls: ['./ribbon.component.less'],
})
export class RibbonComponent implements OnInit {
    @Input() type: RibbonType = RibbonType.required;
    text: string;
    colorClass: 'lightest-blue' | 'green' = 'lightest-blue';
    constructor(private translateService: TranslateService) {}

    ngOnInit() {
        switch (this.type) {
            case 'required':
                this.text = this.translateService.instant('SHARED.RIBBON.REQUIRED');
                this.colorClass = 'lightest-blue';
                break;
            case 'optional':
                this.text = this.translateService.instant('SHARED.RIBBON.OPTIONAL');
                this.colorClass = 'lightest-blue';
                break;
            case 'travel':
                this.text = this.translateService.instant('SHARED.RIBBON.TRAVEL');
                this.colorClass = 'green';
                break;
        }
    }
}
