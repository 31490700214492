import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from 'app/utils/appconfig/app-config.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HeardAboutTypeDetails, SetHeardAboutInput } from 'next-typescript-api';

@Injectable()
export class BasketHeardAboutService {
    private apiUrl: string;

    constructor(private http: HttpClient, private config: AppConfigService) {
        this.apiUrl = this.config.appConfig.apiHost;
    }

    getBasketHeardAboutTypes(): Observable<HeardAboutTypeDetails[]> {
        const url = `${this.apiUrl}/heardabout/types?api-version=2.0`;
        return this.http.get<HeardAboutTypeDetails[]>(url);
    }

    setBasketHeardAbout(basketPublicId: string, heardAboutType: SetHeardAboutInput): Observable<boolean> {
        const url = `${this.apiUrl}/baskets/${basketPublicId}/heardabout?api-version=2.0`;
        return this.http.post(url, heardAboutType, { observe: 'response' }).pipe(
            map(resp => {
                const statusCode = resp.status;

                if (statusCode === 200 || statusCode === 204) {
                    return true;
                }

                return false;
            })
        );
    }
}
