<div class="full-width-image-section contact-top">
    <div class="arrow-up">
        <next-topbar></next-topbar>
        <next-menu></next-menu>

        <div class="container white">
            <div class="row">
                <div class="col-sm-12 text-center">
                    <h1>{{ 'PAGE.MANUAL_PAYMENT.TITLE' | translate }}</h1>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="loadingspacer" *ngIf="loading">
    <pageloader [show]="loading"></pageloader>
</div>

<div class="container container-padding-20-40" *ngIf="!loading">
    <div class="row">
        <div class="col-sm-12">
            <div *ngIf="paymentresult !== null && paymentresult !== undefined">
                <div class="msg msg-danger" *ngIf="!paymentresult" style="width: 100%">
                    <b>{{ 'SHARED.ERROR' | translate }}</b> {{ 'PAGE.MANUAL_PAYMENT.PAYMENT_FAILED' | translate }}
                </div>
                <div class="msg msg-success" *ngIf="paymentresult" style="width: 100%">
                    {{ 'PAGE.MANUAL_PAYMENT.PAYMENT_SUCCESS' | translate }}
                </div>
            </div>

            <div class="msg msg-danger" *ngIf="errorGettingPaymentMethods">
                <b>{{ 'SHARED.ERROR' | translate }}</b> {{ 'PAGE.MANUAL_PAYMENT.ERROR_GETTING_PAYMENT_METHODS' | translate }}
            </div>

            <div *ngIf="!errorGettingPaymentMethods">
                <div *ngFor="let paymentmethod of manuelPaymentMethods; let i = index" style="margin-top: 20px">
                    <!-- 100: CreditCard -->
                    <div *ngIf="paymentmethod.type.code === '100'" class="creditcard-container">
                        <div class="col-xs-12 col-sm-5">
                            <img src="https://static.next.dk/website/images/logos/creditcards.png" style="margin-right: 25px" />
                            <img src="https://static.next.dk/website/images/logos/mobilepay.png" srcset="https://static.next.dk/website/images/logos/mobilepay@2x.png 2x" />
                        </div>
                        <div class="col-xs-12 col-sm-7">
                            <a [href]="paymentmethod.redirectUrl" class="btn btn-blue">
                                <span>{{ 'PAGE.MANUAL_PAYMENT.PAY_WITH_CARD_OR_MOBILEPAY' | translate }}</span>
                            </a>
                        </div>
                    </div>
                    <!-- EXTEND WHEN MOBILEPAY IS NO LONGER INCLUDED IN CREDITCARD -->
                </div>
            </div>
        </div>
    </div>
</div>

<next-footer></next-footer>
