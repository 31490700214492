import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavigationService } from 'app/utils/navigation/navigation.service';
import { AuthService } from 'app/auth/auth.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
    selector: 'selfcare-timeout',
    templateUrl: './timeout.component.html',
    styleUrls: ['./timeout.component.less'],
})
export class TimeoutComponent implements OnInit, OnDestroy {
    timeoutDate: Date;
    warningDate: Date;
    warningTimeMinutes = 2;
    warningCountdownSeconds: number;
    isVisible = false;

    countdownIntervalId: number;
    warningTimeoutId: number;
    timeoutId: number;
    subscriptions: Subscription[] = [];

    constructor(private authService: AuthService, public navigationService: NavigationService, private router: Router) {}

    ngOnInit(): void {
        if (this.authService.isAuthenticated()) {
            this.onTokenExpirationChanged(this.authService.getExpirationDate());
        }

        this.subscriptions.push(this.authService.tokenExpirationChanged.subscribe(this.onTokenExpirationChanged.bind(this)));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    onTokenExpirationChanged(expirationDate: Date): void {
        this.timeoutDate = expirationDate;
        this.warningDate = new Date(this.timeoutDate.getTime() - this.warningTimeMinutes * 60 * 1000);

        this.resetTimers();
        this.startTimers();
    }

    startTimers(): void {
        const warningMilliseconds = this.warningDate.getTime() - new Date().getTime();

        this.warningTimeoutId = window.setTimeout(() => {
            this.isVisible = true;

            this.countdownIntervalId = window.setInterval(() => {
                const milliseconds = this.timeoutDate.getTime() - new Date().getTime();

                this.warningCountdownSeconds = Math.round(milliseconds / 1000); // whole seconds
            }, 1000);
        }, warningMilliseconds);

        const timeoutMilliseconds = this.timeoutDate.getTime() - new Date().getTime();

        this.timeoutId = window.setTimeout(() => {
            this.logout();
        }, timeoutMilliseconds);
    }

    resetTimers(): void {
        window.clearTimeout(this.timeoutId);
        window.clearTimeout(this.warningTimeoutId);
        window.clearInterval(this.countdownIntervalId);
        this.timeoutId = null;
        this.warningTimeoutId = null;
        this.countdownIntervalId = null;
    }

    extendTimeout(): void {
        this.isVisible = false;
        this.resetTimers();
        this.authService.refreshToken();
    }

    logout(): void {
        this.isVisible = false;
        this.authService.clearToken();
        this.router.navigateByUrl(this.navigationService.getUrl('selfcare-login'));
    }

    setWarningTimeMinutes(minutes: number): void {
        this.warningTimeMinutes = minutes;
    }
}
