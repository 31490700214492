import { Routes } from '@angular/router';
import { carRoutes } from './car/car-routes';
import { homeRoutes } from './home/home-routes';
import { houseRoutes } from './house/house-routes';
import { caravanRoutes } from './caravan/caravan-routes';
import { mopedRoutes } from './moped/moped-routes';
import { secondhouseRoutes } from './secondhouse/secondhouse-routes';
import { dogRoutes } from './dog/dog-routes';
import { accidentRoutes } from './accident/accident-routes';
import { generalRoutes } from './general/general-routes';

export const insuranceRoutes: Routes = [
    { path: 'generelt', children: generalRoutes },
    { path: 'bilforsikring', children: carRoutes },
    { path: 'indboforsikring', children: homeRoutes },
    { path: 'husforsikring', children: houseRoutes },
    { path: 'campingvognsforsikring', children: caravanRoutes },
    { path: 'knallertforsikring', children: mopedRoutes },
    { path: 'fritidshusforsikring', children: secondhouseRoutes },
    { path: 'hundeforsikring', children: dogRoutes },
    { path: 'ulykkesforsikring', children: accidentRoutes },
];
