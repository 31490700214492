import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
    name: 'safehtml',
})
export class SafeHtmlPipe implements PipeTransform {
    constructor(protected domSanitizer: DomSanitizer) {}

    /* IMPORTANT! ONLY USE WITH INNERHTML! */
    transform(val: string): SafeHtml {
        // Consider sanitizing values for script tags, so we can use the pipe universally. Angulars' DomSanitizer is unable to be configured to allow style attributes
        // while sanitizing script tags. I've had a look at third party packages, but none seem particularily suitable for this purpose.
        // We should only use this pipe for injecting into innerHTML, since scripts will not execute there.
        return this.domSanitizer.bypassSecurityTrustHtml(val);
    }
}
