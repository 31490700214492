import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'timedisplay',
})
export class TimeDisplayPipe implements PipeTransform {
    transform(value: number): string {
        if (value !== undefined && value != null) {
            const minutes: number = Math.floor(value / 60);
            return this.padStart(minutes) + ':' + this.padStart(value - minutes * 60);
        } else {
            return '';
        }
    }

    padStart(num) {
        if (num < 10) {
            return '0' + num;
        } else {
            return num;
        }
    }
}
