import { Routes } from '@angular/router';
import { IndexComponent } from './components/index/index.component';
import { CoverInfoComponent } from '../components/cover-info/cover-info.component';
import { CoverInfoSettings } from '../components/cover-info/CoverInfoSettings';

export const secondhouseRoutes: Routes = [
    { path: '', component: IndexComponent },
    { path: 'husejeransvar', component: CoverInfoComponent, data: new CoverInfoSettings('secondhouse', 'houseliability', 'nf nf-house-husejeransvar') },
    { path: 'bygningsbrand', component: CoverInfoComponent, data: new CoverInfoSettings('secondhouse', 'fire', 'nf nf-house-husansvar') },
    { path: 'bygningskasko', component: CoverInfoComponent, data: new CoverInfoSettings('secondhouse', 'comprehensive', 'nf nf-house-kasko') },
    { path: 'glas-og-sanitet', component: CoverInfoComponent, data: new CoverInfoSettings('secondhouse', 'glass', 'nf nf-house-glas-og-sanitet') },
    { path: 'retshjaelp', component: CoverInfoComponent, data: new CoverInfoSettings('secondhouse', 'court', 'nf nf-car-retshjlp') },
    { path: 'roer-og-kabel', component: CoverInfoComponent, data: new CoverInfoSettings('secondhouse', 'pipecable', 'nf nf-house-roer-og-kabel') },
    { path: 'insekt-og-svamp', component: CoverInfoComponent, data: new CoverInfoSettings('secondhouse', 'insectfungi', 'nf nf-house-insekt-og-svamp') },
    { path: 'stikledning', component: CoverInfoComponent, data: new CoverInfoSettings('secondhouse', 'serviceline', 'nf nf-broken-pipe') },
    { path: 'udvidet-daekning', component: CoverInfoComponent, data: new CoverInfoSettings('secondhouse', 'extended', 'nf nf-house-udvidet-deakning') },
    // Home-related covers
    { path: 'privat-indbo', component: CoverInfoComponent, data: new CoverInfoSettings('secondhouse', 'private', 'nf nf-home-ansvar') },
    { path: 'elektronikdaekning', component: CoverInfoComponent, data: new CoverInfoSettings('secondhouse', 'electronic', 'nf nf-home-elektronik-dkning') },
    { path: 'pludselig-skade', component: CoverInfoComponent, data: new CoverInfoSettings('secondhouse', 'sudden', 'nf nf-home-pludselig-skade') },

    // Non cover pages
    { path: 'alternativ-energi', component: CoverInfoComponent, data: new CoverInfoSettings('secondhouse', 'alternativeenergy', '') },
    { path: 'tilbygning-ombygning', component: CoverInfoComponent, data: new CoverInfoSettings('secondhouse', 'extension', '') },
];
