import { Observable, Subscription, of } from 'rxjs';
import { AppConfigService } from 'app/utils/appconfig/app-config.service';
import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'app/auth/auth.service';
import { share, map } from 'rxjs/operators';
import { CustomerCampaignDetailsExtend } from 'app/class/nextapiextend/CustomerCampaignDetailsExtend';
import { ObservableHelper } from '../observableHelper/observableHelper';

@Injectable()
export class CampaignService implements OnDestroy {
    subscriptions: Subscription[] = [];
    private apiUrl: string;

    private campaignData: CustomerCampaignDetailsExtend[];
    private campaignObservable: Observable<CustomerCampaignDetailsExtend[]>;

    constructor(private http: HttpClient, private config: AppConfigService, private auth: AuthService, private observableHelper: ObservableHelper) {
        this.apiUrl = this.config.appConfig.apiHost;
        const subscription = this.auth.clearTokenObserveable.subscribe(this.invalidateCache.bind(this));
        this.subscriptions.push(subscription);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    invalidateCache() {
        this.campaignData = null;
    }

    getCampaigns(): Observable<CustomerCampaignDetailsExtend[]> {
        if (!this.auth.isAuthenticated()) {
            console.log('getCampaigns returning empty array since user is not authenticated');
            return of<CustomerCampaignDetailsExtend[]>();
        }

        const customerId = this.auth.getCustomerId();

        if (!customerId) {
            return this.observableHelper.CreateFailedObservableAndLogStackTrace('Missing customer number');
        }

        if (this.campaignData) {
            return of(this.campaignData);
        } else if (this.campaignObservable) {
            return this.campaignObservable;
        } else {
            const url = `${this.apiUrl}/customers/${customerId}/campaigns?api-version=2.0`;

            this.campaignObservable = this.http
                .get<CustomerCampaignDetailsExtend[]>(url, {
                    observe: 'response',
                })
                .pipe(
                    map((response) => {
                        this.campaignObservable = null;
                        if (response.status === 200) {
                            this.campaignData = response.body;
                            return this.campaignData;
                        }

                        throw new Error('Failed to get campaigns');
                    }),
                    share()
                );
            return this.campaignObservable;
        }
    }
}
