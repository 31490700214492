/* eslint no-console: 0 */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
    selector: 'consolelogger',
    templateUrl: './consolelogger.component.html',
    styleUrls: ['./consolelogger.component.less'],
})
export class ConsoleloggerComponent implements OnInit {
    visible: boolean;
    fullscreen: boolean;
    constructor(private route: ActivatedRoute) {}

    ngOnInit() {
        this.route.queryParams.subscribe((params: Params) => {
            const active = params['consolelogger'];
            if (active) {
                this.start();
            }
        });
    }

    start() {
        console.log('Consolelogger is active - all console output from now on is shown in the consolelogger component');
        this.visible = true;
        const logger = document.getElementById('log');
        if (logger) {
            const currentMs = new Date();
            console.log =
                console.warn =
                console.info =
                    function (message) {
                        if (typeof message === 'object') {
                            logger.innerHTML += currentMs + ' ' + (JSON && JSON.stringify ? JSON.stringify(message) : String(message)) + '<br />';
                        } else {
                            logger.innerHTML += currentMs + ' ' + message + '<br />';
                        }
                    };
        }

        console.log('consolelogger started');
    }

    toggleFullscreen() {
        this.fullscreen = !this.fullscreen;
    }
}
