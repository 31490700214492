import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MitIdService } from 'app/utils/mitId/mit-id.service';
import { QueryParametersService } from 'app/utils/queryParameters/query-parameters.service';

@Component({
    selector: 'mit-id-signing-landing',
    templateUrl: './mit-id-signing-landing.component.html',
    styleUrls: ['./mit-id-signing-landing.component.less'],
})
export class MitIdSigningLandingComponent implements OnInit {
    constructor(private router: Router, private route: ActivatedRoute, private mitIdService: MitIdService, private queryParametersService: QueryParametersService) {}

    ngOnInit(): void {
        this.route.queryParams.subscribe((params: Params) => {
            // const basketSignId = params['baxBasketSignId'];
            const success: string = params['baxOidcSuccess'];

            if (success.toLowerCase() === 'true') {
                let redirectUrl = this.mitIdService.getSuccessfulSigningRedirectUrl();

                if (this.queryParametersService.getQueryVariable('signingSuccess')) {
                    redirectUrl = this.queryParametersService.removeQueryVariable('signingSuccess', redirectUrl);
                }

                const paramPrefix = redirectUrl.indexOf('?') === -1 ? '?' : '&';

                redirectUrl += paramPrefix + 'signingSuccess=true';

                this.router.navigateByUrl(redirectUrl);
            } else {
                let redirectUrl = this.mitIdService.getFailedSigningRedirectUrl();

                if (this.queryParametersService.getQueryVariable('mitIdError')) {
                    redirectUrl = this.queryParametersService.removeQueryVariable('mitIdError', redirectUrl);
                }

                const errorPrefix = redirectUrl.indexOf('?') === -1 ? '?' : '&';

                const errorMessage = params['baxOidcMessage'];

                redirectUrl += errorPrefix + 'mitIdError=' + encodeURIComponent(errorMessage);

                this.router.navigateByUrl(redirectUrl);
            }
        });
    }
}
