import { SelfcareService } from 'app/selfcare/selfcare.service';
import { Component, OnDestroy } from '@angular/core';
import { NavigationService } from 'app/utils/navigation/navigation.service';
import { AuthService } from 'app/auth/auth.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TopbarService } from 'app/shared/topbar/topbar.service';
import { CustomerTaskService } from 'app/selfcare/tasks/customertask.service';

@Component({
    moduleId: module.id,
    selector: 'next-selfcaremenu',
    templateUrl: './selfcaremenu.component.html',
    styleUrls: ['./selfcaremenu.component.less'],
})
export class SelfcareMenuComponent implements OnDestroy {
    showLeftMenu = false;
    showSummaryBasket: boolean;
    userDisplayName: string;
    isAuthenticated: boolean;
    numInBasket?: number;
    tasksCount: number;

    public selfcaremode = 'selfcare';
    subscriptions: Subscription[] = [];

    showProducts = false;
    goToSummaryLoading: boolean;

    constructor(
        public navigationService: NavigationService,
        public auth: AuthService,
        public selfcareService: SelfcareService,
        private router: Router,
        private topbarService: TopbarService,
        private customerTaskService: CustomerTaskService
    ) {
        this.subscriptions.push(
            this.customerTaskService.tasksChanged.subscribe((tasks) => {
                this.tasksCount = tasks?.length;
            })
        );

        this.subscriptions.push(this.topbarService.currentUserDisplayName.subscribe((x) => (this.userDisplayName = x)));

        this.subscriptions.push(this.topbarService.currentIsLoggedIn.subscribe((x) => (this.isAuthenticated = x)));

        this.subscriptions.push(this.topbarService.currentNumInBasket.subscribe((x) => (this.numInBasket = x)));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    toggleLeftMenu(): void {
        this.showLeftMenu = !this.showLeftMenu;
    }

    toggleShowProducts() {
        this.showProducts = !this.showProducts;
    }

    goToSummary() {
        this.goToSummaryLoading = true;
        if (this.numInBasket != null && this.numInBasket > 0) {
            this.router.navigate([this.navigationService.getUrl('summary')]);
        } else {
            this.router.navigate([this.navigationService.getUrl('newcustomer')]);
            this.goToSummaryLoading = false;
        }
    }
}
