import { Cover } from 'app/class/cover';
import { CoverSelect } from 'app/class/cover-select';
import { Address } from 'app/class/address';

export class Dog {
    name: string;
    birthdate: Date;
    address: Address;
    genderCode: string;
    raceCode: string;
    covers: CoverSelect[];
    adoptAddress = true;
    eventDate?: Date;

    constructor() {
        this.name = '';
        this.birthdate = null;
        this.address = new Address();
        this.genderCode = '';
        this.raceCode = '';
    }
}
