import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IntegerDisplayPipe } from './integerdisplaypipe';
import { DecimalDisplayPipe } from './decimaldisplaypipe';
import { DateDisplayPipe } from './datedisplaypipe';
import { DateTimeDisplayPipe } from './datetimedisplaypipe';
import { GenitivePipe } from './genitivepipe';
import { TimeDisplayPipe } from './timedisplaypipe';
import { MultiFilterPipe } from './multifilter';
import { ThousandSeperatorIntDisplayPipe } from './thousandseperatorintdisplaypipe';
import { ThousandSeperatorFloatDisplayPipe } from './thousandSeperatorFloatDisplayPipe';
import { LoadingPipe } from './loadingPipe';
import { SafeHtmlPipe } from './safeHtmlPipe';
import { SomePipe } from './somePipe';
import { ReplacePipe } from './replacePipe';

@NgModule({
    declarations: [
        IntegerDisplayPipe,
        DecimalDisplayPipe,
        DateDisplayPipe,
        DateTimeDisplayPipe,
        GenitivePipe,
        TimeDisplayPipe,
        MultiFilterPipe,
        ThousandSeperatorIntDisplayPipe,
        ThousandSeperatorFloatDisplayPipe,
        LoadingPipe,
        SafeHtmlPipe,
        SomePipe,
        ReplacePipe,
    ],
    imports: [CommonModule],
    exports: [
        IntegerDisplayPipe,
        DecimalDisplayPipe,
        DateDisplayPipe,
        DateTimeDisplayPipe,
        GenitivePipe,
        TimeDisplayPipe,
        MultiFilterPipe,
        ThousandSeperatorIntDisplayPipe,
        ThousandSeperatorFloatDisplayPipe,
        LoadingPipe,
        SafeHtmlPipe,
        SomePipe,
        ReplacePipe,
    ],
})
export class CustomPipeModule {}
