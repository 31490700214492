import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { carRoutes } from './car-routes';

@NgModule({
    imports: [RouterModule.forChild(carRoutes)],
    exports: [RouterModule],
})
export class CarRoutingModule {}
