import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { VideoModalComponent } from './components/videomodal/videomodal.component';
import { NavigationService } from 'app/utils/navigation/navigation.service';
import { RemoveProductModalComponent } from './components/removeproductmodal/removeproductmodal.component';
import { MobilepayModalComponent } from './components/mobilepaymodal/mobilepaymodal.component';
import { YoungHomeModalComponent } from './components/younghomemodal/younghomemodal.component';
import { UpdatingPaymentMethodModalComponent } from './components/updating-payment-method-modal/updating-payment-method-modal.component';
import { PageLoaderModule } from '../pageloader/pageloader.module';

@NgModule({
    imports: [RouterModule, CommonModule, FormsModule, PageLoaderModule, TranslateModule],
    declarations: [VideoModalComponent, RemoveProductModalComponent, MobilepayModalComponent, YoungHomeModalComponent, UpdatingPaymentMethodModalComponent],
    exports: [VideoModalComponent, RemoveProductModalComponent, MobilepayModalComponent, YoungHomeModalComponent, UpdatingPaymentMethodModalComponent],
    providers: [NavigationService],
})
export class CustomModalModule {}
