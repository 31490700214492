import { Component, OnInit, Inject, PLATFORM_ID, EventEmitter, Output, AfterViewInit, OnDestroy } from '@angular/core';
import { NemIdService } from 'app/utils/nemid/nemid.service';
import { AuthService } from 'app/auth/auth.service';
import { isPlatformBrowser } from '@angular/common';
import { UpdateBasketCustomer, CustomerAdditionalInfo } from 'app/class/bax/updateBasketCustomer';
import { Cookie } from 'ng2-cookies';
import { BaxService } from 'app/utils/bax/bax.service';
import { Subscription } from 'rxjs';
import { NemIdLoginParameters } from 'next-typescript-api';

@Component({
    selector: 'nemid-login',
    templateUrl: './nem-id-login.component.html',
    styleUrls: ['./nem-id-login.component.less'],
})
export class NemIdLoginComponent implements OnInit, AfterViewInit, OnDestroy {
    @Output() loginComplete: EventEmitter<any> = new EventEmitter();

    loading: boolean;
    ssnInput: string;
    showNotFoundError: boolean;
    showSSNError: boolean;
    nemIdBound: any;
    nemIdLoginParameters: NemIdLoginParameters;
    tempSignature: string;
    showError: boolean;
    errorCode: string;
    showSSNInput: boolean;
    subscriptions: Subscription[] = [];
    timedOut = false;

    loadingIframe = true;

    constructor(private nemIdService: NemIdService, private auth: AuthService, private baxService: BaxService, @Inject(PLATFORM_ID) private platformId: any) {}

    ngOnInit() {
        this.startNemIdTimeout();
    }

    ngAfterViewInit(): void {
        if (this.auth.isAuthenticated()) {
            this.loginComplete.next(); // Here we go to the next step without logging in since we are already logged in
        } else {
            this.initializeNemId();
        }
    }

    startNemIdTimeout(): void {
        this.timedOut = false;

        setTimeout(() => {
            this.destroyNemId();
            this.timedOut = true;
        }, 20 * 60000); // 20 minutes
    }

    reloadNemId(): void {
        this.timedOut = false;

        this.initializeNemId();

        this.startNemIdTimeout();
    }

    initializeNemId(): void {
        this.subscriptions.push(
            this.nemIdService.login().subscribe(
                (resp) => {
                    if (isPlatformBrowser(this.platformId)) {
                        this.nemIdLoginParameters = resp;

                        if (!this.nemIdBound) {
                            this.nemIdBound = this.onNemIDMessage.bind(this);
                        }

                        window.addEventListener('message', this.nemIdBound);

                        (<HTMLIFrameElement>document.getElementById('nemid_iframe')).src = 'https://applet.danid.dk/launcher/lmt/' + new Date().getTime();
                    }
                },
                (err) => {
                    this.showError = true;
                }
            )
        );
    }

    onNemIDMessage(e): void {
        if (isPlatformBrowser(this.platformId)) {
            this.showError = false;
            const event = e;
            const win = (<HTMLIFrameElement>document.getElementById('nemid_iframe')).contentWindow;
            const postMessage: any = {};
            if (event.data == null) {
                return;
            }

            if (event.origin !== 'https://applet.danid.dk') {
                return;
            }

            const message = JSON.parse(event.data);

            if (message.command === 'SendParameters') {
                postMessage.command = 'parameters';
                postMessage.content = JSON.stringify(this.nemIdLoginParameters.parameters);

                win.postMessage(JSON.stringify(postMessage), 'https://applet.danid.dk');

                this.loadingIframe = false;
            }

            if (message.command === 'changeResponseAndSubmit') {
                let signature = '';

                if (message.content.length > 20) {
                    // Success event
                    signature = message.content;
                } else {
                    // Base64 decode response
                    const responseCode = atob(message.content);

                    if (responseCode === 'CAN002') {
                        // Cancel event - destroy and reinitialize
                        this.destroyNemId();
                        this.initializeNemId();
                    } else {
                        // Error event - show error
                        this.errorCode = responseCode;
                        this.showError = true;
                    }
                    return;
                }

                this.showNotFoundError = false;

                this.auth.login(this.nemIdLoginParameters.challenge, signature).then(
                    () => {
                        const basketId = Cookie.get('basketid');

                        // If there is a basket, then we have to handle this according to the customer now authed
                        if (basketId) {
                            this.baxService.syncBasket(basketId, this.auth.getCustomerId()).then(() => {
                                this.loginComplete.next();
                                this.destroyNemId();
                            });
                        } else {
                            this.loginComplete.next(); // Here we go to the next step after the NEM ID login
                            this.destroyNemId();
                        }
                    },
                    (err) => {
                        this.destroyNemId();
                        if (err.error.error === 'pid_not_found') {
                            this.showSSNInput = true;
                            this.tempSignature = signature;
                        } else if (err.error.error === 'customer_not_found') {
                            this.showNotFoundError = true;
                        } else {
                            this.initializeNemId();
                            this.showError = true;
                        }
                    }
                );
            }
        }
    }

    loginWithSsn(): void {
        this.showNotFoundError = false;
        this.showSSNError = false;
        this.loading = true;
        this.auth.login(this.nemIdLoginParameters.challenge, this.tempSignature, this.ssnInput).then(
            () => {
                this.loginComplete.next(); // Here we go to the next step after the NEM ID login with SSN
                this.destroyNemId();
                this.loading = false;
            },
            (err) => {
                this.loading = false;

                if (err != null && err.error != null && err.error.error === 'customer_not_found') {
                    this.showNotFoundError = true;
                } else {
                    this.showSSNError = true;
                }
            }
        );
    }

    ngOnDestroy(): void {
        this.destroyNemId();

        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    destroyNemId(): void {
        if (isPlatformBrowser(this.platformId)) {
            const iframe = <HTMLIFrameElement>document.getElementById('nemid_iframe');
            if (iframe) {
                iframe.src = '';
            }

            window.removeEventListener('message', this.nemIdBound);
        }
    }
}
