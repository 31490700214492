<!-- TrustBox widget - Horizontal -->
<div
    id="trustpilot-line"
    class="trustpilot-widget"
    data-locale="da-DK"
    data-template-id="5406e65db0d04a09e042d5fc"
    data-businessunit-id="4c90a80400006400050ddc71"
    data-style-height="28px"
    data-style-width="100%"
    data-theme="dark"
>
    <a href="https://dk.trustpilot.com/review/next.dk" target="_blank" rel="noopener">Trustpilot</a>
</div>
<!-- End TrustBox widget -->
