<div class="insurance-container">
    <a [routerLink]="navigationService.getUrl('cover-' + cover.product.toLowerCase() + '-' + cover.cover)">
        <div [class]="'ribbon-parent insurance-box insurance-img img-' + cover.product.toLowerCase() + '-' + cover.cover">
            <ribbon [type]="cover.ribbonType"></ribbon>
            <div class="icon-circle center">
                <div class="icon-wrap">
                    <i [class]="cover.coverIcon"></i>
                </div>
            </div>
        </div>
        <div class="insurance-bottom-box">
            <h2>{{ 'INSURANCE.' + cover.product.toUpperCase() + '.COMPONENTS.COVER.' + cover.cover.toUpperCase() | translate }}</h2>
            <p class="readmore">{{ 'SHARED.READ_MORE' | translate }}</p>
        </div>
    </a>
</div>
