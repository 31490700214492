import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ContexService } from 'app/utils/contex/contex.service';
import { MetaTagsHelper } from 'app/utils/metaTags/metaTagsHelper';
import { Subscription } from 'rxjs';
import { PromiseHelper, CancelablePromise } from 'app/utils/promiseHelper/promiseHelper';
import { BlogPostPreview } from 'app/utils/contex/models/blogPostPreview';
import { TranslationService } from 'app/utils/translation/translation.service';

@Component({
    moduleId: module.id,
    selector: 'blog-previews-page',
    templateUrl: './blog-previews-page.component.html',
    styleUrls: ['./blog-previews-page.component.less'],
    encapsulation: ViewEncapsulation.None,
})
export class BlogPreviewsPageComponent implements OnInit, OnDestroy {
    subscriptions: Subscription[] = [];
    postCount: number;
    page = 1;
    pageSize = 5;
    getPostPreviewsPromise: CancelablePromise<BlogPostPreview[]>;
    getPostCountPromise: CancelablePromise<number>;
    blogPostPreviews: BlogPostPreview[];

    constructor(private contexService: ContexService, private metaTagsHelper: MetaTagsHelper, private translationService: TranslationService) {
        this.metaTagsHelper.setTitleAndMetaTags('META.BLOG');
    }

    ngOnInit(): void {
        this.setPagePreviews(this.page - 1);

        this.getPostCountPromise = PromiseHelper.makeCancelable(this.contexService.getBlogPostPageCount().toPromise());

        this.getPostCountPromise.promise.then((count) => {
            this.postCount = count;
        });

        this.translationService.getLanguageChangeEmitter().subscribe(() => {
            this.setPagePreviews(this.page - 1);
        });
    }

    ngOnDestroy(): void {
        if (this.getPostPreviewsPromise) {
            this.getPostPreviewsPromise.cancel();
        }

        if (this.getPostCountPromise) {
            this.getPostCountPromise.cancel();
        }
    }

    onPageChanged(pageChangedEvent) {
        this.setPagePreviews(pageChangedEvent.page - 1);
    }

    setPagePreviews(page: number) {
        if (this.getPostPreviewsPromise) {
            this.getPostPreviewsPromise.cancel();
        }

        this.getPostPreviewsPromise = PromiseHelper.makeCancelable(this.contexService.getBlogPostPreviews(page).toPromise());

        this.getPostPreviewsPromise.promise.then((blogPostPreviews) => {
            this.blogPostPreviews = blogPostPreviews;
            window.scrollTo(0, 0);
        });
    }
}
