import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConsoleloggerComponent } from './components/consolelogger/consolelogger.component';

@NgModule({
    declarations: [ConsoleloggerComponent],
    imports: [CommonModule],
    exports: [ConsoleloggerComponent],
})
export class ConsoleloggerModule {}
