import { NgModule } from '@angular/core';
import { OnlyNumberDirective } from './directives/onlynumber.directive';
import { OnlyAlphaDirective } from './directives/onlyalpha.directive';
import { OnlyAlphaAndNumberDirective } from './directives/onlyalphaandnumber.directive';

@NgModule({
    imports: [],
    declarations: [OnlyNumberDirective, OnlyAlphaDirective, OnlyAlphaAndNumberDirective],
    exports: [OnlyNumberDirective, OnlyAlphaDirective, OnlyAlphaAndNumberDirective],
    providers: [],
})
export class InputModule {}
