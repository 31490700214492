import { MetaTagsHelper } from './../../../../utils/metaTags/metaTagsHelper';
import { Component, OnInit } from '@angular/core';
import { NavigationService } from 'app/utils/navigation/navigation.service';
import { TelemetryService } from '../../../../utils/telemetry/telemetry.service';

@Component({
    moduleId: module.id,
    selector: 'app-pagenotfound',
    templateUrl: './pagenotfound.component.html',
    styleUrls: ['./pagenotfound.component.less'],
})
export class PagenotfoundComponent implements OnInit {
    constructor(public navigationService: NavigationService, private telemetryService: TelemetryService, private metaTagsHelper: MetaTagsHelper) {}

    ngOnInit() {
        this.telemetryService.logEvent('Page not found', {
            origin: location.origin,
            path: location.pathname,
        });

        this.metaTagsHelper.addTags([{ name: 'prerender-status-code', content: '404' }]);
    }
}
