import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { ContexService } from 'app/utils/contex/contex.service';
import { NavigationService } from 'app/utils/navigation/navigation.service';
import { MetaTagsHelper } from 'app/utils/metaTags/metaTagsHelper';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-trustpilot-terms',
    templateUrl: './trustpilot-terms.component.html',
    styleUrls: ['./trustpilot-terms.component.less'],
    encapsulation: ViewEncapsulation.None, // allows us to style contex content
})
export class TrustpilotTermsComponent implements OnInit, OnDestroy {
    contex: any;
    subscriptions: Subscription[] = [];

    constructor(public navigationService: NavigationService, private contexService: ContexService, private metaTagsHelper: MetaTagsHelper) {
        this.metaTagsHelper.setTitleAndMetaTags('META.TRUSTPILOT_TERMS');
    }

    ngOnInit(): void {
        const pagecode = this.navigationService.getPathObject('trustpilotterms').contexPageCode;
        this.subscriptions.push(
            this.contexService.getContent(pagecode).subscribe((x) => {
                this.contex = x;
            })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }
}
