<div class="full-width-image-section login-top">
    <div class="arrow-up">
        <next-topbar [selfcaremodeoverride]="false"></next-topbar>
        <next-menu [selfcaremodeoverride]="false"></next-menu>

        <div class="video-container">
            <ng-template #loginModal>
                <next-videomodal [videoid]="'S-xz51d8qGg'" [bsModalRef]="bsModalRef"></next-videomodal>
            </ng-template>
        </div>

        <div class="container white" (click)="openModal(loginModal)">
            <div class="row">
                <div class="col-sm-12 text-center">
                    <h1>{{ 'PAGE.SELFCARE_LOGIN.TITLE' | translate }}</h1>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12 text-center play-video-icon">
                    <i class="nf-play-video-icon-v3"></i>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container login-container">
    <div class="row">
        <div class="col-xs-12 col-sm-6">
            <h3 class="login-h3">{{ 'PAGE.SELFCARE_LOGIN.LOG_IN_TITLE' | translate }}</h3>
            <p class="login-text">
                {{ 'PAGE.SELFCARE_LOGIN.LOG_IN_TEXT' | translate }}
            </p>
            <nemid-login *ngIf="useMitId === false" (loginComplete)="nemIdLoginComplete()"></nemid-login>
            <mit-id-login *ngIf="useMitId === true" [redirectSuccessUrl]="redirectRelativeUrl"></mit-id-login>
        </div>
        <div class="col-xs-12 col-sm-6 border-left">
            <div class="border-top visible-xs"></div>
            <h3 class="login-h3">{{ 'PAGE.SELFCARE_LOGIN.WHAT_YOU_CAN_DO' | translate }}</h3>
            <li>
                <i class="nf nf-your-insurance-icon-v3"></i>
                <span>{{ 'PAGE.SELFCARE_LOGIN.OVERVIEW' | translate }}</span>
            </li>
            <li>
                <i class="nf nf-cart-icon-v3"></i>
                <span>{{ 'PAGE.SELFCARE_LOGIN.CALCULATE' | translate }}</span>
            </li>
            <li>
                <i class="nf nf-adjust-icon-v3"></i>
                <span>{{ 'PAGE.SELFCARE_LOGIN.EDIT' | translate }}</span>
            </li>
            <li>
                <i class="nf nf-report-damage-icon-v3"></i>
                <span>{{ 'PAGE.SELFCARE_LOGIN.REPORT_DAMAGE' | translate }}</span>
            </li>
            <li>
                <i class="nf nf-payment-icon-v3"></i>
                <span>{{ 'PAGE.SELFCARE_LOGIN.CHANGE_PAYMENT_INFO' | translate }}</span>
            </li>
            <li>
                <i class="nf nf-your-informations-icon-v3"></i>
                <span>{{ 'PAGE.SELFCARE_LOGIN.CHANGE_CONTACT_INFO' | translate }}</span>
            </li>
        </div>
    </div>
</div>

<next-footer></next-footer>
