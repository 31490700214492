import { Injectable } from '@angular/core';
import { AppConfigService } from '../appconfig/app-config.service';
import { AppInsights } from 'applicationinsights-js';
import { TokenStorage } from 'app/auth/token-storage.service';

// eslint-disable-next-line @typescript-eslint/naming-convention
declare let Cookiebot: any;

@Injectable()
export class TelemetryService {
    config: Microsoft.ApplicationInsights.IConfig;
    userConsented = false;
    isInitialized = false;

    constructor(private appConfigService: AppConfigService, private tokenStorage: TokenStorage) {}

    init() {
        if (!Cookiebot || !Cookiebot.hasResponse) {
            window.addEventListener(
                'CookiebotOnAccept',
                (e) => {
                    if (Cookiebot.consent.statistics) {
                        this.userConsented = Cookiebot.consent.statistics;
                        this.doInit();
                    }
                },
                false
            );

            return;
        }

        this.userConsented = Cookiebot.consent.statistics;

        if (this.userConsented) {
            this.doInit();
        }
    }

    doInit() {
        if (this.isInitialized) {
            return;
        }

        this.isInitialized = true;

        // Setup config
        this.config = {
            instrumentationKey: this.appConfigService.appConfig.applicationInsightsKey,
            enableCorsCorrelation: true,
            correlationHeaderExcludedDomains: ['dawa.aws.dk', '*.fpapi.io', '*.fpjs.io', '*.fptls.com', 'google-analytics.com', 'fpnpmcdn.net', 'maps.googleapis.com'],
        };

        // If not configured/loaded, download and setup if instrumentation key is configured
        if (!AppInsights.config && this.config.instrumentationKey) {
            AppInsights.downloadAndSetup(this.config);

            // Add default tags on all telemetry events
            const appVersion = this.appConfigService.appConfig.application.version;
            AppInsights.queue.push(() => {
                AppInsights.context.addTelemetryInitializer((envelope) => {
                    envelope.tags['ai.cloud.role'] = 'NEXT';
                    envelope.tags['ai.application.ver'] = appVersion;
                });
            });
        }
    }

    logPageView(name?: string, url?: string, properties?: any, measurements?: any, duration?: number) {
        if (!this.userConsented) {
            return;
        }

        // Handle every page view as new operation, if an operation id allready is assigned to the context
        if (AppInsights.context && AppInsights.context.operation) {
            AppInsights.context.operation.id = Microsoft.ApplicationInsights.UtilHelpers.newId();
        }

        AppInsights.trackPageView(name, url, properties, measurements, duration);
    }

    logEvent(name: string, properties?: any, measurements?: any) {
        if (!this.userConsented) {
            return;
        }

        AppInsights.trackEvent(name, properties, measurements);
    }

    logTrace(message: string, properties: any, severity: number) {
        if (!this.userConsented) {
            return;
        }

        AppInsights.trackTrace(message, properties, severity);
    }

    logTraceAndToken(message: string) {
        if (!this.userConsented) {
            return;
        }

        const tokenInformation =
            'accessToken: ' +
            this.tokenStorage.getAccessToken() +
            '/\r\n' +
            'customerId: ' +
            this.tokenStorage.getCustomerId() +
            '/\r\n' +
            'expirationDate: ' +
            this.tokenStorage.getExpirationDate() +
            '/\r\n' +
            'refreshToken: ' +
            this.tokenStorage.getRefreshToken() +
            '/\r\n';

        AppInsights.trackTrace(message + '. Token information: ' + tokenInformation + '/\r\n' + 'Stack trace: ' + Error().stack);
    }

    setAuthenticatedUserContext(userRef: string) {
        if (!this.userConsented) {
            return;
        }

        AppInsights.setAuthenticatedUserContext(userRef);
    }

    clearAuthenticatedUserContext() {
        if (!this.userConsented) {
            return;
        }

        AppInsights.clearAuthenticatedUserContext();
    }

    getOperationId(): string {
        if (!this.userConsented) {
            return '';
        }

        if (AppInsights.context && AppInsights.context.operation) {
            return AppInsights.context.operation.id;
        } else {
            return null;
        }
    }
}
