import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ProductChangeRequest } from 'app/selfcare/personal/models/productChangeRequest';
import { SigningDataExtended } from 'app/shared/nem-id-signing/NemIdSigningDataExtended';
import { AppConfigService } from 'app/utils/appconfig/app-config.service';
import { TerminateRequest, UnApprovedOffersSignRequest } from 'next-typescript-api';
import { Observable } from 'rxjs';
import { NavigationService } from '../navigation/navigation.service';
import { ILoginResponse } from './models/loginResponse';

@Injectable()
export class MitIdService {
    private apiUrl: string;
    private baxUrl: string;
    private sessionStorageLoginSuccessRedirectKey = 'loginSuccessRedirectMitIdPage';
    private sessionStorageLoginFailRedirectKey = 'loginFailRedirectMitIdPage';

    private sessionStorageSignSuccessRedirectKey = 'signSuccessRedirectMitIdPage';
    private sessionStorageSignFailRedirectKey = 'signFailRedirectMitIdPage';

    private sessionStorageRequestKey = 'mitIdRequest';

    constructor(public http: HttpClient, private config: AppConfigService, private navigationService: NavigationService) {
        this.apiUrl = this.config.appConfig.apiHost;
        this.baxUrl = this.config.appConfig.baxUrl;
    }

    private saveSuccessLoginRedirectUrl(redirectUrl: string) {
        const encoded = redirectUrl ? this.safeUrlEncode(redirectUrl) : '';

        sessionStorage.setItem(this.sessionStorageLoginSuccessRedirectKey, encoded);
    }

    private saveFailLoginRedirectUrl(redirectUrl: string) {
        const encoded = redirectUrl ? this.safeUrlEncode(redirectUrl) : '';

        sessionStorage.setItem(this.sessionStorageLoginFailRedirectKey, encoded);
    }

    private saveSuccessSignRedirectUrl(redirectUrl: string) {
        const encoded = redirectUrl ? this.safeUrlEncode(redirectUrl) : '';

        sessionStorage.setItem(this.sessionStorageSignSuccessRedirectKey, encoded);
    }

    private saveFailSignRedirectUrl(redirectUrl: string) {
        const encoded = redirectUrl ? this.safeUrlEncode(redirectUrl) : '';

        sessionStorage.setItem(this.sessionStorageSignFailRedirectKey, encoded);
    }

    private safeUrlEncode(url: string): string {
        // encode url parameters or they will be removed
        const urlSplit = url.split('?');

        // filter out MitId parameters (state and code)
        const parameters =
            urlSplit.length === 1
                ? ''
                : urlSplit[1]
                      .split('&')
                      .filter((p) => p.indexOf('state') === -1 && p.indexOf('code') === -1)
                      .join('&');
        const encodedUri = urlSplit.length === 1 ? urlSplit[0] : `${urlSplit[0]}?${encodeURIComponent(parameters)}`;

        return encodedUri;
    }

    private saveRequest<T>(request: T) {
        // Note: JSON.stringify does a shallow clone. I'm not sure this will work for all scenarios, but it works for adding and removing covers at least.
        const json = JSON.stringify(request);

        sessionStorage.setItem(this.sessionStorageRequestKey, json);
    }

    getSuccessfulLoginRedirectUrl(): string {
        const redirect = sessionStorage.getItem(this.sessionStorageLoginSuccessRedirectKey);

        return !redirect ? null : decodeURIComponent(redirect);
    }

    getFailedLoginRedirectUrl(): string {
        const redirect = sessionStorage.getItem(this.sessionStorageLoginFailRedirectKey);

        return !redirect ? null : decodeURIComponent(redirect);
    }

    getSuccessfulSigningRedirectUrl(): string {
        const redirect = sessionStorage.getItem(this.sessionStorageSignSuccessRedirectKey);

        return !redirect ? null : decodeURIComponent(redirect);
    }

    getFailedSigningRedirectUrl(): string {
        const redirect = sessionStorage.getItem(this.sessionStorageSignFailRedirectKey);

        return !redirect ? null : decodeURIComponent(redirect);
    }

    getRequest<T>(): T {
        const requestJson = sessionStorage.getItem(this.sessionStorageRequestKey);

        if (!requestJson) {
            return null;
        }

        const request = JSON.parse(requestJson);

        return request;
    }

    clearLoginRedirectUrls() {
        sessionStorage.removeItem(this.sessionStorageLoginSuccessRedirectKey);
        sessionStorage.removeItem(this.sessionStorageLoginFailRedirectKey);
    }

    clearSigningRedirectUrls() {
        sessionStorage.removeItem(this.sessionStorageSignSuccessRedirectKey);
        sessionStorage.removeItem(this.sessionStorageSignFailRedirectKey);
    }

    login(successRedirectUrl: string, failRedirectUrl: string, requireSsn: boolean = false): Observable<ILoginResponse> {
        this.saveSuccessLoginRedirectUrl(successRedirectUrl);
        this.saveFailLoginRedirectUrl(failRedirectUrl);

        const mitIdLandingPage = window.location.origin + this.navigationService.getUrl('mitid-landing');

        const url = `${this.apiUrl}/user-identity/login?redirectUri=${mitIdLandingPage}&requireSsn=${requireSsn}&api-version=2.0`;
        return this.http.get<ILoginResponse>(url);
    }

    initBasketSigning(basketId: string, successRedirectUrl: string, failRedirectUrl: string): string {
        this.saveSuccessSignRedirectUrl(successRedirectUrl);
        this.saveFailSignRedirectUrl(failRedirectUrl);

        const mitIdSigningLandingPage = window.location.origin + this.navigationService.getUrl('mitid-sign-landing');

        const url = `${this.baxUrl}/signing/oidc?basketId=${basketId}&returnUrl=${mitIdSigningLandingPage}`;

        return url;
    }

    initSigning<T>(signingData: SigningDataExtended, successRedirectUrl: string, failRedirectUrl: string, request: T): string {
        this.saveSuccessSignRedirectUrl(successRedirectUrl);
        this.saveFailSignRedirectUrl(failRedirectUrl);
        this.saveRequest(request);

        return signingData.uri;
    }
}
