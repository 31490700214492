import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class QueryParametersService {
    getQueryVariable(variable: string): string {
        const query = window.location.search.substring(1);
        const vars = query.split('&');
        for (const v of vars) {
            const pair = v.split('=');
            if (pair[0] === variable) {
                return pair[1];
            }
        }
        return null;
    }

    removeQueryVariable(key, sourceUrl) {
        let result = sourceUrl.split('?')[0];
        let paramsArray = [];
        const queryString = sourceUrl.indexOf('?') !== -1 ? sourceUrl.split('?')[1] : '';

        if (queryString) {
            paramsArray = queryString.split('&');

            for (let i = paramsArray.length - 1; i >= 0; i -= 1) {
                const param = paramsArray[i].split('=')[0];
                if (param === key) {
                    paramsArray.splice(i, 1);
                }
            }

            if (paramsArray.length) {
                result = result + '?' + paramsArray.join('&');
            }
        }
        return result;
    }
}
