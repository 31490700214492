<div id="privacycookie">
    <div class="full-width-image-section privacy-top">
        <div class="arrow-up-grey">
            <next-topbar></next-topbar>
            <next-menu></next-menu>

            <div class="container white">
                <div class="row">
                    <div class="col-sm-12 text-center">
                        <h1 class="header-title">{{ 'PAGE.PRIVACY_COOKIE.TITLE' | translate }}</h1>
                        <p class="header-text">{{ 'PAGE.PRIVACY_COOKIE.SUB_TITLE' | translate }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="privacycookie-container">
        <div class="container">
            <div class="row text-center">
                <div class="col-xs-12 col-sm-6">
                    <div class="privacy active" [ngClass]="{ active: activeTab === 'privacy' }" (click)="setTab('privacy')">
                        <i class="nf nf-security-icon"></i>
                        <br />
                        <span>{{ 'PAGE.PRIVACY_COOKIE.PRIVACY' | translate }}</span>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-6">
                    <div class="cookie" [ngClass]="{ active: activeTab === 'cookie' }" (click)="setTab('cookie')">
                        <i class="nf nf-cookie-icon"></i>
                        <br />
                        <span>{{ 'PAGE.PRIVACY_COOKIE.COOKIES' | translate }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="overview-container">
        <div *ngIf="activeTab === 'privacy'">
            <div class="container">
                <div class="row">
                    <div class="col-xs-12" [innerHTML]="contex?.content | safehtml"></div>
                </div>
            </div>
        </div>
        <div *ngIf="activeTab === 'cookie'">
            <div class="container cookie-content">
                <div class="row">
                    <div class="col-xs-12">
                        <h1>{{ 'PAGE.PRIVACY_COOKIE.COOKIES' | translate }}</h1>
                        <div id="CookieDeclarationWrapper"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <next-footer></next-footer>
</div>
