import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NemIdLoginComponent } from './components/nem-id-login/nem-id-login.component';
import { MitIdLoginComponent } from './components/mit-id-login/mit-id-login.component';
import { FormsModule } from '@angular/forms';
import { NemIdService } from 'app/utils/nemid/nemid.service';
import { NgxMaskModule } from 'ngx-mask';
import { MitIdService } from 'app/utils/mitId/mit-id.service';
import { MitIdLandingComponent } from './components/mit-id-landing/mit-id-landing.component';
import { PageLoaderModule } from '../pageloader/pageloader.module';

@NgModule({
    imports: [FormsModule, CommonModule, TranslateModule, NgxMaskModule.forRoot(), PageLoaderModule],
    declarations: [NemIdLoginComponent, MitIdLoginComponent, MitIdLandingComponent],
    exports: [NemIdLoginComponent, MitIdLoginComponent],
    providers: [NemIdService, MitIdService],
})
export class LoginModule {}
