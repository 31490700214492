import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomTooltipComponent } from './components/customtooltip/customtooltip.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip'; //

@NgModule({
    declarations: [CustomTooltipComponent],
    imports: [CommonModule, TooltipModule],
    exports: [CustomTooltipComponent],
})
export class CustomTooltipModule {}
