import { Component } from '@angular/core';
import { NavigationService } from 'app/utils/navigation/navigation.service';
import { MetaTagsHelper } from 'app/utils/metaTags/metaTagsHelper';

@Component({
    moduleId: module.id,
    selector: 'productinformation',
    templateUrl: './productinformation.component.html',
    styleUrls: ['./productinformation.component.less', '../../../../../styles/app/covers.less'],
})
export class ProductInformationComponent {
    constructor(public navigationService: NavigationService, private metaTagsHelper: MetaTagsHelper) {
        this.metaTagsHelper.setTitleAndMetaTags('META.PRODUCT_INFORMATION');
    }
}
