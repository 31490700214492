import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AppConfigService } from 'app/utils/appconfig/app-config.service';
import { HttpClient } from '@angular/common/http';
import { OrderPaymentCollectorDetails } from 'next-typescript-api';
import { ObservableHelper } from 'app/utils/observableHelper/observableHelper';

@Injectable()
export class PaymentCollectorService {
    apiUrl: string;

    constructor(private config: AppConfigService, private http: HttpClient, private observableHelper: ObservableHelper) {
        this.apiUrl = this.config.appConfig.apiHost;
    }

    // referenceType:
    // NotSelected = 0,
    // Basket = 1,
    // Customer = 2
    // - set referenceId accordingly

    getBasketPaymentCollectors(basketid: string, acceptUrl: string, declineUrl: string): Observable<OrderPaymentCollectorDetails[]> {
        if (!basketid) {
            return this.observableHelper.CreateFailedObservableAndLogStackTrace('Missing basket ID');
        }

        const url = `${this.apiUrl}/paymentcollectors/subscription?referenceType=1&referenceId=${basketid}&acceptUrl=${acceptUrl}&declineUrl=${declineUrl}&api-version=2.0`;
        return this.http.get<OrderPaymentCollectorDetails[]>(url);
    }

    getCustomerPaymentCollectors(customerId: string, acceptUrl: string, declineUrl: string): Observable<OrderPaymentCollectorDetails[]> {
        if (!customerId) {
            return this.observableHelper.CreateFailedObservableAndLogStackTrace('Missing customer number');
        }

        const url = `${this.apiUrl}/paymentcollectors/subscription?referenceType=2&referenceId=${customerId}&acceptUrl=${acceptUrl}&declineUrl=${declineUrl}&api-version=2.0`;
        return this.http.get<OrderPaymentCollectorDetails[]>(url);
    }

    getManuelPaymentCollectors(collectionId: string, secure: string, acceptUrl: string, declineUrl: string): Observable<OrderPaymentCollectorDetails[]> {
        const url = `${this.apiUrl}/paymentcollectors/manuel?collectionId=${collectionId}&secure=${secure}&acceptUrl=${acceptUrl}&declineUrl=${declineUrl}&api-version=2.0`;
        return this.http.get<OrderPaymentCollectorDetails[]>(url);
    }
}
