<div id="answerquestion">
    <div class="full-width-image-section contact-top">
        <div [class.arrow-up]="result && result.panels.length >= 0" [class.arrow-up-grey]="!result">
            <next-topbar></next-topbar>
            <next-menu></next-menu>

            <div class="container white">
                <div class="row">
                    <div class="col-sm-12 text-center">
                        <h1>{{ 'SHARED.MENU.FAQ' | translate }}</h1>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 text-center search">
                        <input [(ngModel)]="searchValue" (keyup.enter)="updateSearchResult(1)" type="text" [placeholder]="'PAGE.ANSWER_QUESTION.SEARCH_PLACEHOLDER' | translate" />
                        <button (click)="updateSearchResult(1)" [class.loading]="loading" [disabled]="!searchValue || searchValue.length < 2 || loading" class="btn search-btn">
                            <span>{{ 'PAGE.ANSWER_QUESTION.SEARCH' | translate }}</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container">
        <h2 class="text-center" *ngIf="result?.panels.length === 0">{{ 'PAGE.ANSWER_QUESTION.NO_RESULTS' | translate }}</h2>
        <h2 class="text-center" *ngIf="result?.panels.length > 0">{{ 'PAGE.ANSWER_QUESTION.RESULTS' | translate }}</h2>
        <div class="result-container" *ngFor="let value of result?.panels">
            <h4 [innerHTML]="value.panelTypeName"></h4>
            <b>: </b>
            <h4 [innerHTML]="value.subject"></h4>
            <p [innerHTML]="value.content | safehtml"></p>
            <div class="divider"></div>
        </div>
        <div class="text-center">
            <ul class="pagination" *ngIf="!loading && result?.numberOfPages > 0">
                <li>
                    <a (click)="previousPage()"><i class="nf nf-chevron-left-icon-v3"></i></a>
                </li>
                <li *ngFor="let n of paginationnumbers" [class.active]="n === pageno">
                    <a *ngIf="n !== pageno" (click)="updateSearchResult(n)">{{ n }}</a>
                    <a *ngIf="n === pageno">{{ n }}</a>
                </li>
                <li>
                    <a (click)="nextPage()"><i class="nf nf-chevron-right-icon-v3"></i></a>
                </li>
            </ul>
        </div>
    </div>

    <div class="bg-gray padding-30-0-50">
        <next-faq [category]="'FAQ'" allowSetUrl="true"></next-faq>
    </div>

    <next-footer></next-footer>
</div>
