import { CustomerService } from 'app/selfcare/personal/customer.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { VerifyEmailInput } from 'next-typescript-api';
import { NavigationService } from 'app/utils/navigation/navigation.service';

@Component({
    selector: 'app-verifyemail',
    templateUrl: './verifyemail.component.html',
    styleUrls: ['./verifyemail.component.less'],
})
export class VerifyemailComponent implements OnInit, OnDestroy {
    loading = true;
    success = false;
    tokenExpiredOrNotFound = false;
    invalidVerifyData = false;
    generalError = false;
    alreadyVerified = false;
    conflicts = false;

    subscriptions: Subscription[] = [];

    constructor(private route: ActivatedRoute, private customerService: CustomerService, public navigationService: NavigationService) {}

    ngOnInit() {
        this.subscriptions.push(
            this.route.params.subscribe((params) => {
                const input: VerifyEmailInput = {};
                input.verifyToken = params['token'];
                const customerId = params['customerno'];
                this.subscriptions.push(
                    this.customerService.verifyEmail(customerId, input).subscribe(
                        (resp) => {
                            this.success = resp;
                            this.loading = false;
                        },
                        (err) => {
                            this.loading = false;
                            if (err.status === 404) {
                                this.tokenExpiredOrNotFound = true;
                                return;
                            }
                            if (err.status === 400 && err.error.message === 'Invalid verify data') {
                                this.invalidVerifyData = true;
                                return;
                            }
                            if (err.status === 400 && err.error.message === 'Email has already been verified') {
                                this.alreadyVerified = true;
                                return;
                            }

                            if (err.status === 403) {
                                this.invalidVerifyData = true;
                                return;
                            }

                            if (err.status === 409) {
                                this.conflicts = true;
                                return;
                            }

                            this.generalError = true;
                        }
                    )
                );
            })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }
}
