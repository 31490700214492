import { Component, TemplateRef } from '@angular/core';
import { MetaTagsHelper } from 'app/utils/metaTags/metaTagsHelper';
import { Router } from '@angular/router';
import { NavigationService } from 'app/utils/navigation/navigation.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AppConfigService } from 'app/utils/appconfig/app-config.service';

@Component({
    moduleId: module.id,
    selector: 'reportdamage',
    templateUrl: './reportdamage.component.html',
    styleUrls: ['./reportdamage.component.less'],
})
export class ReportDamageComponent {
    bsModalRef: BsModalRef;
    redirectUrl: string;
    mitIdRedirectSuccessUrl: string;
    mitIdRedirectFailUrl: string;
    useMitId: boolean;

    constructor(
        private router: Router,
        private metaTagsHelper: MetaTagsHelper,
        private navService: NavigationService,
        private modalService: BsModalService,
        private appConfigService: AppConfigService
    ) {
        this.metaTagsHelper.setTitleAndMetaTags('META.REPORT_DAMAGE');
        this.useMitId = this.appConfigService.appConfig.useMitId;
        this.mitIdRedirectSuccessUrl = this.navService.getUrl('selfcare-anmeldskade');
        this.mitIdRedirectFailUrl = this.navService.getUrl('reportdamage');
    }

    nemIdLoginComplete() {
        this.router.navigateByUrl(this.navService.getUrl('selfcare-anmeldskade'));
    }

    openModal(template: TemplateRef<any>) {
        this.bsModalRef = this.modalService.show(template);
    }
}
