import { Component, ViewEncapsulation, Input, OnInit, OnDestroy } from '@angular/core';
import { MetaTagsHelper } from 'app/utils/metaTags/metaTagsHelper';
import { NavigationService } from 'app/utils/navigation/navigation.service';
import { ContexPanelService } from 'app/utils/contex/panel/contex.panel.service';
import { Subscription } from 'rxjs';
import { PagedPanelSearchResult } from 'app/utils/contex/panel/pagedPanelSearchResult';
import { TranslationService } from 'app/utils/translation/translation.service';

@Component({
    moduleId: module.id,
    selector: 'answer-question',
    templateUrl: './answer-question.component.html',
    styleUrls: ['./answer-question.component.less'],
    encapsulation: ViewEncapsulation.None, // allows us to style contex content
})
export class AnswerQuestionComponent implements OnInit, OnDestroy {
    searchAllowed = false;
    numberOfButtons = 5;
    pageno: number;
    pagecount: number;
    paginationnumbers: number[] = [];
    loading = false;

    subscriptions: Subscription[] = [];

    panelTypeCode = '';
    panelGroupCode = '';
    searchValue = '';
    result: PagedPanelSearchResult;

    constructor(
        public navigationService: NavigationService,
        private metaTagsHelper: MetaTagsHelper,
        private contexPanelService: ContexPanelService,
        private translationService: TranslationService
    ) {
        this.metaTagsHelper.setTitleAndMetaTags('META.ANSWER_QUESTION');

        this.translationService.getLanguageChangeEmitter().subscribe(() => {
            this.updateSearchResult(this.pageno);
        });
    }

    ngOnInit() {}

    updateSearchResult(pageno: number) {
        if (this.searchValue.length < 2) {
            return;
        }

        this.loading = true;
        this.subscriptions.push(
            this.contexPanelService.getSearchResult(this.panelTypeCode, this.panelGroupCode, this.searchValue, pageno - 1, 5).subscribe((x) => {
                this.result = x;

                this.pageno = this.result.currentPage + 1;
                this.pagecount = this.result.numberOfPages;

                let place = this.pageno % this.numberOfButtons; // 13 % 5 = 3
                if (place === 0) {
                    place = this.numberOfButtons;
                }

                this.paginationnumbers = [];
                const start = this.pageno - place + 1; // start will be 1, 6, 11, 16 and so on if numberofbuttons is 5

                for (let i = start; i < start + this.numberOfButtons; i++) {
                    if (i <= this.pagecount) {
                        this.paginationnumbers.push(i);
                    }
                }

                this.loading = false;
            })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    nextPage() {
        if (this.pageno < this.pagecount) {
            this.pageno = this.pageno + 1;
            this.updateSearchResult(this.pageno);
        }
    }

    previousPage() {
        if (this.pageno > 1) {
            this.pageno = this.pageno - 1;
            this.updateSearchResult(this.pageno);
        }
    }
}
