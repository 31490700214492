import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageLoaderComponent } from './components/pageloader/pageloader.component';

@NgModule({
    imports: [CommonModule, RouterModule],
    declarations: [PageLoaderComponent],
    exports: [PageLoaderComponent],
})
export class PageLoaderModule {}
