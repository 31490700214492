import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { FaqComponent } from './components/faq/faq.component';
import { ContexPanelService } from 'app/utils/contex/panel/contex.panel.service';
import { FaqArticleComponent } from './components/faqarticle/faqarticle.component';
import { RouterModule } from '@angular/router';
import { CustomPipeModule } from 'app/class/pipe/custompipe.module';

@NgModule({
    imports: [FormsModule, RouterModule, AccordionModule.forRoot(), CommonModule, TranslateModule, CustomPipeModule],
    declarations: [FaqComponent, FaqArticleComponent],
    exports: [FaqComponent, FaqArticleComponent],
    providers: [ContexPanelService],
})
export class FaqModule {}
