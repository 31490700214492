<div id="jobinnext">
    <div class="full-width-image-section jobinnext-top">
        <div class="arrow-up">
            <next-topbar></next-topbar>
            <next-menu></next-menu>

            <div class="container white">
                <div class="row">
                    <div class="col-sm-12 text-center">
                        <h1 class="jobinnext-h1">{{ 'SHARED.MENU.JOB' | translate }}</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container padding-40-20 page-content">
        <div class="row text-center">
            <h1 *ngIf="vacancies && vacancies.length > 0">{{ 'PAGE.JOBINNEXT.VACANCIES' | translate }}</h1>
            <div *ngIf="vacancies && vacancies.length === 0">
                <h1>{{ 'PAGE.JOBINNEXT.NO_VACANT_POSITIONS' | translate }}</h1>

                <p [innerHTML]="'PAGE.JOBINNEXT.SEND_MAIL' | translate"></p>
            </div>
        </div>
        <br />
        <br />
        <div class="row jobs">
            <div class="job-container" *ngFor="let vacancy of vacancies">
                <div class="green-line"></div>
                <div class="job-avatar">
                    <div class="job-avatar-image"></div>
                </div>
                <div class="job-title">
                    {{ vacancy.headline }}
                    <a class="read-more-button" [routerLink]="'/job-i-next/' + vacancy.url">{{ 'SHARED.READ_MORE' | translate }}</a>
                </div>
            </div>
        </div>
    </div>

    <next-footer></next-footer>
</div>
