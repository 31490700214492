import { Injectable, EventEmitter } from '@angular/core';

@Injectable()
export class DeviceQueriesHelper {
    public isMobileChanged: EventEmitter<boolean> = new EventEmitter();
    private isMobileQuery = window.matchMedia('(max-width: 767px)');

    constructor() {
        if (this.isMobileQuery.addEventListener) {
            this.isMobileQuery.addEventListener('change', this.isMobileChangeHandler.bind(this));
        } else {
            // Fix for IE
            // eslint-disable-next-line import/no-deprecated
            this.isMobileQuery.addListener(this.isMobileChangeHandler.bind(this));
        }
    }

    public isMobileDevice(): boolean {
        return this.isMobileQuery.matches;
    }

    private isMobileChangeHandler(mq: any) {
        this.isMobileChanged.next(mq.matches);
    }
}
