import { Component, Input, OnInit } from '@angular/core';
import { NavigationService } from 'app/utils/navigation/navigation.service';
import { ContexPanelService } from 'app/utils/contex/panel/contex.panel.service';
import { PanelGroupDetails } from 'app/utils/contex/panel/panelGroupDetails';
import { TranslationService } from 'app/utils/translation/translation.service';

@Component({
    moduleId: module.id,
    selector: 'next-faqarticle',
    templateUrl: './faqarticle.component.html',
    styleUrls: ['./faqarticle.component.less'],
})
export class FaqArticleComponent implements OnInit {
    @Input() category: string;
    contex: PanelGroupDetails[];

    constructor(public navigationService: NavigationService, private contexPanelService: ContexPanelService, private translationService: TranslationService) {}

    ngOnInit() {
        this.updatePanelGroupDetails();

        this.translationService.getLanguageChangeEmitter().subscribe(() => {
            this.updatePanelGroupDetails();
        });
    }

    updatePanelGroupDetails() {
        this.contexPanelService.getPanelTypeGroups(this.category).subscribe(x => {
            this.contex = x;
        });
    }
}
