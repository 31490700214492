import { Injectable } from '@angular/core';
import { UserAgentService } from '../useragent/user-agent.service';

@Injectable()
export class ChatConfigurator {
    initAttempts = 0;
    maxAttempts = 20;
    retryWaitMs = 100;

    constructor() {
        if (UserAgentService.isIeLowerThan11()) {
            return;
        }

        this.configureZendeskChat();
    }

    private configureZendeskChat() {
        // Retry {maxAttempts} times. Sometimes zopim is not loaded initially...
        this.initAttempts += 1;

        if (this.initAttempts < this.maxAttempts && !window.$zopim) {
            setTimeout(this.configureZendeskChat.bind(this), this.retryWaitMs);
            return;
        }

        if (this.initAttempts >= this.maxAttempts) {
            return;
        }

        window.$zopim.livechat.setOnStatus(function (status) {
            if (window.innerWidth < 991) {
                // small device as defined in functions.less
                window.$zopim.livechat.button.show();
            } else {
                if (status === 'online') {
                    window.$zopim.livechat.badge.show();
                } else {
                    window.$zopim.livechat.button.show();
                }
            }
        });
    }
}
