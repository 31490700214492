import { Component, Input, OnInit } from '@angular/core';
import { CoverInfoSettings } from 'app/insurance/components/cover-info/CoverInfoSettings';
import { NavigationService } from 'app/utils/navigation/navigation.service';

@Component({
    selector: 'cover-thumbnail',
    templateUrl: './cover-thumbnail.component.html',
    styleUrls: ['./cover-thumbnail.component.less', '../../../../../styles/app/covers.less'],
})
export class CoverThumbnailComponent implements OnInit {
    @Input() cover: CoverInfoSettings;
    constructor(public navigationService: NavigationService) {}

    ngOnInit(): void {}
}
