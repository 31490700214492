import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { caravanRoutes } from './caravan-routes';

@NgModule({
    imports: [RouterModule.forChild(caravanRoutes)],
    exports: [RouterModule],
})
export class CaravanRoutingModule {}
