import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from 'app/utils/appconfig/app-config.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RegisterRequestInput } from 'next-typescript-api';
import { Calltime } from 'app/calculate/summary/models/calltime';

// ContactRequestController
@Injectable()
export class ContactRequestService {
    private apiUrl: string;

    constructor(private http: HttpClient, private config: AppConfigService, private translateService: TranslateService) {
        this.apiUrl = this.config.appConfig.apiHost;
    }

    isContactRequestAllowed(basketId?: string, reasonId: ContactReason = undefined): Observable<boolean> {
        const version = 'api-version=2.0';
        let url = `${this.apiUrl}/contactrequests/allowed`;
        if (basketId && basketId.length > 0) {
            url += `?basketId=${basketId}&${version}`;
        } else {
            url += `?${version}`;
        }

        if (reasonId) {
            url += `&reasonId=${reasonId}`;
        }

        return this.http.get<boolean>(url);
    }

    registerContactRequest(data: RegisterRequestInput): Observable<boolean> {
        const url = `${this.apiUrl}/contactrequests?api-version=2.0`;
        return this.http.post(url, data, { observe: 'response' }).pipe(
            map((resp) => {
                const statusCode = resp.status;

                if (statusCode === 200 || statusCode === 202) {
                    return true;
                }

                return false;
            })
        );
    }

    getCallTimeOptions(): Calltime[] {
        const calltimes: Calltime[] = [];
        calltimes.push({
            text: this.translateService.instant('SHARED.TOPBAR.COMPONENTS.CALLME_MODAL.SELECT_TIME'),
            value: 'select',
        });
        calltimes.push({
            text: this.translateService.instant('CALCULATE.SUMMARY.COMPONENTS.PHONE_CALL_BOX.CALL_TIME_3'),
            value: '3',
        });
        calltimes.push({
            text: this.translateService.instant('CALCULATE.SUMMARY.COMPONENTS.PHONE_CALL_BOX.CALL_TIME_1'),
            value: '1',
        });
        calltimes.push({
            text: this.translateService.instant('CALCULATE.SUMMARY.COMPONENTS.PHONE_CALL_BOX.CALL_TIME_2'),
            value: '2',
        });

        return calltimes;
    }
}

export enum ContactReason {
    Sales = '1',
    CustomerService = '2',
    CallMe = '3',
    CallMeTermination = '4',
}
