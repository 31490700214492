import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { houseRoutes } from './house-routes';

@NgModule({
    imports: [RouterModule.forChild(houseRoutes)],
    exports: [RouterModule],
})
export class HouseRoutingModule {}
