import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'some',
})
export class SomePipe implements PipeTransform {
    transform(inputArray: any[], matchValue: any, matchProp?: string): boolean {
        return matchProp ? inputArray.some((item) => item[matchProp] === matchValue) : inputArray.some((item) => item === matchValue);
    }
}
