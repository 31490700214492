<div class="modal-mask" id="callme-modal">
    <div class="modal-wrapper">
        <div class="modal-container" [class.only-show-phone-number]="!showCallMeBack">
            <div (click)="closeModal()" class="close-icon pull-right hand">
                <i class="nf nf-close-icon-outlined"></i>
            </div>
            <div class="circle">
                <div class="circle-icon-wrapper"><i class="nf nf-phone-icon"></i></div>
            </div>

            <div *ngIf="!createRequestSuccess">
                <div *ngIf="showPhoneNumberOnMobile" class="visible-xs">
                    <h5>{{ 'SHARED.TOPBAR.COMPONENTS.CALLME_MODAL.GIVE_US_A_CALL' | translate }}</h5>
                    <a href="tel:+4570407040" class="phone-no">70 40 70 40</a>
                    <div *ngIf="showCallMeBack">
                        <div class="separator"></div>
                        <div class="callback-instead">{{ 'SHARED.TOPBAR.COMPONENTS.CALLME_MODAL.CALL_YOU_BACK' | translate }}</div>
                    </div>
                </div>

                <div *ngIf="showCallMeBack">
                    <ng-container *ngIf="callmeExperience !== 'CALLMETERMINATION'">
                        <h5>{{ 'SHARED.TOPBAR.COMPONENTS.CALLME_MODAL.TITLE' | translate }}</h5>
                    </ng-container>

                    <ng-container *ngIf="callmeExperience === 'CALLMETERMINATION'">
                        <h5>{{ 'SHARED.TOPBAR.COMPONENTS.CALLME_MODAL.TITLE_TERMINATION' | translate }}</h5>
                    </ng-container>

                    <div class="left-content">
                        <ng-container *ngIf="callmeExperience === 'DEFAULT'">
                            <div>{{ 'SHARED.TOPBAR.COMPONENTS.CALLME_MODAL.WHO_SHOULD_CALL_YOU' | translate }}</div>
                            <div class="green-radio-input big">
                                <div *ngFor="let reason of reasons">
                                    <input type="radio" [(ngModel)]="selectedReason" [value]="reason" [id]="'reason_' + reason.id" (ngModelChange)="validate()" />
                                    <label [for]="'reason_' + reason.id">{{ translateReasonName(reason) }}</label>
                                    <br />
                                </div>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="callmeExperience === 'CALLME'">
                            <div>{{ 'SHARED.TOPBAR.COMPONENTS.CALLME_MODAL.WHICH_NUMBER_TO_CONTACT' | translate }}</div>
                        </ng-container>

                        <ng-container *ngIf="callmeExperience === 'CALLMETERMINATION'">
                            <div>{{ 'SHARED.TOPBAR.COMPONENTS.CALLME_MODAL.WHICH_NUMBER_TO_CONTACT_TERMINATION' | translate }}</div>
                        </ng-container>

                        <div class="field-input full-width" *ngIf="!callmeExperience.includes('CALLME')">
                            <input
                                type="text"
                                id="nameinput"
                                name="nameinput"
                                required
                                [class.missing-input]="!name || name.length === 0"
                                [(ngModel)]="name"
                                (ngModelChange)="validate()"
                                (keyup.enter)="sendContactRequest()"
                            />
                            <span class="floating-label">{{ 'SHARED.TOPBAR.COMPONENTS.CALLME_MODAL.NAME' | translate | uppercase }}</span>
                        </div>

                        <div class="field-input full-width">
                            <input
                                type="text"
                                id="phoneinput"
                                name="phoneinput"
                                required
                                OnlyNumber="true"
                                maxlength="8"
                                [class.missing-input]="!phone || phone.length !== 8"
                                [(ngModel)]="phone"
                                (ngModelChange)="validate()"
                                (keyup.enter)="sendContactRequest()"
                            />
                            <span class="floating-label">{{ 'SHARED.TOPBAR.COMPONENTS.CALLME_MODAL.PHONE' | translate | uppercase }}</span>
                        </div>
                        <br />
                        <!-- 1: Sales-->
                        <ng-container *ngIf="selectedReason?.id === '1' && !callmeExperience.includes('CALLME')">
                            <div class="best-time">{{ 'SHARED.TOPBAR.COMPONENTS.CALLME_MODAL.BEST_TIME' | translate }}</div>

                            <!-- mat select currently not working on fixed container -->
                            <select [(ngModel)]="calltime" [class.missing-input]="!calltime" (ngModelChange)="validate()">
                                <option *ngFor="let obj of calltimes" [ngValue]="obj">
                                    {{ obj.text }}
                                </option>
                            </select>
                        </ng-container>
                        <!-- 2: CustomerService-->
                        <ng-container *ngIf="selectedReason?.id === '2' && !callmeExperience.includes('CALLME')">
                            <br />
                            <p>{{ 'SHARED.TOPBAR.COMPONENTS.CALLME_MODAL.CALL_ASAP_TEXT' | translate }}</p>
                        </ng-container>
                    </div>

                    <button [disabled]="!okToSend || sending" class="btn btn-success" type="button" (click)="sendContactRequest()" [class.loading]="sending">
                        <span>{{ 'SHARED.TOPBAR.COMPONENTS.CALLME_MODAL.TITLE' | translate }}</span>
                    </button>

                    <div class="msg msg-danger topspacer" *ngIf="errorMessage && errorMessage.length > 0">
                        {{ errorMessage }}
                    </div>

                    <ng-container *ngIf="callmeExperience === 'DEFAULT'">
                        <div class="separator"></div>

                        <div *ngIf="isChatOpen">
                            <div>{{ 'SHARED.TOPBAR.COMPONENTS.CALLME_MODAL.CHAT_INSTEAD' | translate }}</div>
                            <div (click)="openChat()" class="btn btn-success alternative-button">
                                <span>{{ 'SHARED.TOPBAR.START_CHAT' | translate }}</span>
                                <i class="nf nf-chat-icon-v3"></i>
                            </div>
                        </div>

                        <div *ngIf="!isChatOpen">
                            <div>{{ 'SHARED.TOPBAR.COMPONENTS.CALLME_MODAL.MESSAGE_INSTEAD' | translate }}</div>
                            <div [routerLink]="navigationService.getUrl('contact')" class="btn btn-success alternative-button">
                                <span>{{ 'SHARED.TOPBAR.CONTACT_US' | translate }}</span>
                                <i class="nf nf-chat-icon-v3"></i>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div *ngIf="createRequestSuccess">
                <div class="thanks">{{ 'SHARED.TOPBAR.COMPONENTS.CALLME_MODAL.THANKS' | translate }}</div>
                <div class="soon">{{ 'SHARED.TOPBAR.COMPONENTS.CALLME_MODAL.SOON' | translate }}</div>

                <button class="btn btn-success" type="button" (click)="closeModal()">
                    <span>{{ 'SHARED.OK' | translate }}</span>
                </button>
            </div>
        </div>
    </div>
</div>
