<div class="full-width-image-section front-top">
    <next-topbar></next-topbar>
    <next-menu></next-menu>

    <div class="container">
        <div class="col-xs-12" id="driverapp-front-container">
            <h1 class="white">{{ 'FRONTPAGE.TITLE' | translate }}</h1>
        </div>
    </div>

    <a [routerLink]="navigationService.getUrl('jobinnext')" *ngIf="vacantPositionsText" class="vacant-positions">
        <div class="content" [innerHTML]="vacantPositionsText"></div>
        <div class="readmore">{{ 'SHARED.READ_MORE' | translate }}</div>
    </a>

    <div class="container">
        <div class="whitebox">
            <h5>{{ 'FRONTPAGE.START' | translate }}</h5>
            <a [routerLink]="calculateCarUrl" (click)="setLoadingProduct('car-btn')" class="btn btn-success calculate-btn" [class.loading]="loadingProduct === 'car-btn'">
                <span> <i class="nf nf-car-icon-v3"></i> {{ 'FRONTPAGE.CALCULATE' | translate }}</span>
            </a>
        </div>
    </div>

    <div class="container hidden-xs">
        <br /><br /><br /><br />
        <trustpilot-line></trustpilot-line>
    </div>

    <div class="product-menu-container">
        <product-menu></product-menu>
    </div>
</div>

<div class="container frontpage-video-container">
    <div class="text-center">
        <h2>{{ 'FRONTPAGE.SEE_HOW_EASY_TITLE' | translate }}</h2>
        <h4>{{ 'FRONTPAGE.SEE_HOW_EASY_TEXT' | translate }}</h4>
        <div class="img-responsive video white" (click)="openModal(frontpageModal)">
            <div class="frontpage-play-video-icon">
                <i class="nf-play-video-icon-v3"></i>
            </div>
        </div>
        <div class="video-container">
            <ng-template #frontpageModal>
                <next-videomodal [videoid]="'MUpCsSZ3Fpg'" [bsModalRef]="bsModalRef"></next-videomodal>
            </ng-template>
        </div>
    </div>
</div>

<div class="bg-gray">
    <div class="container container-padding-20-60">
        <div class="row">
            <div class="col-xs-12 col-sm-6">
                <div class="row infobox">
                    <div class="col-xs-3 col-md-2">
                        <i class="nf nf-credit-cards-icon-v3"></i>
                    </div>
                    <div class="col-xs-9 col-md-10">
                        <h4>{{ 'FRONTPAGE.MONTHLY_PAYMENT_TITLE' | translate }}</h4>
                        <p>{{ 'FRONTPAGE.MONTHLY_PAYMENT_TEXT' | translate }}</p>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 col-sm-6">
                <div class="row infobox">
                    <div class="col-xs-3 col-md-2">
                        <i class="nf nf-light-lock-icon-v3"></i>
                    </div>
                    <div class="col-xs-9 col-md-10">
                        <h4>{{ 'FRONTPAGE.NO_BINDING_TITLE' | translate }}</h4>
                        <p>{{ 'FRONTPAGE.NO_BINDING_TEXT' | translate }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12 col-sm-6">
                <div class="row infobox">
                    <div class="col-xs-3 col-md-2">
                        <img
                            height="51"
                            width="48"
                            class="mobilepay"
                            src="https://static.next.dk/website/images/pages/frontpage/mobilepay.png"
                            srcset="https://static.next.dk/website/images/pages/frontpage/mobilepay@2x.png 2x"
                        />
                    </div>
                    <div class="col-xs-9 col-md-10">
                        <h4>{{ 'FRONTPAGE.MOBILEPAY_TITLE' | translate }}</h4>
                        <p>{{ 'FRONTPAGE.MOBILEPAY_TEXT' | translate }}</p>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 col-sm-6">
                <div class="row infobox">
                    <div class="col-xs-3 col-md-2">
                        <i class="nf nf-mouse-icon-v3"></i>
                    </div>
                    <div class="col-xs-9 col-md-10">
                        <h4>{{ 'FRONTPAGE.SELFCARE_TITLE' | translate }}</h4>
                        <p>{{ 'FRONTPAGE.SELFCARE_TEXT' | translate }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<next-footer></next-footer>
