import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { CoverTableComponent } from '../cover-table/cover-table.component';
import { CustomPipeModule } from 'app/class/pipe/custompipe.module';
import { ProductApiService } from 'app/utils/product/product-api.service';

@NgModule({
    declarations: [CoverTableComponent],
    exports: [CoverTableComponent],
    imports: [CommonModule, TranslateModule, CustomPipeModule],
    providers: [ProductApiService],
})
export class CoverTableModule {}
