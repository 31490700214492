import { InputModule } from 'app/shared/input/input.module';
import { CallmeModalComponent } from './components/callme-modal/callme-modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TopbarComponent } from './components/topbar/topbar.component';
import { NavigationService } from 'app/utils/navigation/navigation.service';
import { DeviceQueriesHelper } from 'app/utils/deviceQueries/deviceQueriesHelper';
import { LanguageSelectorModule } from '../language-selector/language-selector.module';
import { MatSelectModule } from '@angular/material/select';
import { ContactRequestReasonService } from 'app/utils/contactrequestreason/contactrequestreason.service';
@NgModule({
    imports: [RouterModule, CommonModule, FormsModule, TranslateModule, LanguageSelectorModule, InputModule, MatSelectModule],
    declarations: [TopbarComponent, CallmeModalComponent],
    exports: [TopbarComponent, CallmeModalComponent],
    providers: [NavigationService, DeviceQueriesHelper, ContactRequestReasonService],
})
export class TopbarModule {}
