import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationService } from 'app/utils/navigation/navigation.service';
import { Cookie } from 'ng2-cookies';
import { Subscription } from 'rxjs';

@Component({
    selector: 'landing',
    template: '',
})
export class LandingComponent implements OnInit, OnDestroy {
    basketId: string;

    subscriptions: Subscription[] = [];

    constructor(private router: Router, private route: ActivatedRoute, private navigationService: NavigationService) {}

    ngOnInit() {
        this.subscriptions.push(
            this.route.params.subscribe(params => {
                this.basketId = params['basketid'];
                Cookie.set('basketid', this.basketId, null, '/');

                const goto = this.navigationService.getUrl('summary');
                this.router.navigate([goto], { queryParams: { splash: 1 } });
            })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}
