import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CalculateService } from './calculate.service';
import { Observable } from 'rxjs';
import { BaxService } from 'app/utils/bax/bax.service';

@Injectable()
export class ProductResolver implements Resolve<any> {
    constructor(private calculateService: CalculateService, private baxService: BaxService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return this.calculateService.resolvePage(route.queryParams, route.url[0]);
    }
}
