<div class="full-width-image-section contact-top">
    <div class="arrow-up">
        <next-topbar></next-topbar>
        <next-menu></next-menu>

        <div class="container white">
            <div class="row">
                <div class="col-sm-12 text-center">
                    <h1>{{ 'PAGE.CONTACT.TITLE' | translate }}</h1>
                </div>
            </div>
            <div class="col-sm-4 col-sm-offset-4">
                <div class="icon-bg center">
                    <div class="icon-wrap">
                        <i class="nf nf-paperplane-icon-v3"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container">
    <div class="row">
        <div class="two-split-container no-sub">
            <div class="row">
                <div class="col-xs-12 col-sm-2 text-center">
                    <div class="icon-bg center">
                        <div class="icon-wrap">
                            <i class="nf nf-mail-contact-icon-v3"></i>
                        </div>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-10">
                    <h3>{{ 'PAGE.CONTACT.CONTACT_US_FORMULAR_TITLE' | translate }}</h3>
                    <p>
                        {{ 'PAGE.CONTACT.CONTACT_US_FORMULAR_TEXT' | translate }}
                    </p>

                    <form (ngSubmit)="createMailTicket()" #contactForm="ngForm" *ngIf="!createTicketSuccess">
                        <input type="hidden" [(ngModel)]="newticket.googleCaptchaResponse" name="googlecaptcharesponse" required minlength="1" />

                        <div class="field-input">
                            <input type="text" required name="customername" [(ngModel)]="newticket.name" [class.missing-input]="newticket.name.length === 0" minlength="1" />
                            <span class="floating-label">{{ 'PAGE.CONTACT.NAME' | translate }}</span>
                        </div>

                        <div class="field-input">
                            <input type="text" required name="customeremail" [(ngModel)]="newticket.email" [class.missing-input]="newticket.email.length === 0" minlength="1" />
                            <span class="floating-label">{{ 'PAGE.CONTACT.EMAIL' | translate }}</span>
                        </div>

                        <div class="field-input">
                            <textarea required name="text" [(ngModel)]="newticket.text" [class.missing-input]="newticket.text.length === 0" minlength="1"></textarea>
                            <span class="floating-label">{{ 'PAGE.CONTACT.INPUT_MESSAGE' | translate }}</span>
                        </div>

                        <div class="row">
                            <div class="col-xs-12 col-sm-8 col-md-6">
                                <re-captcha
                                    (captchaResponse)="handleCaptchaResponse($event)"
                                    (captchaExpired)="handleCaptchaExpired()"
                                    site_key="6LemahEUAAAAAFuArA5IpiZgACo8bHgSBxnyetks"
                                    language="da"
                                ></re-captcha>
                            </div>
                            <div class="col-xs-12 col-sm-4 col-md-6">
                                <button type="submit" class="btn btn-success" [class.loading]="creatingTicket" [disabled]="!contactForm.form.valid || creatingTicket">
                                    <span>{{ 'PAGE.CONTACT.SEND' | translate }}</span>
                                </button>
                            </div>
                        </div>

                        <div class="row" *ngIf="createTicketError">
                            <div class="col-sm-12">
                                <div class="msg msg-danger">
                                    {{ 'PAGE.CONTACT.CREATE_TICKET_ERROR' | translate }}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="text-center message-send" *ngIf="createTicketSuccess">
                <h3>{{ 'PAGE.CONTACT.CREATE_TICKET_SUCCESS_TITLE' | translate }}</h3>
                <p>{{ 'PAGE.CONTACT.CREATE_TICKET_SUCCESS_TEXT' | translate }}</p>
            </div>
            <div class="border-bottom visible-xs"></div>
        </div>
    </div>
</div>

<div class="container">
    <div class="row">
        <div class="two-split-container no-sub">
            <div class="row">
                <div class="col-xs-12 col-sm-2 text-center">
                    <div class="icon-bg center">
                        <div class="icon-wrap">
                            <i class="nf nf-phone-icon"></i>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-4">
                    <h3>{{ 'PAGE.CONTACT.SALES_OPENING_HOURS' | translate }}</h3>
                    <table>
                        <tr>
                            <td class="text-bold week-day">{{ 'PAGE.CONTACT.MONDAY' | translate }}</td>
                            <td class="spacer"></td>
                            <td class="openhours">08:00 - 16:30</td>
                        </tr>
                        <tr>
                            <td class="text-bold week-day">{{ 'PAGE.CONTACT.TUESDAY' | translate }}</td>
                            <td class="spacer"></td>
                            <td class="openhours">08:00 - 16:30</td>
                        </tr>
                        <tr>
                            <td class="text-bold week-day">{{ 'PAGE.CONTACT.WEDNESDAY' | translate }}</td>
                            <td class="spacer"></td>
                            <td class="openhours">08:00 - 16:30</td>
                        </tr>
                        <tr>
                            <td class="text-bold week-day">{{ 'PAGE.CONTACT.THURSDAY' | translate }}</td>
                            <td class="spacer"></td>
                            <td class="openhours">08:00 - 16:30</td>
                        </tr>
                        <tr>
                            <td class="text-bold week-day">{{ 'PAGE.CONTACT.FRIDAY' | translate }}</td>
                            <td class="spacer"></td>
                            <td class="openhours">08:00 - 16:30</td>
                        </tr>
                        <tr>
                            <td class="text-bold week-day">{{ 'PAGE.CONTACT.SATURDAY' | translate }}</td>
                            <td class="spacer"></td>
                            <td class="openhours">{{ 'PAGE.CONTACT.CLOSED' | translate }}</td>
                        </tr>
                        <tr>
                            <td class="text-bold week-day">{{ 'PAGE.CONTACT.SUNDAY' | translate }}</td>
                            <td class="spacer"></td>
                            <td class="openhours">{{ 'PAGE.CONTACT.CLOSED' | translate }}</td>
                        </tr>
                        <tr>
                            <td class="text-bold week-day">{{ 'PAGE.CONTACT.HOLIDAYS' | translate }}</td>
                            <td class="spacer"></td>
                            <td class="openhours">{{ 'PAGE.CONTACT.CLOSED' | translate }}</td>
                        </tr>
                    </table>
                </div>
                <div class="col-xs-12 col-sm-6 hidden-xs">
                    <h3>{{ 'PAGE.CONTACT.ORDER_PHONE_CALL' | translate }}</h3>
                    <div class="row">
                        <div class="col-xs-12">
                            <p class="margin-0">
                                {{ 'PAGE.CONTACT.ORDER_PHONE_CALL_TEXT_1' | translate }} <a href="tel:+4570407040">70 40 70 40</a> {{ 'PAGE.CONTACT.ORDER_PHONE_CALL_TEXT_2' | translate }}
                            </p>
                            <button class="btn btn-success" (click)="showCallMeModal()">
                                <span>{{ 'PAGE.CONTACT.ORDER_PHONE_CALL' | translate }}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <br />

            <div class="row">
                <div class="col-xs-12 col-sm-2 text-center"></div>
                <div class="col-xs-12 col-sm-4">
                    <h3>{{ 'PAGE.CONTACT.CUSTOMER_SERVICE_OPENING_HOURS' | translate }}</h3>
                    <table>
                        <tr>
                            <td class="text-bold week-day">{{ 'PAGE.CONTACT.MONDAY' | translate }}</td>
                            <td class="spacer"></td>
                            <td class="openhours">08:00 - 16:00</td>
                        </tr>
                        <tr>
                            <td class="text-bold week-day">{{ 'PAGE.CONTACT.TUESDAY' | translate }}</td>
                            <td class="spacer"></td>
                            <td class="openhours">08:00 - 16:00</td>
                        </tr>
                        <tr>
                            <td class="text-bold week-day">{{ 'PAGE.CONTACT.WEDNESDAY' | translate }}</td>
                            <td class="spacer"></td>
                            <td class="openhours">08:00 - 16:00</td>
                        </tr>
                        <tr>
                            <td class="text-bold week-day">{{ 'PAGE.CONTACT.THURSDAY' | translate }}</td>
                            <td class="spacer"></td>
                            <td class="openhours">08:00 - 16:00</td>
                        </tr>
                        <tr>
                            <td class="text-bold week-day">{{ 'PAGE.CONTACT.FRIDAY' | translate }}</td>
                            <td class="spacer"></td>
                            <td class="openhours">08:00 - 16:00</td>
                        </tr>
                        <tr>
                            <td class="text-bold week-day">{{ 'PAGE.CONTACT.SATURDAY' | translate }}</td>
                            <td class="spacer"></td>
                            <td class="openhours">{{ 'PAGE.CONTACT.CLOSED' | translate }}</td>
                        </tr>
                        <tr>
                            <td class="text-bold week-day">{{ 'PAGE.CONTACT.SUNDAY' | translate }}</td>
                            <td class="spacer"></td>
                            <td class="openhours">{{ 'PAGE.CONTACT.CLOSED' | translate }}</td>
                        </tr>
                        <tr>
                            <td class="text-bold week-day">{{ 'PAGE.CONTACT.HOLIDAYS' | translate }}</td>
                            <td class="spacer"></td>
                            <td class="openhours">{{ 'PAGE.CONTACT.CLOSED' | translate }}</td>
                        </tr>
                    </table>
                </div>
                <div class="col-xs-12 col-sm-6 visible-xs">
                    <h3>{{ 'PAGE.CONTACT.ORDER_PHONE_CALL' | translate }}</h3>
                    <div class="row">
                        <div class="col-xs-12">
                            <p class="margin-0">
                                {{ 'PAGE.CONTACT.ORDER_PHONE_CALL_TEXT_1' | translate }} <a href="tel:+4570407040">70 40 70 40</a> {{ 'PAGE.CONTACT.ORDER_PHONE_CALL_TEXT_2' | translate }}
                            </p>
                            <button class="btn btn-success" (click)="showCallMeModal()">
                                <span>{{ 'PAGE.CONTACT.ORDER_PHONE_CALL' | translate }}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="border-bottom visible-xs"></div>
        </div>
    </div>
</div>

<div class="container">
    <div class="row">
        <div class="two-split-container no-sub">
            <div class="row">
                <div class="col-xs-12 col-sm-2 text-center">
                    <div class="icon-bg center">
                        <div class="icon-wrap">
                            <i class="nf nf-chat-contact-icon-v3"></i>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-4">
                    <h3>{{ 'PAGE.CONTACT.CHAT_OPENING_HOURS' | translate }}</h3>
                    <table>
                        <tr *ngFor="let day of chatopenhours7days">
                            <td class="text-bold week-day">{{ day.key }}</td>
                            <td class="spacer"></td>
                            <td class="openhours">{{ day.value }}</td>
                        </tr>
                    </table>
                </div>
                <div class="col-xs-12 col-sm-6">
                    <h3>{{ 'PAGE.CONTACT.CONTACT_US_CHAT' | translate }}</h3>
                    <div class="row">
                        <div class="col-xs-12">
                            <p class="margin-0">{{ chatopenhours }}</p>
                            <button *ngIf="ischatopen" class="btn btn-success" (click)="openChat()">
                                <span>{{ 'PAGE.CONTACT.START_CHAT' | translate }}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="border-bottom visible-xs"></div>
        </div>
    </div>
</div>

<div class="container">
    <div class="row">
        <div class="two-split-container no-sub">
            <div class="row">
                <div class="col-xs-12 col-sm-2 text-center">
                    <div class="icon-bg center">
                        <div class="icon-wrap">
                            <i class="nf nf-report-damage-icon-v3"></i>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-10">
                    <h3>{{ 'PAGE.CONTACT.CONTACT_US_URGENT_TITLE' | translate }}</h3>
                    <p>{{ 'PAGE.CONTACT.CONTACT_US_URGENT_TEXT' | translate }}</p>

                    <h3 class="margin-40">{{ 'PAGE.CONTACT.BUILDING_AND_HOME_TITLE' | translate }}</h3>
                    <p>
                        {{ 'PAGE.CONTACT.BUILDING_AND_HOME_TEXT' | translate }}
                    </p>
                    <img src="https://static.next.dk/website/images/logos/polygon-logo-small.jpg" />
                    <p>
                        <b>
                            Polygon skadeservice
                            <br />
                            {{ 'PAGE.CONTACT.UGENT_PHONE' | translate }}
                        </b>
                    </p>
                    <hr />
                    <h3>{{ 'PAGE.CONTACT.TRAVEL_TITLE' | translate }}</h3>
                    <p>
                        {{ 'PAGE.CONTACT.TRAVEL_TEXT' | translate }}
                    </p>
                    <img src="https://static.next.dk/website/images/logos/sos-international.png" srcset="https://static.next.dk/website/images/logos/sos-international@2x.png 2x" />
                    <p>
                        <b>
                            SOS International
                            <br />
                            {{ 'PAGE.CONTACT.SOS_PHONE' | translate }}
                        </b>
                    </p>

                    <h4>{{ 'PAGE.CONTACT.MEDICAL_PRE_APPROVAL' | translate }}</h4>
                    <a href="https://www.sos.eu/da/medicinsk-forhaandsvurdering/" target="_blank">
                        <span class="text-green">{{ 'PAGE.CONTACT.DOWNLOAD_MEDICAL_PRE_APPROVAL' | translate }}</span>
                    </a>

                    <hr />
                    <h3>{{ 'PAGE.CONTACT.ROAD_TITLE' | translate }}</h3>
                    <p>
                        {{ 'PAGE.CONTACT.ROAD_TEXT' | translate }}
                    </p>
                    <img src="https://static.next.dk/website/images/logos/sos-dansk-autohjælp.png" srcset="https://static.next.dk/website/images/logos/sos-dansk-autohjælp@2x.png 2x" />
                    <p>
                        <b>
                            SOS Dansk Autohjælp
                            <br />
                            {{ 'PAGE.CONTACT.ROAD_PHONE' | translate }}
                        </b>
                    </p>
                </div>
            </div>
            <div class="border-bottom visible-xs"></div>
        </div>
    </div>
</div>

<div class="container bottom-spacer-big">
    <div class="row">
        <div class="two-split-container no-sub">
            <div class="row">
                <div class="col-xs-12 col-sm-2 text-center">
                    <div class="icon-bg center">
                        <div class="icon-wrap">
                            <i class="nf nf-location-contact-icon-v3"></i>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-10">
                    <h3>{{ 'PAGE.CONTACT.OTHER_INFO' | translate }}</h3>
                    <p class="line-height">
                        <b>NEXT</b>
                        <br />
                        <br />
                        <b>Danmarksvej 26</b>
                        <br />
                        <br />
                        <b>8660 Skanderborg</b>
                        <br />
                        <br />
                        <a class="mailto" href="mailto:mail@next.dk">mail@next.dk</a>
                        <br />
                        <br />
                        <b>{{ 'PAGE.CONTACT.OTHER_PHONE' | translate }}</b>
                        <a *ngIf="!displayPhoneNumber" class="text-green click-here" [routerLink]="navigationService.getUrl('onlineinsurance')">{{
                            'PAGE.CONTACT.CLICK_HERE' | translate
                        }}</a>
                        <a *ngIf="displayPhoneNumber" href="tel:+4570407040"> 70 40 70 40</a>
                        <br />
                        <br />
                        <b>{{ 'PAGE.CONTACT.CVR' | translate }}</b> 34706905
                    </p>
                    <p class="line-height">
                        <b>{{ 'PAGE.CONTACT.BANK' | translate }}</b> Sparekassen Kronjylland
                        <br />
                        <br />
                        <b>{{ 'PAGE.CONTACT.ACCOUNTANT' | translate }}</b> Christensen Kjærulff
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

<next-footer></next-footer>
