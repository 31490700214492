import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Message } from '../message.model';
import { MessageService } from '../message.service';
import { AlertType } from '../alert-type.model';

@Component({
    selector: 'app-message',
    templateUrl: './message.component.html',
    styleUrls: ['./message.component.less'],
})
export class MessageComponent implements OnInit, OnDestroy {
    message: Message;

    private subscription: Subscription;

    constructor(private messageService: MessageService) {}

    ngOnInit() {
        this.subscription = this.messageService.getMessage().subscribe(msg => {
            this.message = msg;
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    clear() {
        this.messageService.clear();
    }

    cssClass(message: Message) {
        if (!message) {
            return;
        }

        // return css class based on alert type
        switch (message.alertType) {
            case AlertType.Success:
                return 'message-success';
            case AlertType.Error:
                return 'message-danger';
            case AlertType.Info:
                return 'message-info';
            case AlertType.Warning:
                return 'message-warning';
        }
    }
}
