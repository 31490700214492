import { Observable, throwError, timer } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { RetryParams } from './retryParams';

export const retryStrategy = (params: RetryParams) => (attempts: Observable<any>) =>
    attempts.pipe(
        mergeMap((error, i) => {
            const retryAttempt = i + 1;
            if (retryAttempt > params.maxAttempts || !params.shouldRetry(error)) {
                return throwError(error);
            }
            console.log(`Attempt ${retryAttempt}: retrying in ${retryAttempt * params.scalingDuration}ms`);
            return timer(retryAttempt * params.scalingDuration);
        })
    );
