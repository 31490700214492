<div *ngIf="!hideMaintenance && hasMaintenance">
    <div class="maintenance-container animated slideInLeft hidden-xs">
        <i *ngIf="maintenanceType === 1" class="icon nf-warning-icon-v3"></i>
        <i *ngIf="maintenanceType === 2" class="icon {{ dmriconname }}"></i>
        <i *ngIf="maintenanceType === 3" class="icon nf-warning-icon-v3"></i>
        <i *ngIf="maintenanceType === 4" class="icon nf-warning-icon-v3"></i>
        <br />

        <div class="title">{{ currentMaintenance?.title }}</div>
        <div class="text" [innerHTML]="currentMaintenance?.description">
            <a *ngIf="maintenanceType === 2" class="withoutRegNo" (click)="btnClick()">{{ dmrBtnText }}</a>
        </div>

        <a class="btn-rounded" (click)="setHideMaintenance()">{{ 'SHARED.OK' | translate | uppercase }}</a>
    </div>

    <div class="maintenance-container mobile animated slideInDown visible-xs" (click)="toogleMaintenanceExpanded()">
        <i *ngIf="maintenanceType === 1" class="icon nf-warning-icon-v3"></i>
        <i *ngIf="maintenanceType === 2" class="icon {{ dmriconname }}"></i>
        <i *ngIf="maintenanceType === 3" class="icon nf-warning-icon-v3"></i>
        <i *ngIf="maintenanceType === 4" class="icon nf-warning-icon-v3"></i>
        <i *ngIf="!maintenanceExpanded" class="arrow nf nf-chevron-down-icon-v3"></i>
        <i *ngIf="maintenanceExpanded" class="arrow nf nf-chevron-up-icon-v3"></i>
        <div class="title">{{ currentMaintenance?.title }}</div>

        <div *ngIf="maintenanceExpanded" class="text">
            <div class="text" [innerHTML]="currentMaintenance?.description"></div>
            <a *ngIf="maintenanceType === 2" class="withoutRegNo" (click)="btnClick()">{{ dmrBtnText }}</a>
        </div>
        <a *ngIf="maintenanceExpanded" class="btn-rounded" (click)="setHideMaintenance()">{{ 'SHARED.OK' | translate | uppercase }}</a>
    </div>
</div>
