import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { BlogPostPreview } from 'app/utils/contex/models/blogPostPreview';
import { ContexService } from 'app/utils/contex/contex.service';
import { CancelablePromise, PromiseHelper } from 'app/utils/promiseHelper/promiseHelper';
import { TranslationService } from 'app/utils/translation/translation.service';

@Component({
    moduleId: module.id,
    selector: 'blogs-overview',
    templateUrl: './blogs-overview.component.html',
    styleUrls: ['./blogs-overview.component.less'],
})
export class BlogsOverviewComponent implements OnInit, OnDestroy {
    blogPostPreviews: BlogPostPreview[];
    getPostPreviewsPromise: CancelablePromise<BlogPostPreview[]>;

    constructor(private contexService: ContexService, private translationService: TranslationService) {}

    ngOnInit(): void {
        this.updateBlogPostPreviews();

        this.translationService.getLanguageChangeEmitter().subscribe(() => {
            this.updateBlogPostPreviews();
        });
    }

    updateBlogPostPreviews() {
        this.getPostPreviewsPromise = PromiseHelper.makeCancelable(this.contexService.getBlogPostPreviews(0, 10).toPromise());

        this.getPostPreviewsPromise.promise.then(blogPostPreviews => {
            this.blogPostPreviews = blogPostPreviews;
        });
    }

    ngOnDestroy(): void {
        if (this.getPostPreviewsPromise) {
            this.getPostPreviewsPromise.cancel();
        }
    }

    getUrl(parameter: string) {
        return 'blog/' + parameter;
    }
}
