import { Component, OnInit } from '@angular/core';

@Component({
    moduleId: module.id,
    selector: 'trustpilot-carousel',
    templateUrl: './trustpilot-carousel.component.html',
    styleUrls: ['./trustpilot-carousel.component.less'],
})
export class TrustpilotCarouselComponent implements OnInit {
    ngOnInit(): void {
        setTimeout(function () {
            // Hack - but without this the noopener button in shown instead of the widget content on page change
            const trustpilotRef = document.getElementById('trustpilot-review-carousel');
            window.Trustpilot.loadFromElement(trustpilotRef);
        }, 200);
    }
}
