import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AppConfigService } from 'app/utils/appconfig/app-config.service';
import { HttpClient } from '@angular/common/http';
import { Status, NemIdLoginParameters } from 'next-typescript-api';

@Injectable()
export class NemIdService {
    private apiUrl: string;

    constructor(public http: HttpClient, private config: AppConfigService) {
        this.apiUrl = this.config.appConfig.apiHost;
    }

    statusGet(): Observable<Status> {
        const url = `${this.apiUrl}/nemid/status?api-version=2.0`;
        return this.http.get<Status>(url);
    }

    login(): Observable<NemIdLoginParameters> {
        const url = `${this.apiUrl}/nemid/login?api-version=2.0`;
        return this.http.get<NemIdLoginParameters>(url);
    }
}
