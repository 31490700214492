<div class="full-width-image-section" [class]="pageCode">
    <div class="arrow-up">
        <next-topbar></next-topbar>
        <next-menu></next-menu>

        <div class="container white">
            <div class="row">
                <div class="col-sm-12 text-center">
                    <h1 id="header">{{ header }}</h1>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container">
    <div class="row content">
        <pageloader [show]="loading"></pageloader>
        <div *ngIf="!loading" class="col-sm-8 col-sm-offset-2" [innerHTML]="content | safehtml"></div>
    </div>
</div>

<next-footer></next-footer>
