import { LeadCampaignLandingService } from './utils/leadCampaignLanding/leadCampaignLanding.service';
import { TokenStorage } from './auth/token-storage.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, Params } from '../../node_modules/@angular/router';
import { NavigationService } from 'app/utils/navigation/navigation.service';
import { filter } from 'rxjs/operators';
import { ChatConfigurator } from './utils/chat/chatConfigurator';
import { TelemetryService } from './utils/telemetry/telemetry.service';
import { AuthService } from './auth/auth.service';
import { UserAgentService } from './utils/useragent/user-agent.service';
import { Subscription } from 'rxjs';
import { PageChangeTracker } from './utils/tracking/pageChangeTracker';
import { SeoService } from './utils/seo/seo.service';
import { TranslationService } from './utils/translation/translation.service';
import { AppConfigService } from './utils/appconfig/app-config.service';
import { QueryParametersService } from './utils/queryParameters/query-parameters.service';

@Component({
    moduleId: module.id,
    selector: 'next-app',
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
    isBrowserUnsupported: boolean;
    subscriptions: Subscription[] = [];
    currentLanguage: string;

    constructor(
        private router: Router,
        public navigationService: NavigationService,
        private chatConfigurator: ChatConfigurator, // we just need to instanciate
        private telemetryService: TelemetryService,
        private authService: AuthService,
        private pageChangeTracker: PageChangeTracker, // we just need to instanciate the tracker
        private seoService: SeoService,
        private route: ActivatedRoute,
        private tokenStorage: TokenStorage,
        private leadCampaignLandingService: LeadCampaignLandingService,
        private translationService: TranslationService,
        private config: AppConfigService,
        private queryParametersService: QueryParametersService
    ) {}

    ngOnInit(): void {
        this.isBrowserUnsupported = UserAgentService.isIeLowerThan11();

        if (this.isBrowserUnsupported) {
            return;
        }

        this.seoService.injectGoogleTagManager(this.config.appConfig.googleTagManagerId);

        this.currentLanguage = this.translationService.getSelectedLanguage();
        this.translationService.getLanguageChangeEmitter().subscribe((x) => {
            this.currentLanguage = x.lang;
        });

        let leadCampaignId = this.queryParametersService.getQueryVariable('lcid'); // this.route.queryParams.subscribe not working bacause first emit is null
        const removeQueryVariable = !!leadCampaignId;
        if (!leadCampaignId) {
            leadCampaignId = this.config.appConfig.defaultCampaignId;
        }

        if (leadCampaignId) {
            this.tokenStorage.setLeadCampaignId(leadCampaignId);
            this.leadCampaignLandingService.addLeadCampaignLanding(leadCampaignId).subscribe(
                (x) => {
                    console.log(leadCampaignId + (x ? ' stored' : ' not stored'));
                },
                () => {
                    console.log('Storing leadCampaignId ' + leadCampaignId + ' failed.');
                }
            );

            if (removeQueryVariable) {
                const urlWithoutLcid = this.queryParametersService.removeQueryVariable('lcid', location.href);

                // Update url without refreshing the page
                window.history.replaceState({}, document.title, urlWithoutLcid);
            }
        }

        this.subscriptions.push(
            this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
                this.seoService.setCanonicalLink();

                // Set or clear user context
                if (this.authService.isAuthenticated()) {
                    this.telemetryService.setAuthenticatedUserContext(this.authService.getCustomerId());
                } else {
                    this.telemetryService.clearAuthenticatedUserContext();
                }

                // Log page view
                this.telemetryService.logPageView();

                // Update Zendesk Chat with path
                if (window.$zopim) {
                    window.$zopim(() => {
                        window.$zopim.livechat.sendVisitorPath({
                            url: window.location.href,
                            title: document.title,
                        });
                    });
                }

                // Scroll window
                window.scroll(0, 0);
            })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }
}
