import { CoverSelect } from 'app/class/cover-select';
import { Address } from 'app/class/address';

export class Caravan {
    camplet?: boolean;
    regNo: string;
    address: Address;
    brandName: string;
    brandId: string;
    modelText: string;
    modelYear?: number;
    marketValue?: number;
    dmr: boolean;
    foundDmr: boolean;
    stationary: boolean;
    deductibleId: string;
    usageCode: string;
    covers: CoverSelect[];
    adoptAddress = true;
    eventDate?: Date;

    constructor() {
        this.camplet = null;
        this.regNo = '';
        this.address = new Address();
        this.brandName = '';
        this.brandId = '';
        this.modelText = '';
        this.modelYear = null;
        this.marketValue = null;
        this.dmr = false;
        this.foundDmr = false;
        this.stationary = false;
        this.deductibleId = '';
        this.usageCode = '';
    }
}
