<div id="videomodal">
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{ title }}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
            <i class="nf nf-close-icon-outlined"></i>
        </button>
    </div>
    <div class="modal-body video-container">
        <iframe id="player-iframe" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
    </div>
</div>
