import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'trustpilot-mini',
    templateUrl: './trustpilot-mini.component.html',
})
export class TrustpilotMiniComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {
        setTimeout(function () {
            // Hack - but without this the noopener button in shown instead of the widget content on page change
            const trustpilotMiniRef = document.getElementById('trustpilot-mini');
            window.Trustpilot.loadFromElement(trustpilotMiniRef);
        }, 200);
    }
}
