import { TrustpilotModule } from 'app/shared/trustpilot/trustpilot.module';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { FooterComponent } from './components/footer/footer.component';
import { LanguageSelectorModule } from '../language-selector/language-selector.module';

@NgModule({
    imports: [FormsModule, AccordionModule.forRoot(), CommonModule, RouterModule, TranslateModule, LanguageSelectorModule, TrustpilotModule],
    declarations: [FooterComponent],
    exports: [FooterComponent],
})
export class FooterModule {}
