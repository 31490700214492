import { Component, OnInit, TemplateRef } from '@angular/core';
import { NavigationService } from 'app/utils/navigation/navigation.service';
import { ContexService } from 'app/utils/contex/contex.service';
import { CalculateService } from 'app/calculate/calculate.service';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { MetaTagsHelper } from 'app/utils/metaTags/metaTagsHelper';
import { AppConfigService } from 'app/utils/appconfig/app-config.service';

@Component({
    moduleId: module.id,
    selector: 'newcustomer',
    templateUrl: './newcustomer.component.html',
    styleUrls: ['./newcustomer.component.less', '../../../../../styles/app/covers.less'],
})
export class NewCustomerComponent implements OnInit {
    bsModalRef: BsModalRef;

    contex: any;
    basketId: string;
    loading: boolean;
    productselected = false;
    addCar: boolean;
    addHouse: boolean;
    addHome: boolean;
    addAccident: boolean;
    addDog: boolean;
    addCaravan: boolean;
    addSecondHouse: boolean;
    addMoped: boolean;
    startCalculateInfo: boolean;
    showExpiredBasketModal = false;

    constructor(
        private calculateService: CalculateService,
        public navigationService: NavigationService,
        public contexService: ContexService,
        private router: Router,
        private modalService: BsModalService,
        private route: ActivatedRoute,
        private metaTagsHelper: MetaTagsHelper,
        private configService: AppConfigService
    ) {}

    ngOnInit(): void {
        this.metaTagsHelper.setTitleAndMetaTags('META.NEW_CUSTOMER');
        this.showExpiredBasketModal = this.route.snapshot.queryParamMap.get('showExpiredBasketModal') === 'true';
    }

    openModal(template: TemplateRef<any>) {
        this.bsModalRef = this.modalService.show(template);
    }

    async startCalculation() {
        const productsToAdd = this.addProducts();
        this.startCalculateInfo = productsToAdd.length === 0;
        this.loading = productsToAdd.length > 0;

        let firstInsuranceId = null;

        for (const key in productsToAdd) {
            const product = productsToAdd[key];
            const insuranceid = await this.addProduct(product);
            if (!firstInsuranceId) {
                firstInsuranceId = insuranceid;
            }
        }

        // redirect to first product
        if (productsToAdd.length > 0) {
            const baseRoute = this.navigationService.getUrl('calculate-' + productsToAdd[0]);
            this.router.navigateByUrl(baseRoute + '?basketid=' + this.calculateService.basketId + '&insuranceid=' + firstInsuranceId);
        }
    }

    addProducts(): string[] {
        const productsToAdd: string[] = [];

        if (this.addCar) {
            const carProductName = this.configService.appConfig.useNewCarProduct ? 'car_simple' : 'car';
            productsToAdd.push(carProductName);
        }
        if (this.addHouse) {
            productsToAdd.push('house');
        }
        if (this.addHome) {
            productsToAdd.push('home');
        }
        if (this.addAccident) {
            productsToAdd.push('accident');
        }
        if (this.addDog) {
            productsToAdd.push('dog');
        }
        if (this.addCaravan) {
            productsToAdd.push('caravan');
        }
        if (this.addSecondHouse) {
            productsToAdd.push('secondhouse');
        }
        if (this.addMoped) {
            productsToAdd.push('moped');
        }

        return productsToAdd;
    }

    async addProduct(productName: string): Promise<string> {
        return await this.calculateService.addBasketProduct(productName);
    }

    clearError(): void {
        this.startCalculateInfo = false;
    }
}
