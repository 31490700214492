<div class="mit-id-container" [class.big-button]="bigButton">
    <button *ngIf="showNormalLogin" class="btn btn-success btn-login" (click)="login()" type="button" [class.loading]="isLoggingIn" [disabled]="isLoggingIn">
        <span>{{ 'SHARED.LOGIN.LOGIN' | translate }}</span>
    </button>

    <ng-container *ngIf="customerNotFoundError">
        <p class="msg msg-error">{{ 'SHARED.LOGIN.CUSTOMER_NOT_FOUND' | translate }}</p>
    </ng-container>

    <ng-container *ngIf="unknownError">
        <p class="msg msg-error">
            {{ 'SHARED.LOGIN.UNKNOWN_ERROR' | translate }}<span *ngIf="error"> {{ 'SHARED.LOGIN.ERROR_MESSAGE' | translate }}: {{ error }}</span>
        </p>
        <button class="btn btn-success btn-login" (click)="login()" type="button" [class.loading]="isLoggingIn" [disabled]="isLoggingIn">
            <span>{{ 'SHARED.LOGIN.LOGIN' | translate }}</span>
        </button>
    </ng-container>
</div>
