import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { retryWhen } from 'rxjs/operators';
import { retryStrategy } from './retryStrategy';

@Injectable()
export class RetryInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            retryWhen(
                retryStrategy({
                    maxAttempts: 3,
                    scalingDuration: 1000,
                    shouldRetry: error => error.status === 0,
                })
            )
        );
    }
}
