import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, HostListener } from '@angular/core';
import { CalculateService } from 'app/calculate/calculate.service';
import { PriceResult } from 'app/class/priceresult';

@Component({
    selector: 'price-sidebar',
    templateUrl: './price-sidebar.component.html',
    styleUrls: ['./price-sidebar.component.less', '../../../../../styles/app/calculate.less'],
})
export class PriceSidebarComponent implements OnInit {
    calculating: boolean;

    priceResult: PriceResult;
    productName: string;
    pageScrolledDown = false;
    isCoveragePage = false;

    isMobile = false;

    constructor(private calculateService: CalculateService, private translateService: TranslateService) {
        this.getScreenSize();
    }

    ngOnInit() {
        this.calculateService.priceResult$.subscribe((x) => {
            this.priceResult = x;
            this.productName = this.translateService.instant('INSURANCE.' + this.calculateService.product?.toUpperCase() + '.PRODUCT');
        });

        this.calculateService.calculating$.subscribe((x) => {
            this.calculating = x;
        });

        this.calculateService.progress$.subscribe((progress) => {
            this.isCoveragePage = progress === 100;
        });
    }

    @HostListener('window:scroll', ['$event'])
    onScroll(event) {
        let limitY = 400;
        if (this.isMobile) {
            limitY = 320;
        }
        this.pageScrolledDown = window.pageYOffset > limitY;
    }

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
        this.isMobile = window.innerWidth <= 500;
    }
}
