export class Address {
    addressText: string;
    addressId: string;
    streetName?: string;
    streetNo?: string;
    zipcode?: string;
    city?: string;
    country?: string;
    floor?: string;
    doorSide?: string;

    constructor() {
        this.addressId = '';
        this.addressText = '';
    }
}
