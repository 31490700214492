import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AppConfigService } from 'app/utils/appconfig/app-config.service';
import { HttpClient } from '@angular/common/http';
import { CreateContactMailTicketInput } from 'next-typescript-api';

@Injectable()
export class MailticketService {
    apiUrl: string;
    constructor(private config: AppConfigService, private http: HttpClient) {
        this.apiUrl = this.config.appConfig.apiHost;
    }

    createMailTicket(input: CreateContactMailTicketInput): Observable<string> {
        const url = `${this.apiUrl}/mail-tickets?api-version=2.0`;
        return this.http.post<string>(url, input);
    }
}
