import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { mopedRoutes } from './moped-routes';

@NgModule({
    imports: [RouterModule.forChild(mopedRoutes)],
    exports: [RouterModule],
})
export class MopedRoutingModule {}
