import { Injectable, Inject, PLATFORM_ID } from '@angular/core';

@Injectable()
export class TokenStorage {
    constructor(@Inject(PLATFORM_ID) private platformId: any) {}

    public getAccessToken(): string {
        const token: string = this.getItem('accessToken');
        return token;
    }

    public getRefreshToken(): string {
        const token: string = this.getItem('refreshToken');
        return token;
    }

    public getCustomerId(): string {
        const customerId: string = this.getItem('customerId');
        return customerId;
    }

    public getAuthenticationType(): string {
        const authenticationType: string = this.getItem('authenticationType');
        return authenticationType;
    }

    public getExpirationDate(): Date {
        const expDate: Date = new Date(this.getItem('expirationDate'));
        return expDate;
    }

    public getSplashShowed(): boolean {
        const splashShowed = Boolean(this.getItem('splashShowed'));
        return splashShowed;
    }

    public getLeadCampaignId(): string {
        const leadCampaignId: string = this.getItem('leadCampaignId');
        return leadCampaignId;
    }

    public setAccessToken(token: string): TokenStorage {
        this.setItem('accessToken', token);
        return this;
    }

    public setRefreshToken(token: string): TokenStorage {
        this.setItem('refreshToken', token);
        return this;
    }

    public setCustomerId(customerId: string): TokenStorage {
        this.setItem('customerId', customerId);
        return this;
    }

    public setAuthenticationType(authenticationType: string): TokenStorage {
        this.setItem('authenticationType', authenticationType);
        return this;
    }

    public setExpDate(expirationDate: Date): TokenStorage {
        this.setItem('expirationDate', expirationDate.toString());
        return this;
    }

    public setSplashShowed(splashShowed: boolean): TokenStorage {
        this.setItem('splashShowed', splashShowed ? 'true' : 'false');
        return this;
    }

    public setLeadCampaignId(leadCampaignId: string): TokenStorage {
        this.setItem('leadCampaignId', leadCampaignId);
        return this;
    }

    public clear() {
        this.removeItem('accessToken');
        this.removeItem('refreshToken');
        this.removeItem('customerId');
        this.removeItem('expirationDate');
        this.removeItem('splashShowed');
    }

    private setItem(itemName: string, value: string): void {
        sessionStorage.setItem(itemName, value);
    }

    private getItem(itemName: string): string {
        return <string>sessionStorage.getItem(itemName);
    }

    private removeItem(itemName: string): void {
        sessionStorage.removeItem(itemName);
    }
}
