import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MitIdService } from 'app/utils/mitId/mit-id.service';

@Component({
    selector: 'mit-id-policy-signing-landing',
    templateUrl: './mit-id-policy-signing-landing.component.html',
    styleUrls: ['./mit-id-policy-signing-landing.component.less'],
})
export class MitIdPolicySigningLandingComponent implements OnInit {
    constructor(private router: Router, private route: ActivatedRoute, private mitIdService: MitIdService) {}

    ngOnInit(): void {
        this.route.queryParams.subscribe((params: Params) => {
            const state: string = params['state'];
            const code: string = params['code'];

            // if code is null/empty it failed
            if (code) {
                let redirectUrl = this.mitIdService.getSuccessfulSigningRedirectUrl();

                const paramPrefix = redirectUrl.indexOf('?') === -1 ? '?' : '&';

                redirectUrl += paramPrefix + `signingSuccess=true&state=${state}&code=${code}`;

                this.router.navigateByUrl(redirectUrl);
            } else {
                let redirectUrl = this.mitIdService.getFailedSigningRedirectUrl();

                const errorPrefix = redirectUrl.indexOf('?') === -1 ? '?' : '&';

                const errorMessage = params['baxOidcMessage'];

                redirectUrl += errorPrefix + `signingSuccess=false&mitIdError=${encodeURIComponent(errorMessage)}`;

                this.router.navigateByUrl(redirectUrl);
            }
        });
    }
}
