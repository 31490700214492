<callme-modal
    *ngIf="showContactRequestModal"
    [showCallMeBack]="callbackAllowed"
    [showPhoneNumberOnMobile]="displayPhoneNumber"
    [isChatOpen]="ischatopen"
    (openChatDialog)="openChat()"
    (closeDialog)="closeContactRequestModal()"
></callme-modal>

<div id="topbar-container" *ngIf="!isMobile" class="{{ selfcaremode ? 'selfcare' : '' }}">
    <div class="container topbar-innercontainer">
        <div *ngIf="displayPhoneNumber === undefined || callbackAllowed === undefined" class="circle-loader small pull-left"></div>
        <div *ngIf="displayPhoneNumber !== undefined && callbackAllowed !== undefined">
            <div class="pull-left phone-number-elm" *ngIf="displayPhoneNumber"><i class="nf nf-phone-icon"></i><a href="tel:+4570407040">70 40 70 40</a></div>
            <div class="separator pull-left" *ngIf="displayPhoneNumber"></div>
            <div class="pull-left">
                <div class="header-item top-padding" *ngIf="!displayPhoneNumber">
                    {{ chatopenhours }}
                </div>

                <div *ngIf="callbackAllowed" (click)="openContactRequestModal()" class="btn btn-success header-item chat-open">
                    <span>{{ 'SHARED.TOPBAR.CALLBACK' | translate }}</span>
                    <i class="nf nf-phone-icon"></i>
                </div>

                <div *ngIf="ischatopen" (click)="openChat()" class="btn btn-success header-item chat-open hidden-sm">
                    <span>{{ 'SHARED.TOPBAR.START_CHAT' | translate }}</span>
                    <i class="nf nf-chat-icon-v3 space-left"></i>
                </div>

                <div *ngIf="!ischatopen" [routerLink]="navigationService.getUrl('contact')" class="btn btn-success header-item">
                    <span>{{ 'SHARED.TOPBAR.CONTACT_US' | translate }}</span>
                    <i class="nf nf-chat-icon-v3 space-left"></i>
                </div>
            </div>
        </div>
        <div *ngIf="!selfcaremode" class="pull-right">
            <language-selector [mini]="true"></language-selector>
            <div (click)="goToSelfcare()" *ngIf="isAuthenticated" class="btn btn-success header-item top-padding">
                <a>
                    <span>{{ 'SHARED.TOPBAR.LOGGED_IN_AS' | translate }} {{ userDisplayName }} </span>
                    <i class="nf nf-lock-icon-v3"></i>
                </a>
            </div>

            <div (click)="goToSelfcare()" *ngIf="!isAuthenticated" class="btn btn-success header-item top-padding">
                <a>
                    <span>{{ 'SHARED.TOPBAR.LOG_IN' | translate }} <i class="nf nf-lock-icon-v3"></i></span>
                </a>
            </div>

            <div class="header-item">
                <div *ngIf="goToSummaryLoading" class="circle-loader small"></div>
                <a *ngIf="!goToSummaryLoading" (click)="goToSummary()" id="cartlink" [title]="'SHARED.MENU.BASKET' | translate">
                    <i class="nf nf-basket-icon"></i>
                    <span class="badge" id="shoppingcart-status">{{ numInBasket }}</span>
                </a>
            </div>
        </div>
        <div *ngIf="selfcaremode" class="pull-right">
            <div class="menu-area" *ngIf="isAuthenticated">
                <nav id="primary_nav_wrap">
                    <ul>
                        <li>
                            <a>{{ userDisplayName }} <i class="nf nf-dropdown-icon"></i></a>
                            <ul>
                                <li>
                                    <a [routerLink]="navigationService.getUrl('selfcare-yourinfo')">{{ 'SHARED.TOPBAR.YOUR_INFO' | translate }}</a>
                                </li>

                                <li>
                                    <a [routerLink]="navigationService.getUrl('selfcare-signout')">{{ 'SHARED.TOPBAR.LOG_OUT' | translate }}</a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
</div>
