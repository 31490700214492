import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalculateButtonComponent } from './components/calculate-button.component';

@NgModule({
    imports: [CommonModule, RouterModule, TranslateModule],
    declarations: [CalculateButtonComponent],
    exports: [CalculateButtonComponent],
})
export class CalculateButtonModule {}
