import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { IMyDpOptions, IMyDate } from 'mydatepicker';

@Injectable()
export class FunctionsService {
    deepCopy(oldObj: any): any {
        let newObj = oldObj;
        if (oldObj && typeof oldObj === 'object') {
            newObj = Object.prototype.toString.call(oldObj) === '[object Array]' ? [] : {};
            for (const i in oldObj) {
                newObj[i] = this.deepCopy(oldObj[i]);
            }
        }
        return newObj;
    }

    convertToUtc(date: Date): string {
        const dateLocal: moment.Moment = moment(date);
        const dateUtc = moment.parseZone(dateLocal).utc().format();
        return dateUtc;
    }

    convertToUtcDate(date: Date, keepLocalTime = false): Date {
        const dateLocal: moment.Moment = moment(date);
        const dateUtc = moment.parseZone(dateLocal).utc(keepLocalTime).toDate();
        return dateUtc;
    }

    convertToDateString(date: Date): string {
        const dateString = moment(date).format('YYYY-MM-DDTHH:mm:ss');
        return dateString;
    }

    convertDatePickerValueToDate(datePickerValue: any): Date {
        if (!datePickerValue) {
            return null;
        }

        const datePickerDate: any = datePickerValue.date;
        const localDate: Date = new Date(datePickerDate.year, datePickerDate.month - 1, datePickerDate.day, 0, 0, 0, 0); // Month is index based, which is way the -1
        return localDate;
    }

    validateEmail(email: string): boolean {
        const regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(email);
    }

    validateRegNo(regNo: string): boolean {
        const regex = /^[a-zA-Z0-9]*$/;
        const result = regex.test(regNo);
        return result;
    }

    getToday(): IMyDate {
        return this.getTodayPlusXDays(0);
    }

    getTomorrow(): IMyDate {
        return this.getTodayPlusXDays(1);
    }

    getTodayPlusXDays(addDays: number): IMyDate {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getUTCDate() + addDays);

        const mapped = this.mapDateToMyDate(tomorrow);
        return mapped;
    }

    getFirstDayPlusTwoMonths(): Date {
        const now = new Date();
        let result: Date;

        if (now.getMonth() === 10) {
            result = new Date(now.getFullYear() + 1, 0, 1);
        }
        if (now.getMonth() === 11) {
            result = new Date(now.getFullYear() + 1, 1, 1);
        } else {
            result = new Date(now.getFullYear(), now.getMonth() + 2, 1);
        }

        return result;
    }

    mapDateToMyDate(date: Date): IMyDate {
        const result = {
            year: date.getUTCFullYear(),
            month: date.getUTCMonth() + 1,
            day: date.getUTCDate(),
        };

        return result;
    }

    getStandardDatePickerOptions(): IMyDpOptions {
        const options: IMyDpOptions = {
            dateFormat: 'dd-mm-yyyy',
            sunHighlight: false,
            editableDateField: false,
            openSelectorOnInputClick: true,
            showClearDateBtn: false,
            height: '46px',
            width: '131px',
        };
        return options;
    }

    isModulus11(cpr: string): boolean {
        if (!this.isCprDatepartValid(cpr)) {
            return false;
        }

        const controlNumbers = [4, 3, 2, 7, 6, 5, 4, 3, 2];

        const accumulatedCtrlCprNumbers = controlNumbers.reduce((accumulator, ctrlNum, index) => accumulator + parseInt(cpr[index], 10) * ctrlNum, 0);
        const modulusResult = accumulatedCtrlCprNumbers % 11;
        return 11 - (modulusResult === 0 ? 11 : modulusResult) === parseInt(cpr[9], 10);
    }

    isCprDatepartValid(input: string): boolean {
        const inputExp = new RegExp('^\\d{10}$');

        if (!input || !inputExp.test(input)) {
            return false;
        }

        const day = parseInt(input.substr(0, 2), 10);

        if (isNaN(day) || day > 31 || day < 1) {
            return false;
        }

        const month = parseInt(input.substr(2, 2), 10) - 1; // beginning with 0 for January to 11 for December.

        if (isNaN(month) || month > 11 || month < 0) {
            return false;
        }

        const year = parseInt(input.substr(4, 2), 10);

        if (isNaN(year) || year > 99 || year < 0) {
            return false;
        }

        const date = new Date(year, month, day);

        return date instanceof Date && !isNaN(date.valueOf());
    }

    isCprOlderThan18(cpr: string): boolean {
        const date = this.cprToDate(cpr);
        const today = new Date();
        const yearDif = today.getFullYear() - date.getFullYear();
        if (yearDif > 18) {
            return true;
        }
        if (yearDif === 18) {
            const monthDif = today.getMonth() - date.getMonth();
            if (monthDif > 0) {
                return true;
            }
            if (monthDif === 0) {
                const dayDif = today.getDate() - date.getDate();
                if (dayDif >= 0) {
                    return true;
                } else {
                    return false;
                }
            }
            if (monthDif < 0) {
                return false;
            }
        }
        if (yearDif < 18) {
            return false;
        }
    }

    cprToDate(cpr: string): Date {
        if (!cpr || cpr.length !== 10) {
            return null;
        }
        let cent: number;
        const sevdig = parseInt(cpr.substr(6, 1), 10);
        const cprYear = parseInt(cpr.substr(4, 2), 10);

        switch (sevdig) {
            case 0:
            case 1:
            case 2:
            case 3:
                cent = 19;
                break;
            case 4:
            case 9:
                if (cprYear <= 36) {
                    cent = 20;
                } else {
                    cent = 19;
                }
                break;
            case 5:
            case 6:
            case 7:
            case 8:
                if (cprYear <= 36) {
                    cent = 20;
                } else if (cprYear >= 58) {
                    cent = 18;
                }
                break;
        }

        const cprYearStr = cpr.substr(4, 2);
        const year = parseInt(cent + '' + cprYearStr, 10);
        const month = parseInt(cpr.substr(2, 2), 10) - 1; // beginning with 0 for January to 11 for December.
        const day = parseInt(cpr.substr(0, 2), 10);

        const date = new Date(year, month, day);
        return date;
    }

    getDaysAndHoursTextFromMinutes(minutes: number): string {
        let result = '';
        let days = minutes / (60 * 24);
        const remainder = minutes % (60 * 24);
        let hours = remainder / 60;

        days = Math.floor(days);
        hours = Math.floor(hours);
        if (days && days > 0) {
            result = (days === 1 ? '1 dag' : days + ' dage') + ' og ';
        }
        result += hours === 1 ? '1 time' : hours + ' timer';
        if (minutes < 60) {
            result = 'mindre end en time';
        }
        return result;
    }
}
