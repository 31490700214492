<div class="table-wrapper">
    <table>
        <tr>
            <th class="cover-header">{{ 'COMPONENTS.COVER_TABLE.COVERS' | translate }}</th>
            <ng-container *ngFor="let coverPackage of coverPackageInfos">
                <th class="gray-background">
                    {{ coverPackage.name }}
                </th>
                <th class="separator" *ngIf="coverPackage.id !== coverPackageInfos[coverPackageInfos.length - 1].id"></th>
            </ng-container>
        </tr>
        <tr *ngFor="let cover of covers">
            <td class="cover-name-wrapper">
                <div class="hand expand-button-wrapper" (click)="cover.expanded = !cover.expanded">
                    <i class="expand-button" [ngClass]="cover.expanded ? 'nf-faq-down' : 'nf-faq-right'"></i>
                    <div class="cover-name">{{ cover.coverName }}</div>
                </div>
                <div *ngIf="cover.expanded" class="cover-description">{{ 'COMPONENTS.COVER_TABLE.' + productCode + '.' + cover.coverCode + '_DESCRIPTION' | uppercase | translate }}</div>
            </td>
            <ng-container *ngFor="let coverPackage of coverPackageInfos">
                <td class="gray-background">
                    <span *ngIf="coverPackage.covers | some : cover.coverCode : 'coverCode'">
                        <i class="nf nf-checkmark-icon-v3"></i>
                    </span>
                </td>
                <td class="separator" *ngIf="coverPackage.id !== coverPackageInfos[coverPackageInfos.length - 1].id"></td>
            </ng-container>
        </tr>
    </table>
</div>
