import { Component } from '@angular/core';
import { NavigationService } from 'app/utils/navigation/navigation.service';
import { MetaTagsHelper } from 'app/utils/metaTags/metaTagsHelper';

@Component({
    moduleId: module.id,
    selector: 'press',
    templateUrl: './press.component.html',
    styleUrls: ['./press.component.less'],
})
export class PressComponent {
    constructor(public navigationService: NavigationService, private metaTagsHelper: MetaTagsHelper) {
        this.metaTagsHelper.setTitleAndMetaTags('META.PRESS');
    }
}
