<div class="full-width-image-section onlineinsurance-top">
    <video autoplay>
        <source src="https://static.next.dk/website/videos/Online-forsikring.mp4" type="video/mp4" />
    </video>
    <div class="arrow-up">
        <next-topbar></next-topbar>
        <next-menu></next-menu>

        <div class="video-container">
            <ng-template #onlineinsuranceModal>
                <next-videomodal [videoid]="'5ne4dU1HiVY'" [bsModalRef]="bsModalRef"></next-videomodal>
            </ng-template>
        </div>

        <div class="container white" (click)="openModal(onlineinsuranceModal)">
            <div class="row">
                <div class="col-sm-12 text-center">
                    <h1>{{ 'PAGE.ONLINE_INSURANCE.TITLE' | translate }}</h1>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12 text-center play-video-icon">
                    <i class="nf nf-play-video-icon-v3"></i>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12 play-btn-text">
                    {{ 'PAGE.ONLINE_INSURANCE.SUB_TITLE' | translate }}
                </div>
            </div>
        </div>
    </div>
</div>

<div class="onlineinsurance-content">
    <div class="container container-padding-40-40">
        <div class="row">
            <div class="col-sm-4 page-content">
                <h2>{{ 'PAGE.ONLINE_INSURANCE.CALL_TITLE' | translate }}</h2>
                <p>{{ 'PAGE.ONLINE_INSURANCE.CALL_1' | translate }}</p>
                <p>
                    {{ 'PAGE.ONLINE_INSURANCE.CALL_2' | translate }}
                </p>
                <p>
                    {{ 'PAGE.ONLINE_INSURANCE.CALL_3' | translate }}
                </p>
                <p>
                    {{ 'PAGE.ONLINE_INSURANCE.CALL_4' | translate }}
                </p>
                <img
                    src="https://static.next.dk/website/images/pages/online/arrow.png"
                    class="pull-right review-arrow"
                    srcset="https://static.next.dk/website/images/pages/online/arrow.png 1x, https://static.next.dk/website/images/pages/online/arrow@2x.png 2x"
                />
            </div>

            <div class="col-sm-8">
                <div class="trustpilot-box">
                    <div class="row review">
                        <div class="col-sm-12 user">
                            <div class="image">
                                <img
                                    src="https://static.next.dk/website/images/pages/online/carstenkilde.png"
                                    srcset="https://static.next.dk/website/images/pages/online/carstenkilde.png 1x, https://static.next.dk/website/images/pages/online/carstenkilde@2x.png 2x"
                                />
                            </div>
                            <div class="userinfo">
                                <b>Carsten Kilde</b><br />
                                <b><small>Fredericia, DK</small></b
                                ><br />
                                <img
                                    src="https://static.next.dk/website/images/pages/employees/trustpilot-5.png"
                                    srcset="
                                        https://static.next.dk/website/images/pages/employees/trustpilot-5.png    1x,
                                        https://static.next.dk/website/images/pages/employees/trustpilot-5@2x.png 2x
                                    "
                                />
                            </div>
                            <div class="content">
                                <i
                                    >...Det at man klarer sig selv <em>online</em> tiltaler mig meget og har man brug for hjælp går man på <em>chatten</em> hvor der sidder nogle
                                    <em>gode medarbejder</em> og hjælper en...</i
                                >
                            </div>
                        </div>
                    </div>

                    <div class="row review">
                        <div class="col-sm-12 user">
                            <div class="image">
                                <img
                                    src="https://static.next.dk/website/images/pages/online/kennetnielsen.png"
                                    srcset="
                                        https://static.next.dk/website/images/pages/online/kennetnielsen.png    1x,
                                        https://static.next.dk/website/images/pages/online/kennetnielsen@2x.png 2x
                                    "
                                />
                            </div>
                            <div class="userinfo">
                                <b>Kennet Nielsen</b><br />
                                <b><small>Fredericia, DK</small></b
                                ><br />
                                <img
                                    src="https://static.next.dk/website/images/pages/employees/trustpilot-5.png"
                                    srcset="
                                        https://static.next.dk/website/images/pages/employees/trustpilot-5.png    1x,
                                        https://static.next.dk/website/images/pages/employees/trustpilot-5@2x.png 2x
                                    "
                                />
                            </div>
                            <div class="content">
                                <i>...Det fungerer rigtig fint med <em>chat/mail</em> og der er bare styr på servicen!..</i>
                            </div>
                        </div>
                    </div>

                    <div class="row review">
                        <div class="col-sm-12 user">
                            <div class="image">
                                <img
                                    src="https://static.next.dk/website/images/pages/online/no-image.png"
                                    srcset="https://static.next.dk/website/images/pages/online/no-image.png 1x, https://static.next.dk/website/images/pages/online/no-image@2x.png 2x"
                                />
                            </div>
                            <div class="userinfo">
                                <b>Anders Ilenkop</b><br />
                                <b><small>Munkebo, DK</small></b
                                ><br />
                                <img
                                    src="https://static.next.dk/website/images/pages/employees/trustpilot-5.png"
                                    srcset="
                                        https://static.next.dk/website/images/pages/employees/trustpilot-5.png    1x,
                                        https://static.next.dk/website/images/pages/employees/trustpilot-5@2x.png 2x
                                    "
                                />
                            </div>
                            <div class="content">
                                <i>
                                    ...Er yderst tilfreds med next's hjemmeside og den måde man laver sine forsikringer på.<br />
                                    <em>Chatten er altid venlig og man slipper for at sidde 30 min i kø for at snakke med en sælger</em> - På next's hjemmeside får man det hele serveret på
                                    sekunder...
                                </i>
                            </div>
                        </div>
                    </div>

                    <div class="row review">
                        <div class="col-sm-12 user">
                            <div class="image">
                                <img
                                    src="https://static.next.dk/website/images/pages/online/no-image.png"
                                    srcset="https://static.next.dk/website/images/pages/online/no-image.png 1x, https://static.next.dk/website/images/pages/online/no-image@2x.png 2x"
                                />
                            </div>
                            <div class="userinfo">
                                <b>Nadia Brønserud Esmann</b><br />
                                <b><small>Middelfart, DK</small></b
                                ><br />
                                <img
                                    src="https://static.next.dk/website/images/pages/employees/trustpilot-5.png"
                                    srcset="
                                        https://static.next.dk/website/images/pages/employees/trustpilot-5.png    1x,
                                        https://static.next.dk/website/images/pages/employees/trustpilot-5@2x.png 2x
                                    "
                                />
                            </div>
                            <div class="content">
                                <i
                                    >...Det var <em>super nemt, gennemskueligt og effektivt</em> at tegne forsikringerne. <em>Smart med chatfunktion</em> til spørgsmål. Har kun oplevet det
                                    som en god og <em>positiv oplevelse af skifte til Next</em>!...</i
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="gray-bg">
        <div class="container container-padding-40-40">
            <div class="row">
                <div class="col-sm-4 page-content">
                    <h2>{{ 'PAGE.ONLINE_INSURANCE.CLAIMS_TITLE' | translate }}</h2>
                    <p>{{ 'PAGE.ONLINE_INSURANCE.CLAIMS_1' | translate }}</p>
                    <p>
                        {{ 'PAGE.ONLINE_INSURANCE.CLAIMS_2' | translate }}
                    </p>
                    <p>
                        {{ 'PAGE.ONLINE_INSURANCE.CLAIMS_3' | translate }}
                    </p>
                    <p>
                        {{ 'PAGE.ONLINE_INSURANCE.CLAIMS_4' | translate }}
                    </p>
                    <img
                        src="https://static.next.dk/website/images/pages/online/arrow.png"
                        class="pull-right review-arrow"
                        srcset="https://static.next.dk/website/images/pages/online/arrow.png 1x, https://static.next.dk/website/images/pages/online/arrow@2x.png 2x"
                    />
                </div>

                <div class="col-sm-8">
                    <div class="trustpilot-box">
                        <div id="trustpilotSlider" class="carousel slide" data-ride="carousel" data-interval="false">
                            <!-- Wrapper for slides -->
                            <div class="carousel-inner" role="listbox">
                                <div *ngIf="activeSlide === 1" class="item active">
                                    <div class="row review">
                                        <div class="col-sm-12 user">
                                            <div class="image">
                                                <img
                                                    src="https://static.next.dk/website/images/pages/online/kresten.png"
                                                    srcset="
                                                        https://static.next.dk/website/images/pages/online/kresten.png    1x,
                                                        https://static.next.dk/website/images/pages/online/kresten@2x.png 2x
                                                    "
                                                />
                                            </div>
                                            <div class="userinfo">
                                                <b>Kresten Hansen, Husejer</b><br />
                                                <b><small>Randers, DK</small></b
                                                ><br />
                                                <img
                                                    src="https://static.next.dk/website/images/pages/employees/trustpilot-5.png"
                                                    srcset="
                                                        https://static.next.dk/website/images/pages/employees/trustpilot-5.png    1x,
                                                        https://static.next.dk/website/images/pages/employees/trustpilot-5@2x.png 2x
                                                    "
                                                />
                                            </div>
                                            <div class="content">
                                                <h2>Simpelt at bruge - og stærk service når uheldet er ude</h2>
                                                <p>Vi har haft Next næsten fra starten -ca. 2 år, og der har kun været positive oplevelser indtil videre.</p>
                                                <p>
                                                    Lige nu har vi en kæmpe rørskad, som har givet rotter adgang til huset. Der gik
                                                    <em>under et døgn fra jeg anmeldte skaden på hjemmesiden til jeg havde en meget bekymret taksator i røret</em>, som dels ville høre om han
                                                    måtte komme allerede næste dag -også selvom vi ikke ville være hjemme- og dels hvilket firma vi ville foretrække til at afdække og udbedre
                                                    skaden. Han mente ikke at folk med rotter i huset kunne vente et sekund mere en absolut nødvendigt!
                                                </p>
                                                <p>
                                                    Siden har vi været i løbende kontakt og idag var både kloakfolk og taksator til kaffe hvor vi aftalte hvad der skulle laves på
                                                    forsikringen og hvilke muligheder vi har for selv at få forbedret andre ting når nu vi er i gang.
                                                    <em>Alt hvad der har hørt til den nuværende skade, eller som kunne give problemer senere, bliver dækket.</em>
                                                </p>
                                                <p>
                                                    Vi er lettede og glade og glæder os til at håndværkerne snart kommer...
                                                    <em>og så er NEXT i øvrigt ca. 10.000 billigere om året for vores samlede forsikringer, end det firma vi kom fra</em>.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="activeSlide === 2" class="item active">
                                    <div class="row review">
                                        <div class="col-sm-12 user">
                                            <div class="image">
                                                <img
                                                    src="https://static.next.dk/website/images/pages/online/morten.png"
                                                    srcset="
                                                        https://static.next.dk/website/images/pages/online/morten.png    1x,
                                                        https://static.next.dk/website/images/pages/online/morten@2x.png 2x
                                                    "
                                                />
                                            </div>
                                            <div class="userinfo">
                                                <b>Morten Engedal</b><br />
                                                <b><small>Randers, DK</small></b
                                                ><br />
                                                <img
                                                    src="https://static.next.dk/website/images/pages/employees/trustpilot-5.png"
                                                    srcset="
                                                        https://static.next.dk/website/images/pages/employees/trustpilot-5.png    1x,
                                                        https://static.next.dk/website/images/pages/employees/trustpilot-5@2x.png 2x
                                                    "
                                                />
                                            </div>
                                            <div class="content">
                                                <h2>Effektiv kommunikation / Billigt / God kulance</h2>
                                                <p>
                                                    Hjemmesiden fungerer virkelig godt, er overskuelig og giver mere end et godt overblik over dækninger, betalinger, mulige ekstradækninger -
                                                    <em>og skader</em>.
                                                </p>
                                                <p>
                                                    "Skader" fordi jeg desværre allerede har brugt forsikringen en enkelt gang. Der blev smadret en bilrude, som så skulle repareres -
                                                    <em>og jeg var jo spændt på, om "billig" også betød "ikke villig til at dække skader"</em>.
                                                </p>
                                                <p>
                                                    Men slet ikke.
                                                    <em
                                                        >Jeg anmeldte skaden, brugte det indbyggede link direkte til CarGlass og fik i sidste ende dækning uden at skulle betale
                                                        selvrisiko.</em
                                                    >
                                                    Jeg er næsten sikker på, at "uden selvrisiko" delen var kulancemæssigt i forbindelse med dén tlf.snak der også var.
                                                </p>
                                                <p>
                                                    Alt-i-alt ... effektivt via nettet, <em>(meget) billigere end andre og - vigtigst - dækker rigtigt godt.</em> Jeg er en endog meget
                                                    tilfreds kunde
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="activeSlide === 3" class="item active">
                                    <div class="row review">
                                        <div class="col-sm-12 user">
                                            <div class="image">
                                                <img
                                                    src="https://static.next.dk/website/images/pages/online/no-image.png"
                                                    srcset="
                                                        https://static.next.dk/website/images/pages/online/no-image.png    1x,
                                                        https://static.next.dk/website/images/pages/online/no-image@2x.png 2x
                                                    "
                                                />
                                            </div>
                                            <div class="userinfo">
                                                <b>Ryan Hansen</b><br />
                                                <b><small>Randers, DK</small></b
                                                ><br />
                                                <img
                                                    src="https://static.next.dk/website/images/pages/employees/trustpilot-5.png"
                                                    srcset="
                                                        https://static.next.dk/website/images/pages/employees/trustpilot-5.png    1x,
                                                        https://static.next.dk/website/images/pages/employees/trustpilot-5@2x.png 2x
                                                    "
                                                />
                                            </div>
                                            <div class="content">
                                                <i
                                                    >... Har haft <em>en skade på min Volvo og sagen gik smertefrit igennem uden bøvl og problemer</em> så kan klart anbefale NEXT til andre,
                                                    hjemmesiden giver et nemt overblik over priser og dækninger og er nemt at ændre. Top karakter herfra...</i
                                                >
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row review">
                                        <div class="col-sm-12 user">
                                            <div class="image">
                                                <img
                                                    src="https://static.next.dk/website/images/pages/online/no-image.png"
                                                    srcset="
                                                        https://static.next.dk/website/images/pages/online/no-image.png    1x,
                                                        https://static.next.dk/website/images/pages/online/no-image@2x.png 2x
                                                    "
                                                />
                                            </div>
                                            <div class="userinfo">
                                                <b>Troels Haugstrup</b><br />
                                                <b><small>Aarhus, DK</small></b
                                                ><br />
                                                <img
                                                    src="https://static.next.dk/website/images/pages/employees/trustpilot-5.png"
                                                    srcset="
                                                        https://static.next.dk/website/images/pages/employees/trustpilot-5.png    1x,
                                                        https://static.next.dk/website/images/pages/employees/trustpilot-5@2x.png 2x
                                                    "
                                                />
                                            </div>
                                            <div class="content">
                                                <i
                                                    >...Dækninger er super og jeg kan ikke sige andet end at det er det <em>bedste forsikringsselskab</em> jeg har været kunde i,
                                                    <em>og da jeg sidst havde skade var det behandlet inden for 24 timer</em>...</i
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Indicators -->
                            <ol class="carousel-indicators">
                                <li data-target="#trustpilotSlider" (click)="setActiveSlide(1)" [class.active]="activeSlide === 1"></li>
                                <li data-target="#trustpilotSlider" (click)="setActiveSlide(2)" [class.active]="activeSlide === 2"></li>
                                <li data-target="#trustpilotSlider" (click)="setActiveSlide(3)" [class.active]="activeSlide === 3"></li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container container-padding-40-40">
        <div class="row">
            <div class="col-sm-4 page-content">
                <h2>{{ 'PAGE.ONLINE_INSURANCE.ONLINE_TITLE' | translate }}</h2>
                <p>{{ 'PAGE.ONLINE_INSURANCE.ONLINE_1' | translate }}</p>
                <p>{{ 'PAGE.ONLINE_INSURANCE.ONLINE_2' | translate }}</p>
                <p>{{ 'PAGE.ONLINE_INSURANCE.ONLINE_3' | translate }}</p>
                <p>
                    {{ 'PAGE.ONLINE_INSURANCE.ONLINE_4' | translate }}
                </p>
                <p>
                    {{ 'PAGE.ONLINE_INSURANCE.ONLINE_5' | translate }}
                </p>
                <img
                    src="https://static.next.dk/website/images/pages/online/arrow.png"
                    class="pull-right review-arrow"
                    srcset="https://static.next.dk/website/images/pages/online/arrow.png 1x, https://static.next.dk/website/images/pages/online/arrow@2x.png 2x"
                />
            </div>

            <div class="col-sm-8">
                <div class="trustpilot-box">
                    <div class="row review">
                        <div class="col-sm-12 user">
                            <div class="image">
                                <img
                                    src="https://static.next.dk/website/images/pages/online/martin-kirkegaard.png"
                                    srcset="
                                        https://static.next.dk/website/images/pages/online/martin-kirkegaard.png    1x,
                                        https://static.next.dk/website/images/pages/online/martin-kirkegaard@2x.png 2x
                                    "
                                />
                            </div>
                            <div class="userinfo">
                                <b>Martin Kirkegaard</b><br />
                                <b><small>Aalborg, DK</small></b
                                ><br />
                                <img
                                    src="https://static.next.dk/website/images/pages/employees/trustpilot-5.png"
                                    srcset="
                                        https://static.next.dk/website/images/pages/employees/trustpilot-5.png    1x,
                                        https://static.next.dk/website/images/pages/employees/trustpilot-5@2x.png 2x
                                    "
                                />
                            </div>
                            <div class="content">
                                <i
                                    >...Alt i alt har NEXT vist sig, at være en rentable forretning for vores husstand.
                                    <em>På alle vores forsikringer kom vi op at spare over 3000 kroner om året</em>...</i
                                >
                            </div>
                        </div>
                    </div>

                    <div class="row review">
                        <div class="col-sm-12 user">
                            <div class="image">
                                <img
                                    src="https://static.next.dk/website/images/pages/online/martinarendal.png"
                                    srcset="
                                        https://static.next.dk/website/images/pages/online/martinarendal.png    1x,
                                        https://static.next.dk/website/images/pages/online/martinarendal@2x.png 2x
                                    "
                                />
                            </div>
                            <div class="userinfo">
                                <b>Martin Arendal Jensen</b><br />
                                <b><small>Fredericia, DK</small></b
                                ><br />
                                <img
                                    src="https://static.next.dk/website/images/pages/employees/trustpilot-5.png"
                                    srcset="
                                        https://static.next.dk/website/images/pages/employees/trustpilot-5.png    1x,
                                        https://static.next.dk/website/images/pages/employees/trustpilot-5@2x.png 2x
                                    "
                                />
                            </div>
                            <div class="content">
                                <i>...Super oplevelse indtil videre. <em>Ca ½ pris på begge mine biler</em>. Fin support via onlinechat...</i>
                            </div>
                        </div>
                    </div>

                    <div class="row review">
                        <div class="col-sm-12 user">
                            <div class="image">
                                <img
                                    src="https://static.next.dk/website/images/pages/online/no-image.png"
                                    srcset="https://static.next.dk/website/images/pages/online/no-image.png 1x, https://static.next.dk/website/images/pages/online/no-image@2x.png 2x"
                                />
                            </div>
                            <div class="userinfo">
                                <b>Mogens Olesen</b><br />
                                <b><small>Havndal, DK</small></b
                                ><br />
                                <img
                                    src="https://static.next.dk/website/images/pages/employees/trustpilot-5.png"
                                    srcset="
                                        https://static.next.dk/website/images/pages/employees/trustpilot-5.png    1x,
                                        https://static.next.dk/website/images/pages/employees/trustpilot-5@2x.png 2x
                                    "
                                />
                            </div>
                            <div class="content">
                                <i>...Endelig et forsikringsselskab der <em>tager en fair pris for en bilforsikring</em>. Forstår ikke hvorfor jeg ikke har valgt NEXT noget før...</i>
                            </div>
                        </div>
                    </div>

                    <div class="row review">
                        <div class="col-sm-12 user">
                            <div class="image">
                                <img
                                    src="https://static.next.dk/website/images/pages/online/no-image.png"
                                    srcset="https://static.next.dk/website/images/pages/online/no-image.png 1x, https://static.next.dk/website/images/pages/online/no-image@2x.png 2x"
                                />
                            </div>
                            <div class="userinfo">
                                <b>Kim Bloch Grouleff</b><br />
                                <b><small>Viborg, DK</small></b
                                ><br />
                                <img
                                    src="https://static.next.dk/website/images/pages/employees/trustpilot-5.png"
                                    srcset="
                                        https://static.next.dk/website/images/pages/employees/trustpilot-5.png    1x,
                                        https://static.next.dk/website/images/pages/employees/trustpilot-5@2x.png 2x
                                    "
                                />
                            </div>
                            <div class="content">
                                <i>...Kan varmt anbefale Next. <em>DET ER BILLIGT</em> samtidigt med <em>høj service</em>, som jeg ikke har oplevet hos andre forsikringsselskaber...</i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="gray-bg">
        <div class="container container-padding-40-40">
            <div class="row">
                <div class="col-sm-4 page-content">
                    <h2>{{ 'PAGE.ONLINE_INSURANCE.PAYMENT_TITLE' | translate }}</h2>
                    <p>{{ 'PAGE.ONLINE_INSURANCE.PAYMENT_1' | translate }}</p>
                    <p>
                        {{ 'PAGE.ONLINE_INSURANCE.PAYMENT_2' | translate }}
                    </p>
                    <p>{{ 'PAGE.ONLINE_INSURANCE.PAYMENT_3' | translate }}</p>
                    <img
                        src="https://static.next.dk/website/images/pages/online/arrow.png"
                        class="pull-right review-arrow"
                        srcset="https://static.next.dk/website/images/pages/online/arrow.png 1x, https://static.next.dk/website/images/pages/online/arrow@2x.png 2x"
                    />
                </div>

                <div class="col-sm-8">
                    <div class="trustpilot-box">
                        <div class="row review">
                            <div class="col-sm-12 user">
                                <div class="image">
                                    <img
                                        src="https://static.next.dk/website/images/pages/online/nickolai.png"
                                        srcset="https://static.next.dk/website/images/pages/online/nickolai.png 1x, https://static.next.dk/website/images/pages/online/nickolai@2x.png 2x"
                                    />
                                </div>
                                <div class="userinfo">
                                    <b>Nickolai Malmkvist</b><br />
                                    <b><small>Aalborg, DK</small></b
                                    ><br />
                                    <img
                                        src="https://static.next.dk/website/images/pages/employees/trustpilot-5.png"
                                        srcset="
                                            https://static.next.dk/website/images/pages/employees/trustpilot-5.png    1x,
                                            https://static.next.dk/website/images/pages/employees/trustpilot-5@2x.png 2x
                                        "
                                    />
                                </div>
                                <div class="content">
                                    <i>...endelig et sted der både er billigt og så endda også <em>månedsbetaling</em>...</i>
                                </div>
                            </div>
                        </div>

                        <div class="row review">
                            <div class="col-sm-12 user">
                                <div class="image">
                                    <img
                                        src="https://static.next.dk/website/images/pages/online/louise.png"
                                        srcset="https://static.next.dk/website/images/pages/online/louise.png 1x, https://static.next.dk/website/images/pages/online/louise@2x.png 2x"
                                    />
                                </div>
                                <div class="userinfo">
                                    <b>Louise Hintze Jensen</b><br />
                                    <b><small>Varde, DK</small></b
                                    ><br />
                                    <img
                                        src="https://static.next.dk/website/images/pages/employees/trustpilot-5.png"
                                        srcset="
                                            https://static.next.dk/website/images/pages/employees/trustpilot-5.png    1x,
                                            https://static.next.dk/website/images/pages/employees/trustpilot-5@2x.png 2x
                                        "
                                    />
                                </div>
                                <div class="content">
                                    <i
                                        >…Som udeboende og på SU er NEXT den perfekte løsning til et billigt selskab. <em>Månedsbetaling og gebyrfrit</em> - det kan man ikke sige nej til.
                                        Super god service og deres hjemmeside er super overskueligt…</i
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <trustpilot-carousel></trustpilot-carousel>
</div>

<next-footer></next-footer>
