import { CoverConditionCode, CoverConditionType, Cover } from 'app/class/cover';
import { TranslateService } from '@ngx-translate/core';

export class ProductCovers {
    coversMap: {
        car: Cover[];
        // eslint-disable-next-line
        car_simple: Cover[];
        house: Cover[];
        home: Cover[];
        moped: Cover[];
        accident: Cover[];
        secondhouse: Cover[];
        dog: Cover[];
        caravan: Cover[];
    };

    constructor(private translateService: TranslateService) {
        this.initializeCoversMap();
    }

    getCovers(productCode: string): Cover[] {
        return this.coversMap[productCode];
    }

    private initializeCoversMap() {
        this.coversMap = {
            car: [
                {
                    name: this.translateService.instant('INSURANCE.CAR.COMPONENTS.LIABILITY.NAME'),
                    code: 'Liability',
                    description: this.translateService.instant('INSURANCE.CAR.COMPONENTS.LIABILITY.DESCRIPTION'),
                    hidePrice: true,
                    chosen: true,
                    required: true,
                    specialText: this.translateService.instant('INSURANCE.SHARED.INCLUSIVE'),
                    icon: 'nf-car-ansvar',
                },
                {
                    name: this.translateService.instant('INSURANCE.CAR.COMPONENTS.COMPREHENSIVE.NAME'),
                    code: 'Hull',
                    description: this.translateService.instant('INSURANCE.CAR.COMPONENTS.COMPREHENSIVE.DESCRIPTION'),
                    hidePrice: true,
                    specialText: this.translateService.instant('INSURANCE.SHARED.VARIABLE_PRICE'),
                    chosen: true,
                    required: false,
                    conditions: [
                        {
                            code: CoverConditionCode.carRemoveHullNew,
                            type: CoverConditionType.remove,
                            show: false,
                            text: this.translateService.instant('INSURANCE.CAR.COMPONENTS.COMPREHENSIVE.CAR_REMOVE_HULL_NEW'),
                            acceptButtonText: this.translateService.instant('INSURANCE.CAR.COMPONENTS.COMPREHENSIVE.CAR_REMOVE_HULL_NEW_ACCEPT'),
                            denyButtonText: this.translateService.instant('INSURANCE.SHARED.REMOVE'),
                        },
                        {
                            code: CoverConditionCode.carRemoveHull,
                            type: CoverConditionType.remove,
                            show: false,
                            text: this.translateService.instant('INSURANCE.CAR.COMPONENTS.COMPREHENSIVE.CAR_REMOVE_HULL_WITH_GLASS'),
                            acceptButtonText: this.translateService.instant('INSURANCE.CAR.COMPONENTS.COMPREHENSIVE.CAR_REMOVE_HULL_WITH_GLASS_ACCEPT'),
                            denyButtonText: this.translateService.instant('INSURANCE.SHARED.REMOVE'),
                        },
                    ],
                    icon: 'nf-car-kasko',
                },
                {
                    name: this.translateService.instant('INSURANCE.CAR.COMPONENTS.DRIVER_ACCIDENT.NAME'),
                    code: 'DriverAccident',
                    description: this.translateService.instant('INSURANCE.CAR.COMPONENTS.DRIVER_ACCIDENT.DESCRIPTION'),
                    chosen: false,
                    required: false,
                    icon: 'nf-car-frerulykke',
                },
                {
                    name: this.translateService.instant('INSURANCE.CAR.COMPONENTS.ROAD.NAME'),
                    code: 'RoadSideAssistance',
                    description: this.translateService.instant('INSURANCE.CAR.COMPONENTS.ROAD.DESCRIPTION'),
                    chosen: false,
                    required: false,
                    icon: 'nf-car-vejhjlp',
                },
                {
                    name: this.translateService.instant('INSURANCE.CAR.COMPONENTS.GLASS.NAME'),
                    code: 'ExpandedCarGlass',
                    description: this.translateService.instant('INSURANCE.CAR.COMPONENTS.GLASS.DESCRIPTION'),
                    conditions: [
                        {
                            code: CoverConditionCode.carAddGlassNoHull,
                            type: CoverConditionType.add,
                            show: false,
                            text: this.translateService.instant('INSURANCE.CAR.COMPONENTS.GLASS.CAR_ADD_GLASS_NO_HULL'),
                            acceptButtonText: this.translateService.instant('INSURANCE.CAR.COMPONENTS.GLASS.CAR_ADD_GLASS_NO_HULL_ACCEPT'),
                            denyButtonText: this.translateService.instant('INSURANCE.SHARED.UNDO'),
                        },
                    ],
                    chosen: false,
                    required: false,
                    icon: 'nf-car-udvidetglas',
                },
            ],
            car_simple: [
                {
                    name: this.translateService.instant('INSURANCE.CAR.COMPONENTS.LIABILITY.NAME'),
                    code: 'Liability',
                    description: this.translateService.instant('INSURANCE.CAR.COMPONENTS.LIABILITY.DESCRIPTION'),
                    hidePrice: true,
                    chosen: true,
                    required: true,
                    specialText: this.translateService.instant('INSURANCE.SHARED.INCLUSIVE'),
                    icon: 'nf-car-ansvar',
                },
                {
                    name: this.translateService.instant('INSURANCE.CARSIMPLE.COMPONENTS.DRIVER_ACCIDENT.NAME'),
                    code: 'DriverAccident',
                    description: this.translateService.instant('INSURANCE.CARSIMPLE.COMPONENTS.DRIVER_ACCIDENT.DESCRIPTION'),
                    chosen: false,
                    required: false,
                    icon: 'nf-car-frerulykke',
                },
                {
                    name: this.translateService.instant('INSURANCE.CAR.COMPONENTS.COMPREHENSIVE.NAME'),
                    code: 'Hull',
                    description: this.translateService.instant('INSURANCE.CAR.COMPONENTS.COMPREHENSIVE.DESCRIPTION'),
                    hidePrice: true,
                    specialText: this.translateService.instant('INSURANCE.SHARED.VARIABLE_PRICE'),
                    chosen: true,
                    required: false,
                    conditions: [
                        {
                            code: CoverConditionCode.carRemoveHullNew,
                            type: CoverConditionType.remove,
                            show: false,
                            text: this.translateService.instant('INSURANCE.CAR.COMPONENTS.COMPREHENSIVE.CAR_REMOVE_HULL_NEW'),
                            acceptButtonText: this.translateService.instant('INSURANCE.CAR.COMPONENTS.COMPREHENSIVE.CAR_REMOVE_HULL_NEW_ACCEPT'),
                            denyButtonText: this.translateService.instant('INSURANCE.SHARED.REMOVE'),
                        },
                        {
                            code: CoverConditionCode.carRemoveHull,
                            type: CoverConditionType.remove,
                            show: false,
                            text: this.translateService.instant('INSURANCE.CAR.COMPONENTS.COMPREHENSIVE.CAR_REMOVE_HULL'),
                            acceptButtonText: this.translateService.instant('INSURANCE.CAR.COMPONENTS.COMPREHENSIVE.CAR_REMOVE_HULL_ACCEPT'),
                            denyButtonText: this.translateService.instant('INSURANCE.SHARED.REMOVE'),
                        },
                    ],
                    icon: 'nf-car-kasko',
                },
                {
                    name: this.translateService.instant('INSURANCE.CAR.COMPONENTS.GLASS.NAME'),
                    code: 'ExpandedCarGlass',
                    description: this.translateService.instant('INSURANCE.CARSIMPLE.COMPONENTS.GLASS.DESCRIPTION'),
                    requiresCoverCodes: ['Hull'],
                    conditions: [
                        {
                            code: CoverConditionCode.carAddGlassNoHull,
                            type: CoverConditionType.add,
                            show: false,
                            text: this.translateService.instant('INSURANCE.CAR.COMPONENTS.GLASS.CAR_ADD_GLASS_NO_HULL'),
                            acceptButtonText: this.translateService.instant('INSURANCE.CAR.COMPONENTS.GLASS.CAR_ADD_GLASS_NO_HULL_ACCEPT'),
                            denyButtonText: this.translateService.instant('INSURANCE.SHARED.UNDO'),
                        },
                    ],
                    chosen: false,
                    required: false,
                    icon: 'nf-car-udvidetglas',
                },
                {
                    name: this.translateService.instant('INSURANCE.CAR.COMPONENTS.ROAD.NAME'),
                    code: 'RoadSideAssistance',
                    description: this.translateService.instant('INSURANCE.CAR.COMPONENTS.ROAD.DESCRIPTION'),
                    chosen: false,
                    required: false,
                    icon: 'nf-car-vejhjlp',
                },
                {
                    name: this.translateService.instant('INSURANCE.CAR.COMPONENTS.NO_DEDUCTIBLE.NAME'),
                    code: 'NoDeductible',
                    description: this.translateService.instant('INSURANCE.CAR.COMPONENTS.NO_DEDUCTIBLE.DESCRIPTION'),
                    requiresCoverCodes: ['Hull'],
                    conditions: [
                        {
                            code: CoverConditionCode.carAddNoDeductibleNoHull,
                            type: CoverConditionType.add,
                            show: false,
                            text: this.translateService.instant('INSURANCE.CAR.COMPONENTS.NO_DEDUCTIBLE.CAR_ADD_NO_DEDUCTIBLE_NO_HULL'),
                            acceptButtonText: this.translateService.instant('INSURANCE.CAR.COMPONENTS.NO_DEDUCTIBLE.CAR_ADD_NO_DEDUCTIBLE_NO_HULL_ACCEPT'),
                            denyButtonText: this.translateService.instant('INSURANCE.SHARED.UNDO'),
                        },
                    ],
                    chosen: false,
                    required: false,
                    icon: 'nf-car-friskade',
                },
                {
                    name: this.translateService.instant('INSURANCE.CAR.COMPONENTS.PARKING_DAMAGE.NAME'),
                    code: 'ParkingDamage',
                    description: this.translateService.instant('INSURANCE.CAR.COMPONENTS.PARKING_DAMAGE.DESCRIPTION'),
                    requiresCoverCodes: ['Hull'],
                    conditions: [
                        {
                            code: CoverConditionCode.carAddParkingDamageNoHull,
                            type: CoverConditionType.add,
                            show: false,
                            text: this.translateService.instant('INSURANCE.CAR.COMPONENTS.PARKING_DAMAGE.CAR_ADD_PARKING_DAMAGE_NO_HULL'),
                            acceptButtonText: this.translateService.instant('INSURANCE.CAR.COMPONENTS.PARKING_DAMAGE.CAR_ADD_PARKING_DAMAGE_NO_HULL_ACCEPT'),
                            denyButtonText: this.translateService.instant('INSURANCE.SHARED.UNDO'),
                        },
                    ],
                    chosen: false,
                    required: false,
                    icon: 'nf-car-parking',
                },
                {
                    name: this.translateService.instant('INSURANCE.CAR.COMPONENTS.PLUS_COVER.NAME'),
                    code: 'PlusCover',
                    description: this.translateService.instant('INSURANCE.CAR.COMPONENTS.PLUS_COVER.DESCRIPTION'),
                    requiresCoverCodes: ['Hull'],
                    conditions: [
                        {
                            code: CoverConditionCode.carAddPlusCoverNoHull,
                            type: CoverConditionType.add,
                            show: false,
                            text: this.translateService.instant('INSURANCE.CAR.COMPONENTS.PLUS_COVER.CAR_ADD_PLUS_COVER_NO_HULL'),
                            acceptButtonText: this.translateService.instant('INSURANCE.CAR.COMPONENTS.PLUS_COVER.CAR_ADD_PLUS_COVER_NO_HULL_ACCEPT'),
                            denyButtonText: this.translateService.instant('INSURANCE.SHARED.UNDO'),
                        },
                    ],
                    chosen: false,
                    required: false,
                    icon: 'nf-car-plus',
                },
            ],
            house: [
                {
                    name: this.translateService.instant('INSURANCE.HOUSE.COMPONENTS.COMPREHENSIVE.NAME'),
                    code: 'Liability',
                    description: this.translateService.instant('INSURANCE.HOUSE.COMPONENTS.COMPREHENSIVE.DESCRIPTION'),
                    additionalCodes: ['Fire', 'Hull', 'GlassSanitation'],
                    chosen: true,
                    required: true,
                    hidePrice: true,
                    icon: 'nf-house-husansvar',
                    specialText: this.translateService.instant('INSURANCE.SHARED.INCLUSIVE'),
                },
                {
                    name: this.translateService.instant('INSURANCE.HOUSE.COMPONENTS.PIPE.NAME'),
                    code: 'PipesCables',
                    description: this.translateService.instant('INSURANCE.HOUSE.COMPONENTS.PIPE.DESCRIPTION'),
                    conditions: [
                        {
                            code: CoverConditionCode.houseRemovePipesCables,
                            type: CoverConditionType.remove,
                            show: false,
                            text: this.translateService.instant('INSURANCE.HOUSE.COMPONENTS.PIPE.HOUSE_REMOVE_PIPES_CABLES'),
                            acceptButtonText: this.translateService.instant('INSURANCE.HOUSE.COMPONENTS.PIPE.HOUSE_REMOVE_PIPES_CABLES_ACCEPT'),
                            denyButtonText: this.translateService.instant('INSURANCE.HOUSE.COMPONENTS.PIPE.HOUSE_REMOVE_PIPES_CABLES_DENY'),
                        },
                    ],
                    chosen: true,
                    required: false,
                    icon: 'nf-house-roer-og-kabel',
                },
                {
                    name: this.translateService.instant('INSURANCE.HOUSE.COMPONENTS.INSECT.NAME'),
                    code: 'FungusInsect',
                    description: this.translateService.instant('INSURANCE.HOUSE.COMPONENTS.INSECT.DESCRIPTION'),
                    conditions: [
                        {
                            code: CoverConditionCode.houseRemoveFungusInsect,
                            type: CoverConditionType.remove,
                            show: false,
                            text: this.translateService.instant('INSURANCE.HOUSE.COMPONENTS.INSECT.HOUSE_REMOVE_FUNGUS_INSECT'),
                            acceptButtonText: this.translateService.instant('INSURANCE.HOUSE.COMPONENTS.INSECT.HOUSE_REMOVE_FUNGUS_INSECT_ACCEPT'),
                            denyButtonText: this.translateService.instant('INSURANCE.HOUSE.COMPONENTS.INSECT.HOUSE_REMOVE_FUNGUS_INSECT_DENY'),
                        },
                        {
                            code: CoverConditionCode.houseRemoveFungusInsectOnlyRot,
                            type: CoverConditionType.remove,
                            show: false,
                            text: this.translateService.instant('INSURANCE.HOUSE.COMPONENTS.INSECT.HOUSE_REMOVE_FUNGUS_INSECT_ONLY_ROT'),
                            acceptButtonText: this.translateService.instant('INSURANCE.HOUSE.COMPONENTS.INSECT.HOUSE_REMOVE_FUNGUS_INSECT_ONLY_ROT_ACCEPT'),
                            denyButtonText: this.translateService.instant('INSURANCE.HOUSE.COMPONENTS.INSECT.HOUSE_REMOVE_FUNGUS_INSECT_ONLY_ROT_DENY'),
                        },
                    ],
                    chosen: true,
                    required: false,
                    icon: 'nf-house-insekt-og-svamp',
                },
                {
                    name: this.translateService.instant('INSURANCE.HOUSE.COMPONENTS.SERVICE_LINE.NAME'),
                    code: 'OutdoorPipes',
                    description: this.translateService.instant('INSURANCE.HOUSE.COMPONENTS.SERVICE_LINE.DESCRIPTION'),
                    conditions: [
                        {
                            code: CoverConditionCode.houseRemoveOutdoorPipes,
                            type: CoverConditionType.remove,
                            show: false,
                            text: this.translateService.instant('INSURANCE.HOUSE.COMPONENTS.SERVICE_LINE.HOUSE_REMOVE_OUTDOOR_PIPES'),
                            acceptButtonText: this.translateService.instant('INSURANCE.HOUSE.COMPONENTS.SERVICE_LINE.HOUSE_REMOVE_OUTDOOR_PIPES_ACCEPT'),
                            denyButtonText: this.translateService.instant('INSURANCE.HOUSE.COMPONENTS.SERVICE_LINE.HOUSE_REMOVE_OUTDOOR_PIPES_DENY'),
                        },
                    ],
                    chosen: true,
                    required: false,
                    icon: 'nf-broken-pipe',
                },
                {
                    name: this.translateService.instant('INSURANCE.HOUSE.COMPONENTS.ROT.NAME'),
                    code: 'Rot',
                    description: this.translateService.instant('INSURANCE.HOUSE.COMPONENTS.ROT.DESCRIPTION'),
                    conditions: [
                        {
                            code: CoverConditionCode.houseAddRot,
                            type: CoverConditionType.add,
                            show: false,
                            text: this.translateService.instant('INSURANCE.HOUSE.COMPONENTS.ROT.HOUSE_ADD_ROT'),
                            acceptButtonText: this.translateService.instant('INSURANCE.HOUSE.COMPONENTS.ROT.HOUSE_ADD_ROT_ACCEPT'),
                            denyButtonText: this.translateService.instant('INSURANCE.SHARED.UNDO'),
                        },
                        {
                            code: CoverConditionCode.houseRemoveRot,
                            type: CoverConditionType.remove,
                            show: false,
                            text: this.translateService.instant('INSURANCE.HOUSE.COMPONENTS.ROT.HOUSE_REMOVE_ROT'),
                            acceptButtonText: this.translateService.instant('INSURANCE.HOUSE.COMPONENTS.ROT.HOUSE_REMOVE_ROT_ACCEPT'),
                            denyButtonText: this.translateService.instant('INSURANCE.HOUSE.COMPONENTS.ROT.HOUSE_REMOVE_ROT_DENY'),
                        },
                    ],
                    chosen: false,
                    required: false,
                    icon: 'nf-house-raad',
                },
                {
                    name: this.translateService.instant('INSURANCE.HOUSE.COMPONENTS.EXTENDED.NAME'),
                    code: 'ExpandedCover',
                    description: this.translateService.instant('INSURANCE.HOUSE.COMPONENTS.EXTENDED.DESCRIPTION'),
                    conditions: [
                        {
                            code: CoverConditionCode.houseAddExpandedCover,
                            type: CoverConditionType.add,
                            show: false,
                            text: this.translateService.instant('INSURANCE.HOUSE.COMPONENTS.EXTENDED.HOUSE_ADD_EXPANDED_COVER'),
                            acceptButtonText: this.translateService.instant('INSURANCE.HOUSE.COMPONENTS.EXTENDED.HOUSE_ADD_EXPANDED_COVER_ACCEPT'),
                            denyButtonText: this.translateService.instant('INSURANCE.SHARED.UNDO'),
                        },
                    ],
                    chosen: false,
                    required: false,
                    icon: 'nf-house-udvidet-deakning',
                },
            ],
            home: [
                {
                    name: this.translateService.instant('INSURANCE.HOME.COMPONENTS.PRIVATE.NAME'),
                    code: 'Contents',
                    description: this.translateService.instant('INSURANCE.HOME.COMPONENTS.PRIVATE.DESCRIPTION'),

                    chosen: true,
                    required: true,
                    hidePrice: true,
                    icon: 'nf-home-ansvar',
                    specialText: this.translateService.instant('INSURANCE.SHARED.INCLUSIVE'),
                },
                {
                    name: this.translateService.instant('INSURANCE.HOME.COMPONENTS.TRAVEL.NAME'),
                    code: 'TravelCover',
                    description: this.translateService.instant('INSURANCE.HOME.COMPONENTS.TRAVEL.DESCRIPTION'),
                    conditions: [
                        {
                            code: CoverConditionCode.homeRemoveTravelInsurance,
                            type: CoverConditionType.remove,
                            show: false,
                            text: this.translateService.instant('INSURANCE.HOME.COMPONENTS.TRAVEL.HOME_REMOVE_TRAVEL_INSURANCE'),
                            acceptButtonText: this.translateService.instant('INSURANCE.HOME.COMPONENTS.TRAVEL.HOME_REMOVE_TRAVEL_INSURANCE_ACCEPT'),
                            denyButtonText: this.translateService.instant('INSURANCE.SHARED.REMOVE'),
                        },
                    ],
                    chosen: true,
                    required: false,
                    icon: 'nf-home-rejseforsikring',
                },
                {
                    name: this.translateService.instant('INSURANCE.HOME.COMPONENTS.IDENTITY.NAME'),
                    code: 'IdSecurity',
                    description: this.translateService.instant('INSURANCE.HOME.COMPONENTS.IDENTITY.DESCRIPTION'),
                    chosen: true,
                    required: false,
                    icon: 'nf-home-idsikring',
                },
                {
                    name: this.translateService.instant('INSURANCE.HOME.COMPONENTS.CANCELLATION.NAME'),
                    code: 'TravelCancellation',
                    description: this.translateService.instant('INSURANCE.HOME.COMPONENTS.CANCELLATION.DESCRIPTION'),
                    conditions: [
                        {
                            code: CoverConditionCode.homeAddCancellation,
                            type: CoverConditionType.add,
                            show: false,
                            text: this.translateService.instant('INSURANCE.HOME.COMPONENTS.CANCELLATION.HOME_ADD_CANCELLATION'),
                            acceptButtonText: this.translateService.instant('INSURANCE.HOME.COMPONENTS.CANCELLATION.HOME_ADD_CANCELLATION_ACCEPT'),
                            denyButtonText: this.translateService.instant('INSURANCE.SHARED.UNDO'),
                        },
                    ],
                    chosen: false,
                    required: false,
                    icon: 'nf-home-afbestillingsforsikring',
                },
                {
                    name: this.translateService.instant('INSURANCE.HOME.COMPONENTS.COUNSELING.NAME'),
                    code: 'MentalCounselling',
                    description: this.translateService.instant('INSURANCE.HOME.COMPONENTS.COUNSELING.DESCRIPTION'),
                    chosen: false,
                    required: false,
                    icon: 'nf-home-krisehjaelp',
                },
                {
                    name: this.translateService.instant('INSURANCE.HOME.COMPONENTS.GLASS.NAME'),
                    code: 'GlassSanitation',
                    description: this.translateService.instant('INSURANCE.HOME.COMPONENTS.GLASS.DESCRIPTION'),
                    conditions: [
                        {
                            code: CoverConditionCode.homeAddGlassSanitation,
                            type: CoverConditionType.add,
                            show: false,
                            text: this.translateService.instant('INSURANCE.HOME.COMPONENTS.GLASS.HOME_ADD_GLASS_SANITATION'),
                            acceptButtonText: this.translateService.instant('INSURANCE.HOME.COMPONENTS.GLASS.HOME_ADD_GLASS_SANITATION_ACCEPT'),
                            denyButtonText: this.translateService.instant('INSURANCE.SHARED.UNDO'),
                        },
                    ],
                    chosen: false,
                    required: false,
                    icon: 'nf-house-glas-og-sanitet',
                },
                {
                    name: this.translateService.instant('INSURANCE.HOME.COMPONENTS.ELECTRONIC.NAME'),
                    code: 'ElectronicCover',
                    description: this.translateService.instant('INSURANCE.HOME.COMPONENTS.ELECTRONIC.DESCRIPTION'),
                    chosen: false,
                    required: false,
                    icon: 'nf-home-elektronik-dkning',
                },
                {
                    name: this.translateService.instant('INSURANCE.HOME.COMPONENTS.SUDDEN.NAME'),
                    code: 'SuddenDamage',
                    description: this.translateService.instant('INSURANCE.HOME.COMPONENTS.SUDDEN.DESCRIPTION'),
                    chosen: false,
                    required: false,
                    icon: 'nf-home-pludselig-skade',
                },
                {
                    name: this.translateService.instant('INSURANCE.HOME.COMPONENTS.BIKE_THEFT.NAME'),
                    code: 'ExtendedBikeTheft',
                    description: this.translateService.instant('INSURANCE.HOME.COMPONENTS.BIKE_THEFT.DESCRIPTION'),
                    chosen: false,
                    required: false,
                    icon: 'nf-plus-icon-v3',
                },
            ],
            accident: [
                {
                    name: this.translateService.instant('INSURANCE.ACCIDENT.COMPONENTS.TREATMENT.NAME'),
                    code: 'TreatmentCosts',
                    description: this.translateService.instant('INSURANCE.ACCIDENT.COMPONENTS.TREATMENT.DESCRIPTION'),
                    chosen: true,
                    required: true,
                    hidePrice: true,
                    icon: 'nf-home-krisehjaelp',
                    specialText: this.translateService.instant('INSURANCE.SHARED.INCLUSIVE'),
                },
                {
                    name: this.translateService.instant('INSURANCE.ACCIDENT.COMPONENTS.DISABILITY.NAME'),
                    code: 'Disability',
                    description: this.translateService.instant('INSURANCE.ACCIDENT.COMPONENTS.DISABILITY.DESCRIPTION'),
                    chosen: true,
                    required: true,
                    hidePrice: true,
                    icon: 'nf-accident-invaliditetsdkning',
                    specialText: this.translateService.instant('INSURANCE.SHARED.INCLUSIVE'),
                },
                {
                    name: this.translateService.instant('INSURANCE.ACCIDENT.COMPONENTS.DENTAL.NAME'),
                    code: 'ToothDamage',
                    description: this.translateService.instant('INSURANCE.ACCIDENT.COMPONENTS.DENTAL.DESCRIPTION'),
                    chosen: true,
                    required: true,
                    hidePrice: true,
                    specialText: this.translateService.instant('INSURANCE.SHARED.INCLUSIVE'),
                    icon: 'nf-accident-tandskade',
                },
                {
                    name: this.translateService.instant('INSURANCE.ACCIDENT.COMPONENTS.SPORT.NAME'),
                    code: 'DangerousSport',
                    description: this.translateService.instant('INSURANCE.ACCIDENT.COMPONENTS.SPORT.DESCRIPTION'),
                    chosen: false,
                    required: false,
                    hidePrice: true,
                    specialText: this.translateService.instant('INSURANCE.SHARED.VARIABLE_PRICE'),
                    icon: 'nf-accident-farlig-sport',
                    conditions: [
                        {
                            code: CoverConditionCode.accidentRiskSportHighSum,
                            type: CoverConditionType.add,
                            show: false,
                            text: this.translateService.instant('INSURANCE.ACCIDENT.COMPONENTS.SPORT.ACCIDENT_RISK_SPORT_HIGH_SUM'),
                            acceptButtonText: this.translateService.instant('INSURANCE.ACCIDENT.COMPONENTS.SPORT.ACCIDENT_RISK_SPORT_HIGH_SUM_ACCEPT'),
                            denyButtonText: this.translateService.instant('INSURANCE.ACCIDENT.COMPONENTS.SPORT.ACCIDENT_RISK_SPORT_HIGH_SUM_DENY'),
                        },
                    ],
                },
                {
                    name: this.translateService.instant('INSURANCE.ACCIDENT.COMPONENTS.BIKE.NAME'),
                    code: 'Transport',
                    description: this.translateService.instant('INSURANCE.ACCIDENT.COMPONENTS.BIKE.DESCRIPTION'),
                    chosen: false,
                    required: false,
                    hidePrice: true,
                    specialText: this.translateService.instant('INSURANCE.SHARED.VARIABLE_PRICE'),
                    icon: 'nf-accident-motorcykel',
                },
                {
                    name: this.translateService.instant('INSURANCE.ACCIDENT.COMPONENTS.DOUBLE.NAME'),
                    code: 'DoubleCompensation',
                    description: this.translateService.instant('INSURANCE.ACCIDENT.COMPONENTS.DOUBLE.DESCRIPTION'),
                    chosen: false,
                    required: false,
                    hidePrice: true,
                    specialText: this.translateService.instant('INSURANCE.SHARED.VARIABLE_PRICE'),
                    icon: 'nf-accident-dobbelt-kompensation',
                },
            ],
            dog: [
                {
                    name: this.translateService.instant('INSURANCE.DOG.COMPONENTS.LIABILITY.NAME'),
                    code: 'Liability',
                    description: this.translateService.instant('INSURANCE.DOG.COMPONENTS.LIABILITY.DESCRIPTION'),
                    chosen: true,
                    required: true,
                    hidePrice: true,
                    icon: 'nf-dog-ansvar',
                    specialText: this.translateService.instant('INSURANCE.SHARED.INCLUSIVE'),
                },
                {
                    name: this.translateService.instant('INSURANCE.DOG.COMPONENTS.EXTENDED.NAME'),
                    code: 'ExpandedDogLiability',
                    description: this.translateService.instant('INSURANCE.DOG.COMPONENTS.EXTENDED.DESCRIPTION'),
                    chosen: false,
                    required: false,
                    icon: 'nf-dog-udvidet-ansvar',
                },
                {
                    name: this.translateService.instant('INSURANCE.DOG.COMPONENTS.SICKNESS.NAME'),
                    code: 'DogIllnessInjury',
                    description: this.translateService.instant('INSURANCE.DOG.COMPONENTS.SICKNESS.DESCRIPTION'),

                    chosen: false,
                    required: false,
                    icon: 'nf-dog-hundesyge',
                },
            ],
            secondhouse: [
                {
                    name: this.translateService.instant('INSURANCE.SECONDHOUSE.COMPONENTS.LIABILITY.NAME'),
                    code: 'Liability',
                    description: this.translateService.instant('INSURANCE.SECONDHOUSE.COMPONENTS.LIABILITY.DESCRIPTION'),
                    additionalCodes: ['FireBuilding', 'Hull', 'GlassSanitation'],
                    chosen: true,
                    required: true,
                    hidePrice: true,
                    icon: 'nf-house-husansvar',
                    item: 'house',
                    specialText: this.translateService.instant('INSURANCE.SHARED.INCLUSIVE'),
                },
                {
                    name: this.translateService.instant('INSURANCE.SECONDHOUSE.COMPONENTS.PIPE.NAME'),
                    code: 'PipesCables',
                    description: this.translateService.instant('INSURANCE.SECONDHOUSE.COMPONENTS.PIPE.DESCRIPTION'),
                    chosen: true,
                    required: false,
                    icon: 'nf-house-roer-og-kabel',
                    item: 'house',
                },
                {
                    name: this.translateService.instant('INSURANCE.SECONDHOUSE.COMPONENTS.INSECT.NAME'),
                    code: 'FungusInsect',
                    description: this.translateService.instant('INSURANCE.SECONDHOUSE.COMPONENTS.INSECT.DESCRIPTION'),
                    chosen: true,
                    required: false,
                    icon: 'nf-house-insekt-og-svamp',
                    item: 'house',
                },
                {
                    name: this.translateService.instant('INSURANCE.SECONDHOUSE.COMPONENTS.SERVICE_LINE.NAME'),
                    code: 'OutdoorPipes',
                    description: this.translateService.instant('INSURANCE.SECONDHOUSE.COMPONENTS.SERVICE_LINE.DESCRIPTION'),
                    chosen: true,
                    required: false,
                    icon: 'nf-broken-pipe',
                    item: 'house',
                },
                {
                    name: this.translateService.instant('INSURANCE.SECONDHOUSE.COMPONENTS.EXTENDED.NAME'),
                    code: 'ExpandedCover',
                    description: this.translateService.instant('INSURANCE.SECONDHOUSE.COMPONENTS.EXTENDED.DESCRIPTION'),
                    chosen: false,
                    required: false,
                    icon: 'nf-house-udvidet-deakning',
                    item: 'house',
                },
                {
                    name: this.translateService.instant('INSURANCE.SECONDHOUSE.COMPONENTS.PRIVATE.NAME'),
                    code: 'Contents',
                    description: this.translateService.instant('INSURANCE.SECONDHOUSE.COMPONENTS.PRIVATE.DESCRIPTION'),
                    chosen: true,
                    required: true,
                    hidePrice: true,
                    icon: 'nf-home-ansvar',
                    item: 'home',
                    specialText: this.translateService.instant('INSURANCE.SHARED.INCLUSIVE'),
                },
                {
                    name: this.translateService.instant('INSURANCE.SECONDHOUSE.COMPONENTS.ELECTRONIC.NAME'),
                    code: 'ElectronicCover',
                    description: this.translateService.instant('INSURANCE.SECONDHOUSE.COMPONENTS.ELECTRONIC.DESCRIPTION'),
                    chosen: false,
                    required: false,
                    icon: 'nf-home-elektronik-dkning',
                    item: 'home',
                },
                {
                    name: this.translateService.instant('INSURANCE.SECONDHOUSE.COMPONENTS.SUDDEN.NAME'),
                    code: 'SuddenDamage',
                    description: this.translateService.instant('INSURANCE.SECONDHOUSE.COMPONENTS.SUDDEN.DESCRIPTION'),
                    chosen: false,
                    required: false,
                    icon: 'nf-home-pludselig-skade',
                    item: 'home',
                },
            ],
            moped: [
                {
                    name: this.translateService.instant('INSURANCE.MOPED.COMPONENTS.LIABILITY.NAME'),
                    code: 'Liability',
                    description: this.translateService.instant('INSURANCE.MOPED.COMPONENTS.LIABILITY.DESCRIPTION'),
                    chosen: true,
                    required: true,
                    icon: 'nf-product-moped',
                },
            ],
            caravan: [
                {
                    name: this.translateService.instant('INSURANCE.CARAVAN.COMPONENTS.HULL.NAME'),
                    code: 'Hull',
                    description: this.translateService.instant('INSURANCE.CARAVAN.COMPONENTS.HULL.DESCRIPTION'),

                    chosen: true,
                    required: true,
                    icon: 'nf-caravan-kasko',
                },
                {
                    name: this.translateService.instant('INSURANCE.CARAVAN.COMPONENTS.HOME.NAME'),
                    code: 'Contents',
                    description: this.translateService.instant('INSURANCE.CARAVAN.COMPONENTS.HOME.DESCRIPTION'),

                    chosen: true,
                    required: true,
                    hidePrice: true,
                    icon: 'nf-caravan-indbo',
                    specialText: this.translateService.instant('INSURANCE.SHARED.INCLUSIVE'),
                },
                {
                    name: this.translateService.instant('INSURANCE.CARAVAN.COMPONENTS.ROAD.NAME'),
                    code: 'RoadSideAssistance',
                    description: this.translateService.instant('INSURANCE.CARAVAN.COMPONENTS.ROAD.DESCRIPTION'),
                    chosen: false,
                    required: false,
                    icon: 'nf-caravan-vejhjlp',
                },
            ],
        };
    }
}
