import { ChatEmployee } from './chatEmployee';
import { AppConfigService } from 'app/utils/appconfig/app-config.service';
import { CustomerServiceEmployee, KeyValuePairOfStringAndString, OpeningHours } from 'next-typescript-api';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

// ChatController
@Injectable()
export class ChatService {
    public customerserviceemployee$: Observable<ChatEmployee>;
    private apiUrl: string;

    private customerserviceemployee: ChatEmployee = this.getChatEmployee();
    private chatEmployeeSource = new BehaviorSubject<ChatEmployee>(this.customerserviceemployee);

    constructor(private http: HttpClient, private config: AppConfigService, private translateService: TranslateService) {
        this.apiUrl = this.config.appConfig.apiHost;
        this.customerserviceemployee$ = this.chatEmployeeSource.asObservable();

        this.chatEmployeeSource.next(this.customerserviceemployee);

        // Maybe they change their mind and want employee pictures again at some point...
        // const url = `${this.apiUrl}/chat/online-service-employee?api-version=2.0`;

        // this.http.get<CustomerServiceEmployee>(url).subscribe((resp) => {
        //     this.customerserviceemployee = resp ? this.getChatEmployee(resp.name) : this.getChatEmployee('Alexander');
        //     this.chatEmployeeSource.next(this.customerserviceemployee);
        // });
    }

    getIsChatOpen(): Observable<boolean> {
        const result = new Observable<boolean>((subscriber) => {
            this.getOpeningHours().subscribe((x) => {
                subscriber.next(x.isCurrentlyOpen);
            });
        });
        return result;
    }

    getChatOpenHours(): Observable<string> {
        const result = new Observable<string>((subscriber) => {
            this.getOpeningHours().subscribe((x) => {
                subscriber.next(this.getChatOpenHoursText(x));
            });
        });
        return result;
    }

    getChatOpenHours7Days(): Observable<KeyValuePairOfStringAndString[]> {
        const result = new Observable<KeyValuePairOfStringAndString[]>((subscriber) => {
            this.getOpeningHours(7).subscribe((x) => {
                subscriber.next(this.get7DaysValues(x));
            });
        });
        return result;
    }

    private getChatEmployee(name?: string): ChatEmployee {
        // Must match the images in https://static.next.dk/website/images/pages/calculate/chatemployees
        const employees = this.config.appConfig.chatEmployees;
        const chatEmployeeImages = employees?.split(';');

        const imageUrlBase = 'https://static.next.dk/website/images/pages/calculate/chatemployees/';
        let employeeImageName = 'kundeservice-new';
        let imageFileExtension = '.png';

        if (name && name.length > 0 && chatEmployeeImages) {
            const employee = chatEmployeeImages.find((x) => x.toLowerCase() === name.toLowerCase());
            if (employee) {
                employeeImageName = name.toLowerCase();
                imageFileExtension = '.jpg';
            }
        }

        const chatEmployee: ChatEmployee = { name, imageUrl: imageUrlBase + employeeImageName + imageFileExtension };
        return chatEmployee;
    }

    private getOpeningHours(days: number = 1): Observable<OpeningHours> {
        const url = `${this.apiUrl}/chat/opening-hours?days=${days}&api-version=2.0`;
        return this.http.get<OpeningHours>(url);
    }

    private getChatOpenHoursText(data: OpeningHours): string {
        let text = '';
        const today = data.days[0];
        if (!today.isClosed) {
            text = this.translateService.instant('UTILS.CHAT.CHAT_IS_OPEN');
            text += ' ';
            text += this.getFromToText(today.from, today.to);
        } else {
            text = this.translateService.instant('UTILS.CHAT.CHAT_IS_CLOSED');
            if (today.holidayCode) {
                text += ' ' + this.translateService.instant('UTILS.CHAT.BECAUSE_OF') + ' ' + this.translateService.instant('UTILS.CHAT.HOLIDAYS.' + today.holidayCode);
            }
        }
        return text;
    }

    private getFromToText(from: Date, to: Date): string {
        return this.getTimeOfDay(from) + ' - ' + this.getTimeOfDay(to);
    }

    private getTimeOfDay(input: Date): string {
        const date = new Date(input);
        if (date == null) {
            return '';
        }
        // date.toLocaleString() not supported by safari
        const timestring = ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2);
        return timestring;
    }

    private get7DaysValues(data: OpeningHours): KeyValuePairOfStringAndString[] {
        const result: KeyValuePairOfStringAndString[] = [];
        data.days.forEach((x) => {
            const dayName = this.translateService.instant('UTILS.CHAT.DAYS.' + x.weekday);
            let openingTime = '';
            if (x.holidayCode) {
                openingTime = this.translateService.instant('UTILS.CHAT.HOLIDAYS.' + x.holidayCode);
            } else if (x.isClosed) {
                openingTime = this.translateService.instant('UTILS.CHAT.CLOSED');
            } else {
                openingTime = this.getFromToText(x.from, x.to);
            }
            result.push({ key: dayName, value: openingTime });
        });
        return result;
    }
}
