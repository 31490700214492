import { AuthService } from 'app/auth/auth.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavigationService } from 'app/utils/navigation/navigation.service';
import { MailticketService } from '../../mailticket.service';
import { Router } from '@angular/router';
import { MetaTagsHelper } from 'app/utils/metaTags/metaTagsHelper';
import { Subscription } from 'rxjs';
import { CreateContactMailTicketInput, SiteSettingType } from 'next-typescript-api';
import { ChatService } from 'app/utils/chat/chat.service';
import { SiteSettingsService } from 'app/utils/sitesettings/site-settings.service';
import { TopbarService } from 'app/shared/topbar/topbar.service';

declare let $zopim: any;

@Component({
    moduleId: module.id,
    selector: 'contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.less'],
})
export class ContactComponent implements OnInit, OnDestroy {
    creatingTicket: boolean;
    createTicketError: boolean;
    createTicketSuccess: boolean;

    public chatopenhours7days: any;
    public ischatopen: boolean;
    public chatopenhours: string;
    displayPhoneNumber: boolean;

    newticket: CreateContactMailTicketInput = {
        email: '',
        subject: 'Besked fra kontaktformular',
        text: '',
        name: '',
        googleCaptchaResponse: '',
    };

    subscriptions: Subscription[] = [];

    constructor(
        public chatService: ChatService,
        public navigationService: NavigationService,
        private mailticketService: MailticketService,
        private auth: AuthService,
        private router: Router,
        private metaTagsHelper: MetaTagsHelper,
        private siteSettingsService: SiteSettingsService,
        public topbarService: TopbarService
    ) {
        this.metaTagsHelper.setTitleAndMetaTags('META.CONTACT');

        this.subscriptions.push(
            this.chatService.getChatOpenHours7Days().subscribe(
                (data) => {
                    this.chatopenhours7days = data;
                },
                (err) => this.logError(err)
            )
        );

        this.subscriptions.push(
            this.chatService.getIsChatOpen().subscribe(
                (data) => (this.ischatopen = data),
                (err) => this.logError(err)
            )
        );

        this.subscriptions.push(
            this.chatService.getChatOpenHours().subscribe(
                (data) => (this.chatopenhours = data),
                (err) => this.logError(err)
            )
        );
    }

    ngOnInit() {
        // Redirect if user is logged in
        if (this.auth.isAuthenticated()) {
            const goto = this.navigationService.getUrl('selfcare-contact-inbox');
            this.router.navigate([goto]);
        }

        this.siteSettingsService.getBooleanSetting(SiteSettingType.DisplayNextPhoneNumber).subscribe((display) => (this.displayPhoneNumber = display));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    logError(err: string) {
        console.error('There was an error: ' + err);
    }

    handleCaptchaResponse(resp: any) {
        this.newticket.googleCaptchaResponse = resp;
    }

    handleCaptchaExpired() {
        this.newticket.googleCaptchaResponse = '';
    }

    showCallMeModal() {
        this.topbarService.setCallmeModal(true);
    }

    createMailTicket() {
        this.creatingTicket = true;
        this.subscriptions.push(
            this.mailticketService.createMailTicket(this.newticket).subscribe(
                (resp) => {
                    this.creatingTicket = false;

                    if (resp != null && resp.length > 0) {
                        this.createTicketSuccess = true;
                    }
                },
                (err) => {
                    this.creatingTicket = false;
                    this.createTicketError = true;
                }
            )
        );
    }

    openChat() {
        $zopim.livechat.window.show();
    }
}
