import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from 'app/utils/appconfig/app-config.service';
import { Observable } from 'rxjs';
import { RequestReason } from './requestreason';

// ContactRequestReasonController
@Injectable()
export class ContactRequestReasonService {
    private apiUrl: string;

    constructor(private http: HttpClient, private config: AppConfigService) {
        this.apiUrl = this.config.appConfig.apiHost;
    }

    getReasons(): Observable<RequestReason[]> {
        const url = `${this.apiUrl}/contactrequest-reasons?api-version=2.0`;
        return this.http.get<RequestReason[]>(url);
    }
}
