import { AppConfigService } from './../appconfig/app-config.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable()
export class LeadCampaignLandingService {
    private apiUrl: string;

    constructor(private http: HttpClient, private config: AppConfigService) {
        this.apiUrl = this.config.appConfig.apiHost;
    }

    addLeadCampaignLanding(leadCampaignId: string): Observable<boolean> {
        const url = `${this.apiUrl}/lead-campaigns/${leadCampaignId}/landings?api-version=2.0`;
        return this.http.post<void>(url, null, { observe: 'response' }).pipe(map((resp) => resp.status === 200 || resp.status === 204));
    }
}
