import { Component, Input, ViewEncapsulation, AfterViewInit, OnInit } from '@angular/core';

@Component({
    moduleId: module.id,
    selector: 'blog-content',
    templateUrl: './blog-content.component.html',
    styleUrls: ['./blog-content.component.less'],
    encapsulation: ViewEncapsulation.None, // allows us to style contex content
})
export class BlogContentComponent implements AfterViewInit {
    @Input() html: string;
    @Input() created: string;
    @Input() online: string;
    @Input() url: string;
    contentClass = 'content-' + this.GenerateGuid();

    constructor() {}

    ngAfterViewInit(): void {
        const createdDate = new Date(this.created);
        const onlineDate = new Date(this.online);

        document.querySelectorAll('.' + this.contentClass + ' .created').forEach((element) => {
            element.innerHTML = createdDate.toLocaleDateString();
        });

        document.querySelectorAll('.' + this.contentClass + ' .online').forEach((element) => {
            element.innerHTML = onlineDate.toLocaleDateString();
        });

        document.querySelectorAll('.' + this.contentClass + ' .wrap-url').forEach((element) => {
            const linkElement = document.createElement('a');
            linkElement.setAttribute('href', 'blog/' + this.url);
            this.WrapElement(element, linkElement);
        });
    }

    WrapElement(el: Element, wrapper: Element) {
        el.parentNode.insertBefore(wrapper, el);
        wrapper.appendChild(el);
    }

    GenerateGuid(): string {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            // eslint-disable-next-line no-bitwise
            const r = (Math.random() * 16) | 0;
            // eslint-disable-next-line no-bitwise
            const v = c === 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    }
}
