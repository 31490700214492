import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from 'app/utils/appconfig/app-config.service';
import { CoverInfo, CoverPackageInfo } from 'next-typescript-api';
import { Observable } from 'rxjs';

@Injectable()
export class ProductApiService {
    private apiUrl: string;

    constructor(private http: HttpClient, private config: AppConfigService) {
        this.apiUrl = this.config.appConfig.apiHost;
    }

    getCoverPackages(productCode: string): Observable<CoverPackageInfo[]> {
        const url = `${this.apiUrl}/products/${productCode}/cover-packages?api-version=2.0`;
        return this.http.get<CoverPackageInfo[]>(url);
    }

    getCovers(productCode: string): Observable<CoverInfo[]> {
        const url = `${this.apiUrl}/products/${productCode}/covers?api-version=2.0`;
        return this.http.get<CoverInfo[]>(url);
    }
}
