import { ReviewService } from './../../../../utils/review/review.service';
import { Component, OnInit } from '@angular/core';
import { Review } from 'next-typescript-api';

@Component({
    selector: 'trustpilot-quotesbox',
    templateUrl: './trustpilot-quotesbox.component.html',
    styleUrls: ['./trustpilot-quotesbox.component.less'],
})
export class TrustpilotQuotesboxComponent implements OnInit {
    review: Review;
    loading = true;
    constructor(private reviewService: ReviewService) {}

    ngOnInit(): void {
        this.reviewService.getFavoriteReview().subscribe((x) => {
            this.review = x;
            if (this.review) {
                this.loading = false;
            }
        });
    }
}
