import { Component } from '@angular/core';
import { NavigationService } from 'app/utils/navigation/navigation.service';
import { MetaTagsHelper } from 'app/utils/metaTags/metaTagsHelper';

@Component({
    moduleId: module.id,
    selector: 'accidentindex',
    templateUrl: './index.component.html',
})
export class IndexComponent {
    constructor(public navigationService: NavigationService, private metaTagsHelper: MetaTagsHelper) {
        this.metaTagsHelper.setTitleAndMetaTags('META.COVER_ACCIDENT_INDEX');
    }
}
