<link rel="preload" as="image" href="https://static.next.dk/website/images/pages/squeeze/background-streets.jpg" />
<div class="top">
    <a class="nextlogo" [routerLink]="'/'">
        <img width="100" height="39" src="https://static.next.dk/website/images/logos/next_logo_white.svg" alt="NEXT logo" />
    </a>
</div>
<div class="text-center">
    <div class="full-width-image-section front-squeeze">
        <div class="shade-overlay"></div>
        <div class="col-lg-4 col-sm-offset-2 text-left left-box">
            <h1 class="white">{{ 'PAGE.LANDING.SQUEEZEPAGE.TITLE' | translate }}</h1>

            <div class="get-a-quote">{{ 'PAGE.LANDING.SQUEEZEPAGE.GET_A_QUOTE' | translate }}</div>
            <a [routerLink]="navigationService.getUrl('newcustomer')" class="btn btn-success btn-get-quote">
                {{ 'PAGE.LANDING.SQUEEZEPAGE.START_A_QUOTE' | translate }}
            </a>

            <div class="tp-container">
                <trustpilot-mini></trustpilot-mini>
            </div>
        </div>
        <div class="col-lg-4">
            <div class="gray-box">
                <div *ngIf="!createRequestSuccess">
                    <div class="talk-to-agent" (click)="createRequestSuccess = true">{{ 'PAGE.LANDING.SQUEEZEPAGE.TALK_TO_AGENT' | translate }}</div>

                    <div class="input-container">
                        <div class="field-input full-width">
                            <input
                                type="text"
                                id="nameinput"
                                name="nameinput"
                                required
                                [class.missing-input]="!name"
                                [(ngModel)]="name"
                                (ngModelChange)="validate()"
                                (keyup.enter)="sendContactRequest()"
                            />
                            <span class="floating-label">{{ 'SHARED.TOPBAR.COMPONENTS.CALLME_MODAL.NAME' | translate | uppercase }}</span>
                        </div>

                        <div class="field-input full-width">
                            <input
                                type="text"
                                id="phoneinput"
                                name="phoneinput"
                                required
                                OnlyNumber="true"
                                maxlength="8"
                                [class.missing-input]="!phone || phone.length !== 8"
                                [(ngModel)]="phone"
                                (ngModelChange)="validate()"
                                (keyup.enter)="sendContactRequest()"
                            />
                            <span class="floating-label">{{ 'SHARED.TOPBAR.COMPONENTS.CALLME_MODAL.PHONE' | translate | uppercase }}</span>
                        </div>
                        <br />
                        <div class="best-time">{{ 'SHARED.TOPBAR.COMPONENTS.CALLME_MODAL.BEST_TIME' | translate }}</div>

                        <!-- mat select currently not working on fixed container -->
                        <select [(ngModel)]="calltime" [class.missing-input]="!calltime" (ngModelChange)="validate()">
                            <option *ngFor="let obj of calltimes" [ngValue]="obj">
                                {{ obj.text }}
                            </option>
                        </select>
                        <button [disabled]="!okToSend || sending" class="btn btn-success" type="button" (click)="sendContactRequest()" [class.loading]="sending">
                            <span>{{ 'SHARED.TOPBAR.COMPONENTS.CALLME_MODAL.TITLE' | translate }}</span>
                        </button>
                    </div>
                </div>
                <div *ngIf="createRequestSuccess">
                    <div class="thanks">{{ 'SHARED.TOPBAR.COMPONENTS.CALLME_MODAL.THANKS' | translate }}</div>
                    <div class="soon">{{ 'SHARED.TOPBAR.COMPONENTS.CALLME_MODAL.SOON' | translate }}</div>

                    <a class="btn btn-success" [routerLink]="'/'">
                        <span>{{ 'SHARED.OK' | translate }}</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="insurances row">
            <div class="bottom-spacer full">
                <div class="arrow-circle">
                    <i class="nf nf-double-chevron-down-icon-v3"></i>
                </div>
            </div>

            <div class="col-sm-12">
                <div class="see-our-selection">{{ 'PAGE.LANDING.SQUEEZEPAGE.SEE_OUR_SELECTION' | translate }}</div>
                <div class="subtext">
                    {{ 'PAGE.LANDING.SQUEEZEPAGE.WE_OFFER' | translate }}
                </div>
            </div>
        </div>
    </div>
    <product-menu [iconTheme]="'blue'" [scroll]="false"></product-menu>

    <div class="spacer-line">
        <div class="white-space">
            <div class="bottom-spacer full">
                <div class="arrow-circle">
                    <i class="nf nf-double-chevron-down-icon-v3"></i>
                </div>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="why-people-like-next">{{ 'PAGE.LANDING.SQUEEZEPAGE.WHY_PEOPLE_LIKE' | translate }} <span>NEXT</span></div>
                <div class="subtext">{{ 'PAGE.LANDING.SQUEEZEPAGE.DONT_JUST_TAKE' | translate }}</div>

                <div class="quotesbox-container">
                    <trustpilot-quotesbox></trustpilot-quotesbox>
                </div>

                <div class="carousel-container">
                    <trustpilot-carousel-light></trustpilot-carousel-light>
                </div>
            </div>
        </div>
        <div class="row reasons">
            <div class="col-sm-12">
                <div class="why-people-change">{{ 'PAGE.LANDING.SQUEEZEPAGE.WHY_PEOPLE_CHANGE' | translate }}</div>
            </div>
            <div class="col-sm-4">
                <div class="reason-logo">
                    <img src="https://static.next.dk/website/images/pages/squeeze/icon-no-fees.svg" />
                </div>
                <div class="reason-header">{{ 'PAGE.WHY_PEOPLE_CHANGE.NO_FEES_TITLE' | translate }}</div>
                <p>{{ 'PAGE.WHY_PEOPLE_CHANGE.NO_FEES_1' | translate }}</p>
                <p>
                    {{ 'PAGE.WHY_PEOPLE_CHANGE.NO_FEES_2' | translate }}
                </p>
                <p>
                    {{ 'PAGE.WHY_PEOPLE_CHANGE.NO_FEES_3' | translate }}
                </p>
            </div>
            <div class="col-sm-4">
                <div class="reason-logo">
                    <img src="https://static.next.dk/website/images/pages/squeeze/icon-quick-claim-notification.svg" />
                </div>
                <div class="reason-header">{{ 'PAGE.WHY_PEOPLE_CHANGE.EASY_CLAIMS_TITLE' | translate }}</div>
                <p>
                    {{ 'PAGE.WHY_PEOPLE_CHANGE.EASY_CLAIMS_1' | translate }}
                </p>
                <p>
                    {{ 'PAGE.WHY_PEOPLE_CHANGE.EASY_CLAIMS_2' | translate }}
                </p>
            </div>
            <div class="col-sm-4">
                <div class="reason-logo">
                    <img src="https://static.next.dk/website/images/pages/squeeze/icon-customize-your-policy.svg" />
                </div>
                <div class="reason-header">{{ 'PAGE.WHY_PEOPLE_CHANGE.EDIT_TITLE' | translate }}</div>
                <p>
                    {{ 'PAGE.WHY_PEOPLE_CHANGE.EDIT_1' | translate }}
                </p>
                <p>
                    {{ 'PAGE.WHY_PEOPLE_CHANGE.EDIT_2' | translate }}
                </p>
            </div>
        </div>
    </div>
</div>

<next-footer [simple]="true"></next-footer>
