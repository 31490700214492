import { AppConfigService } from 'app/utils/appconfig/app-config.service';
import { Injectable, OnDestroy } from '@angular/core';
import { CalculateService } from 'app/calculate/calculate.service';
import { Moped } from './models/moped';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { BrandItem } from 'next-typescript-api';

@Injectable()
export class MopedService implements OnDestroy {
    public totalSteps = 3;

    public product: Moped;
    product$: Observable<Moped>;

    subscriptions: Subscription[] = [];

    private apiUrl: string;
    private productSource = new BehaviorSubject<Moped>(null);

    constructor(private http: HttpClient, private config: AppConfigService, private calculateService: CalculateService) {
        this.apiUrl = this.config.appConfig.apiHost;
        this.product$ = this.productSource.asObservable();

        this.subscriptions.push(
            this.calculateService.getProductLoadedObserveable('moped').subscribe((loaded) => {
                if (loaded) {
                    if (this.calculateService.loadedProduct != null) {
                        this.product = this.calculateService.loadedProduct;
                        this.productSource.next(this.product);
                    } else {
                        this.product = new Moped();
                        this.productSource.next(this.product);
                    }
                }
            })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    updateProduct(product: Moped): Observable<any> {
        this.product = product;
        return this.calculateService.saveBasket(this.product).pipe(
            tap(() => {
                this.productSource.next(product);
            })
        );
    }

    getUsageString(usage: number): string {
        switch (usage) {
            case 1:
                return 'Lille knallert';
            case 2:
                return 'Stor knallert';
            case 3:
                return 'Knallert uden nummerplade';
        }
        return '';
    }

    getMopedText(): Promise<string> {
        return new Promise((resolve, reject) => {
            let str = '';
            if (this.product.usageCode != null) {
                str += this.getUsageString(this.product.usageCode);
            }

            if (!this.product.brand) {
                resolve(str);
                return;
            }

            this.getBrandText()
                .then((brandName) => {
                    resolve(str + ', ' + brandName);
                })
                .catch((reason) => reject(reason));
        });
    }

    getBrandText(): Promise<string> {
        return new Promise((resolve, reject) => {
            let brandName = '';
            this.getBrands().subscribe(
                (brands) => {
                    const brand = brands.find((b) => b.value === this.product.brand);

                    if (brand) {
                        brandName = brand.name;
                    }

                    resolve(brandName);
                },
                (error) => reject(error)
            );
        });
    }

    getProduct(): Moped {
        return this.product;
    }

    getBrands(): Observable<BrandItem[]> {
        const url = `${this.apiUrl}/insurancedata/moped/brands?api-version=2.0`;
        return this.http.get<BrandItem[]>(url);
    }

    checkStep(step: number): boolean {
        if (this.product == null) {
            return false;
        }

        switch (step) {
            case 1:
                return this.product.usageCode != null && this.product.brand != null && this.product.brand.length > 0;
            case 2:
                return this.product.address.addressId != null && this.product.address.addressId.length > 0;
        }

        return false;
    }

    getHighestStep(): number {
        if (this.checkStep(2)) {
            return 3;
        } else if (this.checkStep(1)) {
            return 2;
        } else {
            return 1;
        }
    }
}
