import { Observable } from 'rxjs';
import { AppConfigService } from 'app/utils/appconfig/app-config.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { CreateMailListSubscriberInput } from 'next-typescript-api';

@Injectable()
export class MailListSubscriberService {
    private apiUrl: string;

    constructor(public http: HttpClient, private config: AppConfigService) {
        this.apiUrl = this.config.appConfig.apiHost;
    }

    subscribeToNewsletter(email: string): Observable<boolean> {
        const url = `${this.apiUrl}/maillist-subscribers/?api-version=2.0`;
        const data: CreateMailListSubscriberInput = {
            email,
            mailListTypeCode: '1',
        };

        return this.http.post(url, data, { observe: 'response' }).pipe(
            map((resp) => {
                const statusCode = resp.status;

                if (statusCode === 200 || statusCode === 204) {
                    return true;
                }

                return false;
            })
        );
    }
}
