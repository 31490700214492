import { Routes } from '@angular/router';
import { IndexComponent } from './components/index/index.component';
import { ContexpageComponent } from '../contexpage/components/contexpage/contexpage.component';
import { CoverInfoComponent } from '../components/cover-info/cover-info.component';
import { CoverInfoSettings } from '../components/cover-info/CoverInfoSettings';

export const homeRoutes: Routes = [
    { path: '', component: IndexComponent },
    { path: 'privat-ansvar', component: CoverInfoComponent, data: new CoverInfoSettings('home', 'liability', 'nf nf-home-privat-ansvar') },
    { path: 'privat-indbo', component: CoverInfoComponent, data: new CoverInfoSettings('home', 'private', 'nf nf-home-ansvar') },
    { path: 'retshjaelpforsikring', component: CoverInfoComponent, data: new CoverInfoSettings('home', 'court', 'nf nf-car-retshjlp') },
    { path: 'rejseforsikring', component: CoverInfoComponent, data: new CoverInfoSettings('home', 'travel', 'nf nf-home-rejseforsikring') },
    { path: 'afbestillingsforsikring', component: CoverInfoComponent, data: new CoverInfoSettings('home', 'cancellation', 'nf nf-home-afbestillingsforsikring') },
    { path: 'id-sikring', component: CoverInfoComponent, data: new CoverInfoSettings('home', 'identity', 'nf nf-home-idsikring') },
    { path: 'psykisk-krisehjaelp', component: CoverInfoComponent, data: new CoverInfoSettings('home', 'counseling', 'nf nf-home-krisehjaelp') },
    { path: 'glas-og-sanitet', component: CoverInfoComponent, data: new CoverInfoSettings('home', 'glass', 'nf nf-house-glas-og-sanitet') },
    { path: 'elektronikforsikring', component: CoverInfoComponent, data: new CoverInfoSettings('home', 'electronic', 'nf nf-home-elektronik-dkning') },
    { path: 'pludselig-skade-forsikring', component: CoverInfoComponent, data: new CoverInfoSettings('home', 'sudden', 'nf nf-home-pludselig-skade') },
    { path: 'udvidet-cykeltyveri', component: CoverInfoComponent, data: new CoverInfoSettings('home', 'biketheft', 'nf nf-plus-icon-v3') },
    // Non cover pages
    { path: 'sumloes-indbo', component: CoverInfoComponent, data: new CoverInfoSettings('home', 'sumless', '') },
    { path: 'flytte-hjemmefra', component: CoverInfoComponent, data: new CoverInfoSettings('home', 'movefromhome', '') },
    { path: 'bor-til-leje', component: CoverInfoComponent, data: new CoverInfoSettings('home', 'renting', '') },
    // Contex Pages
    { path: 'blaa-sygesikring', component: ContexpageComponent },
    { path: 'cykelsikring', component: ContexpageComponent },
    { path: 'rejseforsikringer', component: ContexpageComponent },
    { path: 'rejseforsikring-usa', component: ContexpageComponent },
    { path: 'rejseforsikringer-verden', component: ContexpageComponent },
    { path: 'ski-rejseforsikring', component: ContexpageComponent },
];
