import { Component, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';
import { NavigationService } from 'app/utils/navigation/navigation.service';
import { ContexService } from 'app/utils/contex/contex.service';
import { MetaTagsHelper } from 'app/utils/metaTags/metaTagsHelper';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Params } from '@angular/router';
import { ConsentService } from 'app/utils/consent/consent.service';
import { PageContentDetails } from 'app/utils/contex/panel/pageContentDetails';
import { MitIdService } from 'app/utils/mitId/mit-id.service';

@Component({
    moduleId: module.id,
    selector: 'consent',
    templateUrl: './consent.component.html',
    styleUrls: ['./consent.component.less'],
    encapsulation: ViewEncapsulation.None, // allows us to style contex content
})
export class ConsentComponent implements OnInit, OnDestroy {
    consentType: string;
    consentId: string;
    customerNo: string;
    pageContent: PageContentDetails;
    consentGiven: boolean;
    isLoading = false;
    error: string;
    subscriptions: Subscription[] = [];

    constructor(
        private route: ActivatedRoute,
        public navigationService: NavigationService,
        private contexService: ContexService,
        private metaTagsHelper: MetaTagsHelper,
        private consentService: ConsentService,
        private mitIdService: MitIdService
    ) {
        this.metaTagsHelper.setTitleAndMetaTags('META.CONSENT');
    }

    ngOnInit(): void {
        this.route.params.subscribe((params) => {
            this.customerNo = params['customerNo'];
            this.consentType = params['consentType'];
            this.consentId = params['consentId'];

            this.contexService.getContent('PAGE_CONSENT_' + this.consentType.toUpperCase()).subscribe((content) => {
                this.pageContent = content;
            });

            this.route.queryParams.subscribe((queryParams: Params) => {
                const state = queryParams['state'];
                const code = queryParams['code'];
                const errorParam = queryParams['error'];

                if (errorParam) {
                    const errorDescription = queryParams['error_description'];

                    this.handleError(errorDescription);
                    return;
                }

                if (state && code) {
                    this.isLoading = true;

                    this.consentService.executeSigning(this.customerNo, this.consentId, state, code).subscribe(() => {
                        this.isLoading = false;
                        this.consentGiven = true;
                    }, this.handleError.bind(this));
                } else {
                    this.consentGiven = false;
                }
            });
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    sign() {
        this.isLoading = true;
        this.consentService.getSigningInfo(this.customerNo, this.consentId, window.location.href).subscribe((signingInfo) => {
            this.isLoading = false;
            window.open(signingInfo.body.uri, '_self');
        }, this.handleError.bind(this));
    }

    handleError(error: any) {
        this.error = typeof error === 'object' ? 'En fejl opstod. Prøv igen eller kontakt kundeservice.' : error;
        this.isLoading = false;
    }
}
