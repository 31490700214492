import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthService } from 'app/auth/auth.service';
import { CustomerTaskService } from 'app/selfcare/tasks/customertask.service';
import { MitIdService } from 'app/utils/mitId/mit-id.service';
import { NavigationService } from 'app/utils/navigation/navigation.service';
import { QueryParametersService } from 'app/utils/queryParameters/query-parameters.service';
import { BaxService } from 'app/utils/bax/bax.service';
import { Cookie } from 'ng2-cookies';
import { CustomerOfferService } from 'app/selfcare/tasks/customeroffer.service';

@Component({
    selector: 'mit-id-landing',
    templateUrl: './mit-id-landing.component.html',
    styleUrls: ['./mit-id-landing.component.less'],
})
export class MitIdLandingComponent implements OnInit {
    failedGetTasksAttempts = 0;
    requireSsn = false;
    customerNotFound = false;

    constructor(
        private customerTaskService: CustomerTaskService,
        private router: Router,
        private navService: NavigationService,
        private authService: AuthService,
        private route: ActivatedRoute,
        private mitIdService: MitIdService,
        private baxService: BaxService,
        private queryParametersService: QueryParametersService,
        private customerOfferService: CustomerOfferService
    ) {}

    ngOnInit(): void {
        this.route.queryParams.subscribe((params: Params) => {
            const state = params['state'];
            const code = params['code'];
            const errorParam = params['error'];

            if (errorParam) {
                const errorDescription = params['error_description'];

                this.handleError(errorDescription);
                return;
            }

            if (state && code) {
                this.authService
                    .token('useridentity', `state=${state}&code=${code}`)
                    .then(
                        () => {
                            const redirectUrl = this.mitIdService.getSuccessfulLoginRedirectUrl();

                            // If there is a basket, then we have to handle this according to the customer now authed
                            const basketId = Cookie.get('basketid');
                            if (basketId) {
                                this.baxService.syncBasket(basketId, this.authService.getCustomerId()).then(() => this.handleRedirect(redirectUrl));
                            } else {
                                this.handleRedirect(redirectUrl);
                            }
                        },
                        (error) => {
                            this.handleError(error?.error?.error);
                        }
                    )
                    .finally(() => {
                        this.mitIdService.clearLoginRedirectUrls();
                    });
            } else {
                this.redirectToSelfcareLogin();
                this.mitIdService.clearLoginRedirectUrls();
            }
        });
    }

    handleRedirect(redirectUrl: string) {
        if (redirectUrl) {
            this.router.navigateByUrl(redirectUrl);
        } else {
            this.redirectToSelfcare();
        }
    }

    handleError(error: string) {
        let redirectUrl = this.mitIdService.getFailedLoginRedirectUrl() ?? this.navService.getUrl('selfcare-login');

        // Remove existing error
        if (this.queryParametersService.getQueryVariable('mitIdError')) {
            redirectUrl = this.queryParametersService.removeQueryVariable('mitIdError', redirectUrl);
        }

        if (error) {
            const errorPrefix = redirectUrl.indexOf('?') === -1 ? '?' : '&';
            redirectUrl += errorPrefix + 'mitIdError=' + encodeURIComponent(error);
        }

        this.router.navigateByUrl(redirectUrl);
    }

    redirectToPage(page: string) {
        const url = this.navService.getUrl(page);
        this.router.navigateByUrl(url);
    }

    redirectToSelfcareLogin() {
        this.redirectToPage('selfcare-login');
    }

    redirectToSelfcare() {
        this.customerOfferService.getOffers(false).subscribe((offers) => {
            if (offers?.length > 0) {
                const url = this.navService.getUrl('selfcare-offers');

                this.router.navigateByUrl(url);
            } else {
                this.customerTaskService
                    .getTasks()
                    .toPromise()
                    .then((tasks) => {
                        const urlKey = tasks.length > 0 ? 'selfcare-tasks' : 'selfcare-overview';

                        const url = this.navService.getUrl(urlKey);

                        this.router.navigateByUrl(url);
                    }, this.redirectToSelfcareFailed);
            }
        }, this.redirectToSelfcareFailed);
    }

    redirectToSelfcareFailed() {
        this.failedGetTasksAttempts += 1;

        if (this.failedGetTasksAttempts >= 3) {
            const url = this.navService.getUrl('selfcare-overview');

            this.router.navigateByUrl(url);
            return;
        }

        this.authService.refreshToken().then(() => {
            this.redirectToSelfcare(); // try again
        });
    }
}
