import { TrustpilotModule } from './../shared/trustpilot/trustpilot.module';
import { TopbarModule } from './../shared/topbar/topbar.module';
import { PageLoaderModule } from './../shared/pageloader/pageloader.module';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CarModule } from './car/car.module';
import { HouseModule } from './house/house.module';
import { HomeModule } from './home/home.module';
import { AccidentModule } from './accident/accident.module';
import { DogModule } from './dog/dog.module';
import { CaravanModule } from './caravan/caravan.module';
import { SecondHouseModule } from './secondhouse/secondhouse.module';
import { MopedModule } from './moped/moped.module';
import { NavigationService } from 'app/utils/navigation/navigation.service';
import { ContexService } from 'app/utils/contex/contex.service';
import { InsuranceComponent } from './components/insurance/insurance.component';
import { NicetoknowModule } from './nicetoknow/nicetoknow.module';
import { CustomPipeModule } from 'app/class/pipe/custompipe.module';
import { CoverInfoComponent } from './components/cover-info/cover-info.component';
import { FooterModule } from 'app/shared/footer/footer.module';
import { CalculateButtonModule } from 'app/shared/calculate-button/calculate-button.module';
import { CprValidatorModule } from 'app/shared/cpr-validator/cpr-validator.module';
import { MenuModule } from 'app/shared/menu/menu.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        CarModule,
        HouseModule,
        HomeModule,
        AccidentModule,
        DogModule,
        CaravanModule,
        SecondHouseModule,
        MopedModule,
        RouterModule,
        NicetoknowModule,
        CustomPipeModule,
        FooterModule,
        PageLoaderModule,
        CalculateButtonModule,
        MenuModule,
        TopbarModule,
        TrustpilotModule,
        TranslateModule,
        CprValidatorModule,
    ],
    declarations: [InsuranceComponent, CoverInfoComponent],
    providers: [NavigationService, ContexService],
})
export class InsuranceModule {}
