import { HttpClient } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Language } from 'next-typescript-api';
import { forkJoin, Observable } from 'rxjs';
import { AppConfigService } from '../appconfig/app-config.service';

export interface Locale {
    lang: string;
    data: any;
}

@Injectable()
export class TranslationService {
    private langIds: any = [];

    constructor(private translateService: TranslateService, private http: HttpClient, private config: AppConfigService) {}

    async loadLanguages(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.translateService.addLangs(['da-DK']);
            this.translateService.addLangs(['en-GB']);
            this.translateService.setDefaultLang('da-DK');

            const loadLanguage: Observable<any>[] = [];
            const languageList = this.getLanguages();
            if (!languageList || languageList.length === 0) {
                reject();
            }

            let env: 'dev' | 'prod' = 'dev';
            let urlStart = 'https://stpiwebdata001.blob.core.windows.net/next/translations';
            if (this.config.appConfig.application.environment === 'PROD') {
                env = 'prod';
                urlStart = 'https://static.next.dk/translations';
            }

            // Use random version locally (when the build number isn't set)
            const version = this.config.appConfig.application.build.indexOf('#') === -1 ? this.config.appConfig.application.build : Math.floor(Math.random() * 10000).toString();

            // Language files (.json) are hosted in Azure (stpiwebdata001 - blob containers - next - translations)
            languageList.forEach((lan) => {
                const url = urlStart + '/' + env + '/' + lan + '.json?v=' + version;
                console.log(url);
                loadLanguage.push(this.http.get(url));
            });

            forkJoin(loadLanguage).subscribe((resp) => {
                for (let i = 0; i < resp.length; i++) {
                    const locale: Locale = { lang: languageList[i], data: resp[i] };
                    console.log(locale);
                    this.loadTranslations(locale);
                }
                this.setLanguage(this.getSelectedLanguage());
                resolve();
            });
        });
    }

    /**
     * Load Translation
     *
     * @param args: Locale[]
     */
    loadTranslations(...args: Locale[]): void {
        const locales = [...args];

        locales.forEach((locale) => {
            // use setTranslation() with the third argument set to true
            // to append translations instead of replacing them
            this.translateService.setTranslation(locale.lang, locale.data, true);

            this.langIds.push(locale.lang);
        });

        // add new languages to the list
        this.translateService.addLangs(this.langIds);
    }

    /**
     * Setup language
     *
     * @param lang: any
     */
    setLanguage(lang) {
        if (lang) {
            localStorage.setItem('language', lang);
            this.translateService.use(this.translateService.getDefaultLang());
            this.translateService.use(lang);
        }
    }

    /**
     * Returns all languages
     */
    getLanguages(): string[] {
        return this.translateService.getLangs();
    }

    /**
     * Returns selected language
     */
    getSelectedLanguage(): any {
        return localStorage.getItem('language') || this.translateService.getDefaultLang();
    }

    getSelectedApiLanguage(): Language {
        const language = this.getSelectedLanguage();

        switch (language) {
            case 'da-DK':
                return Language.Danish;
            case 'en-GB':
                return Language.English;
            default:
                return Language.Danish;
        }
    }

    getLanguageChangeEmitter(): EventEmitter<LangChangeEvent> {
        return this.translateService.onLangChange;
    }
}
