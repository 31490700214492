import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[OnlyNumber]',
})
export class OnlyNumberDirective {
    @Input() OnlyNumber: boolean;
    constructor(private el: ElementRef) {}

    @HostListener('keydown', ['$event']) onKeyDown(event) {
        const e = <KeyboardEvent>event;
        if (this.OnlyNumber) {
            if (
                ['Delete', 'Tab', 'Backspace', 'Escape', 'Enter', 'Home', 'End', 'ArrowLeft', 'ArrowRight'].indexOf(e.code) !== -1 ||
                // Allow: Ctrl+A
                (e.code === 'KeyA' && e.ctrlKey === true) ||
                // Allow: Ctrl+C
                (e.code === 'KeyC' && e.ctrlKey === true) ||
                // Allow: Ctrl+X
                (e.code === 'KeyX' && e.ctrlKey === true)
            ) {
                // let it happen, don't do anything
                return;
            }
            // Ensure that it is a number and stop the keypress
            if (e.shiftKey || !/^-?\d+$/.test(e.key)) {
                e.preventDefault();
            }
        }
    }
}
