import { AppConfigService } from './../appconfig/app-config.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Review } from 'next-typescript-api';

@Injectable()
export class ReviewService {
    closedByUser = false;
    private apiUrl: string;

    constructor(private http: HttpClient, private config: AppConfigService) {
        this.apiUrl = this.config.appConfig.apiHost;
    }

    getFavoriteReview(starsImageWidth = 128): Observable<Review> {
        const url = `${this.apiUrl}/reviews/favorite?starsImageWidth=${starsImageWidth}&api-version=2.0`;
        return this.http.get<Review>(url);
    }
}
