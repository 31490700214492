import { MessageService } from './../message/message.service';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';

import { TelemetryService } from './telemetry.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class TelemetryInterceptor implements HttpInterceptor {
    constructor(private messageService: MessageService, private telemetryService: TelemetryService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            tap(
                (event: HttpEvent<any>) => {},
                (err: any) => {
                    if (err instanceof HttpErrorResponse) {
                        // HTTP 400 (bad request) and 409 (conflict) responses must be handled in the view. Ignore Bax 404. Ignore lead campaigns.
                        if (
                            err.status === 200 ||
                            err.status === 400 ||
                            err.status === 409 ||
                            (err.status === 404 && err.url.indexOf('/bax/') !== -1) ||
                            ((err.status === 404 || err.status === 403) && err.url.indexOf('/email-verify') !== -1) ||
                            err.url.indexOf('/lead-campaigns/') !== -1 ||
                            (err.status === 404 && err.url.indexOf('/reuse-payment-agreement-consent/') !== -1)
                        ) {
                            return;
                        }

                        this.messageService.error('Ups! Der skete en fejl. Fejl-id: ' + this.telemetryService.getOperationId());
                    }
                }
            )
        );
    }
}
