import { Component, TemplateRef, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';
import { NavigationService } from 'app/utils/navigation/navigation.service';
import { ContexService } from 'app/utils/contex/contex.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MetaTagsHelper } from 'app/utils/metaTags/metaTagsHelper';
import { Subscription } from 'rxjs';

@Component({
    moduleId: module.id,
    selector: 'aboutnext',
    templateUrl: './aboutnext.component.html',
    styleUrls: ['./aboutnext.component.less'],
    encapsulation: ViewEncapsulation.None, // allows us to style contex content
})
export class AboutNextComponent implements OnInit, OnDestroy {
    bsModalRef: BsModalRef;

    contex: any;
    subscriptions: Subscription[] = [];

    constructor(public navigationService: NavigationService, private contexService: ContexService, private metaTagsHelper: MetaTagsHelper, private modalService: BsModalService) {
        this.metaTagsHelper.setTitleAndMetaTags('META.ABOUT_NEXT');
    }

    ngOnInit(): void {
        const pagecode = this.navigationService.getPathObject('aboutnext').contexPageCode;
        this.subscriptions.push(
            this.contexService.getContent(pagecode).subscribe((x) => {
                this.contex = x;
            })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    openModal(template: TemplateRef<any>) {
        this.bsModalRef = this.modalService.show(template);
    }
}
