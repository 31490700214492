<div class="full-width-image-section newcustomer-top">
    <div class="arrow-up">
        <next-topbar></next-topbar>
        <next-menu></next-menu>

        <div class="container white">
            <div class="row">
                <div class="col-sm-12 text-center">
                    <h1>{{ 'PAGE.NEW_CUSTOMER.TITLE' | translate }}</h1>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="newcustomer container-padding-20-40" data-hub="calculate" data-page="newcustomer">
    <div class="container">
        <div class="row">
            <div class="col-sm-12 text-center">
                <h2>{{ 'PAGE.NEW_CUSTOMER.CALCULATE_ONE_OR_MORE' | translate }}</h2>
            </div>
        </div>

        <div class="row">
            <div class="col-xs-12 text-center">
                <button type="button" class="btn btn-success start-cal-btn" [disabled]="loading" [class.loading]="loading" (click)="startCalculation()">
                    <span>{{ 'PAGE.NEW_CUSTOMER.START_CALCULATION' | translate | uppercase }}</span>
                </button>
                <div class="msg msg-warning" *ngIf="startCalculateInfo">
                    <b>{{ 'PAGE.NEW_CUSTOMER.START_CALCULATION_INFO' | translate }}</b>
                </div>
            </div>
        </div>
        <br />
        <br />
        <br />
        <div class="row">
            <div class="col-sm-4 text-center">
                <div class="insurance-container" (click)="addCar = !addCar; clearError()">
                    <div class="insurance-img img-terms-car">
                        <div class="icon-circle center">
                            <div class="icon-wrap">
                                <i class="nf nf-car-icon-v3"></i>
                            </div>
                        </div>
                    </div>
                    <div class="add-insurance-box">
                        <h2>{{ 'INSURANCE.CAR.PRODUCT' | translate | uppercase }}</h2>
                        <div class="checkbox-input-box-oneline big">
                            <input type="checkbox" [(ngModel)]="addCar" />
                            <label>{{ 'PAGE.NEW_CUSTOMER.ADD_TO_BASKET' | translate }}</label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-4 text-center">
                <div class="insurance-container" (click)="addHouse = !addHouse; clearError()">
                    <div class="insurance-img img-terms-house">
                        <div class="icon-circle center">
                            <div class="icon-wrap">
                                <i class="nf nf-house-icon-v3"></i>
                            </div>
                        </div>
                    </div>
                    <div class="add-insurance-box">
                        <h2>{{ 'INSURANCE.HOUSE.PRODUCT' | translate | uppercase }}</h2>
                        <div class="checkbox-input-box-oneline big">
                            <input type="checkbox" [(ngModel)]="addHouse" />
                            <label>{{ 'PAGE.NEW_CUSTOMER.ADD_TO_BASKET' | translate }}</label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-4 text-center">
                <div class="ribbon-parent insurance-container" (click)="addHome = !addHome; clearError()">
                    <div class="insurance-img img-terms-home">
                        <div class="icon-circle center">
                            <div class="icon-wrap">
                                <i class="nf nf-home-icon-v3"></i>
                            </div>
                        </div>
                    </div>
                    <div class="add-insurance-box">
                        <ribbon [type]="'travel'"></ribbon>
                        <h2>{{ 'INSURANCE.HOME.PRODUCT' | translate | uppercase }}</h2>
                        <div class="checkbox-input-box-oneline big">
                            <input type="checkbox" [(ngModel)]="addHome" />
                            <label>{{ 'PAGE.NEW_CUSTOMER.ADD_TO_BASKET' | translate }}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-4 text-center">
                <div class="insurance-container" (click)="addAccident = !addAccident; clearError()">
                    <div class="insurance-img img-terms-accident">
                        <div class="icon-circle center">
                            <div class="icon-wrap">
                                <i class="nf nf-accident-icon-v3"></i>
                            </div>
                        </div>
                    </div>
                    <div class="add-insurance-box">
                        <h2>{{ 'INSURANCE.ACCIDENT.PRODUCT' | translate | uppercase }}</h2>
                        <div class="checkbox-input-box-oneline big">
                            <input type="checkbox" [(ngModel)]="addAccident" />
                            <label>{{ 'PAGE.NEW_CUSTOMER.ADD_TO_BASKET' | translate }}</label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-4 text-center">
                <div class="insurance-container" (click)="addDog = !addDog; clearError()">
                    <div class="insurance-img img-terms-dog">
                        <div class="icon-circle center">
                            <div class="icon-wrap">
                                <i class="nf nf-dog-icon-v3"></i>
                            </div>
                        </div>
                    </div>
                    <div class="add-insurance-box">
                        <h2>{{ 'INSURANCE.DOG.PRODUCT' | translate | uppercase }}</h2>
                        <div class="checkbox-input-box-oneline big">
                            <input type="checkbox" [(ngModel)]="addDog" />
                            <label>{{ 'PAGE.NEW_CUSTOMER.ADD_TO_BASKET' | translate }}</label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-4 text-center">
                <div class="insurance-container" (click)="addCaravan = !addCaravan; clearError()">
                    <div class="insurance-img img-terms-caravan">
                        <div class="icon-circle center">
                            <div class="icon-wrap">
                                <i class="nf nf-caravan-icon-v3"></i>
                            </div>
                        </div>
                    </div>
                    <div class="add-insurance-box">
                        <h2>{{ 'INSURANCE.CARAVAN.PRODUCT' | translate | uppercase }}</h2>
                        <div class="checkbox-input-box-oneline big">
                            <input type="checkbox" [(ngModel)]="addCaravan" />
                            <label>{{ 'PAGE.NEW_CUSTOMER.ADD_TO_BASKET' | translate }}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-4 text-center">
                <div class="insurance-container" (click)="addSecondHouse = !addSecondHouse; clearError()">
                    <div class="insurance-img img-terms-secondhouse">
                        <div class="icon-circle center">
                            <div class="icon-wrap">
                                <i class="nf nf-secondhouse-icon-v3"></i>
                            </div>
                        </div>
                    </div>
                    <div class="add-insurance-box">
                        <h2>{{ 'INSURANCE.SECONDHOUSE.PRODUCT' | translate | uppercase }}</h2>
                        <div class="checkbox-input-box-oneline big">
                            <input type="checkbox" [(ngModel)]="addSecondHouse" />
                            <label>{{ 'PAGE.NEW_CUSTOMER.ADD_TO_BASKET' | translate }}</label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-4 text-center">
                <div class="insurance-container" (click)="addMoped = !addMoped; clearError()">
                    <div class="insurance-img img-terms-moped">
                        <div class="icon-circle center">
                            <div class="icon-wrap">
                                <i class="nf nf-moped-icon-v3"></i>
                            </div>
                        </div>
                    </div>
                    <div class="add-insurance-box">
                        <h2>{{ 'INSURANCE.MOPED.PRODUCT' | translate | uppercase }}</h2>
                        <div class="checkbox-input-box-oneline big">
                            <input type="checkbox" [(ngModel)]="addMoped" />
                            <label>{{ 'PAGE.NEW_CUSTOMER.ADD_TO_BASKET' | translate }}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-xs-12 text-center">
                <button type="button" class="btn btn-success start-cal-btn" [disabled]="loading" [class.loading]="loading" (click)="startCalculation()">
                    <span>{{ 'PAGE.NEW_CUSTOMER.START_CALCULATION' | translate | uppercase }}</span>
                </button>
                <div class="msg msg-warning" *ngIf="startCalculateInfo">
                    <b class="text-center">{{ 'PAGE.NEW_CUSTOMER.START_CALCULATION_INFO' | translate }}</b>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="bg-gray container container-padding-20-40">
    <div class="row">
        <div class="col-sm-12 text-center">
            <h2>{{ 'PAGE.NEW_CUSTOMER.TO_BE_A_CUSTOMER' | translate }}</h2>
            <img
                class="three-step-img img-responsive"
                src="https://static.next.dk/website/images/pages/newcustomer/steps.png?v=2.0"
                srcset="https://static.next.dk/website/images/pages/newcustomer/steps.png?v=2.0 1x, https://static.next.dk/website/images/pages/newcustomer/steps@2x.png?v=2.0 2x"
            />
            <div class="container steps">
                <div class="col-sm-4">{{ 'PAGE.NEW_CUSTOMER.STEP_1' | translate }}</div>
                <div class="col-sm-4">{{ 'PAGE.NEW_CUSTOMER.STEP_2' | translate }}</div>
                <div class="col-sm-4">{{ 'PAGE.NEW_CUSTOMER.STEP_3' | translate }}</div>
            </div>
        </div>
    </div>
</div>

<expired-basket *ngIf="showExpiredBasketModal" (closeDialog)="showExpiredBasketModal = false"></expired-basket>
<trustpilot-carousel></trustpilot-carousel>
<next-footer></next-footer>
